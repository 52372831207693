import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Nav,
  NavItem,
  Tooltip,
  NavbarBrand,
} from 'reactstrap';
import {
  // FaHospital,
  // FaAmbulance,
  // FaUserNurse,
  // FaHome,
  FaHistory,
  FaSyringe,
} from 'react-icons/fa';
import styled from 'styled-components';

import { resetTelehealth } from 'telehealth/actions/tele';
import { resetBooking } from 'medical-checkup-booking/actions/booking';
import { resetHomecare } from 'homecare/actions/homecare';

import { useWindowSize } from 'app/hooks';
import { toggleSidebar } from 'app/actions/global';
import cuttedLogo from '../assets/images/cutted-logo.png';
import logo from '../assets/images/logo.png';

const menuItems = [
  // {
  //   key: 1,
  //   name: 'KCB tại CSYT',
  //   link: '/medical-checkup-booking',
  //   icon: <FaHospital size={25} className="my-2" />,
  // },
  // {
  //   key: 2,
  //   name: 'Cấp cứu tại chỗ',
  //   link: '/emergency',
  //   icon: <FaAmbulance size={25} className="my-2" />,
  // },
  // {
  //   key: 3,
  //   name: 'Chữa bệnh tại nhà',
  //   link: '/homecare',
  //   icon: <FaHome size={25} className="my-2" />,
  // },
  // {
  //   key: 4,
  //   name: 'Tư vấn sức khỏe từ xa',
  //   link: '/telehealth',
  //   icon: <FaUserNurse size={25} className="my-2" />,
  // },
  {
    key: 5,
    name: 'Tiêm chủng',
    link: '/immunization',
    icon: <FaSyringe size={25} className="my-2" />,
  },
  {
    key: 6,
    name: 'Lịch sử',
    link: '/history',
    icon: <FaHistory size={25} className="my-2" />,
  },
];

const handleMinWidth = (props) => {
  if (props.sidebarcollapsed && props.size > 515) {
    return '300px';
  }
  if (props.sidebarcollapsed && props.size < 515) {
    return '233px';
  }
  if (!props.sidebarcollapsed && props.size > 515) {
    return '80px';
  }
  return '0';
};

const handlePadding = (props) => {
  if (props.sidebarcollapsed && props.size > 515) {
    return '8px 16px';
  }
  if (props.sidebarcollapsed && props.size < 515) {
    return '5px';
  }
  return '0 8px';
};

const handleOpacity = (props) => {
  if (props.sidebarcollapsed === 0 && props.size < 515) {
    return '0';
  }
  return '1';
};

const handleMargin = (props) => {
  if (props.sidebarcollapsed === 0 && props.size < 515) {
    return '-80px';
  }
  return '0';
};

const StyledNav = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  min-width: 80px;
  max-width: 80px;
  min-height: 100%;
  transition: all 0.3s ease-in-out;
  background-color: rgb(38, 149, 167);
  text-align: center;
  padding: 16px 12px !important;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  min-width: ${(props) => handleMinWidth(props)};
  opacity: ${(props) => handleOpacity(props)};
  margin-left: ${(props) => handleMargin(props)};
`;

const StyledNavLink = styled.div`
  text-decoration: none;
  color: white;
  width: 100%;
  text-align: ${(props) => (props.sidebarcollapsed === 0 ? 'center' : 'left')};
  padding: ${(props) => handlePadding(props)};
  transition: all 0.3s ease-out;
  border-radius: .65rem;
  background: ${(props) => (props.active ? 'hsla(0, 0%, 100%, 0.15);' : '')};
  opacity: ${(props) => (props.active ? '1' : '.7')};
  border: ${(props) => `2px solid ${(props.active ? 'hsla(0, 0%, 100%, 0.15)' : 'transparent')}`};
  cursor: pointer;

  &:hover {
    background: hsla(0, 0%, 100%, 0.15);
    opacity: 1;
  }

`;

const StyledNavItem = styled(NavItem)`
  position: relative;
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  left: 15px;
  top: 0;
  transform: translate(-50%, -50%);
  display: ${(props) => (props.sidebarcollapsed === 0 ? 'block' : 'none')};
`;

const StyledCuttedLogo = styled.img`
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: ${(props) => (props.sidebarcollapsed === 0 ? 'block' : 'none')};
`;

const StyledLogo = styled.img`
  height: 70px;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: ${(props) => (props.sidebarcollapsed === 0 ? 'none' : 'block')};
`;

const StyledNavbarBrand = styled(NavbarBrand)`
  position: relative;
  margin-right: 0;
  padding-bottom: 20px;
  height: 100px;
`;

const StyledSpan = styled.span`
  transition: all 0.3s ease-in-out;
  display: ${(props) => (props.sidebarcollapsed === 0 ? 'none' : 'block')};
  opacity: ${(props) => (props.sidebarcollapsed === 0 ? '0' : '1')};
`;


const Sidebar = (props) => {
  const windowSize = useWindowSize();
  const { history } = props;
  const [tooltipOpen, setTooltipOpen] = useState(0);
  const { sidebarCollapsed } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const resetState = () => {
    dispatch(resetBooking());
    dispatch(resetTelehealth());
    dispatch(resetHomecare());
  };

  const [selectedMenuItem, setSelectedMenuItem] = useState('0');
  useEffect(() => {
    if (history.location.pathname !== '/home') {
      const currentItem = menuItems.find((i) => i.link === history.location.pathname);
      setSelectedMenuItem(currentItem ? currentItem.key : 0);
    }
  }, [history.location.pathname]);

  return (
    <StyledNav
      sidebarcollapsed={sidebarCollapsed ? 0 : 1}
      size={windowSize.width.toString()}
    >
      <Nav vertical>
        <StyledNavbarBrand>
          <StyledCuttedLogo
            sidebarcollapsed={sidebarCollapsed ? 0 : 1}
            cuttedLogo
            src={cuttedLogo}
            alt="logo"
          />
          <StyledLogo
            sidebarcollapsed={sidebarCollapsed ? 0 : 1}
            logo
            src={logo}
            alt="logo"
          />
        </StyledNavbarBrand>
        {menuItems.map((i) => (
          <StyledNavItem
            key={i.key}
            className="my-1"
            onClick={() => {
              if (!sidebarCollapsed) {
                dispatch(toggleSidebar(!sidebarCollapsed));
              }
              history.push(i.link);
              resetState();
            }}
          >
            <StyledNavLink
              sidebarcollapsed={sidebarCollapsed ? 0 : 1}
              size={windowSize.width.toString()}
              active={selectedMenuItem === i.key}
              id={`tooltip-${i.key}`}
            >
              <StyledSpan sidebarcollapsed={!sidebarCollapsed ? 0 : 1}>
                {i.icon}
              </StyledSpan>
              <StyledSpan
                sidebarcollapsed={sidebarCollapsed ? 0 : 1}
                className="ml-2"
              >
                {i.icon}
                <span className="ml-2">{i.name}</span>
              </StyledSpan>
            </StyledNavLink>
            <StyledTooltip
              placement="right"
              isOpen={tooltipOpen === i.key}
              target={`tooltip-${i.key}`}
              toggle={() => setTooltipOpen(tooltipOpen === i.key ? 0 : i.key)}
              sidebarcollapsed={sidebarCollapsed ? 0 : 1}
            >
              {i.name}
            </StyledTooltip>
          </StyledNavItem>
        ))}
      </Nav>
    </StyledNav>
  );
};

Sidebar.propTypes = {
  /** react-router-dom history object */
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(Sidebar);
