import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  CustomInput,
  Table,
  Button,
  Spinner,
  Alert,
} from 'reactstrap';
import Select from 'react-select';

import moment from 'moment';
import { formatDate, parseDate } from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import nations from 'app/mock/nationsV2.json';
import locations from 'app/mock/locationsV2.json';
import makeAnimated from 'react-select/animated';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateProfileV2,
} from 'app/actions/global';

const StyledSwitch = styled(CustomInput)`
  display: inline;
`;

const genderOptions = [
  { key: 1, value: 1, label: 'Nam' },
  { key: 0, value: 0, label: 'Nữ' },
];

const symptoms = [
  'Sốt',
  'Ho',
  'Khó thở',
  'Đau họng',
  'Nôn/buồn nôn ',
  'Tiêu chảy',
  'Xuất huyết ngoài da',
  'Nổi ban ngoài da',
];

const history = [
  'Đến trang trại chăn nuôi/ chợ buôn bán động vật sống/ cơ sở giết mổ động vật/ tiếp xúc động vật',
  'Trực tiếp chăm sóc người bệnh truyền nhiễm',
];

const visitedLocations = [
  'Bệnh viện C Đà Nẵng Bệnh viện Đa Khoa Đà Nẵng ',
  'BV chỉnh hình và phục hồi chức năng Đà Nẵng ',
  'Xe khách của nhà xe Thanh Hường (Chuyến 15h, ngày 17/7/2020: Đà Nẵng – Quảng Ngãi/Chuyến 3h, 20/7/2020: Quảng Ngãi – Đà nẵng) ',
  'Chuyến tàu số hiệu SE7 (chuyến 23h, 21/7/2020: Đà Nẵng – Quảng Ngãi)',
  'Chợ đầu mối Hòa Cường (Buổi sáng các ngày 8-12/7/2020 – ĐC: 65 Lê Nổ, Hòa Cường Nam, Hải Châu, Đà Nẵng) ',
  'Quán bún Cô Nở (sáng 18/7/2020 – ĐC: đường Nguyễn Thụy, TP Quảng Ngãi)',
  'Hẻm 1 Nguyễn Thông, Quảng Phú, TP Quảng Ngãi (dự Tân gia ngày 19/7/2020)',
  'Nhà Văn hóa Lao Động (20-21h, 23/7/2020 đường Hùng Vương, TP Quảng Ngãi)',
  'Intercontinental Đà Nẵng, Sơn Trà, Đà Nẵng (17-18/7 và chiều 24/7) ',
  'Nhà hàng Bảy Ban, Bãi Rạn, đường Hoàng Sa, Thọ Quang, Sơn Trà (17h30-20h ngày 17/7/20) ',
  'Quán Lẩu và Nướng Phúc Tửu quán (366 Đống Đa, Thanh Bình, Hải Châu, Đà Nẵng: 16-17h 24/7/20) ',
  'Sân cầu lông Thanh Khê Đông (đường Đỗ Ngọc Du, Thanh Khê Đông, Thanh Khê Đà Nẵng: 14-16h ngày 19/7/20) ',
  'Quan bún trên vỉa hè, đối diện 152 Lê Duẩn, Thạch Thang, Hải Châu, Đà Nẵng (các buổi sáng ngày 20-25/7/2020) ',
  'Cà phê Lối Cũ (7 Hải Phòng, Thạch Thang, Hải Châu, Đà Nẵng: 7h-7h15 ngày 22/7/2020) ',
  'Domen coffee house (89 Nguyễn Trác,, Hòa Cường Bắc, Hải Châu, Đà Nẵng: chiều 23/7/2020) ',
  'Chùa Pháp Hội (69 Nguyễn Văn Thoại, An Hải Đông, Sơn Trà, Đà Nẵng: ngày 18 và 25/7/2020) ',
  'Quán trên vỉa hè (đối diện 40 Đinh Tiền Hoàng, Thanh Bình, Hải Châu, Đà Nẵng: sáng 25/7/2020) ',
  'Coffee Highland (203 Ông Ích Khiêm, Tân Chính, Thanh Khê, Đà Nẵng: 19h-20h30 ngày 24/7/2020) ',
  'Limousine Café (419 Nguyễn Tất Thành, Thanh Bình, Hải Châu, Đà Nẵng: sáng 20-23/7/2020) ',
  'Bệnh viện 199- Bộ Công An (216 Nguyễn Công Trứ, An Hải Bắc, Sơn Trà, Đà Nẵng: chiều 26/7/2020) ',
  'Trung tâm Y tế quận Sơn Trà (118 Ngô Quyền, An Hải Bắc, Sơn Trà, Đà Nẵng: chiều 26/7/2020) ',
  'Nhà hàng khách sạn Công Đoàn (số 2 Ông Ích Khiêm, Thanh Bình, Hải Châu, Đà Nẵng: trưa 257/2020) ',
  'Chợ An Hải Đông (54 Lế Hữu Trác, An Hải Đông, Sơn Trà, Đà Nẵng: ngày 20-24/7/2020) ',
  'Chợ tự phát (dọc đường Hải Phòng, Thanh Khê, Đà Nẵng: ngày 20-25/7/2020) ',
  'Chợ Ái Nghĩa (Ái Nghĩa, Đại Lộc: ngày 22/7/2020) ',
  'Bệnh viện Đa Khoa Vĩnh Đức (Khối 8A, Điện Bàn: ngày 25/7) ',
  'Chùa Giác Nguyên (khu 2, Ái Nghĩa, Đại Lộc: tối 24/7, sáng 25/7) ',
];

const handleKeyUp = (e, setDate) => {
  const inputValue = e.target.value;
  if (inputValue) {
    setDate(null);
  }
  const d = inputValue.replace(/\D/g, '').slice(0, 10);
  if (d.length >= 5) {
    const stringResult = `${d.slice(0, 2)}-${d.slice(2, 4)}-${d.slice(4)}`;
    setDate(stringResult);
  } else if (d.length >= 3) {
    setDate(`${d.slice(0, 2)}-${d.slice(2)}`);
  } else {
    setDate(d);
  }
};

const animatedComponents = makeAnimated();

const HealthDeclarationForm = (props) => {
  const {
    isCreateToExamination,
    onSubmit,
    patient,
  } = props;
  const [fullname, setFullname] = useState(patient?.fullname ?? '');
  const [gender, setGender] = useState(patient?.gender ? 1 : 0);
  const [national, setNational] = useState('vn');
  const [dob, setDob] = useState(patient?.dateOfBirth ? moment(patient?.dateOfBirth).format('YYYY') : '');
  const [cmnd, setCmnd] = useState(patient?.IC ?? '');
  const [room, setRoom] = useState('');
  const [floor, setFloor] = useState('');
  const [block, setBlock] = useState('');
  const [houseNumber, setHouseNumber] = useState(patient?.address ?? '');
  const [street, setStreet] = useState('');
  const [smallQuarter, setSmallQuarter] = useState('');
  const [quarter, setQuarter] = useState('');
  const [phone, setPhone] = useState(patient?.phone ?? '');
  const [email, setEmail] = useState(patient?.email ?? '');
  const [isOtherPlace, setIsOtherPlace] = useState(false);
  const [isVisited, setIsVisited] = useState(false);
  const [otherPlaces, setOtherPlaces] = useState([]);
  const [fromVisitedDate, setFromVisitedDate] = useState(null);
  const [toVisitedDate, setToVisitedDate] = useState(null);
  const [transport, setTransport] = useState('');
  const [numberOfTransport, setNumberOfTransport] = useState('');
  const [seatNumber, setSeatNumber] = useState('');
  const [departDate, setDepartDate] = useState(null);
  const [visitedPlaces, setVisitedPlaces] = useState([]);
  const [haveOtherVisitedPlace, setHaveOtherVisitedPlace] = useState(false);
  const [otherVisitedPlace, setOtherVisitedPlace] = useState('');
  const [isContactWithSubject, setIsContactWithSubject] = useState(false);
  const [haveSymptom, setHaveSymptom] = useState([]);
  const [medicines, setMedicines] = useState('');
  const [exposedHistory, setExposedHistory] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({
    province: patient?.province && (locations.find((p) => p.value === patient?.province) || null),
    district: patient?.district && (locations.find((p) => p.value === patient?.province).districts.find((d) => d.value === patient?.district) || null),
    ward: patient?.ward && (locations.find((p) => p.value === patient?.province).districts.find((d) => d.value === patient?.district).wards.find((w) => w.value === patient?.ward) || null),
  });

  const [loadingEditInfo, setLoadingEditInfo] = useState(false);
  const [updateFailed, setUpdateFailed] = useState(false);
  const { loadingCreateCustomer } = useSelector((state) => state.global);

  const dispatch = useDispatch();
  // const [loadingGetRelativeInfo, setLoadingGetRelativeInfo] = useState(false);

  // const getNewCustomerInfo = (d, allowToTakeExam, id) => {
  //   setLoadingGetRelativeInfo(true);
  //   httpClient.callApi({
  //     method: 'GET',
  //     url: apiLinks.getBkCustomer + id,
  //   }).then((response) => {
  //     setLoadingGetRelativeInfo(false);
  //     onSubmit({ d, allowToTakeExam, newCustomer: response.data });
  //   });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      ...patient,
      fullname,
      phone,
      email,
      address: houseNumber,
      dateOfBirth: moment(dob).format('YYYY-MM-DD'),
      gender: gender === 1,
      province: selectedLocation?.province?.value ?? '',
      district: selectedLocation?.district?.value ?? '',
      ward: selectedLocation?.ward?.value ?? '',
      ic: cmnd,
    };
    const d = {
      national,
      cmnd,
      room,
      floor,
      block,
      houseNumber,
      street,
      smallQuarter,
      quarter,
      isOtherPlace,
      isVisited,
      otherPlaces,
      fromVisitedDate,
      toVisitedDate,
      transport,
      numberOfTransport,
      seatNumber,
      departDate,
      visitedPlaces,
      haveOtherVisitedPlace,
      otherVisitedPlace,
      isContactWithSubject,
      haveSymptom,
      medicines,
      exposedHistory,
    };

    const allowToTakeExam = isOtherPlace
    || isVisited
    || isContactWithSubject
    || otherPlaces.length > 0
    || haveSymptom.length > 0
    || visitedPlaces.length > 0
    || exposedHistory.length > 0;

    if (isCreateToExamination) {
      const creatingData = {
        ...data,
        national,
        cmnd,
        room,
        floor,
        block,
        houseNumber,
        street,
        smallQuarter,
        quarter,
      };
      onSubmit({ creatingData, allowToTakeExam });
    } else {
      setLoadingEditInfo(true);
      dispatch(updateProfileV2(data))
        .then(() => {
          setUpdateFailed(false);
          setLoadingEditInfo(false);
          onSubmit({
            d,
            allowToTakeExam,
          });
        })
        .catch(() => {
          setUpdateFailed(true);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row form>
        <Col>
          <FormGroup>
            <Label for="name" className="font-weight-bold">
              1. Họ tên (viết chữ in hoa)
            </Label>
            <Input
              required
              name="name"
              id="name"
              value={fullname}
              onChange={(e) => {
                setFullname(e.target.value);
              }}
              onBlur={() => setFullname(fullname.toUpperCase())}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Label for="dob" className="font-weight-bold">
              2. Năm sinh
            </Label>
            <Input
              id="dob"
              name="dob"
              type="number"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="gender" className="font-weight-bold">
              Giới tính
            </Label>
            <Select
              required
              defaultValue={gender === null ? null : { label: gender ? 'Nam' : 'Nữ', value: gender ? 1 : 0 }}
              placeholder=""
              options={genderOptions}
              onChange={(e) => {
                setGender(e.value);
              }}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="national" className="font-weight-bold">
              Quốc tịch
            </Label>
            <Select
              required
              defaultValue={{ label: 'Việt Nam', value: 'vn' }}
              placeholder=""
              options={nations.map((n) => ({
                key: n.countryCode,
                label: n.name,
                value: n.countryCode,
              }))}
              onChange={(e) => {
                setNational(e.value);
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Label for="cmnd" className="font-weight-bold">
              3. Số CMND hoặc Hộ chiếu
            </Label>
            <Input
              name="cmnd"
              id="cmnd"
              value={cmnd}
              onChange={(e) => {
                setCmnd(e.target.value);
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <b>4. Địa chỉ liên lạc tại thành phố Hồ Chí Minh</b>
      <Row form>
        <Col>
          <Label for="room">Phòng</Label>
          <Input
            id="room"
            name="room"
            value={room}
            onChange={(e) => setRoom(e.target.value)}
          />
        </Col>
        <Col>
          <Label for="floor">Tầng</Label>
          <Input
            id="floor"
            name="floor"
            value={floor}
            onChange={(e) => setFloor(e.target.value)}
          />
        </Col>
        <Col>
          <Label for="block">Lô/Khu/Block</Label>
          <Input
            id="block"
            name="block"
            value={block}
            onChange={(e) => setBlock(e.target.value)}
          />
        </Col>
      </Row>
      <Row form className="mt-2">
        <Col>
          <Label for="houseNumber">Số nhà</Label>
          <Input
            id="houseNumber"
            name="houseNumber"
            value={houseNumber}
            onChange={(e) => setHouseNumber(e.target.value)}
          />
        </Col>
        <Col>
          <Label for="street">Đường phố</Label>
          <Input
            id="street"
            name="street"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
        </Col>
      </Row>
      <Row form className="mt-2">
        <Col>
          <Label for="smallQuarter">Tổ</Label>
          <Input
            id="smallQuarter"
            name="smallQuarter"
            value={smallQuarter}
            onChange={(e) => setSmallQuarter(e.target.value)}
          />
        </Col>
        <Col>
          <Label for="quarter">Khu phố/ấp/thôn</Label>
          <Input
            id="quarter"
            name="quarter"
            value={quarter}
            onChange={(e) => setQuarter(e.target.value)}
          />
        </Col>
      </Row>
      <Row form className="mt-2">
        <Col>
          <FormGroup>
            <Label for="province">Tỉnh/Thành</Label>
            <Select
              required
              isSearchable
              placeholder=""
              value={{
                value: selectedLocation?.province?.value,
                label: selectedLocation?.province?.label,
              }}
              noOptionsMessage={() => <span>Không tìm thấy</span>}
              options={locations.map((province) => ({
                value: province.value,
                label: province.label,
              }))}
              onChange={(el) => setSelectedLocation({
                province: locations.find(
                  (province) => province.value === el.value,
                ),
                district: null,
                ward: null,
              })}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="district">Quận/Huyện</Label>
            <Select
              isSearchable
              required
              placeholder=""
              isDisabled={!selectedLocation.province}
              value={selectedLocation?.district?.value ? {
                value: selectedLocation?.district?.value,
                label: selectedLocation?.district?.label,
              } : null}
              noOptionsMessage={() => <span>Không tìm thấy</span>}
              onChange={(el) => setSelectedLocation({
                ...selectedLocation,
                district: selectedLocation.province.districts.find(
                  (district) => district.value === el.value,
                ),
                ward: null,
              })}
              options={selectedLocation.province
                && selectedLocation.province.districts.map((district) => ({
                  value: district.value,
                  label: district.label,
                }))}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="ward">Phường/Xã</Label>
            <Select
              required
              isSearchable
              placeholder=""
              value={selectedLocation?.ward?.value ? {
                value: selectedLocation?.ward?.value,
                label: selectedLocation?.ward?.label,
              } : null}
              noOptionsMessage={() => <span>Không tìm thấy</span>}
              isDisabled={!selectedLocation.district}
              onChange={(el) => setSelectedLocation({
                ...selectedLocation,
                ward: selectedLocation.district.wards.find(
                  (ward) => ward.value === el.value,
                ),
              })}
              options={selectedLocation.district
                && selectedLocation.district.wards.map((ward) => ({
                  value: ward.value,
                  label: ward.label,
                }))}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Label for="phone">Điện thoại</Label>
            <Input
              type="number"
              name="phone"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <b>
        5. Trong vòng 14 ngày qua, Anh/Chị có đến quốc gia/vùng lãnh thổ nào
        không?
      </b>
      <Row form>
        <Col>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                checked={!isOtherPlace}
                name="otherPlace"
                onChange={() => setIsOtherPlace(false)}
              />
              Không
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                checked={isOtherPlace}
                name="otherPlace"
                onChange={() => setIsOtherPlace(true)}
              />
              Có
            </Label>
            <FormGroup>
              <Label for="national">Quốc gia/vùng lãnh thổ anh (chị) đến</Label>
              <Select
                isMulti
                isClearable
                isSearchable
                isDisabled={!isOtherPlace}
                components={animatedComponents}
                required
                placeholder=""
                noOptionsMessage={() => <span>Không tìm thấy</span>}
                options={nations.map((n) => ({
                  key: n.countryCode,
                  label: n.name,
                  value: n.countryCode,
                }))}
                onChange={setOtherPlaces}
              />
            </FormGroup>
          </FormGroup>
        </Col>
      </Row>
      <b>
        6. Từ ngày 01/7/2020 đến nay, anh (chị) có đi/đến/ở thành phố Đà Nẵng
        không?
      </b>
      <Row form>
        <Col>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                checked={!isVisited}
                name="isVisited"
                onChange={() => setIsVisited(false)}
              />
              Không
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                checked={isVisited}
                name="isVisited"
                onChange={() => setIsVisited(true)}
              />
              Có
            </Label>
          </FormGroup>
        </Col>
      </Row>
      {isVisited && (
      <>
        <Row form>
          <Col xs="12">
            <Label>- Thời gian anh (chị) đi/đến/ở Đà Nẵng: </Label>
          </Col>
          <Col>
            <FormGroup>
              <DayPickerInput
                value={fromVisitedDate}
                style={{ width: '100%' }}
                placeholder="DD-MM-YYYY"
                format="DD-MM-YYYY"
                parseDate={parseDate}
                formatDate={formatDate}
                dayPickerProps={{
                  modifiers: {
                    disabled: [
                      {
                        after: new Date(),
                      },
                    ],
                  },
                }}
                inputProps={{
                  className: 'form-control',
                  maxLength: 10,
                  onKeyUp: (e) => handleKeyUp(e, setFromVisitedDate),
                }}
                onDayChange={(date) => {
                  if (date) {
                    setFromVisitedDate(date);
                  }
                }}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <DayPickerInput
                id="toVisitedDate"
                name="toVisitedDate"
                style={{ width: '100%' }}
                value={toVisitedDate}
                format="DD-MM-YYYY"
                placeholder="DD-MM-YYYY"
                parseDate={parseDate}
                formatDate={formatDate}
                inputProps={{
                  className: 'form-control',
                  maxLength: 10,
                  onKeyUp: (e) => handleKeyUp(e, setToVisitedDate),
                }}
                onDayChange={(date) => {
                  if (date) {
                    setToVisitedDate(date);
                  }
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col xs="12">
            <Label>- Thông tin đi từ TP Đà Nẵng đến TP. Hồ chí Minh: </Label>
          </Col>
          <Col>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  checked={transport === 'Tàu bay'}
                  name="transport"
                  onChange={() => setTransport('Tàu bay')}
                />
                Tàu bay
              </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  checked={transport === 'Tàu thuyền'}
                  name="transport"
                  onChange={() => setTransport('Tàu thuyền')}
                />
                Tàu thuyền
              </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  checked={transport === 'Ô tô'}
                  name="transport"
                  onChange={() => setTransport('Ô tô')}
                />
                Ô tô
              </Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  checked={
                    !(
                      transport === 'Ô tô'
                      || transport === 'Tàu bay'
                      || transport === 'Tàu thuyền'
                    )
                  }
                  name="transport"
                  onChange={() => setTransport('Khác')}
                />
                Khác
              </Label>
            </FormGroup>
            <Input
              disabled={
                transport === 'Ô tô'
                || transport === 'Tàu bay'
                || transport === 'Tàu thuyền'
              }
              name="otherTransport"
              id="otherTransport"
              onChange={(e) => setTransport(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label for="numberOfTransport">Số hiệu phương tiện</Label>
            <Input
              id="numberOfTransport"
              name="numberOfTransport"
              value={numberOfTransport}
              onChange={(e) => setNumberOfTransport(e.target.value)}
            />
          </Col>
          <Col>
            <Label for="seatNumber">Số ghế</Label>
            <Input
              id="seatNumber"
              name="seatNumber"
              value={seatNumber}
              onChange={(e) => setSeatNumber(e.target.value)}
            />
          </Col>
        </Row>
        <Row form className="mt-2">
          <Col>
            <Label for="departDate">Ngày khởi hành</Label>
            <DayPickerInput
              id="departDate"
              name="departDate"
              style={{ width: '100%' }}
              value={departDate}
              format="DD-MM-YYYY"
              placeholder="DD-MM-YYYY"
              parseDate={parseDate}
              formatDate={formatDate}
              inputProps={{
                className: 'form-control',
                maxLength: 10,
                onKeyUp: (e) => handleKeyUp(e, setDepartDate),
              }}
              onDayChange={(date) => {
                if (date) {
                  setDepartDate(date);
                }
              }}
            />
          </Col>
        </Row>
        <Row form className="mt-2">
          <Col xs="12">- Anh (chị) đi/đến/ở nơi nào của Đà nẵng</Col>
          {visitedLocations.map((l) => (
            <React.Fragment key={l}>
              <Col xs="11">
                <Label for={l}>
                  +
                  {' '}
                  {l}
                </Label>
              </Col>
              <Col xs="1" className="text-right">
                <StyledSwitch
                  type="switch"
                  id={l}
                  name={l}
                  className="ml-2"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setVisitedPlaces([...visitedPlaces, l]);
                    } else {
                      setVisitedPlaces(visitedPlaces.filter((s) => s !== l));
                    }
                  }}
                />
              </Col>
            </React.Fragment>
          ))}
          <Col xs="11">
            <Label for="otherVisitedPlace">+ Những nơi khác Bộ Y tế thông báo, ghi rõ:</Label>
          </Col>
          <Col xs="1" className="text-right">
            <StyledSwitch
              type="switch"
              id="otherVisitedPlace"
              name="otherVisitedPlace"
              value={otherVisitedPlace}
              className="ml-2"
              onChange={() => { setHaveOtherVisitedPlace(!haveOtherVisitedPlace); }}
            />
          </Col>
          <Col>
            <Input id="otherVisitedPlace" name="otherVisitedPlace" onChange={(e) => setOtherVisitedPlace(e.target.value)} />
          </Col>
        </Row>
      </>
      )}
      <b>7. Anh/chị có tiếp xúc/chăm sóc…với các các ca bệnh hoặc nghi ngờ Covid-19 không?</b>
      <Row form>
        <Col>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                checked={!isContactWithSubject}
                name="isContactWithSubject"
                onChange={() => setIsContactWithSubject(false)}
              />
              Không
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                checked={isContactWithSubject}
                name="isContactWithSubject"
                onChange={() => setIsContactWithSubject(true)}
              />
              Có
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <b>8. Trong vòng 14 ngày, Anh/Chị có thấy xuất hiện dấu hiệu nào sau đây không?</b>
      <Table hover size="sm" className="mb-0">
        <thead>
          <tr>
            <th>Triệu chứng</th>
            <th className="text-right">Trả lời</th>
          </tr>
        </thead>
        <tbody>
          {symptoms.map((s) => (
            <tr key={s}>
              <td>
                <Label for={s}>{s}</Label>
              </td>
              <td className="text-right">
                <StyledSwitch
                  type="switch"
                  id={s}
                  name={s}
                  className="ml-2"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setHaveSymptom([
                        ...haveSymptom,
                        s,
                      ]);
                    } else {
                      setHaveSymptom(haveSymptom.filter((st) => st !== s));
                    }
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row form className="mb-2">
        <Col>
          <Label className="font-weight-bold" for="medicines">
            Liệt kê tên vắc xin hoặc sinh phẩm y tế đã sử dụng
          </Label>
          <Input
            id="medicines"
            name="medicines"
            value={medicines}
            onChange={(e) => setMedicines(e.target.value)}
          />
        </Col>
      </Row>
      <b>Lịch sử phơi nhiễm: Trong vòng 14 ngày qua, Anh/Chị có:</b>
      <Table hover size="sm" className="mb-0">
        <tbody>
          {history.map((h) => (
            <tr key={h}>
              <td>
                <Label for={h}>{h}</Label>
              </td>
              <td className="text-right">
                <StyledSwitch
                  type="switch"
                  id={h}
                  name={h}
                  className="ml-2"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setExposedHistory([
                        ...exposedHistory,
                        h,
                      ]);
                    } else {
                      setExposedHistory(exposedHistory.filter((s) => s !== h));
                    }
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Col sm={12} className="px-0">
        <b>
          Tôi cam kết những thông tin trên là đúng sự thật, tôi hiểu rằng
          nếu cung cấp sai thông tin có thể dẫn đến những hậu quả nghiêm
          trọng.
        </b>
      </Col>
      <Col sm={12} className="text-center mt-3">
        {loadingEditInfo
          // || loadingGetRelativeInfo
          || loadingCreateCustomer ? (
            <Spinner color="info" />
          ) : (
            <Button color="success" disabled={updateFailed || !fullname || !dob}>
              Xác nhận
            </Button>
          )}
      </Col>
      {updateFailed && (
        <Col xs="12" className="d-flex justify-content-center mt-3">
          <Alert color="danger">
            Chưa đủ thông tin hoặc thông tin chưa chính xác!
          </Alert>
        </Col>
      )}
    </Form>
  );
};

HealthDeclarationForm.propTypes = {
  isCreateToExamination: PropTypes.bool,
  patient: PropTypes.shape({
    Address: PropTypes.string,
    BirthDate: PropTypes.string,
    district: PropTypes.string,
    Email: PropTypes.string,
    FullName: PropTypes.string,
    Gender: PropTypes.bool,
    HealthInsurance: PropTypes.string,
    Id: PropTypes.number,
    ImmunizationCode: PropTypes.string,
    Phone: PropTypes.string,
    province: PropTypes.string,
    Relationship: PropTypes.string,
    Type: PropTypes.string,
    ward: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
};

HealthDeclarationForm.defaultProps = {
  isCreateToExamination: false,
  patient: {},
};

export default HealthDeclarationForm;
