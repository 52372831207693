import { changePassword } from 'app/actions/global';
import React, { useState } from 'react';
import { FaLock, FaRev, FaUserLock } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import styled from 'styled-components';

const StyledDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  height: 100%;
`;

const ChangePassword = () => {
  const { addToast } = useToasts();
  const { userInfo } = useSelector((state) => state.auth);
  const { changePasswordErrorMessage, changePasswordLoading } = useSelector((state) => state.global);
  const [updateFailed, setUpdateFailed] = useState(false);
  const isLoading = userInfo === null;
  const [oldPassword, setOldPassword] = useState('');
  const [isChangeOldPass, setIsChangeOldPass] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [isChangeNewPass, setIsChangeNewPass] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isChangeConfirmPass, setIsChangeConfirmPass] = useState(false);

  const dispatch = useDispatch();
  const handleChangePassword = (e) => {
    e.preventDefault();
    const data = {
      OldPassword: oldPassword,
      NewPassword: newPassword,
      ConfirmPassword: confirmPassword,
    };
    setUpdateFailed(true);
    dispatch(changePassword(data))
      .then(() => {
        setUpdateFailed(false);
        addToast('Đổi mật khẩu thành công', { appearance: 'success' });
      });
  };

  return (
    <>
      {isLoading ? (
        <StyledDiv>
          <Spinner color="info" />
        </StyledDiv>
      ) : (
        <Form onSubmit={handleChangePassword} className="required-form">
          <Row>
            <Col sm="4" xl="4" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaUserLock />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  required
                  type="password"
                  invalid={!oldPassword && isChangeOldPass}
                  value={oldPassword || ''}
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                    setIsChangeOldPass(true);
                    setUpdateFailed(false);
                  }}
                />
                <Label>Mật khẩu cũ</Label>
              </InputGroup>
            </Col>
            <Col sm="4" xl="4" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaLock />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  required
                  type="password"
                  invalid={!newPassword && isChangeNewPass}
                  value={newPassword || ''}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    setIsChangeNewPass(true);
                    setUpdateFailed(false);
                  }}
                />
                <Label>Mật khẩu mới</Label>
              </InputGroup>
            </Col>
            <Col sm="4" xl="4" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaRev />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  required
                  type="password"
                  invalid={!confirmPassword && isChangeConfirmPass}
                  value={confirmPassword || ''}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setIsChangeConfirmPass(true);
                    setUpdateFailed(false);
                  }}
                />
                <Label>Xác nhận mật khẩu</Label>
              </InputGroup>
            </Col>
            <Col xs="12" className="mt-3">
              <span className="text-danger">(*): Thông tin bắt buộc</span>
            </Col>
            <Col xs="12" className="d-flex justify-content-center mt-3">
              {changePasswordLoading ? (
                <Spinner color="info" />
              ) : (
                <Button color="success" disabled={updateFailed}>
                  Xác nhận
                </Button>
              )}
            </Col>
            {updateFailed && (
              <Col xs="12" className="d-flex justify-content-center mt-3">
                <Alert color="danger">
                  {changePasswordErrorMessage}
                </Alert>
              </Col>
            )}
          </Row>
        </Form>
      )}
    </>
  );
};

export default ChangePassword;
