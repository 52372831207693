import apiLinks from 'app/utils/api-links';
import httpClient from 'app/utils/http-client';

import types from './types';

const setStep = (step) => ({ type: types.EB_SET_STEP, payload: step });

const getHospitalRequest = () => ({ type: types.EB_GET_HOSPITAL_REQUEST });
const getHospitalSuccess = (response) => ({ type: types.EB_GET_HOSPITAL_SUCCESS, payload: response });
const getHospitalFailure = (error) => ({ type: types.EB_GET_HOSPITAL_FAILURE, payload: error });

const getHospital = (serviceId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getHospitalRequest());
  httpClient.callApi({
    url: `${apiLinks.gateway.hospitals.get}/${serviceId}`,
  }).then((response) => {
    dispatch(getHospitalSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getHospitalFailure(error));
    reject();
  });
});

const getUnitTypeRequest = () => ({ type: types.EB_GET_UNIT_TYPE_REQUEST });
const getUnitTypeSuccess = (response) => ({ type: types.EB_GET_UNIT_TYPE_SUCCESS, payload: response });
const getUnitTypeFailure = (error) => ({ type: types.EB_GET_UNIT_TYPE_FAILURE, payload: error });

const getUnitType = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getUnitTypeRequest());
  httpClient.callApi({
    url: apiLinks.gateway.unitTypes.get,
  }).then((response) => {
    dispatch(getUnitTypeSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getUnitTypeFailure(error));
    reject();
  });
});

const getSchedulesRequest = () => ({ type: types.EB_GET_SCHEDULE_REQUEST });
const getSchedulesSuccess = (response) => ({ type: types.EB_GET_SCHEDULE_SUCCESS, payload: response });
const getSchedulesFailure = (error) => ({ type: types.EB_GET_SCHEDULE_FAILURE, payload: error });

const getSchedules = (unitId, serviceId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getSchedulesRequest());
  httpClient.callApi({
    url: apiLinks.gateway.workingCalendars.get,
    params: {
      unitId,
      serviceId,
    },
  }).then((response) => {
    dispatch(getSchedulesSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getSchedulesFailure(error));
    reject();
  });
});

const getIntervalsRequest = () => ({ type: types.EB_GET_INTERVALS_REQUEST });
const getIntervalsSuccess = (response) => ({ type: types.EB_GET_INTERVALS_SUCCESS, payload: response });
const getIntervalsFailure = (error) => ({ type: types.EB_GET_INTERVALS_FAILURE, payload: error });

const getIntervals = (dayId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getIntervalsRequest());
  httpClient.callApi({
    url: `${apiLinks.gateway.workingCalendars.getIntervals}/${dayId}`,
  }).then((response) => {
    dispatch(getIntervalsSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getIntervalsFailure(error));
    reject();
  });
});

const getAppointmentRequest = () => ({ type: types.EB_GET_APPOINTMENT_REQUEST });
const getAppointmentSuccess = (response) => ({ type: types.EB_GET_APPOINTMENT_SUCCESS, payload: response });
const getAppointmentFailure = (error) => ({ type: types.EB_GET_APPOINTMENT_FAILURE, payload: error });

const getAppointment = (id) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getAppointmentRequest());
  httpClient.callApi({
    url: apiLinks.gateway.examinations.get + id,
  }).then((response) => {
    dispatch(getAppointmentSuccess(response.data.data));
    resolve();
  }).catch((error) => {
    dispatch(getAppointmentFailure(error));
    reject();
  });
});

const getHistoryRequest = () => ({ type: types.EB_GET_HISTORY_REQUEST });
const getHistorySuccess = (response) => ({ type: types.EB_GET_HISTORY_SUCCESS, payload: response });
const getHistoryFailure = (error) => ({ type: types.EB_GET_HISTORY_FAILURE, payload: error });

const getHistory = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getHistoryRequest());
  httpClient.callApi({
    url: apiLinks.gateway.examinations.get,
  }).then((response) => {
    dispatch(getHistorySuccess(response.data.data));
    resolve();
  }).catch((error) => {
    dispatch(getHistoryFailure(error));
    reject();
  });
});

const updateAppointmentRequest = () => ({ type: types.EB_UPDATE_APPOINTMENT_REQUEST });
const updateAppointmentSuccess = (response) => ({ type: types.EB_UPDATE_APPOINTMENT_SUCCESS, payload: response });
const updateAppointmentFailure = (error) => ({ type: types.EB_UPDATE_APPOINTMENT_FAILURE, payload: error });

const updateAppointment = (id, status) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(updateAppointmentRequest());
  httpClient.callApi({
    method: 'PUT',
    url: apiLinks.gateway.examinations.update,
    data: {
      id,
      status,
    },
  }).then((response) => {
    dispatch(updateAppointmentSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(updateAppointmentFailure(error));
    reject();
  });
});

const registerRequest = () => ({ type: types.EB_REGISTER_REQUEST });
const registerSuccess = (response) => ({ type: types.EB_REGISTER_SUCCESS, payload: response });
const registerFailure = (error) => ({ type: types.EB_REGISTER_FAILURE, payload: error });

const register = (data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(registerRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.gateway.examinations.register,
    data,
  }).then((response) => {
    dispatch(registerSuccess(response.data.data));
    resolve();
  }).catch((error) => {
    dispatch(registerFailure(error));
    reject();
  });
});

const resetImmunization = () => ({ type: types.EB_RESET });

export {
  getSchedules,
  getIntervals,
  getAppointment,
  getHistory,
  updateAppointment,
  register,
  setStep,
  resetImmunization,
  getHospital,
  getUnitType,
};
