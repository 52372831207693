export default {
  EXAMINATION_SET_STEP: 'EXAMINATION_SET_STEP',

  EXAMINATION_GET_PROFILES_REQUEST: 'EXAMINATION_GET_PROFILES_REQUEST',
  EXAMINATION_GET_PROFILES_SUCCESS: 'EXAMINATION_GET_PROFILES_SUCCESS',
  EXAMINATION_GET_PROFILES_FAILURE: 'EXAMINATION_GET_PROFILES_FAILURE',

  EXAMINATION_GET_DISEASES_REQUEST: 'EXAMINATION_GET_DISEASES_REQUEST',
  EXAMINATION_GET_DISEASES_SUCCESS: 'EXAMINATION_GET_DISEASES_SUCCESS',
  EXAMINATION_GET_DISEASES_FAILURE: 'EXAMINATION_GET_DISEASES_FAILURE',

  EXAMINATION_GET_VACCINES_REQUEST: 'EXAMINATION_GET_VACCINES_REQUEST',
  EXAMINATION_GET_VACCINES_SUCCESS: 'EXAMINATION_GET_VACCINES_SUCCESS',
  EXAMINATION_GET_VACCINES_FAILURE: 'EXAMINATION_GET_VACCINES_FAILURE',

  EXAMINATION_GET_VACCINE_INJECTION_REQUEST: 'EXAMINATION_GET_VACCINE_INJECTION_REQUEST',
  EXAMINATION_GET_VACCINE_INJECTION_SUCCESS: 'EXAMINATION_GET_VACCINE_INJECTION_SUCCESS',
  EXAMINATION_GET_VACCINE_INJECTION_FAILURE: 'EXAMINATION_GET_VACCINE_INJECTION_FAILURE',

  EXAMINATION_GET_INJECTION_REQUEST: 'EXAMINATION_GET_INJECTION_REQUEST',
  EXAMINATION_GET_INJECTION_SUCCESS: 'EXAMINATION_GET_INJECTION_SUCCESS',
  EXAMINATION_GET_INJECTION_FAILURE: 'EXAMINATION_GET_INJECTION_FAILURE',

  EXAMINATION_GET_HEALTHCARE_REQUEST: 'EXAMINATION_GET_HEALTHCARE_REQUEST',
  EXAMINATION_GET_HEALTHCARE_SUCCESS: 'EXAMINATION_GET_HEALTHCARE_SUCCESS',
  EXAMINATION_GET_HEALTHCARE_FAILURE: 'EXAMINATION_GET_HEALTHCARE_FAILURE',

  EXAMINATION_GET_CLINIC_REQUEST: 'EXAMINATION_GET_CLINIC_REQUEST',
  EXAMINATION_GET_CLINIC_SUCCESS: 'EXAMINATION_GET_CLINIC_SUCCESS',
  EXAMINATION_GET_CLINIC_FAILURE: 'EXAMINATION_GET_CLINIC_FAILURE',

  EXAMINATION_GET_SCHEDULE_REQUEST: 'EXAMINATION_GET_SCHEDULE_REQUEST',
  EXAMINATION_GET_SCHEDULE_SUCCESS: 'EXAMINATION_GET_SCHEDULE_SUCCESS',
  EXAMINATION_GET_SCHEDULE_FAILURE: 'EXAMINATION_GET_SCHEDULE_FAILURE',

  EXAMINATION_GET_TICKETS_REQUEST: 'EXAMINATION_GET_TICKETS_REQUEST',
  EXAMINATION_GET_TICKETS_SUCCESS: 'EXAMINATION_GET_TICKETS_SUCCESS',
  EXAMINATION_GET_TICKETS_FAILURE: 'EXAMINATION_GET_TICKETS_FAILURE',

  EXAMINATION_GET_TICKETS_WITHOUT_DATE_REQUEST: 'EXAMINATION_GET_TICKETS_WITHOUT_DATE_REQUEST',
  EXAMINATION_GET_TICKETS_WITHOUT_DATE_SUCCESS: 'EXAMINATION_GET_TICKETS_WITHOUT_DATE_SUCCESS',
  EXAMINATION_GET_TICKETS_WITHOUT_DATE_FAILURE: 'EXAMINATION_GET_TICKETS_WITHOUT_DATE_FAILURE',

  EXAMINATION_REGISTER_REQUEST: 'EXAMINATION_REGISTER_REQUEST',
  EXAMINATION_REGISTER_SUCCESS: 'EXAMINATION_REGISTER_SUCCESS',
  EXAMINATION_REGISTER_FAILURE: 'EXAMINATION_REGISTER_FAILURE',

  EXAMINATION_GET_APPOINTMENT_REQUEST: 'EXAMINATION_GET_APPOINTMENT_REQUEST',
  EXAMINATION_GET_APPOINTMENT_SUCCESS: 'EXAMINATION_GET_APPOINTMENT_SUCCESS',
  EXAMINATION_GET_APPOINTMENT_FAILURE: 'EXAMINATION_GET_APPOINTMENT_FAILURE',

  EXAMINATION_GET_HISTORY_REQUEST: 'EXAMINATION_GET_HISTORY_REQUEST',
  EXAMINATION_GET_HISTORY_SUCCESS: 'EXAMINATION_GET_HISTORY_SUCCESS',
  EXAMINATION_GET_HISTORY_FAILURE: 'EXAMINATION_GET_HISTORY_FAILURE',

  EXAMINATION_UPDATE_APPOINTMENT_REQUEST: 'EXAMINATION_UPDATE_APPOINTMENT_REQUEST',
  EXAMINATION_UPDATE_APPOINTMENT_SUCCESS: 'EXAMINATION_UPDATE_APPOINTMENT_SUCCESS',
  EXAMINATION_UPDATE_APPOINTMENT_FAILURE: 'EXAMINATION_UPDATE_APPOINTMENT_FAILURE',

  EXAMINATION_GET_DOCTOR_REQUEST: 'EXAMINATION_GET_DOCTOR_REQUEST',
  EXAMINATION_GET_DOCTOR_SUCCESS: 'EXAMINATION_GET_DOCTOR_SUCCESS',
  EXAMINATION_GET_DOCTOR_FAILURE: 'EXAMINATION_GET_DOCTOR_FAILURE',

  CREATE_ASSIGN_REQUEST: 'CREATE_ASSIGN_REQUEST',
  CREATE_ASSIGN_SUCCESS: 'CREATE_ASSIGN_SUCCESS',
  CREATE_ASSIGN_FAILURE: 'CREATE_ASSIGN_FAILURE',

  CREATE_SUBJECT_REQUEST: 'CREATE_SUBJECT_REQUEST',
  CREATE_SUBJECT_SUCCESS: 'CREATE_SUBJECT_SUCCESS',
  CREATE_SUBJECT_FAILURE: 'CREATE_SUBJECT_FAILURE',

  EXAMINATION_RESET: 'EXAMINATION_RESET',
};
