import types, {
  TOGGLE_SIDEBAR,
  ADD_PATIENT_MODAL,
  SHOW_CONFIRM_MODAL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  GET_HOSPITAL_BY_FORM_REQUEST,
  GET_HOSPITAL_BY_FORM_SUCCESS,
  GET_HOSPITAL_BY_FORM_FAILURE,
  SET_HISTORY_TAB,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  ADD_RELATIVE_REQUEST,
  ADD_RELATIVE_SUCCESS,
  ADD_RELATIVE_FAILURE,
  CREATE_ACCOUNT_REQUEST,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILURE,
} from 'app/actions/types';

const initialState = {
  sidebarCollapsed: true,
  listHospitals: [],
  historyTab: 1,
  isOpenModal: false,
  openAddProfileModal: false,
  confirmMessage: '',
  confirmCallback: null,
  loadingForgotPassword: false,
  loadingHospitals: false,
  loadingCreateCustomer: false,
  loadingUpdateCustomer: false,
  loadingAddRelative: false,
  loadingCreateAccount: false,
  createErrorMessage: '',
  updateErrorMessage: '',
  changePasswordErrorMessage: '',
  changePasswordLoading: false,
  selectingServiceFormId: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarCollapsed: !state.sidebarCollapsed,
      };
    case ADD_PATIENT_MODAL:
      return {
        ...state,
        isOpenModal: !state.isOpenModal,
      };
    case types.ADD_PROFILE_MODAL:
      return {
        ...state,
        openAddProfileModal: !state.openAddProfileModal,
      };
    case SET_HISTORY_TAB:
      return {
        ...state,
        historyTab: action.payload,
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loadingForgotPassword: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingForgotPassword: false,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loadingForgotPassword: false,
      };
    case GET_HOSPITAL_BY_FORM_REQUEST:
      return {
        ...state,
        listHospitals: [],
        loadingHospitals: true,
      };
    case GET_HOSPITAL_BY_FORM_SUCCESS:
      return {
        ...state,
        listHospitals: action.payload,
        loadingHospitals: false,
      };
    case GET_HOSPITAL_BY_FORM_FAILURE:
      return {
        ...state,
        loadingHospitals: false,
      };
    case types.GET_SERVICE_FORM_REQUEST:
      return {
        ...state,
        getServiceFormLoading: true,
      };
    case types.GET_SERVICE_FORM_SUCCESS: {
      const { response: serviceFormList, serviceForm } = action.payload;
      return {
        ...state,
        selectingServiceFormId: serviceFormList.find((s) => s.name.toUpperCase() === serviceForm.toUpperCase()).id,
        getServiceFormLoading: false,
      };
    }
    case types.GET_SERVICE_FORM_FAILURE:
      return {
        ...state,
        getServiceFormLoading: false,
      };
    case SHOW_CONFIRM_MODAL:
      return {
        ...state,
        confirmMessage: action.payload.message,
        confirmCallback: action.payload.confirmCallback,
      };
    case CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        loadingCreateCustomer: true,
      };
    case CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loadingCreateCustomer: false,
      };
    case CREATE_CUSTOMER_FAILURE: {
      let createErrorMessage = action.payload;
      if (createErrorMessage.indexOf('Phone') > -1) {
        createErrorMessage = 'Số điện thoại đã tồn tại';
      }
      if (createErrorMessage.indexOf('invalid') > -1) {
        createErrorMessage = 'Mật khẩu phải lớn hơn 6 kí tự';
      }
      return {
        ...state,
        createErrorMessage,
        loadingCreateCustomer: false,
      };
    }
    case UPDATE_CUSTOMER_REQUEST:
      return {
        ...state,
        loadingUpdateCustomer: true,
      };
    case UPDATE_CUSTOMER_SUCCESS:
    case UPDATE_CUSTOMER_FAILURE: {
      let updateErrorMessage = action.payload;
      if (updateErrorMessage.indexOf('Phone') > -1) {
        updateErrorMessage = 'Số điện thoại đã tồn tại';
      }
      if (updateErrorMessage.indexOf('invalid') > -1) {
        updateErrorMessage = 'Mật khẩu phải lớn hơn 6 kí tự';
      }
      return {
        ...state,
        updateErrorMessage,
        loadingUpdateCustomer: false,
      };
    }
    case CREATE_ACCOUNT_REQUEST:
      return {
        ...state,
        loadingCreateAccount: true,
      };
    case CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loadingCreateAccount: false,
      };
    case CREATE_ACCOUNT_FAILURE: {
      let createErrorMessage = action.payload;
      if (createErrorMessage.indexOf('Phone') > -1) {
        createErrorMessage = 'Số điện thoại đã tồn tại';
      }
      if (createErrorMessage.indexOf('invalid') > -1) {
        createErrorMessage = 'Mật khẩu phải lớn hơn 6 kí tự';
      }
      return {
        ...state,
        createErrorMessage,
        loadingCreateAccount: false,
      };
    }
    case types.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordLoading: true,
        changePasswordErrorMessage: '',
      };
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordLoading: false,
      };
    case types.CHANGE_PASSWORD_FAILURE: {
      const { ModelState } = action.payload;
      const arrayKeysOfErrorMessage = Object.keys({ ...ModelState });
      let changePasswordErrorMessage = '';
      if (arrayKeysOfErrorMessage.length > 0) {
        const key = (arrayKeysOfErrorMessage[0]);
        if (key.indexOf('New') > -1) {
          changePasswordErrorMessage = 'Mật khẩu mới phải từ 6 ký tự trở lên';
        } else {
          changePasswordErrorMessage = 'Mật khẩu cũ chưa chính xác';
        }
      }
      return {
        ...state,
        changePasswordErrorMessage,
        changePasswordLoading: false,
      };
    }
    case ADD_RELATIVE_REQUEST:
      return {
        ...state,
        loadingAddRelative: true,
      };
    case ADD_RELATIVE_SUCCESS:
    case ADD_RELATIVE_FAILURE:
      return {
        ...state,
        loadingAddRelative: false,
      };
    default:
      return state;
  }
}
