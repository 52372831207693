import React from 'react';
import HistoryLayout from '../components/HistoryLayout';

const HistoryPage = () => (
  <div>
    <HistoryLayout />
  </div>
);

export default HistoryPage;
