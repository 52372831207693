import {
  HISTORY_GET_MCB_SCHEDULER_REQUEST,
  HISTORY_GET_MCB_SCHEDULER_SUCCESS,
  HISTORY_GET_MCB_SCHEDULER_FAILURE,
  HISTORY_GET_TELE_SCHEDULER_REQUEST,
  HISTORY_GET_TELE_SCHEDULER_SUCCESS,
  HISTORY_GET_TELE_SCHEDULER_FAILURE,
  // HISTORY_GET_PAYMENT_STATUS_REQUEST,
  // HISTORY_GET_PAYMENT_STATUS_SUCCESS,
  // HISTORY_GET_PAYMENT_STATUS_FAILURE,
} from 'history/actions/types';

const initialState = {
  listMCBTickets: [],
  listTeleTickets: [],
  loadingListMCBTickets: false,
  loadingListTeleTickets: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case HISTORY_GET_MCB_SCHEDULER_REQUEST:
      return {
        ...state,
        loadingListMCBTickets: true,
      };
    case HISTORY_GET_MCB_SCHEDULER_SUCCESS:
      return {
        ...state,
        listMCBTickets: action.payload.sort((a, b) => b.Id - a.Id),
        loadingListMCBTickets: false,
      };
    case HISTORY_GET_MCB_SCHEDULER_FAILURE:
      return {
        ...state,
        loadingListMCBTickets: false,
      };
    case HISTORY_GET_TELE_SCHEDULER_REQUEST:
      return {
        ...state,
        loadingListTeleTickets: false,
      };
    case HISTORY_GET_TELE_SCHEDULER_SUCCESS:
      return {
        ...state,
        listTeleTickets: action.payload.sort((a, b) => b.Id - a.Id),
        loadingListTeleTickets: false,
      };
    case HISTORY_GET_TELE_SCHEDULER_FAILURE:
      return {
        ...state,
        loadingListTeleTickets: false,
      };
    // case HISTORY_GET_PAYMENT_STATUS_REQUEST:
    //   return {
    //     ...state,
    //     loadingTicketStatus: true,
    //   };
    // case HISTORY_GET_PAYMENT_STATUS_SUCCESS:
    //   return {
    //     ...state,
    //     loadingTicketStatus: false,
    //   };
    // case HISTORY_GET_PAYMENT_STATUS_FAILURE:
    //   return {
    //     ...state,
    //     loadingTicketStatus: false,
    //   };
    default:
      return state;
  }
}
