import moment from 'moment';
import types, {
  LOG_IN_REQUEST,
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  SET_TOKEN,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  LOG_OUT,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from 'app/actions/types';

const INITIAL_STATE = {
  token: null,
  tokenExpiredTime: null,
  registerLoading: false,
  loginLoading: false,
  userInfo: null,
  getUserInfoLoading: false,
  profileList: [],
  getProfileLoading: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case REGISTER_REQUEST:
      return {
        ...state,
        registerLoading: true,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: {
          access_token: action.payload.token,
        },
        loginFailed: false,
      };
    case REGISTER_SUCCESS:
    case REGISTER_FAILURE:
      return {
        ...state,
        registerLoading: false,
      };
    case LOG_IN_REQUEST:
      return {
        ...state,
        loginLoading: true,
      };
    case LOG_IN_SUCCESS: {
      const token = action.payload;
      return {
        ...state,
        token,
        tokenExpiredTime: new Date(new Date().getTime() + token.expires_in * 1000),
        loginLoading: false,
      };
    }
    case LOG_IN_FAILURE:
      return {
        ...state,
        loginFailed: true,
        loginLoading: false,
      };
    case GET_USER_INFO_REQUEST:
      return {
        ...state,
        getUserInfoLoading: true,
      };
    case GET_USER_INFO_SUCCESS: {
      const userAge = action.payload.BirthDate;
      const today = moment();
      const diff = today.diff(userAge, 'days');
      return {
        ...state,
        userInfo: {
          ...action.payload,
          diff,
        },
        getUserInfoLoading: false,
      };
    }
    case GET_USER_INFO_FAILURE:
      return {
        ...state,
        getUserInfoLoading: false,
      };
    case types.GET_PROFILES_REQUEST:
      return {
        ...state,
        getProfileLoading: true,
      };
    case types.GET_PROFILES_SUCCESS: {
      const response = action.payload;
      let result = [];
      result = response.map((e) => {
        const { dateOfBirth } = e;
        const today = moment();
        const diff = today.diff(dateOfBirth, 'days');
        return {
          ...e,
          diff,
        };
      });
      return {
        ...state,
        profileList: result,
        getProfileLoading: false,
      };
    }
    case types.GET_PROFILES_FAILURE:
      return {
        ...state,
        getProfileLoading: false,
      };
    case LOG_OUT:
      return {
        ...state,
        token: null,
        tokenExpiredTime: null,
      };
    default:
      return state;
  }
}
