import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  Row,
  Card,
  CardText,
  Col,
  Spinner,
  Button,
  CardTitle,
  CardHeader,
} from 'reactstrap';

import moment from 'moment';
import { useToasts } from 'react-toast-notifications';

import { useSelector, useDispatch } from 'react-redux';
import { updateAppointment } from 'immunization/actions/immunization';

import { showConfirmModal } from 'app/actions/global';
import { StatusColor, StatusLabel } from 'immunization/utils/constants';
// import { StatusColor, StatusLabel } from 'immunization/utils/constants';

const StyledDiv = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
const StyledCard = styled(Card)`
  background: linear-gradient(
    90deg,
    rgba(0, 78, 95, 0.7) 0%,
    rgba(77, 169, 188, 1) 100%
  ) !important;
  border-radius: 0px;
  padding: 15px 30px;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
`;
const StyledCardHeader = styled(CardHeader)`
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  border-radius: 0;
  background: rgba(0, 78, 95, 0.8);
`;
const BorderedCol = styled(Col)`
  border-top: 1px solid white;
`;

const StyledCardText = styled(CardText)`
  color: gold;
  font-weight: normal;
`;

const CovidExaminationAppointment = (props) => {
  const { onRefresh, initialData } = props;

  const { loadingUpdateAppointment } = useSelector(
    (state) => state.examination,
  );

  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const appointment = useMemo(
    () => (
      <div className="mt-3">
        <StyledCardHeader className="text-center">
          <h4 className="font-weight-normal text-warning mb-0">
            Thông tin phiếu hẹn
          </h4>
        </StyledCardHeader>
        <StyledCard inverse>
          {initialData.status !== 1 && (
            <div className="ribbon-wrapper">
              <div className={`ribbon ${StatusColor[initialData.status]}`}>
                {StatusLabel[initialData.status]}
              </div>
            </div>
          )}
          <CardTitle className="mb-0 text-left">
            <h4>{initialData?.unit.name}</h4>
            <p className="mb-2">{`Địa chỉ: ${initialData?.unit?.address}`}</p>
          </CardTitle>

          <Row>
            <BorderedCol xs={12}>
              <h5 className="my-2">Thông tin người xét nghiệm</h5>
            </BorderedCol>
            <Col xs={4}>
              <CardText>Họ và tên</CardText>
            </Col>
            <Col xs={8}>
              <CardText>{initialData?.customer?.fullname}</CardText>
            </Col>
            <Col xs={4}>
              <CardText>Năm sinh</CardText>
            </Col>
            <Col xs={8}>
              <CardText>
                {initialData?.customer?.birthDate
                  ? moment(initialData?.customer?.birthDate).format('YYYY')
                  : 'Chưa xác định'}
              </CardText>
            </Col>
            <Col xs={4}>
              <CardText>Điện thoại</CardText>
            </Col>
            <Col xs={8}>
              <CardText>{initialData?.customer?.phone}</CardText>
            </Col>
            <BorderedCol xs={12}>
              <h5 className="my-2">Thông tin lịch hẹn</h5>
            </BorderedCol>
            <Col xs={4}>
              <CardText>Địa chỉ xét nghiệm</CardText>
            </Col>
            <Col xs={8}>
              <CardText>
                {initialData?.unit?.address}
              </CardText>
            </Col>
            <Col xs={4}>
              <CardText>Loại bệnh</CardText>
            </Col>
            <Col xs={8}>
              <CardText>{initialData?.service?.name}</CardText>
            </Col>
            {/* <Col xs={4}>
              <CardText>Bác sĩ xét nghiệm</CardText>
            </Col>
            <Col xs={8}>
              <CardText>{initialData?.doctor.fullname}</CardText>
            </Col> */}
            <Col xs={4}>
              <CardText>Ngày xét nghiệm</CardText>
            </Col>
            <Col xs={8}>
              <StyledCardText>
                {moment(initialData?.instance?.dateTime).format('DD-MM-YYYY')}
              </StyledCardText>
            </Col>
            <Col xs={4}>
              <CardText>Giờ xét nghiệm</CardText>
            </Col>
            <Col xs={8}>
              <StyledCardText>
                {moment(initialData?.instance?.dateTime).format('HH:mm')}
              </StyledCardText>
            </Col>
          </Row>
        </StyledCard>
        {initialData.status === 1 && (
          <Row>
            <Col xs="12" className="text-center">
              <Button
                outline
                color="danger"
                className="mt-3"
                onClick={() => {
                  dispatch(showConfirmModal(
                    `Xác nhận hủy phiếu hẹn số ${initialData.id}?`,
                    () => {
                      dispatch(updateAppointment(initialData.id, 5)).then(
                        () => {
                          addToast('Hủy phiếu hẹn thành công', {
                            appearance: 'success',
                          });
                          onRefresh();
                        },
                      );
                    },
                  ));
                }}
              >
                Hủy phiếu hẹn
              </Button>
            </Col>
          </Row>
        )}
      </div>
    ),
    [dispatch, addToast, onRefresh, initialData],
  );
  return (
    <div>
      {loadingUpdateAppointment ? (
        <StyledDiv>
          <Spinner color="info" />
        </StyledDiv>
      ) : (
        appointment
      )}
    </div>
  );
};

CovidExaminationAppointment.propTypes = {
  initialData: PropTypes.shape({
    id: PropTypes.string,
    instance: PropTypes.shape({
      id: PropTypes.number,
      dateTime: PropTypes.string,
    }),
    unit: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      information: PropTypes.string,
      address: PropTypes.string,
      username: PropTypes.string,
    }),
    doctor: PropTypes.shape({
      id: PropTypes.string,
      fullname: PropTypes.string,
    }),
    room: {
      id: PropTypes.string,
      name: PropTypes.string,
    },
    service: {
      id: PropTypes.string,
      name: PropTypes.string,
    },
    customer: {
      id: PropTypes.number,
      fullname: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      address: PropTypes.string,
      birthDate: PropTypes.string,
      gender: PropTypes.bool,
      provinceCode: PropTypes.string,
      districtCode: PropTypes.string,
      wardCode: PropTypes.string,
      ic: PropTypes.string,
    },
    contacts: [],
    status: PropTypes.number,
    note: PropTypes.string,
    form: PropTypes.shape({}),
    bookedByUser: PropTypes.string,
  }),
  onRefresh: PropTypes.func,
};

CovidExaminationAppointment.defaultProps = {
  initialData: {},
  onRefresh: () => {},
};

export default CovidExaminationAppointment;
