
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Col, Row } from 'reactstrap';
import Select from 'react-select';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { useSelector } from 'react-redux';

const statusOptions = [
  { id: 0, text: 'Tất cả' },
  { id: 1, text: 'Chưa thực hiện' },
  { id: 2, text: 'Đã thực hiện' },
  { id: 3, text: 'Bên hẹn huỷ' },
  { id: 4, text: 'Bên nhận huỷ' },
  { id: 5, text: 'Không thực hiện' },
  { id: 6, text: 'Đã chuyển tiếp' },
];

const DateWrapper = styled.div`
  width: 100%;
  position: relative;
  flex: 1 1;
  min-width: 0;
  margin-bottom: 0;
`;

const handleKeyUp = (e, setDate) => {
  const inputValue = e.target.value;
  if (inputValue) {
    setDate(null);
  }
  const d = inputValue.replace(/\D/g, '').slice(0, 10);
  if (d.length >= 5) {
    const stringResult = `${d.slice(0, 2)}-${d.slice(2, 4)}-${d.slice(4)}`;
    setDate(stringResult);
  } else if (d.length >= 3) {
    setDate(`${d.slice(0, 2)}-${d.slice(2)}`);
  } else {
    setDate(d);
  }
};

const ImmunizationHistoryFilter = (props) => {
  const { onChange } = props;
  // const [searchValue, setSearchValue] = useState('');
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [selectingStatus, setSelectingStatus] = useState(null);
  const [selectingPatient, setSelectingPatient] = useState(null);

  const [patientOptions, setPatientOptions] = useState([]);

  const { profileList } = useSelector((state) => state.auth);

  useEffect(() => {
    setPatientOptions([
      { value: 0, label: 'Tất cả' },
      ...profileList
        .map((p) => ({
          label: p.fullname,
          value: p.id,
          key: p.id,
          subject: p,
        })),
    ]);
  }, [
    profileList,
  ]);

  useEffect(() => {
    onChange({
      // searchValue,
      selectingPatient,
      selectingStatus,
      from,
      to,
    });
  }, [
    onChange,
    selectingPatient,
    // searchValue,
    selectingStatus,
    from,
    to,
  ]);
  return (
    <Row>
      {/* <Col sm={12} md={3} className="mt-2">
        <Input
          placeholder="Mã phiếu hẹn"
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </Col> */}
      <Col sm={12} md={3} className="mt-2">
        <Select
          placeholder="Người tiêm"
          onChange={setSelectingPatient}
          options={patientOptions}
        />
      </Col>
      <Col sm={12} md={3} className="mt-2">
        <Select
          placeholder="Trạng thái"
          onChange={setSelectingStatus}
          options={statusOptions.map((s) => ({
            value: s.id,
            label: s.text,
          }))}
        />
      </Col>
      <Col sm={12} md={3} className="mt-2">
        <DateWrapper>
          <DayPickerInput
            style={{ width: '100%' }}
            value={from}
            format="DD-MM-YYYY"
            parseDate={parseDate}
            formatDate={formatDate}
            placeholder="Từ ngày"
            inputProps={{
              className: 'form-control',
              maxLength: 10,
              onKeyUp: (e) => handleKeyUp(e, setFrom),
            }}
            onDayChange={setFrom}
          />
        </DateWrapper>
      </Col>
      <Col sm={12} md={3} className="mt-2">
        <DateWrapper>
          <DayPickerInput
            style={{ width: '100%' }}
            value={to}
            format="DD-MM-YYYY"
            parseDate={parseDate}
            formatDate={formatDate}
            placeholder="Đến ngày"
            dayPickerProps={{
              modifiers: {
                disabled: [
                  {
                    before: from && new Date(from),
                  },
                ],
              },
            }}
            inputProps={{
              className: 'form-control',
              maxLength: 10,
              onKeyUp: (e) => handleKeyUp(e, setTo),
            }}
            onDayChange={setTo}
          />
        </DateWrapper>
      </Col>
    </Row>
  );
};

ImmunizationHistoryFilter.propTypes = {
  onChange: PropTypes.func,
};

ImmunizationHistoryFilter.defaultProps = {
  onChange: () => {},
};

export default ImmunizationHistoryFilter;
