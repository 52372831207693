/* eslint-env browser */

import store from 'app/store';
import {
  setToken,
  login as li,
  logout as lo,
  getUserInfo,
} from 'app/actions/auth';

import { TOKEN, EXPIRED_TIME } from 'app/utils/constants';

const auth = {
  /**
   * Check and handle token from localStorage and return if the token is still validate
   * @returns {boolean} token validate status
   */
  // isAuthenticated: () => true,
  isAuthenticated: () => {
    const token = JSON.parse(localStorage.getItem(TOKEN) || sessionStorage.getItem(TOKEN));
    const expiredTime = new Date(localStorage.getItem(EXPIRED_TIME) || sessionStorage.getItem(EXPIRED_TIME));
    if (token && expiredTime > new Date()) {
      store.dispatch(setToken(token, expiredTime));
      if (!store.getState().auth.userInfo) {
        store.dispatch(getUserInfo());
      }
      return true;
    }
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(EXPIRED_TIME);
    sessionStorage.removeItem(TOKEN);
    sessionStorage.removeItem(EXPIRED_TIME);
    store.dispatch(lo());
    return false;
  },

  /**
   * Return a Promise which resolve when login successfully
   * @param {string} username Username
   * @param {string} password Password
   * @param {boolean} remember Option to remember password
   * @returns {Promise} Resolve if login successfully and reject if login failed
   */
  login: (username, password, remember) => new Promise(
    (resolve, reject) => {
      store
        .dispatch(li(username, password))
        .then((token) => {
          if (remember) {
            localStorage.setItem(TOKEN, JSON.stringify(token));
            localStorage.setItem(EXPIRED_TIME, `${new Date(new Date().getTime() + token.expires_in * 1000)}`);
          } else {
            sessionStorage.setItem(TOKEN, JSON.stringify(token));
            sessionStorage.setItem(EXPIRED_TIME, `${new Date(new Date().getTime() + token.expires_in * 1000)}`);
          }
          store.dispatch(getUserInfo());
          resolve(token);
        })
        .catch(() => {
          reject();
        });
    },
  ),

  /**
   * Logout and delete token from localStorage
   */
  logout: () => {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(EXPIRED_TIME);
    sessionStorage.removeItem(TOKEN);
    sessionStorage.removeItem(EXPIRED_TIME);
    store.dispatch(lo());
  },
};

export default auth;
