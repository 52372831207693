import auth from 'app/reducers/auth';
import global from 'app/reducers/global';
import booking from 'medical-checkup-booking/reducers/booking';
import tele from 'telehealth/reducers/tele';
import homecare from 'homecare/reducers/homecare';
import history from 'history/reducers/history';
import immunization from 'immunization/reducers/immunization';
import immunizationBooking from 'immunization-booking/reducers/immunization-booking';
import examination from 'covid-examination/reducers/examination';
import examinationBooking from 'covid-examination-booking/reducers/examination-booking';

export default {
  auth,
  global,
  booking,
  tele,
  homecare,
  history,
  immunization,
  immunizationBooking,
  examination,
  examinationBooking,
};
