export default {
  IB_SET_STEP: 'IB_SET_STEP',

  IB_GET_PROFILES_REQUEST: 'IB_GET_PROFILES_REQUEST',
  IB_GET_PROFILES_SUCCESS: 'IB_GET_PROFILES_SUCCESS',
  IB_GET_PROFILES_FAILURE: 'IB_GET_PROFILES_FAILURE',

  IB_GET_DISEASES_REQUEST: 'IB_GET_DISEASES_REQUEST',
  IB_GET_DISEASES_SUCCESS: 'IB_GET_DISEASES_SUCCESS',
  IB_GET_DISEASES_FAILURE: 'IB_GET_DISEASES_FAILURE',

  IB_GET_VACCINES_REQUEST: 'IB_GET_VACCINES_REQUEST',
  IB_GET_VACCINES_SUCCESS: 'IB_GET_VACCINES_SUCCESS',
  IB_GET_VACCINES_FAILURE: 'IB_GET_VACCINES_FAILURE',

  IB_GET_VACCINE_INJECTION_REQUEST: 'IB_GET_VACCINE_INJECTION_REQUEST',
  IB_GET_VACCINE_INJECTION_SUCCESS: 'IB_GET_VACCINE_INJECTION_SUCCESS',
  IB_GET_VACCINE_INJECTION_FAILURE: 'IB_GET_VACCINE_INJECTION_FAILURE',

  IB_GET_INJECTION_REQUEST: 'IB_GET_INJECTION_REQUEST',
  IB_GET_INJECTION_SUCCESS: 'IB_GET_INJECTION_SUCCESS',
  IB_GET_INJECTION_FAILURE: 'IB_GET_INJECTION_FAILURE',

  IB_GET_HEALTHCARE_REQUEST: 'IB_GET_HEALTHCARE_REQUEST',
  IB_GET_HEALTHCARE_SUCCESS: 'IB_GET_HEALTHCARE_SUCCESS',
  IB_GET_HEALTHCARE_FAILURE: 'IB_GET_HEALTHCARE_FAILURE',

  IB_GET_CLINIC_REQUEST: 'IB_GET_CLINIC_REQUEST',
  IB_GET_CLINIC_SUCCESS: 'IB_GET_CLINIC_SUCCESS',
  IB_GET_CLINIC_FAILURE: 'IB_GET_CLINIC_FAILURE',

  IB_GET_SCHEDULE_REQUEST: 'IB_GET_SCHEDULE_REQUEST',
  IB_GET_SCHEDULE_SUCCESS: 'IB_GET_SCHEDULE_SUCCESS',
  IB_GET_SCHEDULE_FAILURE: 'IB_GET_SCHEDULE_FAILURE',

  IB_GET_TICKETS_REQUEST: 'IB_GET_TICKETS_REQUEST',
  IB_GET_TICKETS_SUCCESS: 'IB_GET_TICKETS_SUCCESS',
  IB_GET_TICKETS_FAILURE: 'IB_GET_TICKETS_FAILURE',

  IB_REGISTER_REQUEST: 'IB_REGISTER_REQUEST',
  IB_REGISTER_SUCCESS: 'IB_REGISTER_SUCCESS',
  IB_REGISTER_FAILURE: 'IB_REGISTER_FAILURE',

  IB_GET_APPOINTMENT_REQUEST: 'IB_GET_APPOINTMENT_REQUEST',
  IB_GET_APPOINTMENT_SUCCESS: 'IB_GET_APPOINTMENT_SUCCESS',
  IB_GET_APPOINTMENT_FAILURE: 'IB_GET_APPOINTMENT_FAILURE',

  IB_GET_HISTORY_REQUEST: 'IB_GET_HISTORY_REQUEST',
  IB_GET_HISTORY_SUCCESS: 'IB_GET_HISTORY_SUCCESS',
  IB_GET_HISTORY_FAILURE: 'IB_GET_HISTORY_FAILURE',

  IB_UPDATE_APPOINTMENT_REQUEST: 'IB_UPDATE_APPOINTMENT_REQUEST',
  IB_UPDATE_APPOINTMENT_SUCCESS: 'IB_UPDATE_APPOINTMENT_SUCCESS',
  IB_UPDATE_APPOINTMENT_FAILURE: 'IB_UPDATE_APPOINTMENT_FAILURE',

  IB_RESET: 'IB_RESET',

  IB_GET_INJECTION_OBJECT_REQUEST: 'IB_GET_INJECTION_OBJECT_REQUEST',
  IB_GET_INJECTION_OBJECT_SUCCESS: 'IB_GET_INJECTION_OBJECT_SUCCESS',
  IB_GET_INJECTION_OBJECT_FAILURE: 'IB_GET_INJECTION_OBJECT_FAILURE',

  IB_GET_SERVICE_TYPE_REQUEST: 'IB_GET_SERVICE_TYPE_REQUEST',
  IB_GET_SERVICE_TYPE_SUCCESS: 'IB_GET_SERVICE_TYPE_SUCCESS',
  IB_GET_SERVICE_TYPE_FAILURE: 'IB_GET_SERVICE_TYPE_FAILURE',

  IB_GET_SERVICE_REQUEST: 'IB_GET_SERVICE_REQUEST',
  IB_GET_SERVICE_SUCCESS: 'IB_GET_SERVICE_SUCCESS',
  IB_GET_SERVICE_FAILURE: 'IB_GET_SERVICE_FAILURE',

  IB_GET_HOSPITAL_REQUEST: 'IB_GET_HOSPITAL_REQUEST',
  IB_GET_HOSPITAL_SUCCESS: 'IB_GET_HOSPITAL_SUCCESS',
  IB_GET_HOSPITAL_FAILURE: 'IB_GET_HOSPITAL_FAILURE',

  IB_GET_UNIT_TYPE_REQUEST: 'IB_GET_UNIT_TYPE_REQUEST',
  IB_GET_UNIT_TYPE_SUCCESS: 'IB_GET_UNIT_TYPE_SUCCESS',
  IB_GET_UNIT_TYPE_FAILURE: 'IB_GET_UNIT_TYPE_FAILURE',

  IB_GET_INTERVALS_REQUEST: 'IB_GET_INTERVALS_REQUEST',
  IB_GET_INTERVALS_SUCCESS: 'IB_GET_INTERVALS_SUCCESS',
  IB_GET_INTERVALS_FAILURE: 'IB_GET_INTERVALS_FAILURE',
};
