import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const StyledPagination = (props) => {
  const numberOfPage = [];
  const { listData, itemPerPage, refreshData } = props;
  const [current, setCurrent] = useState(0);

  if (listData.length > itemPerPage) {
    for (
      let index = 0;
      index < Math.ceil(listData.length / itemPerPage);
      index += 1
    ) {
      numberOfPage.push(index);
    }
  }
  const pagingData = (e) => {
    if (listData.length !== 0) {
      const pagedData = listData.slice(itemPerPage * e, itemPerPage * (e + 1));
      refreshData(pagedData);
      setCurrent(e);
    }
  };

  useEffect(() => {
    pagingData(current);
    // eslint-disable-next-line
  }, [listData]);

  return (
    <div className="d-flex justify-content-center mt-3 col-12">
      <Pagination>
        <PaginationItem
          onClick={() => ((current !== 0) ? pagingData(0) : {})}
          disabled={current === 0}
        >
          <PaginationLink first />
        </PaginationItem>
        <PaginationItem
          onClick={() => ((current !== 0) ? pagingData(current - 1) : {})}
          disabled={current === 0}
        >
          <PaginationLink previous />
        </PaginationItem>
        {numberOfPage.map((e) => (
          <PaginationItem
            key={e}
            active={current === e}
            onClick={() => pagingData(e)}
          >
            <PaginationLink>{e + 1}</PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem
          onClick={() => (current !== numberOfPage.length - 1 ? pagingData(current + 1) : {})}
          disabled={current === numberOfPage.length - 1}
        >
          <PaginationLink next />
        </PaginationItem>
        <PaginationItem
          onClick={() => (current !== numberOfPage.length - 1 ? pagingData(numberOfPage.length - 1) : {})}
          disabled={current === numberOfPage.length - 1}
        >
          <PaginationLink last />
        </PaginationItem>
      </Pagination>
    </div>
  );
};

StyledPagination.propTypes = {
  listData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemPerPage: PropTypes.number.isRequired,
  refreshData: PropTypes.func,
};

StyledPagination.defaultProps = {
  refreshData: () => {},
};

export default StyledPagination;
