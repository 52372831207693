import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Row,
  Col,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
  Input,
} from 'reactstrap';
import { FaPhone } from 'react-icons/fa';
import { setAddress, setPhone } from 'homecare/actions/homecare';
import ButtonFragment from './ButtonFragment';

const PhoneContact = () => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState('');
  return (
    <Row className="w-75">
      <Col md="8" xs="12" className="mb-3">
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <FaPhone />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Số điện thoại"
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </InputGroup>
      </Col>
      <Col xs="12">
        <ButtonFragment
          onGoNext={() => dispatch(setPhone(phoneNumber))}
          onGoBack={() => dispatch(setAddress(null))}
          data={{ phoneNumber }}
        />
      </Col>
    </Row>
  );
};

export default PhoneContact;
