import axios from 'axios';

import store from 'app/store';

const httpClient = {
  callApi: async ({
    method = 'GET',
    contentType = 'application/json',
    url,
    data,
    params,
    onUploadProgress,
  }) => {
    const { token } = store.getState().auth; // token
    const headerToken = token ? { Authorization: `bearer ${token.access_token}` } : null;

    return axios({
      method,
      contentType,
      url,
      headers: { ...headerToken },
      data,
      params,
      onUploadProgress,
    });
  },
};

export default httpClient;
