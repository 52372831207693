import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setStep } from '../actions/tele';

const ButtonFragment = (props) => {
  const {
    data,
    onGoNext,
    onGoBack,
    onFinish,
  } = props;
  const { currentStep } = useSelector((state) => state.tele);
  const dispatch = useDispatch();

  return (
    <div>
      {currentStep !== 0 && (
        <Button
          size="sm"
          className="mr-2"
          color="info"
          onClick={() => {
            dispatch(setStep(currentStep - 1));
            onGoBack();
          }}
        >
          Quay lại
        </Button>
      )}
      {currentStep !== 4 && (
        <Button
          size="sm"
          color="info"
          disabled={data === null || Object.keys(data).length === 0}
          onClick={() => {
            dispatch(setStep(currentStep + 1));
            onGoNext();
          }}
        >
          Bước sau
        </Button>
      )}
      {currentStep === 4 && (
        <Button
          size="sm"
          color="success"
          disabled={data === null || Object.keys(data).length === 0}
          onClick={() => {
            dispatch(setStep(currentStep + 1));
            onFinish();
          }}
        >
          Hoàn tất
        </Button>
      )}
    </div>
  );
};

ButtonFragment.propTypes = {
  data: PropTypes.shape({}),
  onGoNext: PropTypes.func,
  onGoBack: PropTypes.func,
  onFinish: PropTypes.func,
};

ButtonFragment.defaultProps = {
  data: {},
  onGoNext: () => {},
  onGoBack: () => {},
  onFinish: () => {},
};

export default ButtonFragment;
