import React from 'react';

const HomePage = (props) => {
  return (
    <div>
      HomePage
    </div>
  );
};

export default HomePage;
