import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Spinner } from 'reactstrap';
import Steps, { Step } from 'rc-steps';
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';

import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';

// import { setStep } from '../actions/booking';

import PatientSelect from '../components/PatientSelect';
import ClinicSelect from '../components/ClinicSelect';
import InsuranceSelect from '../components/InsuranceSelect';
import ServiceExpertSection from '../components/ServiceExpertSection';
import DateTimeSelect from '../components/DateTimeSelect';
import ServiceTypeSelect from '../components/ServiceTypeSelect';
import Appointment from '../components/Appointment';

const steps = [
  {
    key: 0,
    isSelected: '',
    title: 'Người khám bệnh',
    component: <PatientSelect />,
    isDisplay: true,
  },
  {
    key: 1,
    isSelected: '',
    title: 'Cơ sở',
    component: <ClinicSelect />,
  },
  {
    key: 2,
    isSelected: '',
    title: 'Loại hình',
    component: <ServiceTypeSelect />,
  },
  {
    key: 3,
    isSelected: '',
    title: 'Bảo hiểm Y tế',
    component: <InsuranceSelect />,
  },
  {
    key: 4,
    isSelected: '',
    title: 'Chuyên khoa',
    component: <ServiceExpertSection />,
  },
  {
    key: 5,
    isSelected: '',
    title: 'Thời gian',
    component: <DateTimeSelect />,
  },
];

const StyledDiv = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const MedicalCheckupBookingPage = () => {
  // const dispatch = useDispatch();
  const {
    currentStep,
    selectedPatient,
    selectedClinic,
    selectedServiceType,
    selectedService,
    insuranceInfo,
    selectedExpert,
    examinationData,
    creatingExamination,
  } = useSelector((state) => state.booking);

  // change title
  if (selectedPatient && selectedPatient.value !== 0) {
    steps[0].isSelected = '';
    steps[0].isSelected = (
      <>
        {`${steps[0].title} - `}
        <span className="text-secondary">{`${selectedPatient.label}`}</span>
      </>
    );
  } else {
    steps[0].isSelected = '';
  }
  if (selectedClinic) {
    steps[1].isSelected = '';
    steps[1].isSelected = (
      <>
        {`${steps[1].title} - `}
        <span className="text-secondary">{`${selectedClinic.Name}`}</span>
      </>
    );
  } else {
    steps[1].isSelected = '';
  }
  if (selectedServiceType) {
    steps[2].isSelected = '';
    steps[2].isSelected = (
      <>
        {`${steps[2].title} - `}
        <span className="text-secondary">{`${selectedServiceType.label}`}</span>
      </>
    );
    if (selectedServiceType.CanChoiceDoctor) {
      steps[4].title = 'Chuyên gia';
    } else {
      steps[4].title = 'Chuyên khoa';
    }
  } else {
    steps[2].isSelected = '';
  }
  if (insuranceInfo.insuranceId) {
    steps[3].isSelected = '';
    steps[3].isSelected = (
      <>
        {`${steps[3].title} - Số: `}
        <span className="text-secondary">{`${insuranceInfo.insuranceId}`}</span>
      </>
    );
  } else {
    steps[3].isSelected = '';
  }
  if (selectedService || selectedExpert) {
    steps[4].isSelected = '';
    steps[4].isSelected = (
      <>
        {`${steps[4].title} - `}
        {selectedService ? (
          <span className="text-secondary">{`${selectedService.label}`}</span>
        ) : (
          <span className="text-secondary">{`${selectedExpert.label}`}</span>
        )}
      </>
    );
  } else {
    steps[4].isSelected = '';
  }

  return (
    <Container fluid>
      {!examinationData && !creatingExamination && (
        <Steps
          direction="vertical"
          current={currentStep}
          // onChange={(step) => dispatch(setStep(step))}
        >
          {/* {selectedServiceType.CanUseHealthInsurance} */}
          {steps.map((s) => (
            <Step
              icons={<FaCheck />}
              key={s.key}
              title={s.isSelected.length === 0 ? s.title : s.isSelected}
              description={s.key === currentStep ? s.component : null}
            />
          ))}
        </Steps>
      )}
      {examinationData && !creatingExamination && <Appointment />}
      {creatingExamination && (
        <StyledDiv>
          <h5 className="text-secondary font-weight-normal">
            Đang tiến hành tạo phiếu hẹn
          </h5>
          <Spinner color="info" />
        </StyledDiv>
      )}
    </Container>
  );
};

export default MedicalCheckupBookingPage;
