/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { useDispatch, useSelector } from 'react-redux';
import { getInfectionObject } from 'immunization-booking/actions/immunization-booking';

import ButtonFragment from './ButtonFragment';

const StyledSelect = styled(Select)`
  max-width: 350px;
  width: 100%;
  background-color: transparent;
`;

const SubjectSelect = (props) => {
  const {
    onChange,
    onBack,
    serviceType,
    patientSubject,
  } = props;


  const [selectingInjectionObject, setSelectingInjectionObject] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (serviceType) {
      dispatch(getInfectionObject(serviceType.value));
    }
  }, [dispatch, serviceType]);

  const {
    injectionObjectList,
    getInfectionObjectLoading,
  } = useSelector((state) => state.immunizationBooking);

  const { diff } = patientSubject;
  const availableInjectionObject = injectionObjectList.find((e) => e.fromDaysOld <= diff && e.toDaysOld >= diff);

  const injectionObjectSelect = useMemo(() => (
    <StyledSelect
      isSearchable
      isLoading={getInfectionObjectLoading}
      value={selectingInjectionObject || availableInjectionObject ? {
        value: availableInjectionObject?.id ?? '',
        label: availableInjectionObject?.name ?? '',
      } : null}
      className="basic-single mb-2"
      classNamePrefix="select"
      name="color"
      noOptionsMessage={() => <span>Không tìm thấy</span>}
      placeholder="Đối tượng tiêm"
      options={injectionObjectList.map((e) => ({
        key: e.id,
        value: e.id,
        label: e.name,
      }))}
      onChange={(d) => {
        if (d) {
          if (d.value !== selectingInjectionObject?.value) {
            setSelectingInjectionObject(d);
            onChange(d);
          }
        } else {
          setSelectingInjectionObject(null);
          onChange(null);
        }
      }}
    />
  ), [
    onChange,
    injectionObjectList,
    selectingInjectionObject,
    getInfectionObjectLoading,
    availableInjectionObject,
  ]);

  return (
    <div>
      {injectionObjectSelect}
      <ButtonFragment
        disabled={!selectingInjectionObject && !availableInjectionObject}
        onGoNext={() => onChange(selectingInjectionObject || availableInjectionObject ? {
          value: availableInjectionObject?.id ?? '',
          label: availableInjectionObject?.name ?? '',
        } : null)}
        onGoBack={onBack}
      />
    </div>
  );
};

SubjectSelect.propTypes = {
  serviceType: PropTypes.shape({
    value: PropTypes.string,
  }),
  patientSubject: PropTypes.shape({
    diff: PropTypes.number,
  }),
  onChange: PropTypes.func,
  onBack: PropTypes.func,
};

SubjectSelect.defaultProps = {
  serviceType: {},
  patientSubject: {},
  onChange: () => {},
  onBack: () => {},
};

export default SubjectSelect;
