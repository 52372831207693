import apiLinks from 'app/utils/api-links';
import httpClient from 'app/utils/http-client';
import { TELE_FORM } from 'app/utils/constants';

import {
  TELE_SET_STEP,
  TELE_SELECT_PATIENT,
  TELE_SELECT_CLINIC,
  TELE_SELECT_DATE,
  TELE_SELECT_SERVICE_TYPE,
  TELE_SELECT_SERVICE,
  TELE_SELECT_EXPERT,
  TELE_GET_SERVICE_TYPE_REQUEST,
  TELE_GET_SERVICE_TYPE_SUCCESS,
  TELE_GET_SERVICE_TYPE_FAILURE,
  TELE_GET_DOCTOR_REQUEST,
  TELE_GET_DOCTOR_SUCCESS,
  TELE_GET_DOCTOR_FAILURE,
  TELE_GET_SERIVCE_BY_DOCTOR_REQUEST,
  TELE_GET_SERIVCE_BY_DOCTOR_SUCCESS,
  TELE_GET_SERIVCE_BY_DOCTOR_FAILURE,
  TELE_GET_HEALTHCARE_BY_HOSPITAL_REQUEST,
  TELE_GET_HEALTHCARE_BY_HOSPITAL_SUCCESS,
  TELE_GET_HEALTHCARE_BY_HOSPITAL_FAILURE,
  TELE_GET_HEALTHCARE_SCHEDULE_REQUEST,
  TELE_GET_HEALTHCARE_SCHEDULE_SUCCESS,
  TELE_GET_HEALTHCARE_SCHEDULE_FAILURE,
  TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_REQUEST,
  TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_SUCCESS,
  TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_FAILURE,
  TELE_CREATE_EXAMINATION_REQUEST,
  TELE_CREATE_EXAMINATION_SUCCESS,
  TELE_CREATE_EXAMINATION_FAILURE,
  TELE_DELETE_EXAMINATION_REQUEST,
  TELE_DELETE_EXAMINATION_SUCCESS,
  TELE_DELETE_EXAMINATION_FAILURE,
  TELE_RESET,
} from './types';

const setStep = (step) => ({ type: TELE_SET_STEP, payload: step });
const selectPatient = (patientInfo) => ({ type: TELE_SELECT_PATIENT, payload: patientInfo });
const selectClinic = (clinic) => ({ type: TELE_SELECT_CLINIC, payload: clinic });
const selectBookingDate = (date) => ({ type: TELE_SELECT_DATE, payload: date });
const selectServiceType = (serviceType) => ({ type: TELE_SELECT_SERVICE_TYPE, payload: serviceType });
const selectService = (service) => ({ type: TELE_SELECT_SERVICE, payload: service });
const selectExpert = (expert) => ({ type: TELE_SELECT_EXPERT, payload: expert });

const getServiceTypeRequest = () => ({ type: TELE_GET_SERVICE_TYPE_REQUEST });
const getServiceTypeSuccess = (response) => ({ type: TELE_GET_SERVICE_TYPE_SUCCESS, payload: response });
const getServiceTypeFailure = (error) => ({ type: TELE_GET_SERVICE_TYPE_FAILURE, payload: error });

const getServiceType = (clinic) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getServiceTypeRequest());
  httpClient.callApi({
    url: apiLinks.getServiceTypes,
    params: {
      HospitalId: clinic.Id,
      Form: TELE_FORM,
    },
  }).then((response) => {
    dispatch(getServiceTypeSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getServiceTypeFailure(error));
    reject();
  });
});

const getServicesByDoctorRequest = (doctor) => ({ type: TELE_GET_SERIVCE_BY_DOCTOR_REQUEST, payload: doctor });
const getServicesByDoctorSuccess = (doctor, response) => ({ type: TELE_GET_SERIVCE_BY_DOCTOR_SUCCESS, payload: { doctor, services: response } });
const getServicesByDoctorFailure = (error) => ({ type: TELE_GET_SERIVCE_BY_DOCTOR_FAILURE, payload: error });

const getServicesByDoctor = (doctor, serviceType) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getServicesByDoctorRequest(doctor));
  httpClient.callApi({
    url: apiLinks.getServicesByDoctor,
    params: {
      DoctorId: doctor,
      ServiceGroup: serviceType,
      Form: TELE_FORM,
    },
  }).then((response) => {
    dispatch(getServicesByDoctorSuccess(doctor, response.data));
    resolve();
  }).catch((error) => {
    dispatch(getServicesByDoctorFailure(error));
    reject();
  });
});

const getDoctorByFormRequest = () => ({ type: TELE_GET_DOCTOR_REQUEST });
const getDoctorByFormSuccess = (response) => ({ type: TELE_GET_DOCTOR_SUCCESS, payload: response });
const getDoctorByFormFailure = (error) => ({ type: TELE_GET_DOCTOR_FAILURE, payload: error });

const getDoctorByForm = (hospital, serviceType) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getDoctorByFormRequest());
  httpClient.callApi({
    url: apiLinks.getDoctor(hospital.Id),
    params: {
      ServiceGroup: serviceType.value,
      Form: TELE_FORM,
    },
  }).then((response) => {
    const doctorList = response.data;
    dispatch(getDoctorByFormSuccess(doctorList));
    doctorList.forEach((d) => {
      dispatch(getServicesByDoctor(d.Id, serviceType.value));
    });
    resolve();
  }).catch((error) => {
    dispatch(getDoctorByFormFailure(error));
    reject();
  });
});

const getHealthCareRequest = () => ({ type: TELE_GET_HEALTHCARE_BY_HOSPITAL_REQUEST });
const getHealthCareSuccess = (response) => ({ type: TELE_GET_HEALTHCARE_BY_HOSPITAL_SUCCESS, payload: response });
const getHealthCareFailure = (error) => ({ type: TELE_GET_HEALTHCARE_BY_HOSPITAL_FAILURE, payload: error });

const getHealthCare = (hospital, type) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getHealthCareRequest());
  httpClient.callApi({
    url: apiLinks.getHealthCare,
    params: {
      HospitalId: hospital.Id,
      Form: TELE_FORM,
      Type: type.value,
    },
  }).then((response) => {
    dispatch(getHealthCareSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getHealthCareFailure(error));
    reject();
  });
});

const getHealthCareScheduleRequest = () => ({ type: TELE_GET_HEALTHCARE_SCHEDULE_REQUEST });
const getHealthCareScheduleSuccess = (response) => ({ type: TELE_GET_HEALTHCARE_SCHEDULE_SUCCESS, payload: response });
const getHealthCareScheduleFailure = (error) => ({ type: TELE_GET_HEALTHCARE_SCHEDULE_FAILURE, payload: error });

const getHealthCareSchedule = (hospital, type, service) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getHealthCareScheduleRequest());
  httpClient.callApi({
    url: apiLinks.getSchedule,
    params: {
      HospitalId: hospital.Id,
      HealthCareId: service.value,
      Type: type.value,
    },
  }).then((response) => {
    dispatch(getHealthCareScheduleSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getHealthCareScheduleFailure(error));
    reject();
  });
});

const getHealthCareScheduleByDoctorRequest = () => ({ type: TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_REQUEST });
const getHealthCareScheduleByDoctorSuccess = (response) => ({ type: TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_SUCCESS, payload: response });
const getHealthCareScheduleByDoctorFailure = (error) => ({ type: TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_FAILURE, payload: error });

const getHealthCareScheduleByDoctor = (hospital, doctor, service) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getHealthCareScheduleByDoctorRequest());
  httpClient.callApi({
    url: apiLinks.getScheduleByDoctor,
    params: {
      HospitalId: hospital.Id,
      DoctorId: doctor,
      ServiceId: service,
    },
  }).then((response) => {
    dispatch(getHealthCareScheduleByDoctorSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getHealthCareScheduleByDoctorFailure(error));
    reject();
  });
});

const createExaminationRequest = () => ({ type: TELE_CREATE_EXAMINATION_REQUEST });
const createExaminationSuccess = (response) => ({ type: TELE_CREATE_EXAMINATION_SUCCESS, payload: response });
const createExaminationFailure = (error) => ({ type: TELE_CREATE_EXAMINATION_FAILURE, payload: error });

const createExamination = (data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(createExaminationRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.createExamination,
    data: {
      HasHealthInsurance: data.HasHealthInsurance,
      HospitalId: data.HospitalId,
      HealthCareSchedulerId: data.HealthCareSchedulerId,
      ServiceId: data.ServiceId,
      CustomerId: data.CustomerId,
      PatientId: data.PatientId,
      DoctorId: data.DoctorId,
      Type: data.Type,
    },
  }).then((response) => {
    dispatch(createExaminationSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(createExaminationFailure(error));
    reject();
  });
});

const deleteExaminationRequest = () => ({ type: TELE_DELETE_EXAMINATION_REQUEST });
const deleteExaminationSuccess = (response) => ({ type: TELE_DELETE_EXAMINATION_SUCCESS, payload: response });
const deleteExaminationFailure = (error) => ({ type: TELE_DELETE_EXAMINATION_FAILURE, payload: error });

const deleteExamination = (id) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(deleteExaminationRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.deleteExamination,
    data: {
      Id: id,
    },
  }).then((response) => {
    dispatch(deleteExaminationSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(deleteExaminationFailure(error));
    reject();
  });
});


const resetTelehealth = () => ({ type: TELE_RESET });

export {
  setStep,
  selectPatient,
  selectClinic,
  selectBookingDate,
  selectServiceType,
  selectService,
  selectExpert,
  getServiceType,
  getDoctorByForm,
  getHealthCare,
  getHealthCareSchedule,
  getHealthCareScheduleByDoctor,
  createExamination,
  deleteExamination,
  resetTelehealth,
};
