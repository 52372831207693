import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Row,
  Col,
  Spinner,
  Alert,
  Label,
} from 'reactstrap';
import {
  FaUserAlt,
  FaCalendarAlt,
  FaTransgender,
  FaAddressBook,
  FaCity,
  FaMap,
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaIdCard,
} from 'react-icons/fa';
import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';

import locations from 'app/mock/locations.json';

import moment from 'moment';
import Select from 'react-select';
import { useToasts } from 'react-toast-notifications';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import { useDispatch, useSelector } from 'react-redux';
import { updateCustomer } from 'app/actions/global';
import { getProfiles as getImmunizationProfiles } from 'immunization/actions/immunization';
import { getProfiles as getExaminationProfiles } from 'covid-examination/actions/examination';

const DateWrapper = styled.div`
  width: 100%;
  position: relative;
  flex: 1 1;
  min-width: 0;
  margin-bottom: 0;
`;

const genderOptions = [
  { key: 1, value: 1, label: 'Nam' },
  { key: 0, value: 0, label: 'Nữ' },
];

const StyledLabel = styled.span`
  position: absolute;
  left: 13px;
  top: 7px;
  color: #999;
  display: ${(props) => (props.dob ? 'none' : 'inline-block')};
  pointer-events: none;
  z-index: -1;
`;

const PatientModal = (props) => {
  const {
    open,
    onClose,
    isCovid,
    isRelative,
    initialData,
  } = props;
  const dispatch = useDispatch();

  const [fullname, setFullname] = useState('');
  const [gender, setGender] = useState(1);
  const [dob, setDob] = useState(null);
  const [address, setAddress] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({ province: null, district: null, ward: null });
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [healthInsuranceId, setHealthInsuranceId] = useState('');
  const [identityCard, setIdentityCard] = useState('');
  const { userInfo } = useSelector((state) => state.auth);
  const { profileList } = useSelector((state) => state.immunization);

  useEffect(() => {
    if (initialData) {
      setFullname(initialData?.FullName);
      setGender(initialData?.Gender ? 1 : 0);
      setDob(moment(initialData?.BirthDate).format('DD-MM-YYYY'));
      setAddress(initialData?.Address);
      setSelectedLocation({
        province: initialData?.ProvinceCode ? (locations.find((p) => p.Value === initialData?.ProvinceCode) || null) : null,
        district: initialData?.DistrictCode ? (locations.find((p) => p.Value === initialData?.ProvinceCode).Districts.find((d) => d.Value === initialData?.DistrictCode) || null) : null,
        ward: initialData?.WardCode ? (locations.find((p) => p.Value === initialData?.ProvinceCode).Districts.find((d) => d.Value === initialData?.DistrictCode).Wards.find((w) => w.Value === initialData?.WardCode) || null) : null,
      });
      setPhone(initialData?.Phone);
      setEmail(initialData?.Email);
      setHealthInsuranceId(initialData?.HealthInsurance);
      setIdentityCard(initialData?.IC);
    }
  }, [initialData]);

  const styles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const handleKeyUp = (e, setDate) => {
    const inputValue = e.target.value;
    if (inputValue) {
      setDate(null);
    }
    const d = inputValue.replace(/\D/g, '').slice(0, 10);
    if (d.length >= 5) {
      const stringResult = `${d.slice(0, 2)}-${d.slice(2, 4)}-${d.slice(4)}`;
      setDate(stringResult);
    } else if (d.length >= 3) {
      setDate(`${d.slice(0, 2)}-${d.slice(2)}`);
    } else {
      setDate(d);
    }
  };

  const { addToast } = useToasts();
  const [updateFailed, setUpdateFailed] = useState(false);
  const [loadingEditInfo, setLoadingEditInfo] = useState(false);

  const editInfo = (e) => {
    e.preventDefault();
    setLoadingEditInfo(true);
    const data = {
      Fullname: fullname,
      Phone: phone,
      Email: email,
      HealthInsurance: healthInsuranceId,
      Address: address,
      BirthDate: moment(dob).format('YYYY-MM-DD'),
      Gender: gender === 1,
      ProvinceCode: selectedLocation.province.Value,
      DistrictCode: selectedLocation.district.Value,
      WardCode: selectedLocation.ward.Value,
      Id: initialData.Id,
    };
    dispatch(updateCustomer(data))
      .then(() => {
        setUpdateFailed(false);
        setLoadingEditInfo(false);
        addToast('Sửa thông tin thành công', { appearance: 'success' });
        dispatch(getImmunizationProfiles(userInfo?.Id));
        dispatch(getExaminationProfiles(userInfo?.Id));
        const patient = data.Id === userInfo?.Id ? userInfo : profileList.find((p) => p.Id === data.Id);
        onClose(patient);
      });
  };

  const { updateErrorMessage } = useSelector((state) => state.global);

  return (
    <Modal
      size="xl"
      isOpen={open}
      toggle={onClose}
      className="required-form"
    >
      <ModalHeader toggle={onClose}>
        Chỉnh sửa thông tin
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={editInfo}>
          <Row>
            <Col sm="12" xl="36" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaUserAlt />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  required
                  invalid={!fullname}
                  value={fullname || ''}
                  onChange={(e) => {
                    setFullname(e.target.value);
                    setUpdateFailed(false);
                  }}
                />
                <Label>Họ và tên</Label>
              </InputGroup>
            </Col>
            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaTransgender />
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                  required
                  styles={styles}
                  placeholder="Giới tính"
                  defaultValue={{ label: gender ? 'Nam' : 'Nữ', value: gender ? 1 : 0 }}
                  options={genderOptions}
                  onChange={(e) => {
                    setGender(e.value);
                    setUpdateFailed(false);
                  }}
                />
              </InputGroup>
            </Col>
            {isRelative && (
              <Col sm="6" xl="3" xs="12" className="mt-3">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaPhone />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    value={phone || ''}
                    onChange={(e) => {
                      setPhone(e.target.value);
                      setUpdateFailed(false);
                    }}
                  />
                  <Label>Số điện thoại</Label>
                </InputGroup>
              </Col>
            )}

            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaCalendarAlt />
                  </InputGroupText>
                </InputGroupAddon>
                <DateWrapper>
                  <DayPickerInput
                    required={!isRelative}
                    style={{ width: '100%' }}
                    value={dob}
                    format="DD-MM-YYYY"
                    parseDate={parseDate}
                    formatDate={formatDate}
                    placeholder="Ngày sinh"
                    dayPickerProps={{
                      modifiers: {
                        disabled: [
                          {
                            after: new Date(),
                          },
                        ],
                      },
                    }}
                    inputProps={{
                      className: 'form-control',
                      maxLength: 10,
                      onKeyUp: (e) => handleKeyUp(e, setDob),
                    }}
                    onDayChange={(date) => {
                      if (date) {
                        setDob(date);
                        setUpdateFailed(false);
                      }
                    }}
                  />
                  {!isRelative && (
                    <StyledLabel dob={dob}>
                      Ngày sinh
                      <span className="text-danger pl-1">*</span>
                    </StyledLabel>
                  )}
                </DateWrapper>
              </InputGroup>
            </Col>

            {!isRelative && (
              <Col sm="6" xl="3" xs="12" className="mt-3">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaPhone />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Số điện thoại"
                    value={phone || ''}
                    onChange={(e) => {
                      setPhone(e.target.value);
                      setUpdateFailed(false);
                    }}
                  />
                </InputGroup>
              </Col>
            )}

            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaEnvelope />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email"
                  value={email || ''}
                  onChange={(e) => {
                    setUpdateFailed(false);
                    setEmail(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>

            {isCovid && (
              <Col sm="6" xl="3" xs="12" className="mt-3">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaIdCard />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Mã định danh"
                    value={identityCard || ''}
                    onChange={(e) => {
                      setIdentityCard(e.target.value);
                      setUpdateFailed(false);
                    }}
                  />
                </InputGroup>
              </Col>
            )}

            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaAddressBook />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  required={!isRelative}
                  value={address || ''}
                  placeholder={isRelative ? 'Số nhà, đường' : ''}
                  onChange={(e) => {
                    setUpdateFailed(false);
                    setAddress(e.target.value);
                  }}
                />
                {!isRelative && <Label>Số nhà, đường</Label>}
              </InputGroup>
            </Col>
            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaCity />
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                  required={!isRelative}
                  isSearchable
                  placeholder="Tỉnh/Thành phố"
                  value={selectedLocation?.province?.Value ? {
                    value: selectedLocation?.province?.Value,
                    label: selectedLocation?.province?.Name,
                  } : null}
                  noOptionsMessage={() => <span>Không tìm thấy</span>}
                  styles={styles}
                  onChange={(el) => setSelectedLocation({
                    province: locations.find(
                      (province) => province.Value === el.value,
                    ),
                    district: null,
                    ward: null,
                  })}
                  options={locations.map((province) => ({
                    value: province.Value,
                    label: province.Name,
                  }))}
                />
              </InputGroup>
            </Col>
            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaMap />
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                  required={!isRelative}
                  isSearchable
                  isDisabled={!selectedLocation.province}
                  placeholder="Quận/Huyện"
                  value={selectedLocation?.district?.Value ? {
                    value: selectedLocation?.district?.Value,
                    label: selectedLocation?.district?.Name,
                  } : null}
                  noOptionsMessage={() => <span>Không tìm thấy</span>}
                  styles={styles}
                  onChange={(el) => setSelectedLocation({
                    ...selectedLocation,
                    district: selectedLocation.province.Districts.find(
                      (district) => district.Value === el.value,
                    ),
                    ward: null,
                  })}
                  options={
                    selectedLocation.province
                    && selectedLocation.province.Districts.map((district) => ({
                      value: district.Value,
                      label: district.Name,
                    }))
                  }
                />
              </InputGroup>
            </Col>
            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaMapMarkerAlt />
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                  required={!isRelative}
                  isDisabled={!selectedLocation.district}
                  placeholder="Phường/Xã"
                  value={selectedLocation?.ward?.Value ? {
                    value: selectedLocation?.ward?.Value,
                    label: selectedLocation?.ward?.Name,
                  } : null}
                  noOptionsMessage={() => <span>Không tìm thấy</span>}
                  isSearchable
                  styles={styles}
                  onChange={(el) => setSelectedLocation({
                    ...selectedLocation,
                    ward: selectedLocation.district.Wards.find(
                      (ward) => ward.Value === el.value,
                    ),
                  })}
                  options={
                    selectedLocation.district
                    && selectedLocation.district.Wards.map((ward) => ({
                      value: ward.Value,
                      label: ward.Name,
                    }))
                  }
                />
              </InputGroup>
            </Col>
            {/* <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaBookOpen />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  value={healthInsuranceId || ''}
                  placeholder="Số bảo hiểm y tế"
                  onChange={(e) => {
                    setUpdateFailed(false);
                    setHealthInsuranceId(e.target.value);
                  }}
                />
              </InputGroup>
            </Col> */}
            <Col xs="12" className="mt-3">
              <span className="text-danger">(*): Thông tin bắt buộc</span>
            </Col>
            <Col xs="12" className="d-flex justify-content-center mt-3">
              {loadingEditInfo ? (
                <Spinner color="info" />
              ) : (
                <Button color="success" disabled={updateFailed}>
                  Xác nhận
                </Button>
              )}
            </Col>
            {updateFailed && (
              <Col xs="12" className="d-flex justify-content-center mt-3">
                <Alert color="danger">
                  {updateErrorMessage}
                </Alert>
              </Col>
            )}
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

PatientModal.propTypes = {
  open: PropTypes.bool,
  isRelative: PropTypes.bool,
  isCovid: PropTypes.bool,
  onClose: PropTypes.func,
  initialData: PropTypes.shape({
    FullName: PropTypes.string,
    Phone: PropTypes.string,
    Email: PropTypes.string,
    HealthInsurance: PropTypes.string,
    HealthInsurancePlace: PropTypes.string,
    HealthInsuranceStartDate: PropTypes.string,
    HealthInsuranceEndDate: PropTypes.string,
    Address: PropTypes.string,
    BirthDate: PropTypes.string,
    Gender: PropTypes.bool,
    ProvinceCode: PropTypes.string,
    DistrictCode: PropTypes.string,
    WardCode: PropTypes.string,
    IC: PropTypes.string,
    ImmunizationCode: PropTypes.string,
    Id: PropTypes.number,
  }),
};

PatientModal.defaultProps = {
  open: false,
  isRelative: false,
  isCovid: false,
  onClose: () => {},
  initialData: {},
};

export default PatientModal;
