/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  CardImg,
  Card,
  CardBody,
  CardSubtitle,
  Button,
  Alert,
  CardTitle,
} from 'reactstrap';

import locations from 'app/mock/locationsV2.json';

import { useDispatch, useSelector } from 'react-redux';

import { getTicketsWithoutDate } from 'covid-examination/actions/examination';
import ClinicFilter from './ClinicFilter';


const StyledCol4 = styled(Col)`
  border-right: 1px solid #b6b6b6;
`;

const StyledCardBody = styled(CardBody)`
  height: 57px !important;
  padding-left: .25rem !important;
  padding-right: .25rem !important;
  padding-top: .7rem !important;
  border-top: 1px dashed rgba(0,0,0,.2);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const StyledCardImg = styled(CardImg)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100px;
`;

const StyledCard = styled(Card)`
  border: ${(props) => (props.active ? '2px solid rgb(226, 107, 71)' : '')};
  cursor: pointer;
`;

const ClinicModal = (props) => {
  const { open, onClose, onSubmit } = props;

  const [filter, setFilter] = useState(null);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const { clinicList, roomList } = useSelector((state) => state.examination);
  const [filteredClinic, setFilteredClinic] = useState(clinicList);

  useEffect(() => {
    if (filter) {
      const { searchValue, selectingUnitType, selectedLocation } = filter;
      const result = clinicList.filter((c) => (
        searchValue ? c.Name.toLowerCase().includes(searchValue) : true)
        && (selectingUnitType ? c.UnitTypeId === selectingUnitType?.value : true)
        && (selectedLocation?.province ? c.ProvinceCode == selectedLocation?.province?.value : true)
        && (selectedLocation?.district ? c.DistrictCode == selectedLocation?.district?.value : true)
        && (selectedLocation?.ward ? c.WardCode == selectedLocation?.ward?.value : true));
      // );
      setFilteredClinic(result);
    }
  }, [filter, clinicList]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedClinic) {
      dispatch(getTicketsWithoutDate(selectedClinic.Id));
    }
  }, [dispatch, selectedClinic]);

  const [roomModal, setRoomModal] = useState(false);
  const [roomData, setRoomData] = useState([]);
  useEffect(() => {
    if (roomList.length > 1) {
      setRoomModal(true);
      setRoomData(roomList.map((r) => {
        const [name, address, wardValue, districtValue, provinceValue] = r.name.split(':');
        return {
          ...r,
          name,
          address,
          province: locations.find((p) => p.value === provinceValue)?.label ?? '',
          district: locations.find((p) => p.value === provinceValue)?.districts.find((d) => d.value === districtValue)?.label ?? '',
          ward: locations.find((p) => p.value === provinceValue)?.districts.find((d) => d.value === districtValue)?.wards.find((w) => w.value === wardValue)?.label ?? '',
        };
      }));
    } else {
      setRoomModal(false);
    }
  }, [roomList]);

  return (
    <Modal size="xl" isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>Chọn cơ sở xét nghiệm</ModalHeader>
      <ModalBody>
        <Row>
          {/* hide filter */}
          <StyledCol4 sm={4} className="mt-3">
            <ClinicFilter filter={filter} onChange={setFilter} />
          </StyledCol4>
          <Col sm={8} className="mt-3">
            <Row>
              {(filteredClinic || []).map((e) => (
                <Col xs="6" lg="4" key={e.Id} className="mb-3">
                  <StyledCard
                    active={
                      selectedClinic !== null && selectedClinic.Id === e.Id
                        ? 1
                        : 0
                    }
                    onClick={() => setSelectedClinic(e)}
                  >
                    <ImageWrapper>
                      <StyledCardImg src={e.Image} alt="Hospital Image" />
                    </ImageWrapper>
                    <StyledCardBody>
                      <CardSubtitle>{e.Name}</CardSubtitle>
                    </StyledCardBody>
                  </StyledCard>
                </Col>
              ))}
            </Row>
            {filteredClinic.length === 0 && filter && (
              <Alert color="danger">
                Không tìm thấy cơ sở phù hợp
              </Alert>
            )}
            {filteredClinic.length > 0 && (
              <Row>
                <Col sm={12} className="text-left">
                  <Button
                    color="info"
                    disabled={!selectedClinic}
                    onClick={() => onSubmit(selectedClinic)}
                  >
                    Chọn
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Modal size="sm" isOpen={roomModal} toggle={() => setRoomModal(false)}>
          <ModalHeader toggle={() => setRoomModal(false)}>Chọn cơ sở trực thuộc</ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={12} className="mt-3">
                <Row>
                  {(roomData || []).map((e) => (
                    <Col sm="12" key={e.id} className="mb-3">
                      <StyledCard
                        active={
                          selectedRoom !== null && selectedRoom.id === e.id
                            ? 1
                            : 0
                        }
                        onClick={() => setSelectedRoom(e)}
                      >
                        <CardBody>
                          <CardTitle>{e.name}</CardTitle>
                          <CardSubtitle>
                            Địa chỉ:
                            {e.address}
                            ,
                            {' '}
                            {e.ward}
                            ,
                            {' '}
                            {e.district}
                            {/* ,
                            {' '}
                            {e.province} */}
                          </CardSubtitle>
                        </CardBody>
                      </StyledCard>
                    </Col>
                  ))}
                </Row>
                <Row>
                  <Col sm={12} className="text-left">
                    <Button
                      color="info"
                      disabled={!selectedRoom}
                      onClick={() => {
                        setRoomModal(false);
                        onSubmit(selectedClinic, selectedRoom);
                      }}
                    >
                      Chọn
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </ModalBody>
    </Modal>
  );
};

ClinicModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

ClinicModal.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
};

export default ClinicModal;
