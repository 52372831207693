import React from 'react';
import {
  Nav,
  Navbar,
  NavItem,
  Collapse,
  Button,
} from 'reactstrap';
import {
  FaBars,
} from 'react-icons/fa';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { useWindowSize } from 'app/hooks';
import { toggleSidebar } from '../actions/global';
import UserInfo from './UserInfo';

const handlePadding = (props) => {
  if (props.sidebarcollapsed && props.size > 515) {
    return '80px';
  }
  if (props.sidebarcollapsed && props.size < 515) {
    return '0';
  }
  if (!props.sidebarcollapsed && props.size < 515) {
    return '233px';
  }
  return '300px';
};

const StyledNavbar = styled(Navbar)`
  background: hsla(0, 0%, 100%, 0.95);
  box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03),
    0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03),
    0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05),
    0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);
  transition: all 0.3s ease-in-out;
  padding-left: ${(props) => handlePadding(props)};
`;

const StyledButton = styled(Button)`
  padding: 0 .5rem !important;
`;

const StyleNavbar = () => {
  const { sidebarCollapsed } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const windowSize = useWindowSize();

  return (
    <StyledNavbar
      light
      color="light"
      expand="xs"
      sidebarcollapsed={sidebarCollapsed ? 1 : 0}
      size={windowSize.width.toString()}
    >
      <Collapse navbar>
        <Nav navbar className="w-100">
          <NavItem
            className="mx-3 mr-auto"
            onClick={() => dispatch(toggleSidebar(!sidebarCollapsed))}
          >
            <StyledButton size="sm" color="info">
              <FaBars size={20} className="my-2" />
            </StyledButton>
          </NavItem>
          <UserInfo />
        </Nav>
      </Collapse>
    </StyledNavbar>
  );
};

export default StyleNavbar;
