import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { ServicesOptions } from 'app/utils/constants';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const styles = {
  container: (base) => ({
    ...base,
    flex: 1,
  }),
};

const services = [
  { url: '/immunization-booking-history', value: 'Lịch sử tiêm chủng' },
  { url: '/covid-examination-history', value: 'Lịch sử xét nghiệm' },
];

const HistorySelect = (props) => {
  const { defaultValue } = props;
  const history = useHistory();
  const {
    token: { access_token: token },
  } = useSelector((state) => state.auth);
  return (
    <>
      <h5>Lịch sử sử dụng dịch vụ</h5>
      <Select
        required
        styles={styles}
        placeholder="Lịch sử dịch vụ"
        options={services.map((s) => ({
          key: s.value,
          value: s.url,
          label: s.value,
          // isDisabled: s.isDisabled,
        }))}
        defaultValue={ServicesOptions.find((s) => s.value === defaultValue)}
        onChange={(e) => {
          history.push(`${e.value}?s=${token}`);
        }}
      />
    </>
  );
};

HistorySelect.propTypes = {
  defaultValue: PropTypes.number.isRequired,
};

export default HistorySelect;
