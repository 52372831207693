import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Select from 'react-select';

import { useSelector, useDispatch } from 'react-redux';
import { getByHealthcare } from 'immunization/actions/immunization';

import ButtonFragment from './ButtonFragment';
import ClinicModal from './ClinicModal';

const StyledSelect = styled(Select)`
  max-width: 350px;
  width: 100%;
  background-color: transparent;
`;

const ClinicSelect = (props) => {
  const {
    patientId,
    initialHealthcareId,
    onChange,
    onBack,
  } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    if (initialHealthcareId && patientId) {
      dispatch(getByHealthcare(initialHealthcareId, patientId));
    }
  }, [
    patientId,
    initialHealthcareId,
    dispatch,
  ]);


  const { loadingGetHealthcare } = useSelector((state) => state.immunization);

  const [selectingClinic, setSelectingClinic] = useState(null);
  const [modal, setModal] = useState(false);

  const clinicSelect = useMemo(() => (
    <StyledSelect
      isDisabled={loadingGetHealthcare}
      isLoading={loadingGetHealthcare}
      value={selectingClinic?.Id}
      className="basic-single mb-2"
      classNamePrefix="select"
      name="color"
      placeholder={selectingClinic?.Name ?? 'Cơ sở tiêm'}
      onMenuOpen={() => setModal(true)}
    />
  ), [
    loadingGetHealthcare,
    selectingClinic,
  ]);

  return (
    <div>
      {clinicSelect}
      <ButtonFragment
        disabled={!selectingClinic}
        onGoNext={() => onChange(selectingClinic)}
        onGoBack={onBack}
      />

      <ClinicModal
        key={modal ? 'OpenClinicModal' : 'CloseClinicModal'}
        open={modal}
        onClose={() => setModal(false)}
        onSubmit={(d) => {
          setModal(false);
          setSelectingClinic(d);
          onChange(d);
        }}
      />
    </div>
  );
};

ClinicSelect.propTypes = {
  initialHealthcareId: PropTypes.number,
  patientId: PropTypes.number,
  onChange: PropTypes.func,
  onBack: PropTypes.func,
};

ClinicSelect.defaultProps = {
  initialHealthcareId: 0,
  patientId: 0,
  onChange: () => {},
  onBack: () => {},
};


export default ClinicSelect;
