/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { useDispatch, useSelector } from 'react-redux';
import { getByFormV2 } from 'immunization/actions/immunization';

import ButtonFragment from './ButtonFragment';

const StyledSelect = styled(Select)`
  max-width: 350px;
  width: 100%;
  background-color: transparent;
`;

const NewDiseaseSelect = (props) => {
  const {
    onChange,
    onBack,
    selectingObject,
    selectingServiceType,
  } = props;

  const [selectingSyringe, setSelectingSyringe] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectingObject) {
      dispatch(getByFormV2(selectingObject.value));
    }
  }, [dispatch, selectingObject]);

  const {
    healthCareList,
    loadingGetHealthcare,
  } = useSelector((state) => state.immunization);


  const syringeSelect = useMemo(() => (
    <StyledSelect
      isSearchable
      isLoading={loadingGetHealthcare}
      className="basic-single mb-2"
      classNamePrefix="select"
      name="color"
      placeholder="Bệnh cần tiêm"
      noOptionsMessage={() => {
        if (healthCareList.length === 0) {
          return (
            <span>Không tìm thấy bệnh cần tiêm cho đối tượng này</span>
          );
        }
        return (
          <span>Không tìm thấy bệnh cần tiêm phù hợp với loại hình tiêm này</span>
        );
      }}
      options={healthCareList
        .filter((e) => e.ServiceTypeId === selectingServiceType?.value)
        .map((e) => ({
          key: e._id,
          value: e.Id,
          label: e.Name,
        }))}
      onChange={(d) => {
        if (d) {
          if (d.value !== selectingSyringe?.value) {
            setSelectingSyringe(d);
            onChange(d);
          }
        } else {
          setSelectingSyringe(null);
          onChange(null);
        }
      }}
    />
  ), [
    onChange,
    healthCareList,
    selectingSyringe,
    loadingGetHealthcare,
    selectingServiceType,
  ]);

  return (
    <div>
      {syringeSelect}
      <ButtonFragment
        disabled={!selectingSyringe}
        onGoNext={() => onChange(selectingSyringe)}
        onGoBack={onBack}
      />
    </div>
  );
};

NewDiseaseSelect.propTypes = {
  selectingServiceType: PropTypes.shape({
    value: PropTypes.number,
  }),
  selectingObject: PropTypes.shape({
    value: PropTypes.number,
  }),
  onChange: PropTypes.func,
  onBack: PropTypes.func,
};

NewDiseaseSelect.defaultProps = {
  selectingServiceType: {},
  selectingObject: {},
  onChange: () => {},
  onBack: () => {},
};

export default NewDiseaseSelect;
