import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Button,
} from 'reactstrap';

import CovidTable from './CovidTable';

const CovidModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
  } = props;

  const [haveCovid, setHaveCovid] = useState([]);
  const [selectingNations, setSelectingNations] = useState([]);

  return (
    <Modal
      size="xl"
      isOpen={open}
      toggle={onClose}
    >
      <ModalHeader toggle={onClose}>
        Tờ khai y tế
        <span style={{ fontSize: '15px', paddingLeft: '5px' }}>(Dành cho bệnh nhân/Trẻ)</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm={12}>
            <span>Vì sức khỏe của chính mình và cộng đồng, Ông/Bà vui lòng điền các thông tin dưới đây</span>
          </Col>
          <Col sm={12} className="mt-3">
            <CovidTable
              onCovidChange={(d) => setHaveCovid(d)}
              onNationsChange={(d) => setSelectingNations(d)}
            />
          </Col>
          <Col sm={12}>
            <span>Tôi cam kết những thông tin trên là đúng sự thật, tôi hiểu rằng nếu cung cấp sai thông tin có thể dẫn đến những hậu quả nghiêm trọng.</span>
          </Col>
          <Col sm={12} className="text-center">
            <Button color="info" onClick={() => onSubmit({ haveCovid, selectingNations })}>Xác nhận</Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

CovidModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

CovidModal.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
};

export default CovidModal;
