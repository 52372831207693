import apiLinks from 'app/utils/api-links';
import httpClient from 'app/utils/http-client';
import { HOMECARE_FORM } from 'app/utils/constants';

import {
  HOMECARE_SET_STEP,
  HOMECARE_SELECT_PATIENT,
  HOMECARE_SELECT_CLINIC,
  HOMECARE_SELECT_DATE,
  HOMECARE_SELECT_SERVICE_TYPE,
  HOMECARE_SELECT_SERVICE,
  HOMECARE_SELECT_EXPERT,
  HOMECARE_GET_SERVICE_TYPE_REQUEST,
  HOMECARE_GET_SERVICE_TYPE_SUCCESS,
  HOMECARE_GET_SERVICE_TYPE_FAILURE,
  HOMECARE_GET_DOCTOR_REQUEST,
  HOMECARE_GET_DOCTOR_SUCCESS,
  HOMECARE_GET_DOCTOR_FAILURE,
  HOMECARE_GET_SERIVCE_BY_DOCTOR_REQUEST,
  HOMECARE_GET_SERIVCE_BY_DOCTOR_SUCCESS,
  HOMECARE_GET_SERIVCE_BY_DOCTOR_FAILURE,
  HOMECARE_GET_HEALTHCARE_BY_HOSPITAL_REQUEST,
  HOMECARE_GET_HEALTHCARE_BY_HOSPITAL_SUCCESS,
  HOMECARE_GET_HEALTHCARE_BY_HOSPITAL_FAILURE,
  HOMECARE_GET_HEALTHCARE_SCHEDULE_REQUEST,
  HOMECARE_GET_HEALTHCARE_SCHEDULE_SUCCESS,
  HOMECARE_GET_HEALTHCARE_SCHEDULE_FAILURE,
  HOMECARE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_REQUEST,
  HOMECARE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_SUCCESS,
  HOMECARE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_FAILURE,
  HOMECARE_CREATE_EXAMINATION_REQUEST,
  HOMECARE_CREATE_EXAMINATION_SUCCESS,
  HOMECARE_CREATE_EXAMINATION_FAILURE,
  HOMECARE_DELETE_EXAMINATION_REQUEST,
  HOMECARE_DELETE_EXAMINATION_SUCCESS,
  HOMECARE_DELETE_EXAMINATION_FAILURE,
  HOMECARE_RESET,
  HOMECARE_SET_ADDRESS,
  HOMECARE_SET_PHONE,
} from './types';

const setStep = (step) => ({ type: HOMECARE_SET_STEP, payload: step });
const setPhone = (phone) => ({ type: HOMECARE_SET_PHONE, payload: phone });
const setAddress = (step) => ({ type: HOMECARE_SET_ADDRESS, payload: step });
const selectPatient = (patientInfo) => ({ type: HOMECARE_SELECT_PATIENT, payload: patientInfo });
const selectClinic = (clinic) => ({ type: HOMECARE_SELECT_CLINIC, payload: clinic });
const selectBookingDate = (date) => ({ type: HOMECARE_SELECT_DATE, payload: date });
const selectServiceType = (serviceType) => ({ type: HOMECARE_SELECT_SERVICE_TYPE, payload: serviceType });
const selectService = (service) => ({ type: HOMECARE_SELECT_SERVICE, payload: service });
const selectExpert = (expert) => ({ type: HOMECARE_SELECT_EXPERT, payload: expert });

const getServiceTypeRequest = () => ({ type: HOMECARE_GET_SERVICE_TYPE_REQUEST });
const getServiceTypeSuccess = (response) => ({ type: HOMECARE_GET_SERVICE_TYPE_SUCCESS, payload: response });
const getServiceTypeFailure = (error) => ({ type: HOMECARE_GET_SERVICE_TYPE_FAILURE, payload: error });

const getServiceType = (clinic) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getServiceTypeRequest());
  httpClient.callApi({
    url: apiLinks.getServiceTypes,
    params: {
      HospitalId: clinic.Id,
      Form: HOMECARE_FORM,
    },
  }).then((response) => {
    dispatch(getServiceTypeSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getServiceTypeFailure(error));
    reject();
  });
});

const getServicesByDoctorRequest = (doctor) => ({ type: HOMECARE_GET_SERIVCE_BY_DOCTOR_REQUEST, payload: doctor });
const getServicesByDoctorSuccess = (doctor, response) => ({ type: HOMECARE_GET_SERIVCE_BY_DOCTOR_SUCCESS, payload: { doctor, services: response } });
const getServicesByDoctorFailure = (error) => ({ type: HOMECARE_GET_SERIVCE_BY_DOCTOR_FAILURE, payload: error });

const getServicesByDoctor = (doctor, serviceType) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getServicesByDoctorRequest(doctor));
  httpClient.callApi({
    url: apiLinks.getServicesByDoctor,
    params: {
      DoctorId: doctor,
      ServiceGroup: serviceType,
      Form: HOMECARE_FORM,
    },
  }).then((response) => {
    dispatch(getServicesByDoctorSuccess(doctor, response.data));
    resolve();
  }).catch((error) => {
    dispatch(getServicesByDoctorFailure(error));
    reject();
  });
});

const getDoctorByFormRequest = () => ({ type: HOMECARE_GET_DOCTOR_REQUEST });
const getDoctorByFormSuccess = (response) => ({ type: HOMECARE_GET_DOCTOR_SUCCESS, payload: response });
const getDoctorByFormFailure = (error) => ({ type: HOMECARE_GET_DOCTOR_FAILURE, payload: error });

const getDoctorByForm = (hospital, serviceType) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getDoctorByFormRequest());
  httpClient.callApi({
    url: apiLinks.getDoctor(hospital.Id),
    params: {
      ServiceGroup: serviceType.value,
      Form: HOMECARE_FORM,
    },
  }).then((response) => {
    const doctorList = response.data;
    dispatch(getDoctorByFormSuccess(doctorList));
    doctorList.forEach((d) => {
      dispatch(getServicesByDoctor(d.Id, serviceType.value));
    });
    resolve();
  }).catch((error) => {
    dispatch(getDoctorByFormFailure(error));
    reject();
  });
});

const getHealthCareRequest = () => ({ type: HOMECARE_GET_HEALTHCARE_BY_HOSPITAL_REQUEST });
const getHealthCareSuccess = (response) => ({ type: HOMECARE_GET_HEALTHCARE_BY_HOSPITAL_SUCCESS, payload: response });
const getHealthCareFailure = (error) => ({ type: HOMECARE_GET_HEALTHCARE_BY_HOSPITAL_FAILURE, payload: error });

const getHealthCare = (hospital, type) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getHealthCareRequest());
  httpClient.callApi({
    url: apiLinks.getHealthCare,
    params: {
      HospitalId: hospital.Id,
      Form: HOMECARE_FORM,
      Type: type.value,
    },
  }).then((response) => {
    dispatch(getHealthCareSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getHealthCareFailure(error));
    reject();
  });
});

const getHealthCareScheduleRequest = () => ({ type: HOMECARE_GET_HEALTHCARE_SCHEDULE_REQUEST });
const getHealthCareScheduleSuccess = (response) => ({ type: HOMECARE_GET_HEALTHCARE_SCHEDULE_SUCCESS, payload: response });
const getHealthCareScheduleFailure = (error) => ({ type: HOMECARE_GET_HEALTHCARE_SCHEDULE_FAILURE, payload: error });

const getHealthCareSchedule = (hospital, type, service) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getHealthCareScheduleRequest());
  httpClient.callApi({
    url: apiLinks.getSchedule,
    params: {
      HospitalId: hospital.Id,
      HealthCareId: service.value,
      Type: type.value,
    },
  }).then((response) => {
    dispatch(getHealthCareScheduleSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getHealthCareScheduleFailure(error));
    reject();
  });
});

const getHealthCareScheduleByDoctorRequest = () => ({ type: HOMECARE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_REQUEST });
const getHealthCareScheduleByDoctorSuccess = (response) => ({ type: HOMECARE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_SUCCESS, payload: response });
const getHealthCareScheduleByDoctorFailure = (error) => ({ type: HOMECARE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_FAILURE, payload: error });

const getHealthCareScheduleByDoctor = (hospital, doctor, service) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getHealthCareScheduleByDoctorRequest());
  httpClient.callApi({
    url: apiLinks.getScheduleByDoctor,
    params: {
      HospitalId: hospital.Id,
      DoctorId: doctor,
      ServiceId: service,
    },
  }).then((response) => {
    dispatch(getHealthCareScheduleByDoctorSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getHealthCareScheduleByDoctorFailure(error));
    reject();
  });
});

const createExaminationRequest = () => ({ type: HOMECARE_CREATE_EXAMINATION_REQUEST });
const createExaminationSuccess = (response) => ({ type: HOMECARE_CREATE_EXAMINATION_SUCCESS, payload: response });
const createExaminationFailure = (error) => ({ type: HOMECARE_CREATE_EXAMINATION_FAILURE, payload: error });

const createExamination = (data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(createExaminationRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.createExamination,
    data: {
      HasHealthInsurance: data.HasHealthInsurance,
      HospitalId: data.HospitalId,
      HealthCareSchedulerId: data.HealthCareSchedulerId,
      ServiceId: data.ServiceId,
      CustomerId: data.CustomerId,
      PatientId: data.PatientId,
      DoctorId: data.DoctorId,
      Type: data.Type,
    },
  }).then((response) => {
    dispatch(createExaminationSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(createExaminationFailure(error));
    reject();
  });
});

const deleteExaminationRequest = () => ({ type: HOMECARE_DELETE_EXAMINATION_REQUEST });
const deleteExaminationSuccess = (response) => ({ type: HOMECARE_DELETE_EXAMINATION_SUCCESS, payload: response });
const deleteExaminationFailure = (error) => ({ type: HOMECARE_DELETE_EXAMINATION_FAILURE, payload: error });

const deleteExamination = (id) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(deleteExaminationRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.deleteExamination,
    data: {
      Id: id,
    },
  }).then((response) => {
    dispatch(deleteExaminationSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(deleteExaminationFailure(error));
    reject();
  });
});


const resetHomecare = () => ({ type: HOMECARE_RESET });

export {
  setStep,
  setPhone,
  setAddress,
  selectPatient,
  selectClinic,
  selectBookingDate,
  selectServiceType,
  selectService,
  selectExpert,
  getServiceType,
  getDoctorByForm,
  getHealthCare,
  getHealthCareSchedule,
  getHealthCareScheduleByDoctor,
  createExamination,
  deleteExamination,
  resetHomecare,
};
