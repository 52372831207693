import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';

import Steps, { Step } from 'rc-steps';
import { Container, Spinner } from 'reactstrap';
import { FaCheck } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';

import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';

import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { setToken, getUserInfo } from 'app/actions/auth';
import { register, setStep, getProfiles } from '../actions/examination';

import ClinicSelect from '../components/ClinicSelect';
import DateSelect from '../components/DateSelect';
import PatientSelect from '../components/PatientSelect';
import Appointment from '../components/Appointment';

const StyledDiv = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Wrapper = styled.div`
  /* width: 100vw;
  height: 100vh; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const spinner = (
  <Wrapper>
    <Spinner color="info" />
  </Wrapper>
);

const useQuery = () => new URLSearchParams(useLocation().search);

const CovidExaminationPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery(location);

  const tokenParam = query.get('s');

  useEffect(() => {
    dispatch(setToken(tokenParam));
  }, [dispatch, tokenParam]);

  // component
  const {
    currentStep,
    loadingRegister,
    registerData,
    loadingGetProfiles,
    doctorList,
  } = useSelector((state) => state.examination);

  const { token, userInfo } = useSelector((state) => state.auth);
  const isLoading = userInfo === null || loadingGetProfiles;

  useEffect(() => {
    if (token) {
      dispatch(getUserInfo());
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (userInfo) {
      dispatch(getProfiles(userInfo.Id));
    }
  }, [dispatch, userInfo]);

  const [patientSubject, setPatientSubject] = useState(null);
  const [clinic, setClinic] = useState(null);
  const [room, setRoom] = useState(null);
  const [bookingDate, setBookingDate] = useState(null);

  const handleRefresh = () => {
    setPatientSubject(null);
    setClinic(null);
    setRoom(null);
    setBookingDate(null);
  };

  const patientName = patientSubject?.FullName ?? '';
  const clinicLabel = clinic?.Name ?? '';
  const roomLabel = `${room?.name}, ${room?.address}, ${room?.ward}` ?? '';

  const { addToast } = useToasts();
  const submit = () => {
    const { date, time } = bookingDate;
    const subDate = moment(date).format('DD-MM-YYYY');
    const subTime = moment(time, 'HH:mm:ss').format('HH:mm');
    const dateTime = moment(`${subDate} ${subTime}`, 'DD-MM-YYYY HH:mm').toJSON();
    const data = {
      instance: {
        id: bookingDate.id,
        dateTime,
      },
      unit: {
        id: clinic.Id,
        name: clinic.Name,
        address: clinic.Address,
        username: clinic.Username,
      },
      doctor: {
        id: bookingDate.doctorId,
        fullname: doctorList.find((d) => d.GUID === bookingDate.doctorId)?.FullName ?? '',
      },
      room: bookingDate.room,
      service: {
        id: 'b9d3aeb8-02d6-4d44-9353-dde186547d0e',
        name: 'Xét nghiệm Covid-19',
      },
      customer: {
        id: patientSubject.Id,
        fullname: patientSubject.FullName,
        birthDate: patientSubject?.BirthDate,
        phone: userInfo?.Phone,
        email: patientSubject?.Email,
        gender: patientSubject?.Gender,
        districtCode: patientSubject?.DistrictCode,
        provinceCode: patientSubject?.ProvinceCode,
        wardCode: patientSubject?.WardCode,
        ic: patientSubject?.IC,
      },
      form: patientSubject.form,
    };
    dispatch(register(data))
      .then(() => {
        addToast('Tạo phiếu hẹn thành công', { appearance: 'success' });
      })
      .catch(() => {
        addToast('Thời gian trên đã bị đặt, vui lòng chọn thời gian khác', {
          appearance: 'error',
        });
        dispatch(setStep(2));
      });
  };

  const steps = [
    {
      key: 0,
      isSelected: patientName ? (
        <>
          <span>{'Người xét nghiệm - '}</span>
          <span className="text-secondary">{`${patientName}`}</span>
        </>
      ) : (
        ''
      ),
      title: 'Người xét nghiệm',
      component: (
        <PatientSelect
          isLoading={isLoading}
          initialPatient={patientSubject}
          onChange={(d) => {
            setPatientSubject(d);
            if (d) {
              dispatch(setStep(currentStep + 1));
            }
          }}
        />
      ),
    },
    {
      key: 1,
      isSelected: clinicLabel ? (
        <>
          <span>{'Cơ sở xét nghiệm - '}</span>
          <span className="text-secondary">{`${room?.name ? roomLabel : clinicLabel}`}</span>
        </>
      ) : (
        ''
      ),
      title: 'Cơ sở xét nghiệm',
      component: (
        <ClinicSelect
          patientId={patientSubject?.Id}
          onChange={(selectingClinic, selectingRoom) => {
            setClinic(selectingClinic);
            setRoom(selectingRoom);
            if (selectingClinic) {
              dispatch(setStep(currentStep + 1));
            }
          }}
          onBack={() => {
            setPatientSubject(null);
            setClinic(null);
            setRoom(null);
          }}
        />
      ),
    },
    {
      key: 2,
      isSelected: '',
      title: 'Thời gian làm việc',
      component: (
        <DateSelect
          hospitalId={clinic?.Id ?? 0}
          onChange={setBookingDate}
          selectingRoom={room}
          onBack={() => {
            setRoom(null);
            setClinic(null);
            setBookingDate(null);
          }}
          onFinish={submit}
        />
      ),
    },
  ];

  const appointment = useMemo(() => (
    <Appointment
      id={registerData?.id}
      onRefresh={handleRefresh}
    />
  ), [registerData]);

  const container = useMemo(
    () => (
      <Container fluid className="pt-3">
        {/* {appointment} */}
        {!registerData && !loadingRegister && <h5>Đặt lịch xét nghiệm</h5>}
        {!registerData && !loadingRegister && (
          <Steps direction="vertical" current={currentStep}>
            {steps.map((s) => (
              <Step
                icons={<FaCheck />}
                key={s.key}
                title={s.isSelected.length === 0 ? s.title : s.isSelected}
                description={s.key === currentStep ? s.component : null}
              />
            ))}
          </Steps>
        )}
        {registerData && appointment}
        {loadingRegister && (
          <StyledDiv>
            <h5 className="text-secondary font-weight-normal">
              Đang tiến hành tạo phiếu hẹn
            </h5>
            <Spinner color="info" />
          </StyledDiv>
        )}
      </Container>
    ),
    [
      steps,
      currentStep,
      appointment,
      registerData,
      loadingRegister,
    ],
  );

  return <div>{token ? container : spinner}</div>;
};

export default CovidExaminationPage;
