const Status = {
  UNFINISHED: 1,
  FINISHED: 2,
  CANCELED: 3,
  DOCTOR_CANCEL: 5,
  NOT_DOING: 4,
  TRANSFERRED: 6,
};
const StatusColor = {
  [Status.UNFINISHED]: 'blue',
  [Status.FINISHED]: 'green',
  [Status.CANCELED]: 'grey',
  [Status.DOCTOR_CANCEL]: 'red',
  [Status.NOT_DOING]: 'yellow',
  [Status.TRANSFERRED]: 'blueGrey',
};
const StatusLabel = {
  [Status.UNFINISHED]: 'Chưa thực hiện',
  [Status.FINISHED]: 'Đã thực hiện',
  [Status.CANCELED]: 'Bên hẹn huỷ',
  [Status.DOCTOR_CANCEL]: 'Không thực hiện',
  [Status.NOT_DOING]: 'Bên nhận huỷ',
  [Status.TRANSFERRED]: 'Đã chuyển tiếp',
};

export {
  Status,
  StatusColor,
  StatusLabel,
};
