import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Spinner } from 'reactstrap';
import styled from 'styled-components';
import Steps, { Step } from 'rc-steps';
import { FaCheck } from 'react-icons/fa';

import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';

import PatientSelect from '../components/PatientSelect';
import ServiceExpertSection from '../components/ServiceExpertSection';
import DateTimeSelect from '../components/DateTimeSelect';
import AddressSelect from '../components/AddressSelect';
import PhoneContact from '../components/PhoneContact';
import Appointment from '../components/Appointment';
import ServiceTypeSelect from '../components/ServiceTypeSelect';

const steps = [
  {
    key: 0,
    isSelected: '',
    title: 'Người khám bệnh',
    component: <PatientSelect />,
  },
  {
    key: 1,
    isSelected: '',
    title: 'Địa chỉ',
    component: <AddressSelect />,
  },
  {
    key: 2,
    isSelected: '',
    title: 'Số ĐT liên hệ',
    component: <PhoneContact />,
  },
  {
    key: 3,
    isSelected: '',
    title: 'Loại dịch vụ',
    component: <ServiceTypeSelect />,
  },
  {
    key: 4,
    isSelected: '',
    title: 'Chuyên khoa',
    component: <ServiceExpertSection />,
  },
  {
    key: 5,
    isSelected: '',
    title: 'Thời gian',
    component: <DateTimeSelect />,
  },
];

const StyledDiv = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const HomecarePage = () => {
  const {
    currentStep,
    selectedPatient,
    address,
    phone,
    // selectedServiceType,
    selectedService,
    selectedExpert,
    examinationData,
    creatingExamination,
  } = useSelector((state) => state.homecare);

  if (selectedPatient && selectedPatient.value !== 0) {
    steps[0].isSelected = '';
    steps[0].isSelected = (
      <>
        {`${steps[0].title} - `}
        <span className="text-secondary">{`${selectedPatient.label}`}</span>
      </>
    );
  } else {
    steps[0].isSelected = '';
  }
  if (address) {
    steps[1].isSelected = '';
    steps[1].isSelected = (
      <>
        {`${steps[1].title} - `}
        <span className="text-secondary">{`${address.addressDetail} - ${address.selectedLocation.ward.Name} `}</span>
      </>
    );
  } else {
    steps[1].isSelected = '';
  }
  if (phone) {
    steps[2].isSelected = '';
    steps[2].isSelected = (
      <>
        {`${steps[2].title} - `}
        <span className="text-secondary">{`${phone}`}</span>
      </>
    );
  } else {
    steps[2].isSelected = '';
  }
  if (selectedService || selectedExpert) {
    steps[3].isSelected = '';
    steps[3].isSelected = (
      <>
        {`${steps[3].title} - `}
        {selectedService ? (
          <span className="text-secondary">{`${selectedService.label}`}</span>
        ) : (
          <span className="text-secondary">{`${selectedExpert.label}`}</span>
        )}
      </>
    );
  } else {
    steps[3].isSelected = '';
  }
  if (selectedService || selectedExpert) {
    steps[4].isSelected = '';
    steps[4].isSelected = (
      <>
        {`${steps[4].title} - `}
        {selectedService ? (
          <span className="text-secondary">{`${selectedService.label}`}</span>
        ) : (
          <span className="text-secondary">{`${selectedExpert.label}`}</span>
        )}
      </>
    );
  } else {
    steps[4].isSelected = '';
  }

  return (
    <Container fluid>
      {!examinationData && !creatingExamination && (
        <Steps
          direction="vertical"
          current={currentStep}
          // onChange={(step) => dispatch(setStep(step))}
        >
          {/* {selectedServiceType.CanUseHealthInsurance} */}
          {steps.map((s) => (
            <Step
              icons={<FaCheck />}
              key={s.key}
              title={s.isSelected.length === 0 ? s.title : s.isSelected}
              description={s.key === currentStep ? s.component : null}
            />
          ))}
        </Steps>
      )}
      {examinationData && !creatingExamination && <Appointment />}
      {creatingExamination && (
        <StyledDiv>
          <h5 className="text-secondary font-weight-normal">
            Đang tiến hành tạo phiếu hẹn
          </h5>
          <Spinner color="info" />
        </StyledDiv>
      )}
    </Container>
  );
};

export default HomecarePage;
