import types, {
  LOG_IN_REQUEST,
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  SET_TOKEN,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  LOG_OUT,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from 'app/actions/types';

import httpClient from 'app/utils/http-client';
import apiLinks from 'app/utils/api-links';

const registerRequest = () => ({ type: REGISTER_REQUEST });
const registerSuccess = (response) => ({ type: REGISTER_SUCCESS, payload: response });
const registerFailure = (error) => ({ type: REGISTER_FAILURE, payload: error });

const register = (data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(registerRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.register,
    data,
  }).then((response) => {
    dispatch(registerSuccess(response.data));
    resolve(data);
  }).catch((error) => {
    dispatch(registerFailure(error));
    reject(error.response.data);
  });
});

const loginRequest = () => ({ type: LOG_IN_REQUEST });
const loginSuccess = (response) => ({ type: LOG_IN_SUCCESS, payload: response });
const loginFailure = (error) => ({ type: LOG_IN_FAILURE, payload: error });

const login = (username, password) => (dispatch) => new Promise(
  (resolve, reject) => {
    dispatch(loginRequest());
    httpClient.callApi({
      method: 'POST',
      url: apiLinks.gateway.users.login,
      data: {
        username,
        password,
      },
    }).then((response) => {
      dispatch(loginSuccess(response.data));
      resolve(response.data);
    }).catch((error) => {
      dispatch(loginFailure(error));
      reject();
    });
  },
);

const newLoginRequest = () => ({ type: LOG_IN_REQUEST });
const newLoginSuccess = (response) => ({ type: LOG_IN_SUCCESS, payload: response });
const newLoginFailure = (error) => ({ type: LOG_IN_FAILURE, payload: error });

const newLogin = (username, password) => (dispatch) => new Promise(
  (resolve, reject) => {
    dispatch(newLoginRequest());
    httpClient.callApi({
      method: 'POST',
      url: apiLinks.gateway.users.login,
      data: {
        username,
        password,
      },
    }).then((response) => {
      dispatch(newLoginSuccess(response.data));
      resolve(response.data);
    }).catch((error) => {
      dispatch(newLoginFailure(error));
      reject();
    });
  },
);

const setToken = (token, tokenExpiredTime) => ({
  type: SET_TOKEN,
  payload: {
    token,
    tokenExpiredTime,
  },
});

const getUserInfoRequest = () => ({ type: GET_USER_INFO_REQUEST });
const getUserInfoSuccess = (response) => ({ type: GET_USER_INFO_SUCCESS, payload: response });
const getUserInfoFailure = (error) => ({ type: GET_USER_INFO_FAILURE, payload: error });

function getUserInfo() {
  return (dispatch) => {
    dispatch(getUserInfoRequest());
    httpClient.callApi({
      method: 'GET',
      url: apiLinks.userInfo,
    }).then((response) => dispatch(getUserInfoSuccess(response.data)))
      .catch((error) => dispatch(getUserInfoFailure(error)));
  };
}

const getUserInfoV2Request = () => ({ type: GET_USER_INFO_REQUEST });
const getUserInfoV2Success = (response) => ({ type: GET_USER_INFO_SUCCESS, payload: response });
const getUserInfoV2Failure = (error) => ({ type: GET_USER_INFO_FAILURE, payload: error });

function getUserInfoV2() {
  return (dispatch) => {
    dispatch(getUserInfoV2Request());
    httpClient.callApi({
      url: apiLinks.gateway.profiles.get,
    }).then((response) => dispatch(getUserInfoV2Success(response.data)))
      .catch((error) => dispatch(getUserInfoV2Failure(error)));
  };
}

const getProfilesRequest = () => ({ type: types.GET_PROFILES_REQUEST });
const getProfilesSuccess = (response) => ({ type: types.GET_PROFILES_SUCCESS, payload: response });
const getProfilesFailure = (error) => ({ type: types.GET_PROFILES_FAILURE, payload: error });

const getProfiles = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getProfilesRequest());
  httpClient.callApi({
    url: apiLinks.gateway.profiles.getRelations,
  }).then((response) => {
    dispatch(getProfilesSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getProfilesFailure(error));
    reject();
  });
});

const logout = () => ({ type: LOG_OUT });

export {
  register,
  login,
  newLogin,
  setToken,
  logout,
  getUserInfo,
  getUserInfoV2,
  getProfiles,
};
