import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Spinner, Row } from 'reactstrap';

import StyledPagination from 'app/components/StyledPagination';
import { APPOINTMENT_PER_PAGE, TELE_FORM } from 'app/utils/constants';
import { getTeleTickets } from 'history/actions/history';
import Appointment from './Appointment';

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const TeleheathTab = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const { listTeleTickets, loadingListTeleTickets } = useSelector((state) => state.history);
  const [data, setData] = useState([]);

  const callback = (slicedData) => {
    setData(slicedData);
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(getTeleTickets(userInfo.Id, TELE_FORM));
    }
  }, [userInfo, dispatch]);

  return (
    <Row>
      {loadingListTeleTickets ? (
        <StyledSpinner color="info" />
      ) : (
        data.map((e) => <Appointment key={e.Id} data={e} />)
      )}
      {!loadingListTeleTickets && (
        <StyledPagination
          listData={listTeleTickets}
          itemPerPage={APPOINTMENT_PER_PAGE}
          refreshData={callback}
        />
      )}
    </Row>
  );
};

export default TeleheathTab;
