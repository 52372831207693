import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setStep } from '../actions/examination';

const ButtonFragment = (props) => {
  const {
    disabled,
    onGoNext,
    onGoBack,
    // onFinish,
  } = props;

  const {
    currentStep,
  } = useSelector((state) => state.examination);

  const dispatch = useDispatch();

  return (
    <div>
      {currentStep !== 0 && (
        <Button
          size="sm"
          className="mr-2"
          color="info"
          onClick={() => {
            dispatch(setStep(currentStep - 1));
            onGoBack();
          }}
        >
          Quay lại
        </Button>
      )}
      {(currentStep === 1 || currentStep === 3) && (
        <Button
          size="sm"
          color="info"
          disabled={disabled}
          onClick={() => {
            dispatch(setStep(currentStep + 1));
            onGoNext();
          }}
        >
          Bước sau
        </Button>
      )}
      {/* {currentStep === 6 && (
        <Button
          size="sm"
          color="success"
          disabled={disabled}
          onClick={() => {
            dispatch(setStep(currentStep + 1));
            onFinish();
          }}
        >
          Hoàn tất
        </Button>
      )} */}
    </div>
  );
};

ButtonFragment.propTypes = {
  disabled: PropTypes.bool,
  onGoNext: PropTypes.func,
  onGoBack: PropTypes.func,
  // onFinish: PropTypes.func,
};

ButtonFragment.defaultProps = {
  disabled: false,
  onGoNext: () => {},
  onGoBack: () => {},
  // onFinish: () => {},
};

export default ButtonFragment;
