/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Select, { components } from 'react-select';
import {
  Row,
  Button,
  Spinner,
} from 'reactstrap';
import { FaEye } from 'react-icons/fa';

import { useToasts } from 'react-toast-notifications';

import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  toggleAddPatientModal,
  toggleSidebar,
} from 'app/actions/global';

import AddPatientModal from 'app/components/AddPatientModal';
import ButtonFragment from './ButtonFragment';
import PatientModal from './PatientModal';
import CovidModal from './CovidModal';

const StyledSelect = styled(Select)`
  max-width: 350px;
  width: 100%;
  background-color: transparent;
`;

const StyledFaEye = styled(FaEye)`
  margin-left: 10px;
`;

const StyledButton = styled(Button)`
  padding: 0 !important;
  color: #808080 !important;
`;

const { Option } = components;

const PatientSelect = (props) => {
  const {
    isLoading,
    initialPatient,
    initialRelative,
    onChange,
  } = props;

  const { profileList } = useSelector((state) => state.immunization);
  const { sidebarCollapsed } = useSelector((state) => state.global);
  const { userInfo } = useSelector((state) => state.auth);

  const [options, setOptions] = useState([]);
  const [patientModal, setPatientModal] = useState({
    isOpen: false,
    data: null,
  });

  useEffect(() => {
    if (userInfo) {
      setOptions([
        { value: 0, label: 'Tạo mới' },
        ...[userInfo, ...profileList]
          .filter((p) => p.Id !== (initialRelative ? initialRelative.Id : ''))
          .map((p) => ({
            label: p.FullName,
            value: p.Id,
            key: p.Id,
            subject: p,
          })),
      ]);
    }
    // eslint-disable-next-line
  }, [
    userInfo,
    profileList,
    initialRelative,
  ]);

  const dispatch = useDispatch();
  const handleClick = (e, subject) => {
    e.stopPropagation();
    setPatientModal({
      isOpen: true,
      data: subject,
    });
  };

  const IconOption = (iProps) => {
    const { data } = iProps;
    return (
      <Option {...iProps}>
        <Row className="d-flex justify-content-between px-3">
          <span>{data.label}</span>
          {data.subject && (
            <span className="border-left">
              <StyledButton onClick={(e) => handleClick(e, data.subject)} size="sm" color="link">
                <StyledFaEye />
              </StyledButton>
            </span>
          )}
        </Row>
      </Option>
    );
  };

  const [covidModal, setCovidModal] = useState(false);
  const { addToast } = useToasts();
  const [selectingPatient, setSelectingPatient] = useState(null);
  const handleSubmit = (d) => {
    const {
      haveCovid,
      selectingNations,
    } = d;

    if (haveCovid.length > 0) {
      addToast('Không đủ điều kiện tiêm chủng', { appearance: 'error' });
      onChange(null);
    } else {
      onChange({
        ...selectingPatient,
        QuocGiaDaDiQua: selectingNations || [].map((n) => n.label),
      });
    }
    setCovidModal(false);
  };

  useEffect(() => {
    if (selectingPatient) {
      const {
        FullName,
        Address,
        DistrictCode,
        ProvinceCode,
        WardCode,
        BirthDate,
      } = selectingPatient;
      const allowToGoNextStep = Boolean(FullName && Address && DistrictCode && ProvinceCode && WardCode && BirthDate);
      if (!allowToGoNextStep) {
        setPatientModal({
          isOpen: true,
          data: selectingPatient,
        });
      } else {
        setCovidModal(true);
      }
    }
  }, [selectingPatient]);

  return (
    <div className="w-100">
      <StyledSelect
        name="color"
        isSearchable
        isClearable
        className="basic-single mb-2"
        classNamePrefix="select"
        placeholder="Người được tiêm"
        components={{ Option: IconOption }}
        options={options}
        isDisabled={isLoading}
        controlShouldRenderValue={Boolean(initialPatient?.Id)}
        defaultValue={initialPatient?.Id ?? 0}
        noOptionsMessage={() => <span>Không tìm thấy</span>}
        onChange={(d) => {
          // create new subject
          if (d?.value === 0) {
            dispatch(toggleAddPatientModal());
            if (!sidebarCollapsed) {
              dispatch(toggleSidebar(!sidebarCollapsed));
            }
          // existed subject
          } else if (d) {
            if (d?.value === userInfo?.Id) {
              setSelectingPatient(userInfo);
            } else {
              setSelectingPatient(profileList.find((p) => p.Id === d.value));
            }
          } else {
            onChange(null);
          }
        }}
      />
      {isLoading ? (
        <Spinner color="info" />
      ) : (
        <ButtonFragment disabled={!initialPatient?.Id} />
      )}

      <AddPatientModal />

      <PatientModal
        open={patientModal.isOpen}
        initialData={patientModal.data}
        onClose={(p) => {
          setPatientModal({ isOpen: false, data: null });
          if (p.Id) {
            setCovidModal(true);
          } else {
            setSelectingPatient(null);
          }
        }}
      />

      <CovidModal
        open={covidModal}
        onClose={() => setCovidModal(false)}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

PatientSelect.propTypes = {
  initialRelative: PropTypes.shape({
    Id: PropTypes.number,
  }),
  initialPatient: PropTypes.shape({
    Id: PropTypes.number,
  }),
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

PatientSelect.defaultProps = {
  initialRelative: {},
  initialPatient: {},
  onChange: () => {},
  isLoading: false,
};

export default PatientSelect;
