import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import HealthDeclarationForm from 'covid-examination/components/HealthDeclarationForm';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import { assignToHcdc, createSubject } from 'covid-examination/actions/examination';

const HealthDeclarationPage = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const handleSubmit = (d) => {
    const { creatingData, allowToTakeExam } = d;
    const {
      FullName,
      Phone,
      Email,
      Address,
      BirthDate,
      Gender,
      ProvinceCode,
      DistrictCode,
      WardCode,
      national,
      cmnd,
      room,
      floor,
      block,
      quarter,
    } = creatingData;
    const creatingSubject = {
      information: {
        fullName: FullName,
        phoneNumber: Phone,
        gender: Gender === 0,
        email: Email,
        dateOfBirth: BirthDate,
        nationalityId: national,
        identityNumber: cmnd,
        addressesInVietnam: [
          {
            room,
            floor,
            block,
            quarter,
            provinceValue: ProvinceCode,
            districtValue: DistrictCode,
            wardValue: WardCode,
            streetHouseNumber: Address,
            locationType: 'Nhà riêng',
          },
        ],
      },
      // examination-type: 0
      createFromType: 0,
      type: null,
    };
    if (allowToTakeExam) {
      dispatch(createSubject(creatingSubject)).then((res) => {
        dispatch(assignToHcdc({
          personId: res,
          personName: FullName,
        }));
      });
    }
    addToast('Đăng ký xét nghiệm thành công', { appearance: 'success' });
  };
  return (
    <Container>
      <Row className="py-5">
        <Col sm={12}>
          <h3>TỜ KHAI Y TẾ ĐỐI VỚI NGƯỜI TỪ TỈNH/THÀNH PHỐ CÓ DỊCH TRỞ VỀ</h3>
        </Col>
        <Col sm={12}>
          <b>
            Đây là tài liệu quan trọng, thông tin của Anh/Chị sẽ giúp cơ quan y tế
            liên lạc khi cần thiết để phòng chống dịch bệnh truyền nhiễm.
          </b>
        </Col>
        <Col sm={12} className="mt-3">
          <HealthDeclarationForm isCreateToExamination onSubmit={handleSubmit} />
        </Col>
      </Row>
    </Container>
  );
};

export default HealthDeclarationPage;
