import apiLinks from 'app/utils/api-links';
import httpClient from 'app/utils/http-client';
import { IMMUNIZATION_FORM } from 'app/utils/constants';

import types from './types';

const setStep = (step) => ({ type: types.IMMUNIZATION_SET_STEP, payload: step });

const getProfilesRequest = () => ({ type: types.IMMUNIZATION_GET_PROFILES_REQUEST });
const getProfilesSuccess = (response) => ({ type: types.IMMUNIZATION_GET_PROFILES_SUCCESS, payload: response });
const getProfilesFailure = (error) => ({ type: types.IMMUNIZATION_GET_PROFILES_FAILURE, payload: error });

const getProfiles = (CustomerId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getProfilesRequest());
  httpClient.callApi({
    url: apiLinks.getProfiles,
    params: {
      CustomerId,
    },
  }).then((response) => {
    dispatch(getProfilesSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getProfilesFailure(error));
    reject();
  });
});

const getDiseasesRequest = () => ({ type: types.IMMUNIZATION_GET_DISEASES_REQUEST });
const getDiseasesSuccess = (response) => ({ type: types.IMMUNIZATION_GET_DISEASES_SUCCESS, payload: response });
const getDiseasesFailure = (error) => ({ type: types.IMMUNIZATION_GET_DISEASES_FAILURE, payload: error });

const getDiseases = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getDiseasesRequest());
  httpClient.callApi({
    url: apiLinks.getDiseases,
  }).then((response) => {
    dispatch(getDiseasesSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getDiseasesFailure(error));
    reject();
  });
});

const getVaccinesRequest = () => ({ type: types.IMMUNIZATION_GET_VACCINES_REQUEST });
const getVaccinesSuccess = (response) => ({ type: types.IMMUNIZATION_GET_VACCINES_SUCCESS, payload: response });
const getVaccinesFailure = (error) => ({ type: types.IMMUNIZATION_GET_VACCINES_FAILURE, payload: error });

const getVaccines = (diseaseId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getVaccinesRequest());
  httpClient.callApi({
    url: apiLinks.getVaccines,
    params: {
      diseaseId,
    },
  }).then((response) => {
    dispatch(getVaccinesSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getVaccinesFailure(error));
    reject();
  });
});

const getVaccineInjectionRequest = () => ({ type: types.IMMUNIZATION_GET_VACCINE_INJECTION_REQUEST });
const getVaccineInjectionSuccess = (response) => ({ type: types.IMMUNIZATION_GET_VACCINE_INJECTION_SUCCESS, payload: response });
const getVaccineInjectionFailure = (error) => ({ type: types.IMMUNIZATION_GET_VACCINE_INJECTION_FAILURE, payload: error });

const getVaccineInjection = (vaccineId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getVaccineInjectionRequest());
  httpClient.callApi({
    url: apiLinks.getVaccineInjectionObjects,
    params: {
      vaccineId,
    },
  }).then((response) => {
    dispatch(getVaccineInjectionSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getVaccineInjectionFailure(error));
    reject();
  });
});

const getInjectionObjectsRequest = () => ({ type: types.IMMUNIZATION_GET_INJECTION_REQUEST });
const getInjectionObjectsSuccess = (response) => ({ type: types.IMMUNIZATION_GET_INJECTION_SUCCESS, payload: response });
const getInjectionObjectsFailure = (error) => ({ type: types.IMMUNIZATION_GET_INJECTION_FAILURE, payload: error });

const getInjectionObjects = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getInjectionObjectsRequest());
  httpClient.callApi({
    url: apiLinks.getInjectionObjects,
  }).then((response) => {
    dispatch(getInjectionObjectsSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getInjectionObjectsFailure(error));
    reject();
  });
});

const getByFormRequest = () => ({ type: types.IMMUNIZATION_GET_HEALTHCARE_REQUEST });
const getByFormSuccess = (response) => ({ type: types.IMMUNIZATION_GET_HEALTHCARE_SUCCESS, payload: response });
const getByFormFailure = (error) => ({ type: types.IMMUNIZATION_GET_HEALTHCARE_FAILURE, payload: error });

const getByForm = (injectionObjectId, vaccineId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getByFormRequest());
  httpClient.callApi({
    url: apiLinks.getByHealthcare,
    params: {
      Form: IMMUNIZATION_FORM,
      injectionObjectId,
      vaccineId,
    },
  }).then((response) => {
    dispatch(getByFormSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getByFormFailure(error));
    reject();
  });
});

const getByFormV2Request = () => ({ type: types.IMMUNIZATION_GET_HEALTHCARE_REQUEST });
const getByFormV2Success = (response) => ({ type: types.IMMUNIZATION_GET_HEALTHCARE_SUCCESS, payload: response });
const getByFormV2Failure = (error) => ({ type: types.IMMUNIZATION_GET_HEALTHCARE_FAILURE, payload: error });

const getByFormV2 = (injectionObjectId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getByFormV2Request());
  httpClient.callApi({
    url: apiLinks.getByHealthcare,
    params: {
      Form: IMMUNIZATION_FORM,
      injectionObjectId,
    },
  }).then((response) => {
    dispatch(getByFormV2Success(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getByFormV2Failure(error));
    reject();
  });
});

const getByHealthcareRequest = () => ({ type: types.IMMUNIZATION_GET_CLINIC_REQUEST });
const getByHealthcareSuccess = (response) => ({ type: types.IMMUNIZATION_GET_CLINIC_SUCCESS, payload: response });
const getByHealthcareFailure = (error) => ({ type: types.IMMUNIZATION_GET_CLINIC_FAILURE, payload: error });

const getByHealthcare = (id, customerId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getByHealthcareRequest());
  httpClient.callApi({
    url: apiLinks.getByHealthCareId + id,
    params: {
      customerId,
    },
  }).then((response) => {
    dispatch(getByHealthcareSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getByHealthcareFailure(error));
    reject();
  });
});

const getSchedulesRequest = () => ({ type: types.IMMUNIZATION_GET_SCHEDULE_REQUEST });
const getSchedulesSuccess = (response) => ({ type: types.IMMUNIZATION_GET_SCHEDULE_SUCCESS, payload: response });
const getSchedulesFailure = (error) => ({ type: types.IMMUNIZATION_GET_SCHEDULE_FAILURE, payload: error });

const getSchedules = (hospitalId, serviceId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getSchedulesRequest());
  httpClient.callApi({
    url: apiLinks.getImmunizationSchedules(hospitalId),
    params: {
      form: IMMUNIZATION_FORM,
      serviceId,
    },
  }).then((response) => {
    dispatch(getSchedulesSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getSchedulesFailure(error));
    reject();
  });
});

const getTicketsRequest = () => ({ type: types.IMMUNIZATION_GET_TICKETS_REQUEST });
const getTicketsSuccess = (response) => ({ type: types.IMMUNIZATION_GET_TICKETS_SUCCESS, payload: response });
const getTicketsFailure = (error) => ({ type: types.IMMUNIZATION_GET_TICKETS_FAILURE, payload: error });

const getTickets = (hospitalId, date, serviceId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getTicketsRequest());
  httpClient.callApi({
    url: apiLinks.getImmunizationTickets,
    params: {
      hospitalId,
      date,
      serviceId,
    },
  }).then((response) => {
    dispatch(getTicketsSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getTicketsFailure(error));
    reject();
  });
});

const getAppointmentRequest = () => ({ type: types.IMMUNIZATION_GET_APPOINTMENT_REQUEST });
const getAppointmentSuccess = (response) => ({ type: types.IMMUNIZATION_GET_APPOINTMENT_SUCCESS, payload: response });
const getAppointmentFailure = (error) => ({ type: types.IMMUNIZATION_GET_APPOINTMENT_FAILURE, payload: error });

const getAppointment = (id) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getAppointmentRequest());
  httpClient.callApi({
    url: apiLinks.getImmunizationAppointment + id,
  }).then((response) => {
    dispatch(getAppointmentSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getAppointmentFailure(error));
    reject();
  });
});

const getHistoryRequest = () => ({ type: types.IMMUNIZATION_GET_HISTORY_REQUEST });
const getHistorySuccess = (response) => ({ type: types.IMMUNIZATION_GET_HISTORY_SUCCESS, payload: response });
const getHistoryFailure = (error) => ({ type: types.IMMUNIZATION_GET_HISTORY_FAILURE, payload: error });

const getHistory = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getHistoryRequest());
  httpClient.callApi({
    url: apiLinks.getImmunizationHistory,
  }).then((response) => {
    dispatch(getHistorySuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getHistoryFailure(error));
    reject();
  });
});

const updateAppointmentRequest = () => ({ type: types.IMMUNIZATION_UPDATE_APPOINTMENT_REQUEST });
const updateAppointmentSuccess = (response) => ({ type: types.IMMUNIZATION_UPDATE_APPOINTMENT_SUCCESS, payload: response });
const updateAppointmentFailure = (error) => ({ type: types.IMMUNIZATION_UPDATE_APPOINTMENT_FAILURE, payload: error });

const updateAppointment = (id, type) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(updateAppointmentRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.updateAppointment,
    data: {
      Id: id,
      Status: type,
    },
  }).then((response) => {
    dispatch(updateAppointmentSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(updateAppointmentFailure(error));
    reject();
  });
});

const registerRequest = () => ({ type: types.IMMUNIZATION_REGISTER_REQUEST });
const registerSuccess = (response) => ({ type: types.IMMUNIZATION_REGISTER_SUCCESS, payload: response });
const registerFailure = (error) => ({ type: types.IMMUNIZATION_REGISTER_FAILURE, payload: error });

const register = (data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(registerRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.registerImmunization,
    data,
  }).then((response) => {
    dispatch(registerSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(registerFailure(error));
    reject();
  });
});

const resetImmunization = () => ({ type: types.IMMUNIZATION_RESET });

export {
  setStep,
  getProfiles,
  getDiseases,
  getVaccines,
  getVaccineInjection,
  getInjectionObjects,
  getByForm,
  getByFormV2,
  getByHealthcare,
  getSchedules,
  getTickets,
  getAppointment,
  getHistory,
  updateAppointment,
  register,
  resetImmunization,
};
