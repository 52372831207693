import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Row,
  Card,
  CardTitle,
  CardText,
  Col,
  Spinner,
  Button,
  CardHeader,
} from 'reactstrap';
import styled from 'styled-components';

import { withRouter } from 'react-router-dom';
import { getAppointment, resetImmunization, updateAppointment } from 'immunization-booking/actions/immunization-booking';
import { showConfirmModal } from 'app/actions/global';
import { useToasts } from 'react-toast-notifications';

const StyledCard = styled(Card)`
  background: linear-gradient(90deg, rgba(0,78,95,.7) 0%, rgba(77,169,188,1) 100%);
  border-radius: 0;
  padding: 15px 30px;
`;

const StyledCardHeader = styled(CardHeader)`
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  border-radius: 0;
  background: rgba(0,78,95,.8);
`;

const StyledCardFooter = styled(CardHeader)`
  border-radius: 0 0 16px 16px !important;
  background: rgba(0,78,95,.8);
`;

const BorderedCol = styled(Col)`
  border-top: 1px solid white;
`;

const StyledCardText = styled(CardText)`
  color: gold;
  font-weight: normal;
`;

const StyledDiv = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Appointment = (props) => {
  const { id, onRefresh } = props;
  const {
    appointmentData,
    getAppointmentLoading,
    updateAppointmentLoading,
  } = useSelector((state) => state.immunizationBooking);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppointment(id));
  }, [dispatch, id]);

  const haveContactInfo = Boolean(appointmentData?.contacts[0]?.fullname);
  const { addToast } = useToasts();

  const appointmentInfo = useMemo(() => (
    <>
      <Row xs="1" md="2" xl="3" className="justify-content-center">
        <StyledCardHeader className="text-center">
          <h4 className="font-weight-normal text-warning mb-0">
            Thông tin phiếu hẹn
            {/* {` ${appointmentData?.id}`} */}
          </h4>
        </StyledCardHeader>
      </Row>

      <Row xs="1" md="2" xl="3" className="justify-content-center">
        <StyledCard inverse>

          <CardTitle className="mb-0 text-left">
            <h4>{appointmentData?.unit.name}</h4>
            <p className="mb-2">{`Địa chỉ: ${appointmentData?.unit.address}`}</p>
          </CardTitle>

          <Row>
            <BorderedCol xs={12}>
              <h5 className="my-2">Thông tin người tiêm</h5>
            </BorderedCol>
            <Col xs={4}>
              <CardText>Họ và tên</CardText>
            </Col>
            <Col xs={8}>
              <CardText>{appointmentData?.customer.fullname}</CardText>
            </Col>
            <Col xs={4}>
              <CardText>Ngày sinh</CardText>
            </Col>
            <Col xs={8}>
              <CardText>
                {moment(appointmentData?.customer.birthDate).format('DD-MM-YYYY')}
              </CardText>
            </Col>
            <Col xs={4}>
              <CardText>Điện thoại</CardText>
            </Col>
            <Col xs={8}>
              <CardText>{appointmentData?.customer.phone}</CardText>
            </Col>
            <Col xs={4} className="mb-2">
              <CardText>Mã tiêm chủng</CardText>
            </Col>
            <Col xs={8} className="mb-2">
              {appointmentData?.customer.ic}
            </Col>
            {haveContactInfo && (
              <>
                <BorderedCol xs={12}>
                  <h5 className="my-2">Thông tin người đi cùng</h5>
                </BorderedCol>
                {appointmentData?.contacts.map((c) => (
                  <Col xs={12}>
                    <Row>
                      <Col xs={4}>
                        Họ và tên
                      </Col>
                      <Col xs={8}>
                        {`${c?.fullname ?? ''}`}
                      </Col>
                      <Col xs={4}>
                        Điện thoại
                      </Col>
                      <Col xs={8}>
                        {`${c?.phone ?? ''}`}
                      </Col>
                      <Col xs={4} className="mb-2">
                        Mối quan hệ
                      </Col>
                      <Col xs={8} className="mb-2">
                        {`${c?.relationship ?? ''}`}
                      </Col>
                    </Row>
                  </Col>
                ))}
              </>
            )}
            <BorderedCol xs={12}>
              <h5 className="my-2">Thông tin lịch hẹn</h5>
            </BorderedCol>
            <Col xs={4}>
              <CardText>Bệnh cần tiêm</CardText>
            </Col>
            <Col xs={8}>
              <CardText>{appointmentData?.service.name}</CardText>
            </Col>
            {/* <Col xs={4}>
              <CardText>Loại hình tiêm</CardText>
            </Col>
            <Col xs={8}>
              <CardText>{healthCareList.find((e) => e.Id === appointmentData?.ServiceId)?.ServiceType ?? ''}</CardText>
            </Col> */}
            <Col xs={4}>
              <CardText>Ngày tiêm</CardText>
            </Col>
            <Col xs={8}>
              <StyledCardText>{moment(appointmentData?.date).format('DD-MM-YYYY')}</StyledCardText>
            </Col>
            <Col xs={4}>
              <CardText>Giờ tiêm</CardText>
            </Col>
            <Col xs={8}>
              <StyledCardText>
                {appointmentData?.interval.from}
              </StyledCardText>
            </Col>
          </Row>
        </StyledCard>
      </Row>
      <Row xs="1" md="2" xl="3" className="justify-content-center">
        <StyledCardFooter className="text-center">
          <h5 className="text-warning">
            Vui lòng chụp lại hoặc ghi nhớ thông tin phiếu hẹn!
          </h5>
        </StyledCardFooter>
      </Row>
      <Row className="d-xs-block d-lg-none">
        <Col xs="6" className="text-right">
          <Button
            outline
            color="primary"
            className="mt-3"
            onClick={() => {
              dispatch(resetImmunization());
              onRefresh();
            }}
          >
            Hoàn tất
          </Button>
        </Col>
        <Col xs="6" className="text-left">
          <Button
            outline
            color="danger"
            className="mt-3"
            onClick={() => dispatch(showConfirmModal('Xác nhận hủy phiếu hẹn?', () => {
              dispatch(updateAppointment(appointmentData?.id, 5)).then(() => {
                addToast('Hủy phiếu hẹn thành công', { appearance: 'success' });
                dispatch(resetImmunization());
                onRefresh();
              });
            }))}
          >
            Hủy phiếu hẹn
          </Button>
        </Col>
      </Row>
      <Row xs="1" className="d-none d-lg-block justify-content-center pt-3">
        <Col className="text-center mx-auto">
          <Button
            className="mr-1"
            outline
            color="primary"
            onClick={() => {
              dispatch(resetImmunization());
              onRefresh();
            }}
          >
            Hoàn tất
          </Button>
          <Button
            className="ml-1"
            outline
            color="danger"
            onClick={() => dispatch(showConfirmModal('Xác nhận hủy phiếu hẹn?', () => {
              dispatch(updateAppointment(appointmentData?.id, 5)).then(() => {
                addToast('Hủy phiếu hẹn thành công', { appearance: 'success' });
                dispatch(resetImmunization());
                onRefresh();
              });
            }))}
          >
            Hủy phiếu hẹn
          </Button>
        </Col>
      </Row>
    </>
  ), [
    dispatch,
    addToast,
    onRefresh,
    appointmentData,
    haveContactInfo,
  ]);

  return (
    <>
      {updateAppointmentLoading && (
        <StyledDiv>
          <Spinner color="info" />
        </StyledDiv>
      )}
      {getAppointmentLoading ? (
        <StyledDiv>
          <h5 className="text-secondary font-weight-normal">
            Đang tiến hành tạo phiếu hẹn
          </h5>
          <Spinner color="info" />
        </StyledDiv>
      ) : appointmentInfo }
    </>
  );
};

Appointment.propTypes = {
  /** react-router-dom history object */
  onRefresh: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(Appointment);
