import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Input,
} from 'reactstrap';
import Select from 'react-select';

import locations from 'app/mock/locationsV2.json';
import apiLinks from 'app/utils/api-links';
import httpClient from 'app/utils/http-client';

const ClinicFilter = (props) => {
  const { onChange } = props;
  const [searchValue, setSearchValue] = useState('');
  const [selectingUnitType, setSelectingUnitType] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState({
    district: null,
    province: null,
    ward: null,
  });

  const [unitTypeList, setUnitTypeList] = useState([]);
  const [loadingUnitType, setLoadingUnitType] = useState(false);
  useEffect(() => {
    setLoadingUnitType(true);
    httpClient.callApi({
      method: 'GET',
      url: apiLinks.getBkUnitType,
    }).then((response) => {
      setUnitTypeList(response.data.map((ut) => ({
        value: ut.Id,
        label: ut.Name,
      })));
      setLoadingUnitType(false);
    });
  }, []);

  useEffect(() => {
    onChange({
      searchValue: searchValue.toLowerCase(),
      selectingUnitType,
      selectedLocation,
    });
  }, [
    onChange,
    searchValue,
    selectingUnitType,
    selectedLocation,
  ]);

  return (
    <Row>
      <Col sm={12}>
        <Input
          placeholder="Tên cơ sở"
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </Col>
      <Col sm={12} className="mt-3">
        <Select
          isClearable
          isSearchable
          isLoading={loadingUnitType}
          isDisabled={loadingUnitType}
          placeholder="Loại hình cơ sở"
          noOptionsMessage={() => <span>Không tìm thấy</span>}
          onChange={(el) => setSelectingUnitType(el)}
          options={
            unitTypeList || [].map((ut) => ({
              value: ut.Id,
              label: ut.Name,
            }))
          }
        />
      </Col>
      <Col sm={12} className="mt-3">
        <Select
          isClearable
          isSearchable
          placeholder="Tỉnh/Thành phố"
          controlShouldRenderValue={selectedLocation.province}
          noOptionsMessage={() => <span>Không tìm thấy</span>}
          onChange={(el) => setSelectedLocation({
            province: el && locations.find(
              (province) => province.value === el.value,
            ),
            district: null,
            ward: null,
          })}
          options={locations.map((province) => ({
            value: province.value,
            label: province.label,
          }))}
        />
      </Col>
      <Col sm={12} className="mt-3">
        <Select
          isClearable
          isSearchable
          isDisabled={!selectedLocation.province}
          controlShouldRenderValue={selectedLocation.district}
          placeholder="Quận/Huyện"
          noOptionsMessage={() => <span>Không tìm thấy</span>}
          onChange={(el) => setSelectedLocation({
            ...selectedLocation,
            district: el && selectedLocation.province.districts.find(
              (district) => district.value === el.value,
            ),
            ward: null,
          })}
          options={
            selectedLocation.province
            && selectedLocation.province.districts.map((district) => ({
              value: district.value,
              label: district.label,
            }))
          }
        />
      </Col>
      <Col sm={12} className="mt-3">
        <Select
          isClearable
          isSearchable
          isDisabled={!selectedLocation.district}
          controlShouldRenderValue={selectedLocation.ward}
          placeholder="Phường/Xã"
          noOptionsMessage={() => <span>Không tìm thấy</span>}
          onChange={(el) => setSelectedLocation({
            ...selectedLocation,
            ward: el && selectedLocation.district.wards.find(
              (ward) => ward.value === el.value,
            ),
          })}
          options={
            selectedLocation.district
            && selectedLocation.district.wards.map((ward) => ({
              value: ward.value,
              label: ward.label,
            }))
          }
        />
      </Col>
    </Row>
  );
};

ClinicFilter.propTypes = {
  onChange: PropTypes.func,
};

ClinicFilter.defaultProps = {
  onChange: () => {},
};

export default ClinicFilter;
