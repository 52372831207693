import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import Select from 'react-select';
import styled from 'styled-components';
import {
  Spinner,
  Card,
  Row,
  Col,
  CardImg,
  CardBody,
  CardSubtitle,
} from 'reactstrap';

import { getHospitalByForm } from 'app/actions/global';
import { MCB_FORM } from 'app/utils/constants';

import ButtonFragment from './ButtonFragment';
import { selectClinic, selectPatient } from '../actions/booking';

// const StyledSelect = styled(Select)`
//   max-width: 350px;
//   width: 100%;
//   background-color: transparent;
// `;

const StyledCardImg = styled(CardImg)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 150px;
`;

const StyledCard = styled(Card)`
  border: ${(props) => (props.active ? '2px solid rgb(226, 107, 71)' : '')};
  cursor: pointer;
`;

const ClinicSelect = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHospitalByForm(MCB_FORM));
  }, [dispatch]);

  const [selectedClinic, setSelectedClinic] = useState(null);

  const { listHospitals, loadingHospitals } = useSelector((state) => state.global);

  return (
    <div className="w-100">
      {!loadingHospitals && (
        <Row>
          {listHospitals.map((e) => (
            <Col
              xs="6"
              md="4"
              lg="3"
              key={e.Id}
              className="mb-3"
            >
              <StyledCard
                active={((selectedClinic !== null) && (selectedClinic.Id === e.Id)) ? 1 : 0}
                onClick={() => setSelectedClinic(e)}
              >
                <ImageWrapper>
                  <StyledCardImg src={e.Image} alt="Hospital Image" />
                </ImageWrapper>
                <CardBody>
                  <CardSubtitle>{e.Name}</CardSubtitle>
                </CardBody>
              </StyledCard>
            </Col>
          ))}
        </Row>
      )}
      {loadingHospitals ? (
        <Spinner color="info" />
      ) : (
        <ButtonFragment
          onGoNext={() => dispatch(selectClinic(selectedClinic))}
          onGoBack={() => dispatch(selectPatient(null))}
          data={selectedClinic}
        />
      )}
    </div>
  );
};

export default ClinicSelect;
