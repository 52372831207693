import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Spinner,
  Row,
  Col,
  Card,
  CardBody,
  // Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from 'reactstrap';

import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';
import DatePickerVietnam from 'app/components/DatePickerVietnam';
import { getSchedules, getTickets } from 'immunization/actions/immunization';

import ButtonFragment from './ButtonFragment';

const StyledDropdownToggle = styled(DropdownToggle)`
  margin: 5px !important;
  border-radius: 16px !important;
  width: 80px !important;
`;

const ButtonWrapper = styled.div`
  margin-top: 15px;
  text-align: left;
`;

const DateSelect = (props) => {
  const {
    hospitalId,
    healthcareId,
    onBack,
    onChange,
    onFinish,
  } = props;

  const dispatch = useDispatch();
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectingDay, setSelectingDay] = useState(null);
  const [selectedTime, setSelectedTime] = useState(0);
  const [month, setMonth] = useState(new Date());
  const [disableDays, setDisableDays] = useState([]);

  const {
    scheduleList,
    loadingGetSchedule,
    ticketList,
    ticketListView,
    loadingGetTickets,
  } = useSelector((state) => state.immunization);

  useEffect(() => {
    if (hospitalId && healthcareId) {
      dispatch(getSchedules(hospitalId, healthcareId));
    }
  }, [dispatch, hospitalId, healthcareId]);

  useEffect(() => {
    // get list of days in month
    let dayOfMonth = [];
    let i = 0;
    do {
      const day = moment(month).startOf('month').startOf('day').add(i, 'days');
      dayOfMonth.push(day);
      i += 1;
    } while (i < moment(month).daysInMonth());

    // remove available date from list of days in month
    const availableDays = scheduleList.map((e) => moment(e));
    dayOfMonth = dayOfMonth.filter(
      (el) => !availableDays
        .map((d) => d.format('DD-MM-YYYY'))
        .includes(el.format('DD-MM-YYYY')),
    );
    setDisableDays(dayOfMonth.map((d) => moment(d).toDate()));
  }, [month, scheduleList]);

  const selectDate = (d, { selected }, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }

    setSelectedDay(selected ? undefined : d);

    const scheduleObj = scheduleList.find((el) => moment(el).format('DD-MM-YYYY') === moment(d).format('DD-MM-YYYY'));

    setSelectingDay(scheduleObj);
  };

  useEffect(() => {
    if (selectingDay) {
      dispatch(getTickets(hospitalId, selectingDay, healthcareId));
    }
  }, [
    dispatch,
    selectingDay,
    hospitalId,
    healthcareId,
  ]);

  const [isOpenDropdownButton, setIsOpenDropdownButton] = useState(0);

  return (
    <div>
      {!loadingGetSchedule ? (
        <>
          <Row>
            <Col xs="12" lg="5" className="text-left">
              <h5>Chọn ngày tiêm</h5>
              <Card>
                <CardBody>
                  <DatePickerVietnam
                    month={month}
                    selectedDays={selectedDay}
                    disabledDays={disableDays}
                    onDayClick={selectDate}
                    onMonthChange={setMonth}
                  />
                </CardBody>
              </Card>
              <ButtonWrapper className="d-none d-lg-block">
                <ButtonFragment
                  onGoBack={onBack}
                  onFinish={onFinish}
                  disabled={selectedTime === 0}
                />
              </ButtonWrapper>
            </Col>
            {loadingGetTickets ? (
              <Spinner color="info" />
            ) : (
              <>
                {selectingDay && (
                  <>
                    <Col xs="12" lg="7">
                      <h5>Chọn giờ tiêm</h5>
                      {ticketListView.length !== 0 && (
                        <Card>
                          <CardBody className="text-center">
                            {ticketListView.map((e) => (
                              <ButtonDropdown
                                key={e}
                                isOpen={isOpenDropdownButton === e}
                                toggle={() => setIsOpenDropdownButton(isOpenDropdownButton === 0 ? e : 0)}
                              >
                                <StyledDropdownToggle
                                  color="info"
                                  outline
                                  active={isOpenDropdownButton === e}
                                  onClick={() => {
                                    setSelectedTime(e);
                                    onChange(ticketList.find((t) => (t.time === e)).id);
                                  }}
                                >
                                  {moment(e, 'HH:mm:ss').format('HH:mm')}
                                </StyledDropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem onClick={onFinish}>
                                    Đặt lịch ngay
                                  </DropdownItem>
                                </DropdownMenu>
                              </ButtonDropdown>
                            ))}
                          </CardBody>
                        </Card>
                      )}
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>
          <ButtonWrapper className="d-xs-block d-lg-none">
            <ButtonFragment
              onGoBack={onBack}
              disabled={selectedTime === 0}
            />
          </ButtonWrapper>
        </>
      ) : (
        <Spinner color="info" />
      )}
    </div>
  );
};

DateSelect.propTypes = {
  hospitalId: PropTypes.number,
  healthcareId: PropTypes.string,
  onBack: PropTypes.func,
  onChange: PropTypes.func,
  onFinish: PropTypes.func,
};

DateSelect.defaultProps = {
  hospitalId: 0,
  healthcareId: '',
  onBack: () => {},
  onChange: () => {},
  onFinish: () => {},
};

export default DateSelect;
