const url = 'https://api.vkhealth.vn';
const urlGw = 'https://apigw.vkhealth.vn';
const urlDev = 'https://booking.vkhealth.vn';
const current = window.location.href;
const examinationApiLink = current.indexOf('/hcdc') > -1 ? 'https://apigw2.vkhealth.vn/api/examination' : 'http://202.78.227.99:30203/api';
const baseUrl = process.env.NODE_ENV === 'development' ? 'http://202.78.227.94:30200/api' : 'https://apigw2.vkhealth.vn/api/cds';

const userGatewayUrl = 'https://apigw2.vkhealth.vn/api/Users';
const scheduleGatewayUrl = 'http://202.78.227.99:32676/api';
const customerBookingUrl = 'http://202.78.227.99:31769/api';

const apiLinks = {
  token: `${url}/token`,
  userInfo: `${url}/api/Account/UserInfo`,
  register: `${url}/api/Account/CustomerRegister`,
  forgotPassword: `${url}/api/Account/ForgotPassword`,
  createBkCustomer: `${url}/api/BkCustomer/Create`,
  createAccount: `${url}/api/Account/CustomerRegister`,
  changePassword: `${url}/api/Account/ChangePassword`,
  updateBkCustomer: `${url}/api/BkCustomer/Update`,
  getBkCustomer: `${url}/api/BkCustomer/GetById/`,
  getBkUnitType: `${url}/api/BkUnitType/Get`,
  getHospitalByForm: `${url}/api/BkHospital/GetByForm`,
  getServiceTypes: `${url}/api/BkHospital/GetServiceTypes`,
  getHealthCare: `${url}/api/BkHealthCare/GetByHospitalId`,
  getSchedule: `${url}/api/BkHealthCareScheduler/GetByHospitalHealthCareId`,
  getScheduleByDoctor: `${url}/api/BkHealthCareScheduler/GetByDoctor`,
  getDoctor: (hospitalId) => `${url}/api/BkHospital/GetDoctorByForm/${hospitalId}`,
  getServicesByDoctor: `${url}/api/BkDoctor/GetServicesByDoctor`,
  createExamination: `${url}/api/BkMedicalExaminationNote/Create`,
  deleteExamination: `${url}/api/BkMedicalExaminationNote/Delete`,
  getTickets: `${url}/api/BkCustomer/GetSchedulerCustomer`,
  getProfiles: `${url}/api/BkCustomer/GetProfiles`,
  getVaccines: `${url}/api/Vaccines/Get`,
  getDiseases: `${url}/api/Diseases/Get`,
  getByHealthCareId: `${url}/api/BkHospital/GetByHealthCareId/`,
  getByHealthcare: `${url}/api/BkHealthCare/GetByForm`,
  getVaccineInjectionObjects: `${url}/api/VaccineInjectionObjects/Get`,
  getInjectionObjects: `${url}/api/InjectionObjects/Get`,
  getImmunizationSchedules: (id) => `${urlGw}/api/Hospitals/${id}/Days`,
  getExaminationSchedules: (id) => `${urlGw}/api/Hospitals/${id}/Days`,
  getImmunizationTickets: `${urlGw}/api/Tickets`,
  getExaminationTickets: `${urlGw}/api/Tickets`,
  getImmunizationAppointment: `${url}/api/Immunizations/GetById/`,
  getImmunizationHistory: `${url}/api/Immunizations/Get/`,
  getExaminationHistory: `${urlDev}/api/MedicalTests`,
  updateAppointment: `${url}/api/Immunizations/Update`,
  registerImmunization: `${url}/api/Immunizations/Register`,
  addProfiles: `${url}/api/BkCustomer/AddProfiles`,
  registerExamination: `${urlDev}/api/MedicalTests`,
  updateExaminationAppointment: `${urlDev}/api/MedicalTests`,
  getExaminationAppointment: `${urlDev}/api/MedicalTests/`,
  assign: {
    create: `${examinationApiLink}/Assigns/Booking`,
  },
  unit: {
    getHcdcId: `${examinationApiLink}/Units/GetHCDCId`,
  },
  subject: {
    create: `${baseUrl}/Subject/`,
  },
  gateway: {
    users: {
      login: `${userGatewayUrl}/Login`,
      get: userGatewayUrl,
      create: userGatewayUrl,
      changePassword: `${userGatewayUrl}/ChangePassword`,
      resetPassword: `${userGatewayUrl}/Tools/ResetDefaultPassword`,
    },
    profiles: {
      get: `${scheduleGatewayUrl}/Profiles`,
      create: `${scheduleGatewayUrl}/Profiles`,
      update: `${scheduleGatewayUrl}/Profiles`,
      getRelations: `${scheduleGatewayUrl}/Profiles/AllRelation`,
    },
    serviceForms: {
      get: `${scheduleGatewayUrl}/ServiceForms`,
    },
    injectionObjects: {
      get: `${scheduleGatewayUrl}/InjectionObjects`,
    },
    services: {
      get: `${scheduleGatewayUrl}/Services/ServiceFormAndServiceType`,
    },
    serviceTypes: {
      get: `${scheduleGatewayUrl}/ServiceTypes`,
    },
    serviceUnits: {
      get: `${scheduleGatewayUrl}/ServiceUnits`,
    },
    unitTypes: {
      get: `${scheduleGatewayUrl}/UnitTypes`,
    },
    hospitals: {
      get: `${scheduleGatewayUrl}/Hospitals`,
      getLogo: `${scheduleGatewayUrl}/Hospitals/Logo`,
    },
    workingCalendars: {
      get: `${scheduleGatewayUrl}/WorkingCalendars/GetDaysByUnitAndService`,
      getIntervals: `${scheduleGatewayUrl}/WorkingCalendars/GetIntervals`,
    },
    vaccinations: {
      get: `${customerBookingUrl}/Vaccinations/`,
      update: `${customerBookingUrl}/Vaccinations/`,
      register: `${customerBookingUrl}/Vaccinations`,
    },
    examinations: {
      get: `${customerBookingUrl}/Examinations/`,
      update: `${customerBookingUrl}/Examinations/`,
      register: `${customerBookingUrl}/Examinations`,
    },
  },
  // getPaymentStatus: (id) => `${url}/api/BkMedicalExaminationNote/PaymentResult/${id}`,
};

export default apiLinks;
