import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  Row,
  Card,
  CardText,
  Col,
  Spinner,
  Button,
  CardTitle,
  CardHeader,
} from 'reactstrap';

import moment from 'moment';
import { useToasts } from 'react-toast-notifications';

import { useSelector, useDispatch } from 'react-redux';
import { updateAppointment } from 'immunization/actions/immunization';

import { showConfirmModal } from 'app/actions/global';
import { StatusColor, StatusLabel } from 'immunization/utils/constants';

const StyledDiv = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
const StyledCard = styled(Card)`
  background: linear-gradient(90deg, rgba(0,78,95,.7) 0%, rgba(77,169,188,1) 100%) !important;
  border-radius: 0px;
  padding: 15px 30px;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
`;
const StyledCardHeader = styled(CardHeader)`
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  border-radius: 0;
  background: rgba(0,78,95,.8);
`;
const BorderedCol = styled(Col)`
  border-top: 1px solid white;
`;

const StyledCardText = styled(CardText)`
  color: gold;
  font-weight: normal;
`;

const StyledCardText2 = styled(CardText)`
  overflow: hidden;
  white-space: nowrap; 
  text-overflow: ellipsis;
`;

const StyledCardTitle = styled(CardTitle)`
  height: 120px;
`;

const AddressText = styled(CardText)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ImmunizationAppointment = (props) => {
  const { onRefresh, initialData } = props;

  const { loadingUpdateAppointment, healthCareList } = useSelector((state) => state.immunization);

  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const appointment = useMemo(
    () => (
      <div className="mt-3">
        <StyledCardHeader className="text-center">
          <h4 className="font-weight-normal text-warning mb-0">
            Mã phiếu hẹn:
            {` ${initialData?.Id}`}
          </h4>
        </StyledCardHeader>
        <StyledCard inverse>
          {initialData.Status !== 1 && (
            <div className="ribbon-wrapper">
              <div className={`ribbon ${StatusColor[initialData.Status]}`}>
                {StatusLabel[initialData.Status]}
              </div>
            </div>
          )}

          <StyledCardTitle className="mb-0 text-left">
            <h4>{initialData?.HospitalName}</h4>
            <AddressText className="mb-2">{`Địa chỉ: ${initialData?.HospitalInfo?.Address}`}</AddressText>
          </StyledCardTitle>

          <Row>
            <BorderedCol xs={12}>
              <h5 className="my-2">Thông tin người tiêm</h5>
            </BorderedCol>
            <Col xs={4}>
              <CardText>Họ và tên</CardText>
            </Col>
            <Col xs={8}>
              <CardText>{initialData?.Customer?.FullName}</CardText>
            </Col>
            <Col xs={4}>
              <CardText>Ngày sinh</CardText>
            </Col>
            <Col xs={8}>
              <CardText>
                {moment(initialData?.Customer?.BirthDate).format('DD-MM-YYYY')}
              </CardText>
            </Col>
            <Col xs={4}>
              <CardText>Điện thoại</CardText>
            </Col>
            <Col xs={8} className="mb-2">
              <CardText>{initialData?.Customer?.Phone}</CardText>
            </Col>
            <Col xs={4} className="mb-2">
              <CardText>Mã tiêm chủng</CardText>
            </Col>
            {/* <Col xs={8}>{customerInfo?.ImmunizationCode}</Col> */}
            <BorderedCol xs={12}>
              <h5 className="my-2">Thông tin người đi cùng</h5>
            </BorderedCol>
            {initialData?.ContactInfo.map((c) => (
              <Col xs={12} key={c?.Name}>
                <Row>
                  <Col xs={4}>Họ và tên</Col>
                  <Col xs={8}>{`${c?.Name ?? 'Không có'}`}</Col>
                  <Col xs={4}>Số điện thoại</Col>
                  <Col xs={8}>{`${c?.Phone ?? 'Không có'}`}</Col>
                  <Col xs={4} className="mb-2">Mối quan hệ</Col>
                  <Col xs={8} className="mb-2">{`${c?.Relationship ?? 'Không có'}`}</Col>
                </Row>
              </Col>
            ))}
            <BorderedCol xs={12}>
              <h5 className="my-2">Thông tin lịch hẹn</h5>
            </BorderedCol>
            <Col xs={4}>
              <CardText>Bệnh cần tiêm</CardText>
            </Col>
            <Col xs={8}>
              <StyledCardText2>{initialData?.ServiceName}</StyledCardText2>
            </Col>
            <Col xs={4}>
              <CardText>Loại hình</CardText>
            </Col>
            <Col xs={8}>
              <CardText>{healthCareList.find((e) => e.Id === initialData?.ServiceId)?.ServiceType ?? ''}</CardText>
            </Col>
            <Col xs={4}>
              <CardText>Ngày tiêm</CardText>
            </Col>
            <Col xs={8}>
              <StyledCardText>
                {moment(initialData?.Schedule?.Date).format('DD-MM-YYYY')}
              </StyledCardText>
            </Col>
            <Col xs={4}>
              <CardText>Giờ tiêm</CardText>
            </Col>
            <Col xs={8}>
              <StyledCardText>
                {moment(initialData?.Schedule?.Time, 'HH:mm:ss').format(
                  'HH:mm',
                )}
              </StyledCardText>
            </Col>
          </Row>
        </StyledCard>
        {initialData.Status === 1 && (
          <Row>
            <Col xs="12" className="text-center">
              <Button
                outline
                color="danger"
                className="mt-3"
                onClick={() => dispatch(
                  showConfirmModal(
                    `Xác nhận hủy phiếu hẹn số ${initialData.Id}?`,
                    () => {
                      dispatch(updateAppointment(initialData.Id, 5)).then(
                        () => {
                          addToast('Hủy phiếu hẹn thành công', {
                            appearance: 'success',
                          });
                          onRefresh();
                        },
                      );
                    },
                  ),
                )}
              >
                Hủy phiếu hẹn
              </Button>
            </Col>
          </Row>
        )}
      </div>
    ), [
      dispatch,
      healthCareList,
      addToast,
      onRefresh,
      initialData,
    ],
  );
  return (
    <div>
      {loadingUpdateAppointment ? (
        <StyledDiv>
          <Spinner color="info" />
        </StyledDiv>
      ) : (
        appointment
      )}
    </div>
  );
};

ImmunizationAppointment.propTypes = {
  initialData: PropTypes.shape({
    BookedUser: PropTypes.string,
    ContactInfo: PropTypes.arrayOf(
      PropTypes.shape({
        Name: PropTypes.string,
        Phone: PropTypes.string,
        Relationship: PropTypes.string,
      }),
    ),
    Customer: PropTypes.shape({
      Address: PropTypes.string,
      BirthDate: PropTypes.string,
      FullName: PropTypes.string,
      Id: PropTypes.number,
      ImmunizationCode: PropTypes.string,
      Phone: PropTypes.string,
    }),
    CustomerId: PropTypes.number,
    DateCreated: PropTypes.string,
    DoctorId: PropTypes.number,
    DoctorName: PropTypes.string,
    Form: PropTypes.shape({}),
    HospitalId: PropTypes.number,
    HospitalInfo: PropTypes.shape({
      Address: PropTypes.string,
      Email: PropTypes.string,
      Phone: PropTypes.string,
      Website: PropTypes.string,
    }),
    HospitalName: PropTypes.string,
    Id: PropTypes.number,
    InstanceId: PropTypes.number,
    IsDeleted: PropTypes.bool,
    RoomId: PropTypes.number,
    RoomName: PropTypes.string,
    Schedule: PropTypes.shape({
      Id: PropTypes.number,
      Date: PropTypes.string,
      Time: PropTypes.string,
    }),
    SecondaryDisease: PropTypes.string,
    ServiceId: PropTypes.number,
    ServiceName: PropTypes.string,
    Status: PropTypes.number,
  }),
  onRefresh: PropTypes.func,
};

ImmunizationAppointment.defaultProps = {
  initialData: {},
  onRefresh: () => {},
};

export default ImmunizationAppointment;
