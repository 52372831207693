import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Row,
  Card,
  CardTitle,
  CardText,
  Col,
  CardBody,
} from 'reactstrap';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const BorderedCol = styled(Col)`
  border-top: 1px solid white;
`;

const StyledCardText = styled(CardText)`
  color: gold;
  font-weight: bold;
`;

const StatusCode = (props) => {
  switch (props.statusCode) {
    case 1:
      return <CardText style={{ color: 'yellow' }} className="font-weight-bold">Chưa thực hiện</CardText>;
    case 2:
      return <CardText style={{ color: 'greenyellow' }} className="font-weight-bold">Đã thực hiện</CardText>;
    case 3:
      return <CardText style={{ color: 'orange' }} className="font-weight-bold">Hủy</CardText>;
    case 4:
      return <CardText style={{ color: 'red' }} className="font-weight-bold">Người dùng hủy</CardText>;
    case 5:
      return <CardText style={{ color: 'grey' }} className="font-weight-bold">Không thực hiện</CardText>;
    default:
      return <CardText>asd</CardText>;
  }
};

const Appointment = (props) => {
  const {
    data,
  } = props;
  const userInfo = useSelector((state) => state.auth.userInfo);
  return (
    <Col sm="12" md="6" lg="4" className="justify-content-center mt-3">
      <Card body inverse color="info">
        <CardTitle className="mb-0 text-center">
          <h4>{data.Hospital.Name}</h4>
          <p>{`Địa chỉ: ${data.Hospital.Address}`}</p>
        </CardTitle>
        <CardBody className="py-0">
          <h4 className="text-center font-weight-normal" xs="12">
            Mã phiếu hẹn:
            {` ${data.Id}`}
          </h4>
        </CardBody>

        <Row xs="2">
          <BorderedCol>
            <CardText>Họ và tên</CardText>
          </BorderedCol>
          <BorderedCol>
            <CardText>{data.Customer.FullName}</CardText>
          </BorderedCol>
          <Col>
            <CardText>Mã định danh</CardText>
          </Col>
          <Col>{/* <CardText></CardText> */}</Col>
          <Col>
            <CardText>Ngày sinh</CardText>
          </Col>
          <Col>
            <CardText>
              {userInfo && moment(userInfo.BirthDate).format('DD-MM-YYYY')}
            </CardText>
          </Col>
          <Col>
            <CardText>Điện thoại</CardText>
          </Col>
          <Col>
            <CardText>{data.Customer.Phone}</CardText>
          </Col>
          <Col>
            <CardText>BHYT</CardText>
          </Col>
          <Col>
            <CardText>{data.Customer.HealthInsurance}</CardText>
          </Col>
          <BorderedCol>
            <CardText>Loại hình</CardText>
          </BorderedCol>
          <BorderedCol>
            <CardText>{data.ServiceType.Name}</CardText>
          </BorderedCol>
          <Col>
            <CardText>Ngày</CardText>
          </Col>
          <Col>
            <StyledCardText>{data.Detail.Time}</StyledCardText>
          </Col>
          <Col>
            <CardText>Giờ</CardText>
          </Col>
          <Col>
            <StyledCardText>
              {moment(data.Detail.From, 'HH:mm:ss').format(
                'HH:mm',
              )}
            </StyledCardText>
          </Col>
          <Col>
            <CardText>STT</CardText>
          </Col>
          <Col>{/* <CardText></CardText> */}</Col>
          <Col>
            <CardText>Sử dụng BHYT</CardText>
          </Col>
          <Col>
            <CardText>
              {data.HasHealthInsurance ? 'Có' : 'Không'}
            </CardText>
          </Col>
          <Col>
            <CardText>Chuyên gia</CardText>
          </Col>
          <Col>
            <CardText>{data.Doctor.FullName}</CardText>
          </Col>
          <Col>
            <CardText>Chuyên khoa</CardText>
          </Col>
          <Col>
            <CardText>{data.Service.Name}</CardText>
          </Col>
          <Col>
            <CardText>Phòng</CardText>
          </Col>
          <Col>
            <CardText>{data.Room.Name}</CardText>
          </Col>
          <BorderedCol>
            <CardText>Tổng phí</CardText>
          </BorderedCol>
          <BorderedCol>
            <CardText>{`${data.Price} VNĐ`}</CardText>
          </BorderedCol>
          <Col>
            <CardText>Thanh toán</CardText>
          </Col>
          <Col>
            <StatusCode statusCode={data.StatusCode} />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

Appointment.propTypes = {
  data: PropTypes.shape({
    Id: PropTypes.number,
    StatusCode: PropTypes.number,
    Hospital: PropTypes.shape({
      Name: PropTypes.string,
      Address: PropTypes.string,
    }),
    Customer: PropTypes.shape({
      FullName: PropTypes.string,
      Address: PropTypes.string,
      Phone: PropTypes.string,
      HealthInsurance: PropTypes.string,
    }),
    Detail: PropTypes.shape({
      Time: PropTypes.string,
      From: PropTypes.string,
    }),
    HasHealthInsurance: PropTypes.shape({
      Name: PropTypes.string,
      Address: PropTypes.string,
    }),
    Doctor: PropTypes.shape({
      FullName: PropTypes.string,
      Address: PropTypes.string,
    }),
    Service: PropTypes.shape({
      Name: PropTypes.string,
      Address: PropTypes.string,
    }),
    Room: PropTypes.shape({
      Name: PropTypes.string,
      Address: PropTypes.string,
    }),
    ServiceType: PropTypes.shape({
      Name: PropTypes.string,
    }),
    Price: PropTypes.number,
    PaymentStatus: PropTypes.number,
  }).isRequired,
};

StatusCode.propTypes = {
  statusCode: PropTypes.number.isRequired,
};

export default Appointment;
