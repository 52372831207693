import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';

import Steps, { Step } from 'rc-steps';
import { Container, Spinner } from 'reactstrap';
import { FaCheck } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';

import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';

import { useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { setToken, getUserInfo } from 'app/actions/auth';
import {
  register,
  setStep,
  getProfiles,
  getByFormV2,
} from '../actions/immunization';

// import DiseaseSelect from '../components/DiseaseSelect';
import Appointment from '../components/Appointment';
import DateSelect from '../components/DateSelect';
import ServiceTypeSelect from '../components/ServiceTypeSelect';
import ClinicSelect from '../components/ClinicSelect';
import PatientSelect from '../components/PatientSelect';
import RelativeSelect from '../components/RelativeSelect';
import NewSubjectSelect from '../components/NewSubjectSelect';
import NewDiseaseSelect from '../components/NewDiseaseSelect';
// import NewAppointment from '../components/NewAppointment';

const StyledDiv = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Wrapper = styled.div`
  /* width: 100vw;
  height: 100vh; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const spinner = (
  <Wrapper>
    <Spinner color="info" />
  </Wrapper>
);

const useQuery = () => new URLSearchParams(useLocation().search);

const ImmunizationPage = () => {
  // set token
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery(location);

  const tokenParam = query.get('s');

  useEffect(() => {
    dispatch(setToken(tokenParam));
  }, [dispatch, tokenParam]);

  // component
  const {
    currentStep,
    loadingRegister,
    registerData,
    loadingGetProfiles,
  } = useSelector((state) => state.immunization);

  const { token, userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (token) {
      dispatch(getUserInfo());
      dispatch(getByFormV2());
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (userInfo) {
      dispatch(getProfiles(userInfo.Id));
    }
  }, [dispatch, userInfo]);

  const isLoading = userInfo === null || loadingGetProfiles;

  const [patientSubject, setPatientSubject] = useState(null);
  const [relativeSubject, setRelativeSubject] = useState(null);
  const [relation, setRelation] = useState(null);
  const [serviceType, setServiceType] = useState(null);
  const [object, setObject] = useState(null);
  const [disease, setDisease] = useState(null);
  const [clinic, setClinic] = useState(null);
  const [date, setDate] = useState(null);

  const handleRefresh = () => {
    setPatientSubject(null);
    setRelativeSubject(null);
    setRelation(null);
    setServiceType(null);
    setObject(null);
    setDisease(null);
    setClinic(null);
    setDate(null);
  };

  const { addToast } = useToasts();

  const submit = () => {
    const data = {
      InstanceId: date,
      ServiceId: disease?.value,
      _serviceId: '',
      SecondaryDisease: '',
      CustomerId: patientSubject?.Id,
      Customer: patientSubject,
      ContactInfo: [
        {
          Name: relativeSubject?.FullName,
          Phone: relativeSubject?.Phone,
          Relationship: relation?.value,
        },
      ],
    };
    dispatch(register(data))
      .then(() => {
        addToast('Tạo phiếu hẹn thành công', { appearance: 'success' });
      })
      .catch(() => {
        addToast('Thời gian trên đã bị đặt, vui lòng chọn thời gian khác', { appearance: 'error' });
        dispatch(setStep(6));
      });
  };

  const patientName = patientSubject?.FullName ?? '';
  const relativeName = relativeSubject?.FullName ?? '';
  const relationLabel = relation?.value ?? '';
  const serviceTypeLabel = serviceType?.label ?? '';
  const objectLabel = object?.label ?? '';
  const diseaseLabel = disease?.label ?? '';
  const clinicLabel = clinic?.Name ?? '';

  const steps = [
    {
      key: 0,
      isSelected: patientName ? (
        <>
          <span>{'Người được tiêm - '}</span>
          <span className="text-secondary">{`${patientName}`}</span>
        </>
      ) : '',
      title: 'Người được tiêm',
      component: (
        <PatientSelect
          isLoading={isLoading}
          initialPatient={patientSubject}
          initialRelative={relativeSubject}
          onChange={(d) => {
            setPatientSubject(d);
            if (d) {
              dispatch(setStep(currentStep + 1));
            }
          }}
        />
      ),
    },
    {
      key: 1,
      isSelected: relativeName ? (
        <>
          <span>{'Người đi cùng - '}</span>
          <span className="text-secondary">{relation ? `${relationLabel}: ` : ''}</span>
          <span className="text-secondary">{`${relativeName}`}</span>
        </>
      ) : '',
      title: 'Người đi cùng',
      component: (
        <RelativeSelect
          isLoading={isLoading}
          initialPatient={patientSubject}
          initialRelative={relativeSubject}
          initialRelation={relation}
          onRelativeChange={setRelativeSubject}
          onRelationChange={(d) => {
            setRelation(d);
            if (d) {
              dispatch(setStep(currentStep + 1));
            }
          }}
          onBack={() => {
            setPatientSubject(null);
            setRelativeSubject(null);
            setRelation(null);
          }}
        />
      ),
    },
    {
      key: 2,
      isSelected: serviceTypeLabel ? (
        <>
          <span>{'Loại hình tiêm - '}</span>
          <span className="text-secondary">{`${serviceTypeLabel}`}</span>
        </>
      ) : '',
      title: 'Loại hình tiêm',
      component: (
        <ServiceTypeSelect
          initialServiceType={serviceType}
          onChange={(d) => {
            setServiceType(d);
            if (d) {
              dispatch(setStep(currentStep + 1));
            }
          }}
          onBack={() => {
            setRelativeSubject(null);
            setRelation(null);
            setServiceType(null);
          }}
        />
      ),
    },
    {
      key: 3,
      isSelected: objectLabel ? (
        <>
          <span>{'Đối tượng tiêm - '}</span>
          <span className="text-secondary">{`${objectLabel}`}</span>
        </>
      ) : '',
      title: 'Đối tượng tiêm',
      component: (
        <NewSubjectSelect
          patientSubject={patientSubject}
          onChange={(d) => {
            setObject(d);
            if (d) {
              dispatch(setStep(currentStep + 1));
            }
          }}
          onBack={() => {
            setServiceType(null);
            setObject(null);
          }}
        />
      ),
    },
    {
      key: 4,
      isSelected: diseaseLabel ? (
        <>
          <span>{'Bệnh cần tiêm - '}</span>
          <span className="text-secondary">{`${diseaseLabel}`}</span>
        </>
      ) : '',
      title: 'Bệnh cần tiêm',
      component: (
        <NewDiseaseSelect
          selectingObject={object}
          selectingServiceType={serviceType}
          onChange={(d) => {
            setDisease(d);
            if (d) {
              dispatch(setStep(currentStep + 1));
            }
          }}
          onBack={() => {
            setObject(null);
            setDisease(null);
          }}
        />
      ),
    },
    {
      key: 5,
      isSelected: clinicLabel ? (
        <>
          <span>{'Cơ sở tiêm - '}</span>
          <span className="text-secondary">{`${clinicLabel}`}</span>
        </>
      ) : '',
      title: 'Cơ sở tiêm',
      component: (
        <ClinicSelect
          initialHealthcareId={disease?.value ?? 0}
          patientId={patientSubject?.Id}
          onChange={(d) => {
            setClinic(d);
            if (d) {
              dispatch(setStep(currentStep + 1));
            }
          }}
          onBack={() => {
            setDisease(null);
            setClinic(null);
          }}
        />
      ),
    },
    {
      key: 6,
      isSelected: '',
      title: 'Thời gian làm việc',
      component: (
        <DateSelect
          hospitalId={clinic?.Id ?? 0}
          healthcareId={disease?.key ?? ''}
          onChange={setDate}
          onBack={() => {
            setClinic(null);
            setDate(null);
          }}
          onFinish={submit}
        />
      ),
    },
  ];

  const appointment = useMemo(() => (
    <Appointment
      id={registerData?.Id}
      onRefresh={handleRefresh}
    />
  ), [registerData]);

  const container = useMemo(() => (
    <Container fluid className="pt-3">
      {/* {appointment} */}
      {!registerData && !loadingRegister && <h5>Đặt lịch tiêm chủng</h5>}
      {!registerData && !loadingRegister && (
        <Steps direction="vertical" current={currentStep}>
          {steps.map((s) => (
            <Step
              icons={<FaCheck />}
              key={s.key}
              title={s.isSelected.length === 0 ? s.title : s.isSelected}
              description={s.key === currentStep ? s.component : null}
            />
          ))}
        </Steps>
      )}
      {registerData && appointment }
      {loadingRegister && (
        <StyledDiv>
          <h5 className="text-secondary font-weight-normal">
            Đang tiến hành tạo phiếu hẹn
          </h5>
          <Spinner color="info" />
        </StyledDiv>
      )}
    </Container>
  ), [
    steps,
    currentStep,
    appointment,
    registerData,
    loadingRegister,
  ]);

  return (
    <div>
      {token ? container : spinner}
    </div>
  );
};

export default ImmunizationPage;
