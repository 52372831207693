import React from 'react';
import { useSelector } from 'react-redux';

import StandardSelectDate from './StandardSelectDate';
import ExpertSelectDate from './ExpertSelectDate';

const DateTimeSelect = () => {
  const { selectedServiceType } = useSelector((state) => state.tele);
  return (
    <div>
      {selectedServiceType.CanChoiceDoctor ? (
        <ExpertSelectDate />
      ) : (
        <StandardSelectDate />
      )}
    </div>
  );
};

export default DateTimeSelect;
