import apiLinks from 'app/utils/api-links';
import httpClient from 'app/utils/http-client';
// import { IB_FORM } from 'app/utils/constants';

import types from './types';

const setStep = (step) => ({ type: types.IB_SET_STEP, payload: step });

const getDiseasesRequest = () => ({ type: types.IB_GET_DISEASES_REQUEST });
const getDiseasesSuccess = (response) => ({ type: types.IB_GET_DISEASES_SUCCESS, payload: response });
const getDiseasesFailure = (error) => ({ type: types.IB_GET_DISEASES_FAILURE, payload: error });

const getDiseases = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getDiseasesRequest());
  httpClient.callApi({
    url: apiLinks.getDiseases,
  }).then((response) => {
    dispatch(getDiseasesSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getDiseasesFailure(error));
    reject();
  });
});

const getVaccinesRequest = () => ({ type: types.IB_GET_VACCINES_REQUEST });
const getVaccinesSuccess = (response) => ({ type: types.IB_GET_VACCINES_SUCCESS, payload: response });
const getVaccinesFailure = (error) => ({ type: types.IB_GET_VACCINES_FAILURE, payload: error });

const getVaccines = (diseaseId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getVaccinesRequest());
  httpClient.callApi({
    url: apiLinks.getVaccines,
    params: {
      diseaseId,
    },
  }).then((response) => {
    dispatch(getVaccinesSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getVaccinesFailure(error));
    reject();
  });
});

const getServiceTypeRequest = () => ({ type: types.IB_GET_SERVICE_TYPE_REQUEST });
const getServiceTypeSuccess = (response) => ({ type: types.IB_GET_SERVICE_TYPE_SUCCESS, payload: response });
const getServiceTypeFailure = (error) => ({ type: types.IB_GET_SERVICE_TYPE_FAILURE, payload: error });

const getServiceType = (serviceFormId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getServiceTypeRequest());
  httpClient.callApi({
    url: `${apiLinks.gateway.serviceTypes.get}/${serviceFormId}`,
  }).then((response) => {
    dispatch(getServiceTypeSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getServiceTypeFailure(error));
    reject();
  });
});

const getInjectionObjectRequest = () => ({ type: types.IB_GET_INJECTION_OBJECT_REQUEST });
const getInjectionObjectSuccess = (response) => ({ type: types.IB_GET_INJECTION_OBJECT_SUCCESS, payload: response });
const getInjectionObjectFailure = (error) => ({ type: types.IB_GET_INJECTION_OBJECT_FAILURE, payload: error });

const getInfectionObject = (serviceTypeId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getInjectionObjectRequest());
  httpClient.callApi({
    url: `${apiLinks.gateway.injectionObjects.get}/${serviceTypeId}`,
  }).then((response) => {
    dispatch(getInjectionObjectSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getInjectionObjectFailure(error));
    reject();
  });
});

const getServiceRequest = () => ({ type: types.IB_GET_SERVICE_REQUEST });
const getServiceSuccess = (response) => ({ type: types.IB_GET_SERVICE_SUCCESS, payload: response });
const getServiceFailure = (error) => ({ type: types.IB_GET_SERVICE_FAILURE, payload: error });

const getService = (serviceTypeId, serviceFormId, injectionObjectId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getServiceRequest());
  httpClient.callApi({
    url: apiLinks.gateway.services.get,
    params: {
      serviceTypeId,
      serviceFormId,
      injectionObjectId,
    },
  }).then((response) => {
    dispatch(getServiceSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getServiceFailure(error));
    reject();
  });
});

const getHospitalRequest = () => ({ type: types.IB_GET_HOSPITAL_REQUEST });
const getHospitalSuccess = (response) => ({ type: types.IB_GET_HOSPITAL_SUCCESS, payload: response });
const getHospitalFailure = (error) => ({ type: types.IB_GET_HOSPITAL_FAILURE, payload: error });

const getHospital = (serviceId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getHospitalRequest());
  httpClient.callApi({
    url: `${apiLinks.gateway.hospitals.get}/${serviceId}`,
  }).then((response) => {
    dispatch(getHospitalSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getHospitalFailure(error));
    reject();
  });
});

const getUnitTypeRequest = () => ({ type: types.IB_GET_UNIT_TYPE_REQUEST });
const getUnitTypeSuccess = (response) => ({ type: types.IB_GET_UNIT_TYPE_SUCCESS, payload: response });
const getUnitTypeFailure = (error) => ({ type: types.IB_GET_UNIT_TYPE_FAILURE, payload: error });

const getUnitType = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getUnitTypeRequest());
  httpClient.callApi({
    url: apiLinks.gateway.unitTypes.get,
  }).then((response) => {
    dispatch(getUnitTypeSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getUnitTypeFailure(error));
    reject();
  });
});

const getInjectionObjectsRequest = () => ({ type: types.IB_GET_INJECTION_REQUEST });
const getInjectionObjectsSuccess = (response) => ({ type: types.IB_GET_INJECTION_SUCCESS, payload: response });
const getInjectionObjectsFailure = (error) => ({ type: types.IB_GET_INJECTION_FAILURE, payload: error });

const getInjectionObjects = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getInjectionObjectsRequest());
  httpClient.callApi({
    url: apiLinks.getInjectionObjects,
  }).then((response) => {
    dispatch(getInjectionObjectsSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getInjectionObjectsFailure(error));
    reject();
  });
});

const getByFormRequest = () => ({ type: types.IB_GET_HEALTHCARE_REQUEST });
const getByFormSuccess = (response) => ({ type: types.IB_GET_HEALTHCARE_SUCCESS, payload: response });
const getByFormFailure = (error) => ({ type: types.IB_GET_HEALTHCARE_FAILURE, payload: error });

const getByForm = (injectionObjectId, vaccineId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getByFormRequest());
  httpClient.callApi({
    url: apiLinks.getByHealthcare,
    params: {
      // Form: IB_FORM,
      injectionObjectId,
      vaccineId,
    },
  }).then((response) => {
    dispatch(getByFormSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getByFormFailure(error));
    reject();
  });
});

const getByFormV2Request = () => ({ type: types.IB_GET_HEALTHCARE_REQUEST });
const getByFormV2Success = (response) => ({ type: types.IB_GET_HEALTHCARE_SUCCESS, payload: response });
const getByFormV2Failure = (error) => ({ type: types.IB_GET_HEALTHCARE_FAILURE, payload: error });

const getByFormV2 = (injectionObjectId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getByFormV2Request());
  httpClient.callApi({
    url: apiLinks.getByHealthcare,
    params: {
      // Form: IB_FORM,
      injectionObjectId,
    },
  }).then((response) => {
    dispatch(getByFormV2Success(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getByFormV2Failure(error));
    reject();
  });
});

const getByHealthcareRequest = () => ({ type: types.IB_GET_CLINIC_REQUEST });
const getByHealthcareSuccess = (response) => ({ type: types.IB_GET_CLINIC_SUCCESS, payload: response });
const getByHealthcareFailure = (error) => ({ type: types.IB_GET_CLINIC_FAILURE, payload: error });

const getByHealthcare = (id, customerId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getByHealthcareRequest());
  httpClient.callApi({
    url: apiLinks.getByHealthCareId + id,
    params: {
      customerId,
    },
  }).then((response) => {
    dispatch(getByHealthcareSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getByHealthcareFailure(error));
    reject();
  });
});

const getSchedulesRequest = () => ({ type: types.IB_GET_SCHEDULE_REQUEST });
const getSchedulesSuccess = (response) => ({ type: types.IB_GET_SCHEDULE_SUCCESS, payload: response });
const getSchedulesFailure = (error) => ({ type: types.IB_GET_SCHEDULE_FAILURE, payload: error });

const getSchedules = (unitId, serviceId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getSchedulesRequest());
  httpClient.callApi({
    url: apiLinks.gateway.workingCalendars.get,
    params: {
      unitId,
      serviceId,
    },
  }).then((response) => {
    dispatch(getSchedulesSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getSchedulesFailure(error));
    reject();
  });
});

const getIntervalsRequest = () => ({ type: types.IB_GET_INTERVALS_REQUEST });
const getIntervalsSuccess = (response) => ({ type: types.IB_GET_INTERVALS_SUCCESS, payload: response });
const getIntervalsFailure = (error) => ({ type: types.IB_GET_INTERVALS_FAILURE, payload: error });

const getIntervals = (dayId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getIntervalsRequest());
  httpClient.callApi({
    url: `${apiLinks.gateway.workingCalendars.getIntervals}/${dayId}`,
  }).then((response) => {
    dispatch(getIntervalsSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getIntervalsFailure(error));
    reject();
  });
});

const getAppointmentRequest = () => ({ type: types.IB_GET_APPOINTMENT_REQUEST });
const getAppointmentSuccess = (response) => ({ type: types.IB_GET_APPOINTMENT_SUCCESS, payload: response });
const getAppointmentFailure = (error) => ({ type: types.IB_GET_APPOINTMENT_FAILURE, payload: error });

const getAppointment = (id) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getAppointmentRequest());
  httpClient.callApi({
    url: apiLinks.gateway.vaccinations.get + id,
  }).then((response) => {
    dispatch(getAppointmentSuccess(response.data.data));
    resolve();
  }).catch((error) => {
    dispatch(getAppointmentFailure(error));
    reject();
  });
});

const getHistoryRequest = () => ({ type: types.IB_GET_HISTORY_REQUEST });
const getHistorySuccess = (response) => ({ type: types.IB_GET_HISTORY_SUCCESS, payload: response });
const getHistoryFailure = (error) => ({ type: types.IB_GET_HISTORY_FAILURE, payload: error });

const getHistory = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getHistoryRequest());
  httpClient.callApi({
    url: apiLinks.gateway.vaccinations.get,
  }).then((response) => {
    dispatch(getHistorySuccess(response.data.data));
    resolve();
  }).catch((error) => {
    dispatch(getHistoryFailure(error));
    reject();
  });
});

const updateAppointmentRequest = () => ({ type: types.IB_UPDATE_APPOINTMENT_REQUEST });
const updateAppointmentSuccess = (response) => ({ type: types.IB_UPDATE_APPOINTMENT_SUCCESS, payload: response });
const updateAppointmentFailure = (error) => ({ type: types.IB_UPDATE_APPOINTMENT_FAILURE, payload: error });

const updateAppointment = (id, status) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(updateAppointmentRequest());
  httpClient.callApi({
    method: 'PUT',
    url: apiLinks.gateway.vaccinations.update,
    data: {
      id,
      status,
    },
  }).then((response) => {
    dispatch(updateAppointmentSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(updateAppointmentFailure(error));
    reject();
  });
});

const registerRequest = () => ({ type: types.IB_REGISTER_REQUEST });
const registerSuccess = (response) => ({ type: types.IB_REGISTER_SUCCESS, payload: response });
const registerFailure = (error) => ({ type: types.IB_REGISTER_FAILURE, payload: error });

const register = (data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(registerRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.gateway.vaccinations.register,
    data,
  }).then((response) => {
    dispatch(registerSuccess(response.data.data));
    resolve();
  }).catch((error) => {
    dispatch(registerFailure(error));
    reject();
  });
});

const resetImmunization = () => ({ type: types.IB_RESET });

export {
  getDiseases,
  getVaccines,
  getInjectionObjects,
  getByForm,
  getByFormV2,
  getByHealthcare,
  getSchedules,
  getIntervals,
  getAppointment,
  getHistory,
  updateAppointment,
  register,
  setStep,
  resetImmunization,
  getInfectionObject,
  getServiceType,
  getService,
  getHospital,
  getUnitType,
};
