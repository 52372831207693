import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Spinner } from 'reactstrap';

import { useLocation } from 'react-router-dom';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  setToken,
  getUserInfo,
} from 'app/actions/auth';

import { getProfiles, getByFormV2 } from 'immunization/actions/immunization';
import CovidExaminationHistory from '../components/history/CovidExaminationHistory';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const spinner = (
  <Wrapper>
    <Spinner color="info" />
  </Wrapper>
);

const useQuery = () => new URLSearchParams(useLocation().search);

const CovidExaminationHistoryPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery(location);

  const tokenParam = query.get('s');

  useEffect(() => {
    dispatch(setToken(tokenParam));
  }, [dispatch, tokenParam]);

  const { token, userInfo } = useSelector((state) => state.auth);
  const { profileList } = useSelector((state) => state.immunization);

  useEffect(() => {
    if (userInfo && profileList.length === 0) {
      dispatch(getProfiles(userInfo.Id));
    }
  }, [
    dispatch,
    userInfo,
    profileList,
  ]);

  useEffect(() => {
    if (token) {
      dispatch(getUserInfo());
      dispatch(getByFormV2());
    }
  }, [dispatch, token]);

  return (
    <div>
      {token ? <CovidExaminationHistory /> : spinner}
    </div>
  );
};

export default CovidExaminationHistoryPage;
