import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
  Collapse,
  Card,
  CardBody,
  CustomInput,
  Label,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import styled from 'styled-components';

import { formatDate, parseDate } from 'react-day-picker/moment';
import ButtonFragment from './ButtonFragment';
import { setInsurance, selectServiceType } from '../actions/booking';

import 'react-day-picker/lib/style.css';

const StyledSwitch = styled(CustomInput)`
  display: inline;
`;

const DateWrapper = styled.div`
  width: 100%;
  position: relative;
  flex: 1 1;
  min-width: 0;
  margin-bottom: 0;
`;

const InsuranceSelect = () => {
  const dispatch = useDispatch();
  const [haveInsurance, setHaveInsurance] = useState(false);
  const [insuranceId, setInsuranceId] = useState('');
  const [insuranceAddress, setInsuranceAddress] = useState('');
  const [registerDate, setRegisterDate] = useState(null);
  const [expiredDate, setExpiredDate] = useState(null);
  const { selectedServiceType } = useSelector((state) => state.booking);

  return (
    <div className="w-100">
      {(selectedServiceType === null || selectedServiceType.CanUseHealthInsurance) && (
        <div>
          <Label for="haveInsurance">Không</Label>
          <StyledSwitch
            type="switch"
            id="haveInsurance"
            name="insurance"
            className="ml-2"
            onChange={(e) => setHaveInsurance(e.target.checked)}
          />
          <Label for="haveInsurance">Có</Label>
        </div>
      )}
      {!selectedServiceType.CanUseHealthInsurance && (
        <div>
          <span className="text-secondary">Loại hình trên không hỗ trợ BHYT</span>
        </div>
      )}
      <Row xs="1" md="2" lg="3" className="mb-2" style={{ margin: '0 .1rem' }}>
        <Collapse isOpen={haveInsurance}>
          <Card>
            <CardHeader>Thông tin BHYT</CardHeader>
            <CardBody>
              <Form>
                <FormGroup>
                  <Label for="insuranceNumber">Số BHYT</Label>
                  <Input
                    type="text"
                    name="insuranceNumber"
                    id="insuranceNumber"
                    placeholder="Số BHYT"
                    onChange={(e) => setInsuranceId(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="insuranceAddress">Nơi đăng ký</Label>
                  <Input
                    type="text"
                    name="insuranceAddress"
                    id="insuranceAddress"
                    placeholder="Nơi đăng ký bảo hiểm y tế"
                    onChange={(e) => setInsuranceAddress(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="registerDate">
                    Ngày đăng ký (Ngày/Tháng/Năm)
                  </Label>
                  <DateWrapper>
                    <DayPickerInput
                      style={{ width: '100%' }}
                      selected={registerDate}
                      format="DD-MM-YYYY"
                      parseDate={parseDate}
                      formatDate={formatDate}
                      placeholder="Ngày đăng ký"
                      inputProps={{ className: 'form-control' }}
                      onChange={(date) => setRegisterDate(date)}
                    />
                  </DateWrapper>
                </FormGroup>
                <FormGroup>
                  <Label for="expiredDate">Ngày hết hạn (Ngày/Tháng/Năm)</Label>
                  <DateWrapper>
                    <DayPickerInput
                      style={{ width: '100%' }}
                      selected={expiredDate}
                      format="DD-MM-YYYY"
                      parseDate={parseDate}
                      formatDate={formatDate}
                      placeholder="Ngày hết hạn"
                      inputProps={{ className: 'form-control' }}
                      onChange={(date) => setExpiredDate(date)}
                    />
                  </DateWrapper>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Collapse>
      </Row>
      <ButtonFragment
        onGoBack={() => dispatch(selectServiceType(null))}
        onGoNext={() => dispatch(
          setInsurance({
            haveInsurance,
            insuranceId,
            insuranceAddress,
            registerDate,
            expiredDate,
          }),
        )}
        data={{ haveInsurance }}
      />
    </div>
  );
};

export default InsuranceSelect;
