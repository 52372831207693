import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Spinner,
} from 'reactstrap';

import { useWindowSize } from 'app/hooks';
import auth from '../utils/auth';
import avatar from '../assets/images/hcdc-random-avatar.png';

const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 20%;
  margin-right: 5px;
`;

const handleDisplay = (props) => {
  if (props.sidebarcollapsed === 1 && props.size < 515) {
    return 'none';
  }
  return 'block';
};


const StyledDropdownToggle = styled(DropdownToggle)`
  padding: 0 !important;
  display: ${(props) => handleDisplay(props)};
`;

const cursorPointer = { cursor: 'pointer' };

const UserInfo = (props) => {
  const { history } = props;
  const { sidebarCollapsed } = useSelector((state) => state.global);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const windowSize = useWindowSize();

  return (
    <UncontrolledDropdown setActiveFromChild style={cursorPointer}>
      <StyledDropdownToggle
        tag="a"
        className="nav-link mt-1"
        caret
        size={windowSize.width.toString()}
        sidebarcollapsed={sidebarCollapsed ? 0 : 1}
      >
        <Avatar src={avatar} alt="avatar" />
        <span>
          {userInfo && (
            <>
              <span className="text-secondary">{userInfo.FullName}</span>
            </>
          )}
          {!userInfo && <Spinner size="sm" color="info" />}
        </span>
      </StyledDropdownToggle>
      <DropdownMenu right>
        <DropdownItem href="/profile">Thông tin cá nhân</DropdownItem>
        <DropdownItem
          onClick={() => {
            auth.logout();
            history.push('/login');
          }}
        >
          Đăng xuất
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

UserInfo.propTypes = {
  /** react-router-dom history object */
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(UserInfo);
