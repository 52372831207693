import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  Row,
  Card,
  CardText,
  Col,
  Spinner,
  Button,
  CardTitle,
  CardHeader,
} from 'reactstrap';

import moment from 'moment';
import { useToasts } from 'react-toast-notifications';

import { useSelector, useDispatch } from 'react-redux';
import { updateAppointment } from 'immunization-booking/actions/immunization-booking';

import { showConfirmModal } from 'app/actions/global';
import { StatusColor, StatusLabel } from 'immunization/utils/constants';

const StyledDiv = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
const StyledCard = styled(Card)`
  background: linear-gradient(90deg, rgba(0,78,95,.7) 0%, rgba(77,169,188,1) 100%) !important;
  border-radius: 0px;
  padding: 15px 30px;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
`;
const StyledCardHeader = styled(CardHeader)`
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  border-radius: 0;
  background: rgba(0,78,95,.8);
`;
const BorderedCol = styled(Col)`
  border-top: 1px solid white;
`;

const StyledCardText = styled(CardText)`
  color: gold;
  font-weight: normal;
`;

const StyledCardText2 = styled(CardText)`
  overflow: hidden;
  white-space: nowrap; 
  text-overflow: ellipsis;
`;

// const StyledCardTitle = styled(CardTitle)`
//   height: 120px;
// `;

const AddressText = styled(CardText)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ImmunizationAppointment = (props) => {
  const { onRefresh, initialData } = props;

  const { updateAppointmentLoading } = useSelector((state) => state.immunizationBooking);

  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const appointment = useMemo(
    () => (
      <div className="mt-3">
        <StyledCardHeader className="text-center">
          <h4 className="font-weight-normal text-warning mb-0">
            Phiếu hẹn
            {/* {` ${initialData?.id}`} */}
          </h4>
        </StyledCardHeader>
        <StyledCard inverse>
          {initialData.status !== 1 && (
            <div className="ribbon-wrapper">
              <div className={`ribbon ${StatusColor[initialData.status]}`}>
                {StatusLabel[initialData.status]}
              </div>
            </div>
          )}

          <CardTitle className="mb-0 text-left">
            <h4>{initialData?.unit.name}</h4>
            <AddressText className="mb-2">{`Địa chỉ: ${initialData?.unit.address}`}</AddressText>
          </CardTitle>

          <Row>
            <BorderedCol xs={12}>
              <h5 className="my-2">Thông tin người tiêm</h5>
            </BorderedCol>
            <Col xs={4}>
              <CardText>Họ và tên</CardText>
            </Col>
            <Col xs={8}>
              <CardText>{initialData.customer?.fullname}</CardText>
            </Col>
            <Col xs={4}>
              <CardText>Ngày sinh</CardText>
            </Col>
            <Col xs={8}>
              <CardText>
                {moment(initialData.customer?.birthDate).format('DD-MM-YYYY')}
              </CardText>
            </Col>
            <Col xs={4}>
              <CardText>Điện thoại</CardText>
            </Col>
            <Col xs={8} className="mb-2">
              <CardText>{initialData.customer?.phone}</CardText>
            </Col>
            <Col xs={4} className="mb-2">
              <CardText>Mã tiêm chủng</CardText>
            </Col>
            <Col xs={8}>{initialData.customer?.ic}</Col>
            {initialData?.contacts.length > 0 && (
              <BorderedCol xs={12}>
                <h5 className="my-2">Thông tin người đi cùng</h5>
              </BorderedCol>
            )}
            {initialData?.contacts.map((c) => (
              <Col xs={12} key={c?.fullname}>
                <Row>
                  <Col xs={4}>Họ và tên</Col>
                  <Col xs={8}>{`${c?.fullname ?? 'Không có'}`}</Col>
                  <Col xs={4}>Số điện thoại</Col>
                  <Col xs={8}>{`${c?.phone ?? 'Không có'}`}</Col>
                  <Col xs={4} className="mb-2">Mối quan hệ</Col>
                  <Col xs={8} className="mb-2">{`${c?.relationship ?? 'Không có'}`}</Col>
                </Row>
              </Col>
            ))}
            <BorderedCol xs={12}>
              <h5 className="my-2">Thông tin lịch hẹn</h5>
            </BorderedCol>
            <Col xs={4}>
              <CardText>Bệnh cần tiêm</CardText>
            </Col>
            <Col xs={8}>
              <StyledCardText2>{initialData?.service.name}</StyledCardText2>
            </Col>
            {/* <Col xs={4}>
              <CardText>Loại hình</CardText>
            </Col>
            <Col xs={8}>
              <CardText>{healthCareList.find((e) => e.id === initialData?.ServiceId)?.ServiceType ?? ''}</CardText>
            </Col> */}
            <Col xs={4}>
              <CardText>Ngày tiêm</CardText>
            </Col>
            <Col xs={8}>
              <StyledCardText>
                {moment(initialData?.date).format('DD-MM-YYYY')}
              </StyledCardText>
            </Col>
            <Col xs={4}>
              <CardText>Giờ tiêm</CardText>
            </Col>
            <Col xs={8}>
              <StyledCardText>
                {initialData?.interval.from}
              </StyledCardText>
            </Col>
          </Row>
        </StyledCard>
        {initialData.status === 1 && (
          <Row>
            <Col xs="12" className="text-center">
              <Button
                outline
                color="danger"
                className="mt-3"
                onClick={() => dispatch(
                  showConfirmModal(
                    'Xác nhận hủy phiếu hẹn?',
                    () => {
                      dispatch(updateAppointment(initialData.id, 5)).then(
                        () => {
                          addToast('Hủy phiếu hẹn thành công', {
                            appearance: 'success',
                          });
                          onRefresh();
                        },
                      );
                    },
                  ),
                )}
              >
                Hủy phiếu hẹn
              </Button>
            </Col>
          </Row>
        )}
      </div>
    ), [
      dispatch,
      addToast,
      onRefresh,
      initialData,
    ],
  );
  return (
    <div>
      {updateAppointmentLoading ? (
        <StyledDiv>
          <Spinner color="info" />
        </StyledDiv>
      ) : (
        appointment
      )}
    </div>
  );
};

ImmunizationAppointment.propTypes = {
  initialData: PropTypes.shape({
    bookedUser: PropTypes.string,
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        fullanem: PropTypes.string,
        phone: PropTypes.string,
        relationship: PropTypes.string,
      }),
    ),
    customer: PropTypes.shape({
      address: PropTypes.string,
      birthDate: PropTypes.string,
      fullname: PropTypes.string,
      id: PropTypes.string,
      ic: PropTypes.string,
      phone: PropTypes.string,
    }),
    dateCreated: PropTypes.string,
    doctor: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    form: PropTypes.shape({}),
    unit: PropTypes.shape({
      address: PropTypes.string,
      amail: PropTypes.string,
      phone: PropTypes.string,
      website: PropTypes.string,
    }),
    id: PropTypes.string,
    room: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    interval: PropTypes.shape({
      id: PropTypes.string,
      from: PropTypes.string,
    }),
    date: PropTypes.string,
    service: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    status: PropTypes.number,
  }),
  onRefresh: PropTypes.func,
};

ImmunizationAppointment.defaultProps = {
  initialData: {},
  onRefresh: () => {},
};

export default ImmunizationAppointment;
