import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
} from 'reactstrap';
import styled from 'styled-components';

import { setHistoryTab } from 'app/actions/global';
import MCBTab from './MCBTab';
import EmergencyTab from './EmergencyTab';
import TelehealthTab from './TelehealthTab';
import HomeCareTab from './HomeCareTab';

const tabItems = [
  {
    key: 1,
    name: 'Khám chữa bệnh',
    component: <MCBTab />,
  },
  {
    key: 2,
    name: 'Cấp cứu tại chỗ',
    component: <EmergencyTab />,
  },
  {
    key: 3,
    name: 'Chữa bệnh tại nhà',
    component: <HomeCareTab />,
  },
  {
    key: 4,
    name: 'Tư vấn sức khỏe từ xa',
    component: <TelehealthTab />,
  },
];

const StyledNavLink = styled(NavLink)`
  color: ${(props) => (props.active ? '#E26B0A !important' : 'red')};
  border-bottom: ${(props) => (props.active ? '1px solid rgba(0,0,0,.125) !important' : 'none')};
  cursor: pointer;
`;

const StyledCard = styled(Card)`
  border-top: none;
  padding-top: .25rem;
  min-height: calc(100vh - 140px);
`;

const HistoryLayout = () => {
  const { historyTab } = useSelector((state) => state.global);
  const dispatch = useDispatch();

  return (
    <div style={{ padding: '0 14px' }}>
      <Nav tabs>
        {tabItems.map((e) => (
          <NavItem key={e.key}>
            <StyledNavLink
              key={e.key}
              active={e.key === historyTab}
              onClick={() => dispatch(setHistoryTab(e.key))}
            >
              {e.name}
            </StyledNavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={historyTab}>
        {tabItems.map((e) => (
          <TabPane
            tabId={e.key}
            key={e.key}
          >
            <Row>
              <Col sm="12">
                <StyledCard
                  body
                >
                  {e.component}
                </StyledCard>
              </Col>
            </Row>
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default HistoryLayout;
