import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { showConfirmModal } from 'app/actions/global';

const StyledModal = styled(Modal)`background-color: transparent !important;`;
const StyledModalHeader = styled(ModalHeader)`border-bottom: none !important;`;
const StyledModalFooter = styled(ModalFooter)`border-top: none !important;`;
const StyledText = styled.p`
  font-size: 2rem;
  font-weight: 300;
  color: white;
`;
const StyledButton = styled(Button)`padding: 8px 24px ;`;

const ConfirmModal = () => {
  const dispatch = useDispatch();
  const {
    confirmMessage,
    confirmCallback,
  } = useSelector((state) => state.global);

  const [onHoverSecondaryButton, setOnHoverSecondaryButton] = useState(false);
  const [onHoverSuccessButton, setOnHoverSuccessButton] = useState(false);

  return (
    <StyledModal className="global-modal" centered isOpen={Boolean(confirmCallback) && Boolean(confirmMessage)}>
      <StyledModalHeader>
        <StyledText>{confirmMessage}</StyledText>
      </StyledModalHeader>
      <StyledModalFooter>
        <StyledButton
          color={!onHoverSecondaryButton ? 'secondary' : 'danger'}
          outline={!onHoverSecondaryButton}
          onMouseEnter={() => setOnHoverSecondaryButton(true)}
          onMouseLeave={() => setOnHoverSecondaryButton(false)}
          onClick={() => dispatch(showConfirmModal(null, null))}
        >
          <FaTimes className="mr-3" />
          Hủy
        </StyledButton>

        <StyledButton
          color="success"
          outline={!onHoverSuccessButton}
          onMouseEnter={() => setOnHoverSuccessButton(true)}
          onMouseLeave={() => setOnHoverSuccessButton(false)}
          onClick={() => {
            confirmCallback();
            dispatch(showConfirmModal(null, null));
          }}
        >
          <FaCheck className="mr-3" />
          Xác nhận
        </StyledButton>

      </StyledModalFooter>
    </StyledModal>
  );
};

export default ConfirmModal;
