import {
  MCB_SET_STEP,
  MCB_SELECT_PATIENT,
  MCB_SELECT_CLINIC,
  MCB_SELECT_DATE,
  MCB_SELECT_EXPERT,
  MCB_SELECT_SERVICE_TYPE,
  MCB_GET_SERVICE_TYPE_REQUEST,
  MCB_GET_SERVICE_TYPE_SUCCESS,
  MCB_GET_SERVICE_TYPE_FAILURE,
  MCB_GET_HEALTHCARE_BY_HOSPITAL_REQUEST,
  MCB_GET_HEALTHCARE_BY_HOSPITAL_SUCCESS,
  MCB_GET_HEALTHCARE_BY_HOSPITAL_FAILURE,
  MCB_SET_INSURANCE,
  MCB_SELECT_SERVICE,
  MCB_GET_HEALTHCARE_SCHEDULE_SUCCESS,
  MCB_GET_HEALTHCARE_SCHEDULE_FAILURE,
  MCB_GET_HEALTHCARE_SCHEDULE_REQUEST,
  MCB_GET_SERIVCE_BY_DOCTOR_REQUEST,
  MCB_GET_SERIVCE_BY_DOCTOR_SUCCESS,
  MCB_GET_SERIVCE_BY_DOCTOR_FAILURE,
  MCB_GET_DOCTOR_REQUEST,
  MCB_GET_DOCTOR_SUCCESS,
  MCB_GET_DOCTOR_FAILURE,
  MCB_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_REQUEST,
  MCB_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_SUCCESS,
  MCB_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_FAILURE,
  MCB_CREATE_EXAMINATION_REQUEST,
  MCB_CREATE_EXAMINATION_SUCCESS,
  MCB_CREATE_EXAMINATION_FAILURE,
  MCB_DELETE_EXAMINATION_REQUEST,
  MCB_DELETE_EXAMINATION_SUCCESS,
  MCB_DELETE_EXAMINATION_FAILURE,
  MCB_RESET,
} from '../actions/types';

const initialState = {
  currentStep: 0,
  selectedService: null,
  selectedPatient: null,
  selectedClinic: null,
  selectedServiceType: null,
  selectedDate: null,
  selectedExpert: null,
  listServices: [],
  listServiceTypes: [],
  listSchedules: [],
  listDoctors: [],
  listSchedulesByDoctor: [],
  insuranceInfo: {
    haveInsurance: false,
    insuranceId: '',
    insuranceAddress: '',
    registerDate: '',
    expiredDate: '',
  },
  examinationData: null,
  // examinationData: {"ServiceType":null,"Hospital":{"Id":0,"Name":"Bệnh Viện Thử Nghiệm","Image":null,"Website":null,"Phone":null,"Email":null,"Price":0.0,"Address":"115/21 Phạm Đình Hổ, P6, Q6, TPHCM","UnitType":null,"Introduction":null,"Username":null,"Fax":null,"IsPublished":false},"Doctor":{"Id":0,"IdH":null,"Image":null,"FullName":"Nguyễn Văn A","Phone":null,"Address":null,"Email":null,"AcademicDegree":null,"AcademicRank":null,"Specialized":null,"Summary":null,"BirthDay":null},"Room":{"Id":0,"Name":"Tổng Quát","HealthCareId":null,"HealthCare":null},"Customer":{"Image":null,"Id":0,"FullName":"Dang Xuan Anh","CustomerCode":"1900002403","Phone":"0962712504","Email":"dxanh97@gmail.com","HealthInsurance":null,"Address":null,"BirthDate":null,"Gender":false,"DistrictCode":null,"ProvinceCode":null,"WardCode":null,"DistrictName":null,"ProvinceName":null,"WardName":null},"Service":{"Id":0,"Name":"Tổng Quát","ServiceType":null,"Price":0.0,"Image":null,"Code":null},"Detail":{"TimeId":4,"Time":"12-03-2020","From":"09:45:00","To":null,"Active":null},"Price":1.0,"Id":3739,"StatusCode":0,"StatusLabel":null,"PaymentStatus":null,"HasHealthInsurance":null,"Type":0,"IsRemoved":null},
  creatingExamination: false,
  deleteExaminationState: null,
  deletingExamination: false,
  loadingSerivceType: false,
  loadingServices: false,
  loadingDoctors: false,
  loadingSchedules: false,
  loadingDoctorSchedules: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MCB_SET_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case MCB_SELECT_PATIENT:
      return {
        ...state,
        selectedPatient: action.payload,
      };
    case MCB_SELECT_CLINIC:
      return {
        ...state,
        selectedClinic: action.payload,
      };
    case MCB_SELECT_SERVICE_TYPE:
      return {
        ...state,
        selectedServiceType: action.payload,
      };
    case MCB_GET_SERVICE_TYPE_REQUEST:
      return {
        ...state,
        listServiceTypes: [],
        loadingSerivceType: true,
      };
    case MCB_GET_SERVICE_TYPE_SUCCESS:
      return {
        ...state,
        listServiceTypes: action.payload,
        loadingSerivceType: false,
      };
    case MCB_GET_SERVICE_TYPE_FAILURE:
      return {
        ...state,
        loadingSerivceType: false,
      };
    case MCB_GET_HEALTHCARE_BY_HOSPITAL_REQUEST:
      return {
        ...state,
        loadingServices: true,
        listServices: [],
      };
    case MCB_GET_HEALTHCARE_BY_HOSPITAL_SUCCESS:
      return {
        ...state,
        loadingServices: false,
        listServices: action.payload,
      };
    case MCB_GET_HEALTHCARE_BY_HOSPITAL_FAILURE:
      return {
        loadingServices: false,
        ...state,
      };
    case MCB_SELECT_EXPERT:
      return {
        ...state,
        selectedExpert: action.payload,
      };
    case MCB_GET_SERIVCE_BY_DOCTOR_REQUEST:
      return {
        loadingDoctors: true,
        ...state,
      };
    case MCB_GET_SERIVCE_BY_DOCTOR_SUCCESS: {
      const { doctor, services } = action.payload;
      return {
        ...state,
        listDoctors: state.listDoctors.map((d) => {
          if (d.Id !== doctor) return d;
          return {
            ...d,
            listServices: services,
          };
        }),
        loadingDoctors: false,
      };
    }
    case MCB_GET_SERIVCE_BY_DOCTOR_FAILURE:
      return {
        loadingDoctors: false,
        ...state,
      };
    case MCB_GET_HEALTHCARE_SCHEDULE_REQUEST:
      return {
        ...state,
        loadingSchedules: true,
        listSchedules: [],
      };
    case MCB_GET_HEALTHCARE_SCHEDULE_SUCCESS:
      return {
        ...state,
        loadingSchedules: false,
        listSchedules: action.payload,
      };
    case MCB_GET_HEALTHCARE_SCHEDULE_FAILURE:
      return {
        ...state,
        loadingSchedules: false,
      };
    case MCB_GET_DOCTOR_REQUEST:
      return {
        ...state,
        listDoctors: [],
      };
    case MCB_GET_DOCTOR_SUCCESS:
      return {
        ...state,
        listDoctors: action.payload,
      };
    case MCB_GET_DOCTOR_FAILURE:
      return {
        ...state,
      };
    case MCB_SET_INSURANCE:
      return {
        ...state,
        insuranceInfo: action.payload,
      };
    case MCB_SELECT_SERVICE:
      return {
        ...state,
        selectedService: action.payload,
      };
    case MCB_SELECT_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case MCB_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_REQUEST:
      return {
        ...state,
        loadingDoctorSchedules: true,
        listSchedulesByDoctor: [],
      };
    case MCB_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_SUCCESS:
      return {
        ...state,
        loadingDoctorSchedules: false,
        listSchedulesByDoctor: action.payload,
      };
    case MCB_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_FAILURE:
      return {
        loadingDoctorSchedules: false,
        ...state,
      };
    case MCB_CREATE_EXAMINATION_REQUEST:
      return {
        ...state,
        creatingExamination: true,
      };
    case MCB_CREATE_EXAMINATION_SUCCESS:
      return {
        ...state,
        examinationData: action.payload,
        creatingExamination: false,
      };
    case MCB_CREATE_EXAMINATION_FAILURE:
      return {
        ...state,
        creatingExamination: false,
      };
    case MCB_DELETE_EXAMINATION_REQUEST:
      return {
        ...state,
        deletingExamination: true,
      };
    case MCB_DELETE_EXAMINATION_SUCCESS:
      return {
        ...state,
        deleteExaminationState: action.payload,
        deletingExamination: false,
      };
    case MCB_DELETE_EXAMINATION_FAILURE:
      return {
        ...state,
        deletingExamination: false,
      };
    case MCB_RESET:
      return initialState;
    default:
      return state;
  }
}
