import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  Form,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Row,
  Col,
  Spinner,
  Alert,
  Label,
} from 'reactstrap';
import {
  FaUserAlt,
  FaCalendarAlt,
  FaTransgender,
  FaAddressBook,
  FaCity,
  FaMap,
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaIdCard,
  FaBookOpen,
} from 'react-icons/fa';
import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';

import locations from 'app/mock/locations.json';

import moment from 'moment';
import Select from 'react-select';
import { useToasts } from 'react-toast-notifications';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import { useDispatch, useSelector } from 'react-redux';
import { updateCustomer } from 'app/actions/global';

const DateWrapper = styled.div`
  width: 100%;
  position: relative;
  flex: 1 1;
  min-width: 0;
  margin-bottom: 0;
`;

const genderOptions = [
  { key: 1, value: 1, label: 'Nam' },
  { key: 0, value: 0, label: 'Nữ' },
];

const StyledLabel = styled.span`
  position: absolute;
  left: 13px;
  top: 7px;
  color: #999;
  display: ${(props) => (props.dob ? 'none' : 'inline-block')};
  pointer-events: none;
  z-index: -1;
`;
const StyledDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  height: 100%;
`;

const UpdateInfo = () => {
  const dispatch = useDispatch();

  const [fullname, setFullname] = useState('');
  const [gender, setGender] = useState(1);
  const [dob, setDob] = useState(null);
  const [address, setAddress] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({ province: null, district: null, ward: null });
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [healthInsurance, setHealthInsurance] = useState('');
  const [healthInsuranceId, setHealthInsuranceId] = useState('');
  const [identityCard, setIdentityCard] = useState('');
  const { userInfo } = useSelector((state) => state.auth);
  const isLoading = userInfo === null;

  useEffect(() => {
    if (userInfo) {
      setFullname(userInfo?.FullName);
      setGender(userInfo?.Gender ? 1 : 0);
      setDob(moment(userInfo?.BirthDate).format('DD-MM-YYYY'));
      setAddress(userInfo?.Address);
      setHealthInsurance(userInfo?.HealthInsurance);
      setSelectedLocation({
        province: userInfo?.ProvinceCode ? (locations.find((p) => p.Value === userInfo?.ProvinceCode) || null) : null,
        district: userInfo?.DistrictCode ? (locations.find((p) => p.Value === userInfo?.ProvinceCode).Districts.find((d) => d.Value === userInfo?.DistrictCode) || null) : null,
        ward: userInfo?.WardCode ? (locations.find((p) => p.Value === userInfo?.ProvinceCode).Districts.find((d) => d.Value === userInfo?.DistrictCode).Wards.find((w) => w.Value === userInfo?.WardCode) || null) : null,
      });
      setPhone(userInfo?.Phone);
      setEmail(userInfo?.Email);
      setHealthInsuranceId(userInfo?.HealthInsurance);
      setIdentityCard(userInfo?.IC);
    }
  }, [userInfo]);

  const styles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const handleKeyUp = (e, setDate) => {
    const inputValue = e.target.value;
    if (inputValue) {
      setDate(null);
    }
    const d = inputValue.replace(/\D/g, '').slice(0, 10);
    if (d.length >= 5) {
      const stringResult = `${d.slice(0, 2)}-${d.slice(2, 4)}-${d.slice(4)}`;
      setDate(stringResult);
    } else if (d.length >= 3) {
      setDate(`${d.slice(0, 2)}-${d.slice(2)}`);
    } else {
      setDate(d);
    }
  };

  const { addToast } = useToasts();
  const [updateFailed, setUpdateFailed] = useState(false);
  const [loadingEditInfo, setLoadingEditInfo] = useState(false);

  const editInfo = (e) => {
    e.preventDefault();
    setLoadingEditInfo(true);
    const data = {
      ...userInfo,
      Fullname: fullname,
      Phone: phone,
      Email: email,
      HealthInsurance: healthInsuranceId,
      Address: address,
      BirthDate: moment(dob).format('YYYY-MM-DD'),
      Gender: gender === 1,
      ProvinceCode: selectedLocation.province.Value,
      DistrictCode: selectedLocation.district.Value,
      WardCode: selectedLocation.ward.Value,
    };
    dispatch(updateCustomer(data))
      .then(() => {
        setUpdateFailed(false);
        setLoadingEditInfo(false);
        addToast('Sửa thông tin thành công', { appearance: 'success' });
      });
  };

  const { updateErrorMessage } = useSelector((state) => state.global);

  return (
    <>
      {isLoading ? (
        <StyledDiv>
          <Spinner color="info" />
        </StyledDiv>
      ) : (
        <Form onSubmit={editInfo} className="required-form">
          <Row>
            <Col sm="12" xl="6" xs="12" className="mt-1">
              <Label for="fullname">Họ và tên</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaUserAlt />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id="fullname"
                  required
                  invalid={!fullname}
                  value={fullname || ''}
                  onChange={(e) => {
                    setFullname(e.target.value);
                    setUpdateFailed(false);
                  }}
                />
                <Label>Họ và tên</Label>
              </InputGroup>
            </Col>
            <Col sm="6" xl="3" xs="12" className="mt-1">
              <Label for="gender">Giới tính</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaTransgender />
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                  id="gender"
                  required
                  styles={styles}
                  placeholder="Giới tính"
                  defaultValue={{ label: gender ? 'Nam' : 'Nữ', value: gender ? 1 : 0 }}
                  options={genderOptions}
                  onChange={(e) => {
                    setGender(e.value);
                    setUpdateFailed(false);
                  }}
                />
              </InputGroup>
            </Col>

            <Col sm="6" xl="3" xs="12" className="mt-1">
              <Label for="fullname">Ngày sinh</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaCalendarAlt />
                  </InputGroupText>
                </InputGroupAddon>
                <DateWrapper>
                  <DayPickerInput
                    style={{ width: '100%' }}
                    value={dob}
                    format="DD-MM-YYYY"
                    parseDate={parseDate}
                    formatDate={formatDate}
                    placeholder="Ngày sinh"
                    dayPickerProps={{
                      modifiers: {
                        disabled: [
                          {
                            after: new Date(),
                          },
                        ],
                      },
                    }}
                    inputProps={{
                      className: 'form-control',
                      maxLength: 10,
                      onKeyUp: (e) => handleKeyUp(e, setDob),
                    }}
                    onDayChange={(date) => {
                      if (date) {
                        setDob(date);
                        setUpdateFailed(false);
                      }
                    }}
                  />
                  <StyledLabel dob={dob}>
                    Ngày sinh
                    <span className="text-danger pl-1">*</span>
                  </StyledLabel>
                </DateWrapper>
              </InputGroup>
            </Col>

            <Col sm="6" xl="3" xs="12" className="mt-1">
              <Label for="phone">Số điện thoại</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaPhone />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id="phone"
                  placeholder="Số điện thoại"
                  value={phone || ''}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setUpdateFailed(false);
                  }}
                />
              </InputGroup>
            </Col>

            <Col sm="6" xl="3" xs="12" className="mt-1">
              <Label for="email">Email</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaEnvelope />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id="email"
                  placeholder="Email"
                  value={email || ''}
                  onChange={(e) => {
                    setUpdateFailed(false);
                    setEmail(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>

            <Col sm="6" xl="3" xs="12" className="mt-1">
              <Label for="cmnd">Mã định danh</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaIdCard />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id="cmnd"
                  placeholder="Mã định danh"
                  value={identityCard || ''}
                  onChange={(e) => {
                    setIdentityCard(e.target.value);
                    setUpdateFailed(false);
                  }}
                />
              </InputGroup>
            </Col>

            <Col sm="6" xl="3" xs="12" className="mt-1">
              <Label for="healthInsurance">Số bảo hiểm y tế</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaBookOpen />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id="healthInsurance"
                  value={healthInsurance}
                  placeholder="Số bảo hiểm y tế"
                  onChange={(e) => {
                    setUpdateFailed(false);
                    setHealthInsuranceId(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>

            <Col sm="6" xl="3" xs="12" className="mt-1">
              <Label for="address">Số nhà, đường</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaAddressBook />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Số nhà, đường"
                  id={address}
                  value={address}
                  onChange={(e) => {
                    setUpdateFailed(false);
                    setAddress(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm="6" xl="3" xs="12" className="mt-1">
              <Label for="fullname">Tỉnh/Thành phố</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaCity />
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                  required
                  isSearchable
                  placeholder="Tỉnh/Thành phố"
                  value={selectedLocation?.province?.Value ? {
                    value: selectedLocation?.province?.Value,
                    label: selectedLocation?.province?.Name,
                  } : null}
                  noOptionsMessage={() => <span>Không tìm thấy</span>}
                  styles={styles}
                  onChange={(el) => setSelectedLocation({
                    province: locations.find(
                      (province) => province.Value === el.value,
                    ),
                    district: null,
                    ward: null,
                  })}
                  options={locations.map((province) => ({
                    value: province.Value,
                    label: province.Name,
                  }))}
                />
              </InputGroup>
            </Col>
            <Col sm="6" xl="3" xs="12" className="mt-1">
              <Label for="fullname">Quận/Huyện</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaMap />
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                  required
                  isSearchable
                  isDisabled={!selectedLocation.province}
                  placeholder="Quận/Huyện"
                  value={selectedLocation?.district?.Value ? {
                    value: selectedLocation?.district?.Value,
                    label: selectedLocation?.district?.Name,
                  } : null}
                  noOptionsMessage={() => <span>Không tìm thấy</span>}
                  styles={styles}
                  onChange={(el) => setSelectedLocation({
                    ...selectedLocation,
                    district: selectedLocation.province.Districts.find(
                      (district) => district.Value === el.value,
                    ),
                    ward: null,
                  })}
                  options={
                    selectedLocation.province
                    && selectedLocation.province.Districts.map((district) => ({
                      value: district.Value,
                      label: district.Name,
                    }))
                  }
                />
              </InputGroup>
            </Col>
            <Col sm="6" xl="3" xs="12" className="mt-1">
              <Label for="fullname">Phường/Xã</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaMapMarkerAlt />
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                  required
                  isDisabled={!selectedLocation.district}
                  placeholder="Phường/Xã"
                  value={selectedLocation?.ward?.Value ? {
                    value: selectedLocation?.ward?.Value,
                    label: selectedLocation?.ward?.Name,
                  } : null}
                  noOptionsMessage={() => <span>Không tìm thấy</span>}
                  isSearchable
                  styles={styles}
                  onChange={(el) => setSelectedLocation({
                    ...selectedLocation,
                    ward: selectedLocation.district.Wards.find(
                      (ward) => ward.Value === el.value,
                    ),
                  })}
                  options={
                    selectedLocation.district
                    && selectedLocation.district.Wards.map((ward) => ({
                      value: ward.Value,
                      label: ward.Name,
                    }))
                  }
                />
              </InputGroup>
            </Col>
            <Col xs="12" className="mt-3">
              <span className="text-danger">(*): Thông tin bắt buộc</span>
            </Col>
            <Col xs="12" className="d-flex justify-content-center mt-3">
              {loadingEditInfo ? (
                <Spinner color="info" />
              ) : (
                <Button color="success" disabled={updateFailed}>
                  Xác nhận
                </Button>
              )}
            </Col>
            {updateFailed && (
              <Col xs="12" className="d-flex justify-content-center mt-3">
                <Alert color="danger">
                  {updateErrorMessage}
                </Alert>
              </Col>
            )}
          </Row>
        </Form>
      )}
    </>
  );
};

export default UpdateInfo;
