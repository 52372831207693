export const TELE_SET_STEP = 'TELE_SET_STEP';
export const TELE_SELECT_SERVICE = 'TELE_SELECT_SERVICE';
export const TELE_SELECT_CLINIC = 'TELE_SELECT_CLINIC';
export const TELE_SELECT_PATIENT = 'TELE_SELECT_PATIENT';
export const TELE_SELECT_EXPERT = 'TELE_SELECT_EXPERT';
export const TELE_SELECT_SERVICE_TYPE = 'TELE_SELECT_SERVICE_TYPE';
export const TELE_SELECT_DATE = 'TELE_SELECT_DATE';
export const TELE_GET_SERVICE_TYPE_REQUEST = 'TELE_GET_SERVICE_TYPE_REQUEST';
export const TELE_GET_SERVICE_TYPE_SUCCESS = 'TELE_GET_SERVICE_TYPE_SUCCESS';
export const TELE_GET_SERVICE_TYPE_FAILURE = 'TELE_GET_SERVICE_TYPE_FAILURE';

export const TELE_GET_HEALTHCARE_BY_HOSPITAL_REQUEST = 'TELE_GET_HEALTHCARE_BY_HOSPITAL_REQUEST';
export const TELE_GET_HEALTHCARE_BY_HOSPITAL_SUCCESS = 'TELE_GET_HEALTHCARE_BY_HOSPITAL_SUCCESS';
export const TELE_GET_HEALTHCARE_BY_HOSPITAL_FAILURE = 'TELE_GET_HEALTHCARE_BY_HOSPITAL_FAILURE';

export const TELE_GET_HEALTHCARE_SCHEDULE_REQUEST = 'TELE_GET_HEALTHCARE_SCHEDULE_REQUEST';
export const TELE_GET_HEALTHCARE_SCHEDULE_SUCCESS = 'TELE_GET_HEALTHCARE_SCHEDULE_SUCCESS';
export const TELE_GET_HEALTHCARE_SCHEDULE_FAILURE = 'TELE_GET_HEALTHCARE_SCHEDULE_FAILURE';

export const TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_REQUEST = 'TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_REQUEST';
export const TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_SUCCESS = 'TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_SUCCESS';
export const TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_FAILURE = 'TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_FAILURE';

export const TELE_GET_SERIVCE_BY_DOCTOR_REQUEST = 'TELE_GET_SERIVCE_BY_DOCTOR_REQUEST';
export const TELE_GET_SERIVCE_BY_DOCTOR_SUCCESS = 'TELE_GET_SERIVCE_BY_DOCTOR_SUCCESS';
export const TELE_GET_SERIVCE_BY_DOCTOR_FAILURE = 'TELE_GET_SERIVCE_BY_DOCTOR_FAILURE';

export const TELE_GET_DOCTOR_REQUEST = 'TELE_GET_DOCTOR_REQUEST';
export const TELE_GET_DOCTOR_SUCCESS = 'TELE_GET_DOCTOR_SUCCESS';
export const TELE_GET_DOCTOR_FAILURE = 'TELE_GET_DOCTOR_FAILURE';

export const TELE_CREATE_EXAMINATION_REQUEST = 'TELE_CREATE_EXAMINATION_REQUEST';
export const TELE_CREATE_EXAMINATION_SUCCESS = 'TELE_CREATE_EXAMINATION_SUCCESS';
export const TELE_CREATE_EXAMINATION_FAILURE = 'TELE_CREATE_EXAMINATION_FAILURE';

export const TELE_DELETE_EXAMINATION_REQUEST = 'TELE_DELETE_EXAMINATION_REQUEST';
export const TELE_DELETE_EXAMINATION_SUCCESS = 'TELE_DELETE_EXAMINATION_SUCCESS';
export const TELE_DELETE_EXAMINATION_FAILURE = 'TELE_DELETE_EXAMINATION_FAILURE';

export const TELE_RESET = 'TELE_RESET';
