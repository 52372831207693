import React from 'react';
import { useSelector } from 'react-redux';

import ServiceSelect from './ServiceSelect';
import ExpertSelect from './ExpertSelect';

const ServiceExpertSection = () => {
  const { selectedServiceType } = useSelector((state) => state.booking);
  return (
    <div>
      {selectedServiceType.CanChoiceDoctor ? (
        <ExpertSelect />
      ) : (
        <ServiceSelect />
      )}
    </div>
  );
};

export default ServiceExpertSection;
