import moment from 'moment';
import types from '../actions/types';

const initialState = {
  currentStep: 0,

  profileList: [],
  diseaseList: [],
  vaccineList: [],
  vaccineInjectionList: [],
  injectionObjectList: [],
  healthCareList: [],
  clinicList: [],
  scheduleList: [],
  ticketList: [],
  ticketListView: [],
  roomList: [],
  historyList: [],
  doctorList: [],
  registerData: null,
  appointmentData: null,
  loadingGetProfiles: false,
  loadingGetDiseases: false,
  loadingGetVaccines: false,
  loadingGetVaccineInjection: false,
  loadingGetInjectionObjects: false,
  loadingGetHealthcare: false,
  loadingGetByHealthcare: false,
  loadingGetSchedule: false,
  loadingGetTickets: false,
  loadingRegister: false,
  loadingGetAppointment: false,
  loadingUpdateAppointment: false,
  loadingGetHistory: false,
  getDoctorLoading: false,
  createSubjectLoading: false,
  createAssignLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.EXAMINATION_SET_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case types.EXAMINATION_GET_PROFILES_REQUEST:
      return {
        ...state,
        loadingGetProfiles: true,
      };
    case types.EXAMINATION_GET_PROFILES_SUCCESS: {
      const response = action.payload;
      let result = [];
      result = response.map((e) => {
        const { BirthDate } = e;
        const today = moment();
        const diff = today.diff(BirthDate, 'days');
        return {
          ...e,
          diff,
        };
      });
      return {
        ...state,
        loadingGetProfiles: false,
        profileList: result,
      };
    }
    case types.EXAMINATION_GET_PROFILES_FAILURE:
      return {
        ...state,
        loadingGetProfiles: false,
      };
    case types.EXAMINATION_GET_DISEASES_REQUEST:
      return {
        ...state,
        loadingGetDiseases: true,
      };
    case types.EXAMINATION_GET_DISEASES_SUCCESS:
      return {
        ...state,
        loadingGetDiseases: false,
        diseaseList: action.payload,
      };
    case types.EXAMINATION_GET_DISEASES_FAILURE:
      return {
        ...state,
        loadingGetDiseases: false,
      };
    case types.EXAMINATION_GET_VACCINES_REQUEST:
      return {
        ...state,
        loadingGetVaccines: true,
      };
    case types.EXAMINATION_GET_VACCINES_SUCCESS:
      return {
        ...state,
        loadingGetVaccines: false,
        vaccineList: action.payload,
      };
    case types.EXAMINATION_GET_VACCINES_FAILURE:
      return {
        ...state,
        loadingGetVaccines: false,
      };
    case types.EXAMINATION_GET_VACCINE_INJECTION_REQUEST:
      return {
        ...state,
        loadingGetVaccineInjection: true,
      };
    case types.EXAMINATION_GET_VACCINE_INJECTION_SUCCESS:
      return {
        ...state,
        loadingGetVaccineInjection: false,
        vaccineInjectionList: action.payload,
      };
    case types.EXAMINATION_GET_VACCINE_INJECTION_FAILURE:
      return {
        ...state,
        loadingGetVaccineInjection: false,
      };
    case types.EXAMINATION_GET_INJECTION_REQUEST:
      return {
        ...state,
        loadingGetInjectionObjects: true,
      };
    case types.EXAMINATION_GET_INJECTION_SUCCESS:
      return {
        ...state,
        loadingGetInjectionObjects: false,
        injectionObjectList: action.payload,
      };
    case types.EXAMINATION_GET_INJECTION_FAILURE:
      return {
        ...state,
        loadingGetInjectionObjects: false,
      };
    case types.EXAMINATION_GET_HEALTHCARE_REQUEST:
      return {
        ...state,
        loadingGetHealthcare: true,
      };
    case types.EXAMINATION_GET_HEALTHCARE_SUCCESS:
      return {
        ...state,
        loadingGetHealthcare: false,
        healthCareList: action.payload,
      };
    case types.EXAMINATION_GET_HEALTHCARE_FAILURE:
      return {
        ...state,
        loadingGetHealthcare: false,
      };
    case types.EXAMINATION_GET_CLINIC_REQUEST:
      return {
        ...state,
        loadingGetByHealthcare: true,
        roomList: [],
      };
    case types.EXAMINATION_GET_CLINIC_SUCCESS:
      return {
        ...state,
        loadingGetByHealthcare: false,
        clinicList: action.payload,
      };
    case types.EXAMINATION_GET_CLINIC_FAILURE:
      return {
        ...state,
        loadingGetByHealthcare: false,
      };
    case types.EXAMINATION_GET_SCHEDULE_REQUEST:
      return {
        ...state,
        loadingGetSchedule: true,
      };
    case types.EXAMINATION_GET_SCHEDULE_SUCCESS:
      return {
        ...state,
        loadingGetSchedule: false,
        scheduleList: action.payload,
      };
    case types.EXAMINATION_GET_SCHEDULE_FAILURE:
      return {
        ...state,
        loadingGetSchedule: false,
      };
    case types.EXAMINATION_REGISTER_REQUEST:
      return {
        ...state,
        loadingRegister: true,
      };
    case types.EXAMINATION_REGISTER_SUCCESS:
      return {
        ...state,
        loadingRegister: false,
        registerData: action.payload,
      };
    case types.EXAMINATION_REGISTER_FAILURE:
      return {
        ...state,
        loadingRegister: false,
      };
    case types.EXAMINATION_GET_APPOINTMENT_REQUEST:
      return {
        ...state,
        loadingGetAppointment: true,
      };
    case types.EXAMINATION_GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loadingGetAppointment: false,
        appointmentData: action.payload,
      };
    case types.EXAMINATION_GET_APPOINTMENT_FAILURE:
      return {
        ...state,
        loadingGetAppointment: false,
      };
    case types.EXAMINATION_GET_TICKETS_REQUEST:
      return {
        ...state,
        loadingGetTickets: true,
      };
    case types.EXAMINATION_GET_TICKETS_SUCCESS:
      return {
        ...state,
        loadingGetTickets: false,
        ticketList: action.payload,
        ticketListView: [...new Set(action.payload.map((t) => t.time))].sort(),
      };
    case types.EXAMINATION_GET_TICKETS_FAILURE:
      return {
        ...state,
        loadingGetTickets: false,
      };
    case types.EXAMINATION_GET_TICKETS_WITHOUT_DATE_REQUEST:
      return {
        ...state,
        loadingGetTickets: true,
      };
    case types.EXAMINATION_GET_TICKETS_WITHOUT_DATE_SUCCESS: {
      const getUniqueListBy = (array) => [...new Map(array.map((item) => [item.room.id, item])).values()];
      return ({
        ...state,
        loadingGetTickets: false,
        roomList: getUniqueListBy(action.payload).map((r) => r.room),
      });
    }
    case types.EXAMINATION_GET_TICKETS_WITHOUT_DATE_FAILURE:
      return {
        ...state,
        loadingGetTickets: false,
      };
    case types.EXAMINATION_GET_HISTORY_REQUEST:
      return {
        ...state,
        loadingGetHistory: true,
      };
    case types.EXAMINATION_GET_HISTORY_SUCCESS:
      return {
        ...state,
        loadingGetHistory: false,
        historyList: action.payload,
      };
    case types.EXAMINATION_GET_HISTORY_FAILURE:
      return {
        ...state,
        loadingGetHistory: false,
      };
    case types.EXAMINATION_UPDATE_APPOINTMENT_REQUEST:
      return {
        ...state,
        loadingUpdateAppointment: true,
      };
    case types.EXAMINATION_UPDATE_APPOINTMENT_SUCCESS:
    case types.EXAMINATION_UPDATE_APPOINTMENT_FAILURE:
      return {
        ...state,
        loadingUpdateAppointment: false,
      };
    case types.EXAMINATION_GET_DOCTOR_REQUEST:
      return {
        ...state,
        getDoctorLoading: true,
      };
    case types.EXAMINATION_GET_DOCTOR_SUCCESS:
      return {
        ...state,
        doctorList: action.payload,
        getDoctorLoading: false,
      };
    case types.EXAMINATION_GET_DOCTOR_FAILURE:
      return {
        ...state,
        getDoctorLoading: false,
      };
    case types.EXAMINATION_RESET:
      return {
        ...state,
        currentStep: 0,
        registerData: null,
        appointmentData: null,
      };
    case types.CREATE_SUBJECT_REQUEST:
      return {
        ...state,
        createSubjectLoading: true,
      };
    case types.CREATE_SUBJECT_SUCCESS:
    case types.CREATE_SUBJECT_FAILURE:
      return {
        ...state,
        createSubjectLoading: false,
      };
    case types.CREATE_ASSIGN_REQUEST:
      return {
        ...state,
        createAssignLoading: true,
      };
    case types.CREATE_ASSIGN_SUCCESS:
    case types.CREATE_ASSIGN_FAILURE:
      return {
        ...state,
        createAssignLoading: false,
      };
    default:
      return state;
  }
}
