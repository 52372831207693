import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Row,
  Card,
  CardTitle,
  CardText,
  Col,
  Spinner,
  Button,
  CardHeader,
} from 'reactstrap';
import styled from 'styled-components';


import { withRouter } from 'react-router-dom';
import { getAppointment, resetExamination, updateExaminationAppointment } from 'covid-examination/actions/examination';

import locations from 'app/mock/locationsV2.json';
import { showConfirmModal } from 'app/actions/global';
import { useToasts } from 'react-toast-notifications';

const StyledCard = styled(Card)`
  background: linear-gradient(90deg, rgba(0,78,95,.7) 0%, rgba(77,169,188,1) 100%);
  border-radius: 0;
  padding: 15px 30px;
`;

const StyledCardHeader = styled(CardHeader)`
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
  border-radius: 0;
  background: rgba(0,78,95,.8);
`;

const StyledCardFooter = styled(CardHeader)`
  border-radius: 0 0 16px 16px !important;
  background: rgba(0,78,95,.8);
`;

const BorderedCol = styled(Col)`
  border-top: 1px solid white;
`;

const StyledCardText = styled(CardText)`
  color: gold;
  font-weight: normal;
`;

const StyledDiv = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Appointment = (props) => {
  const { id, onRefresh } = props;
  const {
    appointmentData,
    loadingGetAppointment,
    loadingUpdateAppointment,
  } = useSelector((state) => state.examination);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppointment(id));
  }, [dispatch, id]);

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  // const [province, setProvince] = useState('');
  const [district, setDistrict] = useState('');
  const [ward, setWard] = useState('');
  useEffect(() => {
    if (appointmentData?.room.name) {
      const [nameValue, addressValue, wardValue, districtValue, provinceValue] = appointmentData?.room.name.split(':');
      setName(nameValue);
      setAddress(addressValue);
      // setProvince(locations.find((p) => p.value === provinceValue).label);
      setDistrict(locations.find((p) => p.value === provinceValue).districts.find((d) => d.value === districtValue).label);
      setWard(locations.find((p) => p.value === provinceValue).districts.find((d) => d.value === districtValue).wards.find((w) => w.value === wardValue).label);
    }
  }, [appointmentData]);

  // const [loadingGetCustomerInfo, setLoadingGetCustomerInfo] = useState(false);

  // useEffect(() => {
  //   if (appointmentData?.Customer?.Id) {
  //     setLoadingGetCustomerInfo(true);
  //     httpClient.callApi({
  //       method: 'GET',
  //       url: apiLinks.getBkCustomer + appointmentData?.Customer?.Id,
  //     }).then((response) => {
  //       setCustomerInfo(response);
  //       setLoadingGetCustomerInfo(false);
  //     });
  //   }
  // }, [appointmentData]);

  const { addToast } = useToasts();

  const appointmentInfo = useMemo(() => (
    <>
      <Row xs="1" md="2" xl="3" className="justify-content-center">
        <StyledCardHeader className="text-center">
          <h4 className="font-weight-normal text-warning mb-0">
            Thông tin phiếu hẹn
          </h4>
        </StyledCardHeader>
      </Row>
      <Row xs="1" md="2" xl="3" className="justify-content-center">
        <StyledCard inverse>

          <CardTitle className="mb-0 text-left">
            <h4>{appointmentData?.unit.name}</h4>
            <p className="mb-2">{`Địa chỉ: ${appointmentData?.unit?.address}`}</p>
          </CardTitle>

          <Row>
            <BorderedCol xs={12}>
              <h5 className="my-2">Thông tin người xét nghiệm</h5>
            </BorderedCol>
            <Col xs={4}>
              <CardText>Họ và tên</CardText>
            </Col>
            <Col xs={8}>
              <CardText>{appointmentData?.customer?.fullname}</CardText>
            </Col>
            <Col xs={4}>
              <CardText>Năm sinh</CardText>
            </Col>
            <Col xs={8}>
              <CardText>
                {appointmentData?.customer?.birthDate ? moment(appointmentData?.customer?.birthDate).format('YYYY') : 'Chưa xác định'}
              </CardText>
            </Col>
            <Col xs={4}>
              <CardText>Điện thoại</CardText>
            </Col>
            <Col xs={8}>
              <CardText>{appointmentData?.customer?.phone}</CardText>
            </Col>
            <BorderedCol xs={12}>
              <h5 className="my-2">Thông tin lịch hẹn</h5>
            </BorderedCol>
            <Col xs={4}>
              <CardText>Địa chỉ xét nghiệm</CardText>
            </Col>
            <Col xs={8}>
              <CardText>
                {`${name}, ${address}, ${ward}, ${district}`}
              </CardText>
            </Col>
            <Col xs={4}>
              <CardText>Loại bệnh</CardText>
            </Col>
            <Col xs={8}>
              <CardText>{appointmentData?.service?.name}</CardText>
            </Col>
            {/* <Col xs={4}>
              <CardText>Bác sĩ xét nghiệm</CardText>
            </Col>
            <Col xs={8}>
              <CardText>{appointmentData?.doctor.fullname}</CardText>
            </Col> */}
            <Col xs={4}>
              <CardText>Ngày xét nghiệm</CardText>
            </Col>
            <Col xs={8}>
              <StyledCardText>{moment(appointmentData?.instance?.dateTime).format('DD-MM-YYYY')}</StyledCardText>
            </Col>
            <Col xs={4}>
              <CardText>Giờ xét nghiệm</CardText>
            </Col>
            <Col xs={8}>
              <StyledCardText>
                {moment(appointmentData?.instance?.dateTime).format('HH:mm')}
              </StyledCardText>
            </Col>
          </Row>
        </StyledCard>
      </Row>
      <Row xs="1" md="2" xl="3" className="justify-content-center">
        <StyledCardFooter className="text-center">
          <h5 className="text-warning">
            Vui lòng chụp lại thông tin phiếu hẹn hoặc ghi nhớ mã phiếu hẹn!
          </h5>
        </StyledCardFooter>
      </Row>
      <Row className="d-xs-block d-lg-none">
        <Col xs="6" className="text-right">
          <Button
            outline
            color="primary"
            className="mt-3"
            onClick={() => {
              dispatch(resetExamination());
              onRefresh();
            }}
          >
            Hoàn tất
          </Button>
        </Col>
        <Col xs="6" className="text-left">
          <Button
            outline
            color="danger"
            className="mt-3"
            onClick={() => dispatch(showConfirmModal('Xác nhận hủy phiếu hẹn này?', () => {
              dispatch(updateExaminationAppointment(appointmentData.Id, 5)).then(() => {
                addToast('Hủy phiếu hẹn thành công', { appearance: 'success' });
                dispatch(resetExamination());
                onRefresh();
              });
            }))}
          >
            Hủy phiếu hẹn
          </Button>
        </Col>
      </Row>
      <Row xs="1" className="d-none d-lg-block justify-content-center pt-3">
        <Col className="text-center mx-auto">
          <Button
            className="mr-1"
            outline
            color="primary"
            onClick={() => {
              dispatch(resetExamination());
              onRefresh();
            }}
          >
            Hoàn tất
          </Button>
          <Button
            className="ml-1"
            outline
            color="danger"
            onClick={() => dispatch(showConfirmModal('Xác nhận hủy phiếu hẹn này?', () => {
              dispatch(updateExaminationAppointment(appointmentData.Id, 5)).then(() => {
                addToast('Hủy phiếu hẹn thành công', { appearance: 'success' });
                dispatch(resetExamination());
                onRefresh();
              });
            }))}
          >
            Hủy phiếu hẹn
          </Button>
        </Col>
      </Row>
    </>
  ), [
    dispatch,
    addToast,
    onRefresh,
    appointmentData,
    name,
    address,
    ward,
    district,
  ]);

  return (
    <>
      {loadingUpdateAppointment && (
        <StyledDiv>
          <Spinner color="info" />
        </StyledDiv>
      )}
      {loadingGetAppointment
      // || loadingGetCustomerInfo
        ? (
          <StyledDiv>
            <h5 className="text-secondary font-weight-normal">
              Đang tiến hành tạo phiếu hẹn
            </h5>
            <Spinner color="info" />
          </StyledDiv>
        ) : appointmentInfo }
    </>
  );
};

Appointment.propTypes = {
  /** react-router-dom history object */
  onRefresh: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(Appointment);
