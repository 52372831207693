import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Row,
  Col,
  Spinner,
  Alert,
  Label,
} from 'reactstrap';
import {
  FaUserAlt,
  FaIdCard,
  FaCalendarAlt,
  FaTransgender,
  FaAddressBook,
  FaCity,
  FaMap,
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaSyringe,
} from 'react-icons/fa';
import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';

import locations from 'app/mock/locationsV2.json';

import moment from 'moment';
import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import { useSelector, useDispatch } from 'react-redux';
import { getProfiles } from 'app/actions/auth';
import {
  toggleAddProfileModal,
  createProfileV2,
  // addRelative,
} from '../actions/global';

const DateWrapper = styled.div`
  width: 100%;
  position: relative;
  flex: 1 1;
  min-width: 0;
  margin-bottom: 0;
`;

const genderOptions = [
  { key: 1, value: 1, label: 'Nam' },
  { key: 0, value: 0, label: 'Nữ' },
];

const StyledLabel = styled(Label)`
  position: absolute;
  left: 13px;
  top: 7px;
  color: #999;
  display: ${(props) => (props.dob ? 'none' : 'inline-block')};
  pointer-events: none;
  z-index: 0;
`;

const AddPatientModalV2 = (props) => {
  const { isRelative, onCreate } = props;
  const {
    openAddProfileModal,
    loadingCreateCustomer,
    loadingAddRelative,
    createErrorMessage,
  } = useSelector((state) => state.global);
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [fullname, setFullname] = useState('');
  const [gender, setGender] = useState(0);
  const [identityCard, setIdentityCard] = useState('');
  const [dob, setDob] = useState(null);
  const [address, setAddress] = useState('');
  const [vaccinationCode, setVaccinationCode] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({
    province: null,
    district: null,
    ward: null,
  });
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const styles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const [registerFailed, setRegisterFailed] = useState(false);

  useEffect(() => {
    if (userInfo) {
      const provinceValue = userInfo.province;
      const districtValue = userInfo.district;
      const wardValue = userInfo.ward;
      const provinceObject = provinceValue && locations.find((p) => p.value === provinceValue);
      const districtObject = districtValue && provinceObject?.districts.find((d) => d.value === districtValue);
      const wardObject = wardValue && districtObject?.wards.find((w) => w.value === wardValue);
      setAddress(userInfo?.Address ?? '');
      setSelectedLocation({
        province: provinceObject,
        district: districtObject,
        ward: wardObject,
      });
    }
  }, [userInfo]);

  const handleRegister = (e) => {
    e.preventDefault();
    const data = {
      fullname,
      gender: gender === 0,
      dateOfBirth: moment(dob, 'DD-MM-YYYY').isValid() ? moment(dob, 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
      phoneNumber: phone,
      email,
      vaccinationCode,
      address,
      province: selectedLocation.province.value,
      district: selectedLocation.district.value,
      ward: selectedLocation.ward.value,
      identityCard,
    };
    dispatch(createProfileV2(data))
      .then(() => {
        dispatch(getProfiles()).then(() => {
          dispatch(toggleAddProfileModal());
          onCreate(data);
        });
      })
      .catch(() => {
        setRegisterFailed(true);
      });
  };

  const disabledCreate = registerFailed || !(selectedLocation.province && selectedLocation.district && selectedLocation.ward);

  const handleKeyUp = (e, setDate) => {
    const inputValue = e.target.value;
    if (inputValue) {
      setDate(null);
    }
    const d = inputValue.replace(/\D/g, '').slice(0, 10);
    if (d.length >= 5) {
      const stringResult = `${d.slice(0, 2)}-${d.slice(2, 4)}-${d.slice(4)}`;
      setDate(stringResult);
    } else if (d.length >= 3) {
      setDate(`${d.slice(0, 2)}-${d.slice(2)}`);
    } else {
      setDate(d);
    }
  };

  return (
    <Modal
      className="required-form"
      size="xl"
      isOpen={openAddProfileModal}
      toggle={() => dispatch(toggleAddProfileModal())}
    >
      <ModalHeader toggle={() => dispatch(toggleAddProfileModal())}>
        Tạo mới thông tin
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleRegister}>
          <Row>
            <Col sm="12" xl="6" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaUserAlt />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  required
                  invalid={registerFailed && !fullname}
                  onChange={(e) => {
                    setFullname(e.target.value);
                    setRegisterFailed(false);
                  }}
                />
                <Label>Họ và tên</Label>
              </InputGroup>
            </Col>
            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaTransgender />
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                  required
                  styles={styles}
                  placeholder="Giới tính"
                  options={genderOptions}
                  onChange={(e) => {
                    setGender(e.value);
                    setRegisterFailed(false);
                  }}
                />
              </InputGroup>
            </Col>

            {isRelative && (
              <Col sm="6" xl="3" xs="12" className="mt-3">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaPhone />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    onChange={(e) => {
                      setPhone(e.target.value);
                      setRegisterFailed(false);
                    }}
                  />
                  <Label>Số điện thoại</Label>
                </InputGroup>
              </Col>
            )}

            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaCalendarAlt />
                  </InputGroupText>
                </InputGroupAddon>
                <DateWrapper>
                  <DayPickerInput
                    required={!isRelative}
                    style={{ width: '100%' }}
                    selected={dob}
                    value={dob}
                    placeholder="Ngày sinh"
                    format="DD-MM-YYYY"
                    parseDate={parseDate}
                    formatDate={formatDate}
                    dayPickerProps={{
                      modifiers: {
                        disabled: [
                          {
                            after: new Date(),
                          },
                        ],
                      },
                    }}
                    inputProps={{
                      className: 'form-control',
                      maxLength: 10,
                      onKeyUp: (e) => handleKeyUp(e, setDob),
                    }}
                    onDayChange={(date) => {
                      if (date) {
                        setDob(date);
                        setRegisterFailed(false);
                      }
                    }}
                  />
                  {!isRelative && (
                    <StyledLabel dob={dob}>
                      Ngày sinh
                      <span className="text-danger pl-1">*</span>
                    </StyledLabel>
                  )}
                </DateWrapper>
              </InputGroup>
            </Col>

            {!isRelative && (
              <Col sm="6" xl="3" xs="12" className="mt-3">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FaPhone />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Số điện thoại"
                    onChange={(e) => {
                      setPhone(e.target.value);
                      setRegisterFailed(false);
                    }}
                  />
                </InputGroup>
              </Col>
            )}

            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaEnvelope />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email"
                  onChange={(e) => {
                    setRegisterFailed(false);
                    setEmail(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>

            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaIdCard />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Mã định danh"
                  onChange={(e) => {
                    setIdentityCard(e.target.value);
                    setRegisterFailed(false);
                  }}
                />
              </InputGroup>
            </Col>

            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaSyringe />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Mã tiêm chủng"
                  onChange={(e) => {
                    setRegisterFailed(false);
                    setVaccinationCode(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaAddressBook />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  required={!isRelative}
                  value={address}
                  placeholder={isRelative ? 'Số nhà, đường' : ''}
                  onChange={(e) => {
                    setRegisterFailed(false);
                    setAddress(e.target.value);
                  }}
                />
                {!isRelative && <Label>Số nhà, đường</Label>}
              </InputGroup>
            </Col>
            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaCity />
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                  required={!isRelative}
                  isSearchable
                  styles={styles}
                  placeholder="Tỉnh/Thành phố"
                  noOptionsMessage={() => <span>Không tìm thấy</span>}
                  value={selectedLocation.province?.value ? {
                    value: selectedLocation.province?.value ?? '',
                    label: selectedLocation.province?.label ?? '',
                  } : null}
                  options={locations.map((province) => ({
                    value: province.value,
                    label: province.label,
                  }))}
                  onChange={(el) => setSelectedLocation({
                    province: locations.find(
                      (province) => province.value === el.value,
                    ),
                    district: null,
                    ward: null,
                  })}
                />
              </InputGroup>
            </Col>
            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaMap />
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                  isSearchable
                  required={!isRelative}
                  placeholder="Quận/Huyện"
                  styles={styles}
                  value={selectedLocation.district?.value ? {
                    value: selectedLocation.district?.value ?? '',
                    label: selectedLocation.district?.label ?? '',
                  } : null}
                  isDisabled={!selectedLocation.province}
                  noOptionsMessage={() => <span>Không tìm thấy</span>}
                  onChange={(el) => setSelectedLocation({
                    ...selectedLocation,
                    district: selectedLocation.province.districts.find(
                      (district) => district.value === el.value,
                    ),
                    ward: null,
                  })}
                  options={
                    selectedLocation.province
                    && selectedLocation.province.districts.map((district) => ({
                      value: district.value,
                      label: district.label,
                    }))
                  }
                />
              </InputGroup>
            </Col>
            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaMapMarkerAlt />
                  </InputGroupText>
                </InputGroupAddon>
                <Select
                  required={!isRelative}
                  isSearchable
                  placeholder="Phường/Xã"
                  styles={styles}
                  value={selectedLocation.ward?.value ? {
                    value: selectedLocation.ward?.value ?? '',
                    label: selectedLocation.ward?.label ?? '',
                  } : null}
                  noOptionsMessage={() => <span>Không tìm thấy</span>}
                  isDisabled={!selectedLocation.district}
                  onChange={(el) => setSelectedLocation({
                    ...selectedLocation,
                    ward: selectedLocation.district.wards.find(
                      (ward) => ward.value === el.value,
                    ),
                  })}
                  options={
                    selectedLocation.district
                    && selectedLocation.district.wards.map((ward) => ({
                      value: ward.value,
                      label: ward.label,
                    }))
                  }
                />
              </InputGroup>
            </Col>
            <Col xs="12" className="mt-3">
              <span className="text-danger">(*): Thông tin bắt buộc</span>
            </Col>

            {/* <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaBookOpen />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Số bảo hiểm y tế"
                  onChange={(e) => {
                    setRegisterFailed(false);
                    setHealthInsuranceId(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaAddressCard />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Nơi Đăng Ký"
                  onChange={(e) => {
                    setRegisterFailed(false);
                    setRegisterAddress(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaClock />
                  </InputGroupText>
                </InputGroupAddon>
                <DateWrapper>
                  <DayPickerInput
                    style={{ width: '100%' }}
                    selected={registerDate}
                    value={registerDate}
                    format="DD-MM-YYYY"
                    parseDate={parseDate}
                    formatDate={formatDate}
                    placeholder="Ngày bắt đầu BHYT"
                    inputProps={{
                      className: 'form-control',
                      maxLength: 10,
                      onKeyUp: (e) => handleKeyUp(e, setRegisterDate),
                    }}
                    onDayChange={(date) => {
                      setRegisterFailed(false);
                      setRegisterDate(date);
                    }}
                  />
                </DateWrapper>
              </InputGroup>
            </Col>
            <Col sm="6" xl="3" xs="12" className="mt-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FaTimes />
                  </InputGroupText>
                </InputGroupAddon>
                <DateWrapper>
                  <DayPickerInput
                    style={{ width: '100%' }}
                    selected={expiredDate}
                    value={expiredDate}
                    format="DD-MM-YYYY"
                    parseDate={parseDate}
                    formatDate={formatDate}
                    placeholder="Ngày hết hạn BHYT"
                    inputProps={{
                      className: 'form-control',
                      maxLength: 10,
                      onKeyUp: (e) => handleKeyUp(e, setExpiredDate),
                    }}
                    onDayChange={(date) => {
                      setRegisterFailed(false);
                      setExpiredDate(date);
                    }}
                  />
                </DateWrapper>
              </InputGroup>
            </Col> */}
            <Col xs="12" className="d-flex justify-content-center mt-3">
              {(loadingAddRelative || loadingCreateCustomer) ? (
                <Spinner color="info" />
              ) : (
                <Button color="success" disabled={disabledCreate}>
                  Tạo mới
                </Button>
              )}
            </Col>
            {registerFailed && (
              <Col xs="12" className="d-flex justify-content-center mt-3">
                <Alert color="danger">
                  { createErrorMessage }
                </Alert>
              </Col>
            )}
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

AddPatientModalV2.propTypes = {
  isRelative: PropTypes.bool,
  onCreate: PropTypes.func,
};

AddPatientModalV2.defaultProps = {
  isRelative: false,
  onCreate: () => {},
};

export default AddPatientModalV2;
