import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Select from 'react-select';

import { useSelector, useDispatch } from 'react-redux';

import { getByHealthcare } from 'covid-examination/actions/examination';
import ButtonFragment from './ButtonFragment';
import ClinicModal from './ClinicModal';

const StyledSelect = styled(Select)`
  max-width: 350px;
  width: 100%;
  background-color: transparent;
`;

const ClinicSelect = (props) => {
  const {
    patientId,
    onChange,
    onBack,
  } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    if (patientId) {
      dispatch(getByHealthcare(patientId));
    }
  }, [
    patientId,
    dispatch,
  ]);


  const { loadingGetByHealthcare } = useSelector((state) => state.examination);

  const [selectingClinic, setSelectingClinic] = useState(null);
  const [modal, setModal] = useState(false);

  const clinicSelect = useMemo(() => (
    <StyledSelect
      isDisabled={loadingGetByHealthcare}
      isLoading={loadingGetByHealthcare}
      value={selectingClinic?.Id}
      className="basic-single mb-2"
      classNamePrefix="select"
      name="color"
      placeholder={selectingClinic?.Name ?? 'Cơ sở xét nghiệm'}
      onMenuOpen={() => setModal(true)}
    />
  ), [
    loadingGetByHealthcare,
    selectingClinic,
  ]);

  return (
    <div>
      {clinicSelect}
      <ButtonFragment
        disabled={!selectingClinic}
        onGoNext={() => onChange(selectingClinic)}
        onGoBack={onBack}
      />

      <ClinicModal
        open={modal}
        onClose={() => setModal(false)}
        onSubmit={(clinic, room) => {
          setModal(false);
          setSelectingClinic(selectingClinic);
          onChange(clinic, room);
        }}
      />
    </div>
  );
};

ClinicSelect.propTypes = {
  patientId: PropTypes.number,
  onChange: PropTypes.func,
  onBack: PropTypes.func,
};

ClinicSelect.defaultProps = {
  patientId: 0,
  onChange: () => {},
  onBack: () => {},
};


export default ClinicSelect;
