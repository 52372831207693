import React, { useState } from 'react';
import {
  Button,
  Form,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Row,
  Col,
  Spinner,
  Alert,
  Container,
  Label,
} from 'reactstrap';
import {
  FaUserAlt,
  FaUser,
  FaLock,
  FaPhone,
} from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';
import 'react-day-picker/lib/style.css';


import { useDispatch, useSelector } from 'react-redux';
import { login } from 'app/actions/auth';
import { createAccount } from '../actions/global';

const RegisterPageV2 = () => {
  const [registerFailed, setRegisterFailed] = useState(false);
  const { loadingCreateAccount, createErrorMessage } = useSelector((state) => state.global);

  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const [fullname, setFullname] = useState('');
  const [phone, setPhone] = useState('');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');

  const handleRegister = (e) => {
    e.preventDefault();
    const data = {
      Fullname: fullname,
      Phone: phone,
      Username: username,
      Password: password,
    };
    dispatch(createAccount(data))
      .then(() => {
        setRegisterFailed(false);
        addToast('Tạo tài khoản thành công', { appearance: 'success' });
        dispatch(login(username, password, false)).then((res) => {
          window.parent.location = `https://hcdc.gov.vn/login/token/${res.access_token}`;
        });
      })
      .catch(() => {
        setRegisterFailed(true);
      });
  };
  return (
    <Container className="required-form">
      <Form onSubmit={handleRegister}>
        <Row className="my-3">
          <Col sm="12" className="text-center">
            <h4>Đăng ký tài khoản</h4>
          </Col>
          <Col sm="12">
            <h5>Thông tin chung</h5>
          </Col>
          <Col sm="6" xl="6" xs="12" className="mt-3">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FaUserAlt />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                required
                invalid={registerFailed && !fullname}
                onChange={(e) => {
                  setFullname(e.target.value);
                  setRegisterFailed(false);
                }}
              />
              <Label>Họ và tên</Label>
            </InputGroup>
          </Col>
          <Col sm="6" xl="6" xs="12" className="mt-3">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FaPhone />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                required
                onChange={(e) => {
                  setPhone(e.target.value);
                  setRegisterFailed(false);
                }}
              />
              <Label>Số điện thoại</Label>
            </InputGroup>
          </Col>
          <Col sm="12" className="mt-3">
            <h5>Thông tin tài khoản</h5>
          </Col>
          <Col sm="4" xs="12" className="mt-3">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FaUser />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                required
                onChange={(e) => {
                  setRegisterFailed(false);
                  setUsername(e.target.value);
                }}
              />
              <Label>Tên tài khoản</Label>
            </InputGroup>
          </Col>
          <Col sm="4" xs="12" className="mt-3">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FaLock />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                required
                type="password"
                onChange={(e) => {
                  setRegisterFailed(false);
                  setPassword(e.target.value);
                }}
              />
              <Label>Mật khẩu</Label>
            </InputGroup>
          </Col>
          <Col sm="4" xs="12" className="mt-3">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FaLock />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                required
                type="password"
                onChange={(e) => {
                  setRegisterFailed(false);
                  setConfirm(e.target.value);
                }}
              />
              <Label>Nhập lại mật khẩu</Label>
            </InputGroup>
          </Col>
          <Col sm="12" className="mt-3 text-muted text-right">
            <span>* Mật khẩu nên sử dụng 8 ký tự trở lên, nên kết hợp chữ cái, chữ số và biểu tượng. Ví dụ: NguyenvanA1983@</span>
          </Col>
          <Col sm="12" className="mt-3 text-danger">
            <span>Ghi Chú:</span>
            <br />
            <span>- Nhập thông tin đầy đủ và chính xác.</span>
            <br />
            <span>- Nhập email để nhận thông tin khi sử dụng dịch vụ và khôi phuc tài khoản khi có sự cố xảy ra.</span>
          </Col>
          <Col xs="12" className="d-flex justify-content-center mt-3">
            {loadingCreateAccount ? (
              <Spinner color="info" />
            ) : (
              <Button color="success" disabled={registerFailed || password !== confirm}>
                Đăng ký
              </Button>
            )}
          </Col>
          {registerFailed && (
            <Col xs="12" className="d-flex justify-content-center mt-3">
              <Alert color="danger">
                {createErrorMessage}
              </Alert>
            </Col>
          )}
          {(password && confirm && password !== confirm) && (
            <Col xs="12" className="d-flex justify-content-center mt-3">
              <Alert color="danger">
                Nhập lại mật khẩu chưa khớp
              </Alert>
            </Col>
          )}
        </Row>
      </Form>
    </Container>
  );
};

export default RegisterPageV2;
