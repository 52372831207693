import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Spinner,
  Row,
  Col,
  Card,
  CardBody,
  // Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from 'reactstrap';

import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';
import DatePickerVietnam from 'app/components/DatePickerVietnam';
import {
  getSchedules,
  getIntervals,
} from 'covid-examination-booking/actions/examination-booking';

import ButtonFragment from './ButtonFragment';

const StyledDropdownToggle = styled(DropdownToggle)`
  margin: 5px !important;
  border-radius: 16px !important;
  width: 80px !important;
`;

const ButtonWrapper = styled.div`
  margin-top: 15px;
  text-align: left;
`;

const DateSelect = (props) => {
  const {
    hospitalId,
    serviceId,
    onBack,
    onIntervalChange,
    onDoctorRoomChange,
    onFinish,
  } = props;

  const dispatch = useDispatch();
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTime, setSelectedTime] = useState(0);
  const [month, setMonth] = useState(new Date());

  const {
    scheduleList,
    getScheduleLoading,
    intervalList,
    getIntervalsLoading,
  } = useSelector((state) => state.examinationBooking);

  useEffect(() => {
    if (hospitalId && serviceId) {
      dispatch(getSchedules(hospitalId, serviceId));
    }
  }, [dispatch, hospitalId, serviceId]);

  const selectDate = (d, { selected }, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    const doctorRoom = scheduleList.find((s) => moment(s.date).isSame(moment(d), 'date'));
    if (doctorRoom) {
      onDoctorRoomChange(doctorRoom);
    }
    setSelectedDay(selected ? undefined : d);
  };

  useEffect(() => {
    const selectedDayId = scheduleList.find((s) => moment(s.date).isSame(moment(selectedDay), 'date'))?.id;
    if (selectedDayId) {
      dispatch(getIntervals(selectedDayId));
    }
  }, [dispatch, scheduleList, selectedDay, hospitalId, serviceId]);

  const [isOpenDropdownButton, setIsOpenDropdownButton] = useState('');

  return (
    <div>
      {!getScheduleLoading ? (
        <>
          <Row>
            <Col xs="12" lg="5" className="text-left">
              <h5>Chọn ngày xét nghiệm</h5>
              <Card>
                <CardBody>
                  <DatePickerVietnam
                    month={month}
                    selectedDays={selectedDay}
                    disabledDays={(d) => !(scheduleList.length === 0 ? [] : scheduleList)
                      .map((s) => moment(s.date).format('YYYY-MM-DD'))
                      .includes(moment(d).format('YYYY-MM-DD'))}
                    onDayClick={selectDate}
                    onMonthChange={setMonth}
                  />
                </CardBody>
              </Card>
              <ButtonWrapper className="d-none d-lg-block">
                <ButtonFragment
                  onGoBack={onBack}
                  onFinish={onFinish}
                  disabled={selectedTime === 0}
                />
              </ButtonWrapper>
            </Col>
            {getIntervalsLoading ? (
              <Spinner color="info" />
            ) : (
              <>
                {selectedDay && (
                  <>
                    <Col xs="12" lg="7">
                      <h5>Chọn giờ xét nghiệm</h5>
                      {intervalList.length !== 0 && (
                        <Card>
                          <CardBody className="text-center">
                            {intervalList
                              .filter(
                                (e) => e.intervals.filter((i) => i.isAvailable)
                                  .length > 0,
                              )
                              .map((e) => {
                                const key = `${e.from}-${e.to}-${e.intervals.length}`;
                                return (
                                  <ButtonDropdown
                                    key={key}
                                    isOpen={isOpenDropdownButton === key}
                                    toggle={() => setIsOpenDropdownButton(
                                      isOpenDropdownButton === '' ? key : '',
                                    )}
                                  >
                                    <StyledDropdownToggle
                                      color="info"
                                      outline
                                      active={isOpenDropdownButton === key}
                                      onClick={() => {
                                        setSelectedTime(key);
                                        onIntervalChange(
                                          e.intervals.find(
                                            (itv) => itv.isAvailable,
                                          ),
                                        );
                                      }}
                                    >
                                      {e.from}
                                    </StyledDropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem onClick={onFinish}>
                                        Đặt lịch ngay
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </ButtonDropdown>
                                );
                              })}
                          </CardBody>
                        </Card>
                      )}
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>
          <ButtonWrapper className="d-xs-block d-lg-none">
            <ButtonFragment onGoBack={onBack} disabled={!selectedTime} />
          </ButtonWrapper>
        </>
      ) : (
        <Spinner color="info" />
      )}
    </div>
  );
};

DateSelect.propTypes = {
  hospitalId: PropTypes.string,
  serviceId: PropTypes.string,
  onBack: PropTypes.func,
  onIntervalChange: PropTypes.func,
  onDoctorRoomChange: PropTypes.func,
  onFinish: PropTypes.func,
};

DateSelect.defaultProps = {
  hospitalId: '',
  serviceId: 'f2490f62-1d28-4edd-362a-08d8a7232229',
  onBack: () => {},
  onIntervalChange: () => {},
  onDoctorRoomChange: () => {},
  onFinish: () => {},
};

export default DateSelect;
