import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useWindowSize } from 'app/hooks';
import Sidebar from './Sidebar';
import Navbar from './StyleNavbar';

const handleWidth = (props) => {
  if (props.sidebarcollapsed && props.size > 515) {
    return 'calc(100% - 80px)';
  }
  if (!props.sidebarcollapsed && props.size > 515) {
    return 'calc(100% - 300px)';
  }
  return '100%';
};

const handlePadding = (props) => {
  if (props.sidebarcollapsed && props.size > 515) {
    return '80px';
  }
  if (!props.sidebarcollapsed && props.size > 515) {
    return '300px';
  }
  return '0';
};

const Children = styled.div`
  position: absolute;
  min-height: calc(100% - 62px);
  margin: 4px;
  padding: 20px 6px;
  width: ${(props) => handleWidth(props)};
  background: #f5f5f5;
  left: ${(props) => handlePadding(props)};
  transition: all .3s ease-in-out;
`;

const StyledDiv = styled.div`
  position: relative;
  height: 100%;
`;
const MainLayout = (props) => {
  const { children } = props;
  const { sidebarCollapsed } = useSelector((state) => state.global);
  const windowSize = useWindowSize();

  return (
    <StyledDiv>
      <Navbar />
      <Sidebar />
      <Children
        sidebarcollapsed={sidebarCollapsed ? 1 : 0}
        size={windowSize.width.toString()}
      >
        {children}
      </Children>
    </StyledDiv>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
