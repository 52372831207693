import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Spinner } from 'reactstrap';

import { toggleSidebar, toggleAddPatientModal } from 'app/actions/global';
import AddPatientModal from 'app/components/AddPatientModal';
import ButtonFragment from './ButtonFragment';
import { selectPatient } from '../actions/homecare';

const StyledSelect = styled(Select)`
  max-width: 350px;
  width: 100%;
  background-color: transparent;
`;

const PatientSelect = () => {
  const [options, setOptions] = useState([{ value: 0, label: 'Tạo mới' }]);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const { isOpenModal, sidebarCollapsed } = useSelector(
    (state) => state.global,
  );
  const [selectingPatient, setSelectingPatient] = useState(null);

  useEffect(() => {
    if (userInfo && options.filter((e) => e.value === userInfo.Id).length === 0) {
      setOptions([
        ...options,
        { value: userInfo.Id, label: userInfo.FullName },
      ]);
    }
    // eslint-disable-next-line
  }, [userInfo]);

  const dispatch = useDispatch();

  return (
    <div className="w-100 position-relative">
      <StyledSelect
        isDisabled={userInfo === null}
        options={options}
        className="basic-single mb-2"
        classNamePrefix="select"
        name="color"
        value={options.value}
        placeholder="Chọn người khám bệnh"
        onChange={(selected) => {
          if (selected.value === 0) {
            dispatch(toggleAddPatientModal(!isOpenModal));
            if (!sidebarCollapsed) {
              dispatch(toggleSidebar(!sidebarCollapsed));
            }
          } else {
            setSelectingPatient(selected);
          }
        }}
      />

      {userInfo === null ? (
        <Spinner color="info" />
      ) : (
        <ButtonFragment
          loading={userInfo === null}
          data={selectingPatient}
          onGoNext={() => dispatch(selectPatient(selectingPatient))}
        />
      )}

      <AddPatientModal />
    </div>
  );
};

export default PatientSelect;
