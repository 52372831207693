import {
  TELE_SET_STEP,
  TELE_SELECT_PATIENT,
  TELE_SELECT_CLINIC,
  TELE_SELECT_DATE,
  TELE_SELECT_EXPERT,
  TELE_SELECT_SERVICE_TYPE,
  TELE_SELECT_SERVICE,
  TELE_GET_SERVICE_TYPE_REQUEST,
  TELE_GET_SERVICE_TYPE_SUCCESS,
  TELE_GET_SERVICE_TYPE_FAILURE,
  TELE_GET_SERIVCE_BY_DOCTOR_REQUEST,
  TELE_GET_SERIVCE_BY_DOCTOR_SUCCESS,
  TELE_GET_SERIVCE_BY_DOCTOR_FAILURE,
  TELE_GET_DOCTOR_REQUEST,
  TELE_GET_DOCTOR_SUCCESS,
  TELE_GET_DOCTOR_FAILURE,
  TELE_GET_HEALTHCARE_BY_HOSPITAL_REQUEST,
  TELE_GET_HEALTHCARE_BY_HOSPITAL_SUCCESS,
  TELE_GET_HEALTHCARE_BY_HOSPITAL_FAILURE,
  TELE_GET_HEALTHCARE_SCHEDULE_REQUEST,
  TELE_GET_HEALTHCARE_SCHEDULE_SUCCESS,
  TELE_GET_HEALTHCARE_SCHEDULE_FAILURE,
  TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_REQUEST,
  TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_SUCCESS,
  TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_FAILURE,
  TELE_CREATE_EXAMINATION_REQUEST,
  TELE_CREATE_EXAMINATION_SUCCESS,
  TELE_CREATE_EXAMINATION_FAILURE,
  TELE_DELETE_EXAMINATION_REQUEST,
  TELE_DELETE_EXAMINATION_SUCCESS,
  TELE_DELETE_EXAMINATION_FAILURE,
  TELE_RESET,
} from '../actions/types';

const initialState = {
  currentStep: 0,
  selectedService: null,
  selectedPatient: null,
  selectedClinic: null,
  selectedServiceType: null,
  selectedDate: null,
  selectedExpert: null,
  listServices: [],
  listServiceTypes: [],
  listSchedules: [],
  listDoctors: [],
  listSchedulesByDoctor: [],
  examinationData: null,
  creatingExamination: false,
  loadingListServices: false,
  deleteExaminationState: null,
  deletingExamination: false,
  loadingSerivceType: false,
  loadingDoctors: false,
  loadingSchedules: false,
  loadingDoctorSchedules: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TELE_SET_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case TELE_SELECT_PATIENT:
      return {
        ...state,
        selectedPatient: action.payload,
      };
    case TELE_SELECT_CLINIC:
      return {
        ...state,
        selectedClinic: action.payload,
      };
    case TELE_SELECT_SERVICE_TYPE:
      return {
        ...state,
        selectedServiceType: action.payload,
      };
    case TELE_SELECT_EXPERT:
      return {
        ...state,
        selectedExpert: action.payload,
      };
    case TELE_SELECT_SERVICE:
      return {
        ...state,
        selectedService: action.payload,
      };
    case TELE_SELECT_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case TELE_GET_SERVICE_TYPE_REQUEST:
      return {
        ...state,
        listServiceTypes: [],
        loadingSerivceType: true,
      };
    case TELE_GET_SERVICE_TYPE_SUCCESS:
      return {
        ...state,
        listServiceTypes: action.payload,
        loadingSerivceType: false,
      };
    case TELE_GET_SERVICE_TYPE_FAILURE:
      return {
        ...state,
        loadingSerivceType: false,
      };
    case TELE_GET_DOCTOR_REQUEST:
      return {
        ...state,
        listDoctors: [],
      };
    case TELE_GET_DOCTOR_SUCCESS:
      return {
        ...state,
        listDoctors: action.payload,
      };
    case TELE_GET_DOCTOR_FAILURE:
      return {
        ...state,
      };
    case TELE_GET_SERIVCE_BY_DOCTOR_REQUEST:
      return {
        ...state,
        loadingDoctors: true,
      };
    case TELE_GET_SERIVCE_BY_DOCTOR_SUCCESS: {
      const { doctor, services } = action.payload;
      return {
        ...state,
        listDoctors: state.listDoctors.map((d) => {
          if (d.Id !== doctor) return d;
          return {
            ...d,
            listServices: services,
          };
        }),
        loadingDoctors: false,
      };
    }
    case TELE_GET_SERIVCE_BY_DOCTOR_FAILURE:
      return {
        ...state,
        loadingDoctors: false,
      };
    case TELE_GET_HEALTHCARE_BY_HOSPITAL_REQUEST:
      return {
        ...state,
        listServices: [],
        loadingListServices: true,
      };
    case TELE_GET_HEALTHCARE_BY_HOSPITAL_SUCCESS:
      return {
        ...state,
        listServices: action.payload,
        loadingListServices: false,
      };
    case TELE_GET_HEALTHCARE_BY_HOSPITAL_FAILURE:
      return {
        ...state,
        loadingListServices: false,
      };
    case TELE_GET_HEALTHCARE_SCHEDULE_REQUEST:
      return {
        ...state,
        listSchedules: [],
        loadingSchedules: true,
      };
    case TELE_GET_HEALTHCARE_SCHEDULE_SUCCESS:
      return {
        ...state,
        listSchedules: action.payload,
        loadingSchedules: false,
      };
    case TELE_GET_HEALTHCARE_SCHEDULE_FAILURE:
      return {
        ...state,
        loadingSchedules: false,
      };
    case TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_REQUEST:
      return {
        ...state,
        loadingDoctorSchedules: true,
        listSchedulesByDoctor: [],
      };
    case TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_SUCCESS:
      return {
        ...state,
        loadingDoctorSchedules: false,
        listSchedulesByDoctor: action.payload,
      };
    case TELE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_FAILURE:
      return {
        ...state,
        loadingDoctorSchedules: false,
      };
    case TELE_CREATE_EXAMINATION_REQUEST:
      return {
        ...state,
        creatingExamination: true,
      };
    case TELE_CREATE_EXAMINATION_SUCCESS:
      return {
        ...state,
        examinationData: action.payload,
        creatingExamination: false,
      };
    case TELE_CREATE_EXAMINATION_FAILURE:
      return {
        ...state,
        creatingExamination: false,
      };
    case TELE_DELETE_EXAMINATION_REQUEST:
      return {
        ...state,
        deletingExamination: true,
      };
    case TELE_DELETE_EXAMINATION_SUCCESS:
      return {
        ...state,
        deleteExaminationState: action.payload,
        deletingExamination: false,
      };
    case TELE_DELETE_EXAMINATION_FAILURE:
      return {
        ...state,
        deletingExamination: false,
      };
    case TELE_RESET:
      return initialState;
    default:
      return state;
  }
}
