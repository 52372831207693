export default {
  EB_SET_STEP: 'EB_SET_STEP',

  EB_GET_SCHEDULE_REQUEST: 'EB_GET_SCHEDULE_REQUEST',
  EB_GET_SCHEDULE_SUCCESS: 'EB_GET_SCHEDULE_SUCCESS',
  EB_GET_SCHEDULE_FAILURE: 'EB_GET_SCHEDULE_FAILURE',

  EB_GET_TICKETS_REQUEST: 'EB_GET_TICKETS_REQUEST',
  EB_GET_TICKETS_SUCCESS: 'EB_GET_TICKETS_SUCCESS',
  EB_GET_TICKETS_FAILURE: 'EB_GET_TICKETS_FAILURE',

  EB_REGISTER_REQUEST: 'EB_REGISTER_REQUEST',
  EB_REGISTER_SUCCESS: 'EB_REGISTER_SUCCESS',
  EB_REGISTER_FAILURE: 'EB_REGISTER_FAILURE',

  EB_GET_APPOINTMENT_REQUEST: 'EB_GET_APPOINTMENT_REQUEST',
  EB_GET_APPOINTMENT_SUCCESS: 'EB_GET_APPOINTMENT_SUCCESS',
  EB_GET_APPOINTMENT_FAILURE: 'EB_GET_APPOINTMENT_FAILURE',

  EB_GET_HISTORY_REQUEST: 'EB_GET_HISTORY_REQUEST',
  EB_GET_HISTORY_SUCCESS: 'EB_GET_HISTORY_SUCCESS',
  EB_GET_HISTORY_FAILURE: 'EB_GET_HISTORY_FAILURE',

  EB_UPDATE_APPOINTMENT_REQUEST: 'EB_UPDATE_APPOINTMENT_REQUEST',
  EB_UPDATE_APPOINTMENT_SUCCESS: 'EB_UPDATE_APPOINTMENT_SUCCESS',
  EB_UPDATE_APPOINTMENT_FAILURE: 'EB_UPDATE_APPOINTMENT_FAILURE',

  EB_RESET: 'EB_RESET',

  EB_GET_HOSPITAL_REQUEST: 'EB_GET_HOSPITAL_REQUEST',
  EB_GET_HOSPITAL_SUCCESS: 'EB_GET_HOSPITAL_SUCCESS',
  EB_GET_HOSPITAL_FAILURE: 'EB_GET_HOSPITAL_FAILURE',

  EB_GET_UNIT_TYPE_REQUEST: 'EB_GET_UNIT_TYPE_REQUEST',
  EB_GET_UNIT_TYPE_SUCCESS: 'EB_GET_UNIT_TYPE_SUCCESS',
  EB_GET_UNIT_TYPE_FAILURE: 'EB_GET_UNIT_TYPE_FAILURE',

  EB_GET_INTERVALS_REQUEST: 'EB_GET_INTERVALS_REQUEST',
  EB_GET_INTERVALS_SUCCESS: 'EB_GET_INTERVALS_SUCCESS',
  EB_GET_INTERVALS_FAILURE: 'EB_GET_INTERVALS_FAILURE',
};
