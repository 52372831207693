/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Select, { components } from 'react-select';
import {
  Row,
  Button,
  Spinner,
} from 'reactstrap';
import { FaEye } from 'react-icons/fa';

import { useToasts } from 'react-toast-notifications';

import { useDispatch, useSelector } from 'react-redux';
import { toggleAddProfileModal } from 'app/actions/global';
import AddPatientModalV2 from 'app/components/AddPatientModalV2';

import PatientModal from 'immunization/components/PatientModal';
import ButtonFragment from './ButtonFragment';
import HealthDeclarationModal from './HealthDeclarationModal';

const StyledSelect = styled(Select)`
  max-width: 350px;
  width: 100%;
  background-color: transparent;
`;

const StyledFaEye = styled(FaEye)`
  margin-left: 10px;
`;

const StyledButton = styled(Button)`
  padding: 0 !important;
  color: #808080 !important;
`;

const { Option } = components;

const PatientSelect = (props) => {
  const {
    isLoading,
    initialPatient,
    onChange,
  } = props;

  const dispatch = useDispatch();
  const { profileList } = useSelector((state) => state.auth);

  const [options, setOptions] = useState([]);
  const [modal, setModal] = useState({
    isOpen: false,
    data: null,
  });

  const [patientModal, setPatientModal] = useState({
    isOpen: false,
    data: null,
  });

  useEffect(() => {
    setOptions([
      { value: 0, label: 'Tạo mới' },
      ...profileList
        .map((p) => ({
          label: p.fullname,
          value: p.id,
          key: p.id,
          subject: p,
        })),
    ]);
    // eslint-disable-next-line
  }, [
    profileList,
  ]);

  const handleClick = (e, subject) => {
    e.stopPropagation();
    setPatientModal({
      isOpen: true,
      data: subject,
    });
  };

  const IconOption = (iProps) => {
    const { data } = iProps;
    return (
      <Option {...iProps}>
        <Row className="d-flex justify-content-between px-3">
          <span>{data.label}</span>
          {data.subject && (
            <span className="border-left">
              <StyledButton onClick={(e) => handleClick(e, data.subject)} size="sm" color="link">
                <StyledFaEye />
              </StyledButton>
            </span>
          )}
        </Row>
      </Option>
    );
  };

  const { addToast } = useToasts();
  const [selectingPatient, setSelectingPatient] = useState(null);
  const handleSubmit = (data) => {
    const {
      d,
      allowToTakeExam,
      newCustomer,
    } = data;

    if (!allowToTakeExam) {
      addToast('Không đủ điều kiện xét nghiệm', { appearance: 'error' });
      onChange(null);
    } else if (newCustomer) {
      onChange({
        ...newCustomer,
        form: d,
      });
    } else {
      onChange({
        ...selectingPatient,
        form: d,
      });
    }
  };

  const select = useMemo(() => (
    <StyledSelect
      name="color"
      isSearchable
      isClearable
      className="basic-single mb-2"
      classNamePrefix="select"
      placeholder="Người xét nghiệm"
      components={{ Option: IconOption }}
      options={options}
      isDisabled={isLoading}
      controlShouldRenderValue={Boolean(initialPatient?.id)}
      defaultValue={initialPatient?.id ?? 0}
      noOptionsMessage={() => <span>Không tìm thấy</span>}
      onChange={(d) => {
        if (d?.value === 0) {
          dispatch(toggleAddProfileModal());
        } else if (d) {
          // hide covid modal
          // setSelectingPatient(profileList.find((p) => p.id === d.value));
          onChange(profileList.find((p) => p.id === d.value));
        } else {
          onChange(null);
        }
      }}
    />
    // eslint-disable-next-line
  ), [
    initialPatient,
    isLoading,
    onChange,
    options,
    profileList,
  ]);

  return (
    <div className="w-100">
      {select}
      {isLoading ? (
        <Spinner color="info" />
      ) : (
        <ButtonFragment disabled={!initialPatient?.id} />
      )}

      <AddPatientModalV2 />

      <HealthDeclarationModal
        open={modal.isOpen}
        patient={modal.data}
        onClose={() => setModal({ isOpen: false, data: null })}
        onSubmit={(d) => {
          const { newCustomer } = d;
          if (newCustomer) {
            setSelectingPatient(newCustomer);
          }
          setModal({ isOpen: false, data: null });
          handleSubmit(d);
        }}
      />

      <PatientModal
        isCovid
        open={patientModal.isOpen}
        initialData={patientModal.data}
        onClose={(p) => {
          setPatientModal({ isOpen: false, data: null });
          if (p?.id) {
            setModal({
              isOpen: true,
              data: p,
            });
          } else {
            setSelectingPatient(null);
          }
        }}
      />
    </div>
  );
};

PatientSelect.propTypes = {
  initialPatient: PropTypes.shape({
    id: PropTypes.string,
  }),
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

PatientSelect.defaultProps = {
  initialPatient: {},
  onChange: () => {},
  isLoading: false,
};

export default PatientSelect;
