import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Row,
  Col,
  Container,
  Spinner,
  Label,
} from 'reactstrap';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import HistorySelect from 'app/components/HistorySelect';
import { SERVICES } from 'app/utils/constants';

import { getHistory } from '../../actions/examination';
import CovidExaminationAppointment from './CovidExaminationAppointment';
import CovidExaminationHistoryFilter from './CovidExaminationHistoryFilter';

const StyledDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const CovidExaminationHistory = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHistory());
  }, [dispatch]);
  const { historyList, loadingGetHistory } = useSelector((state) => state.examination);

  const [filter, setFilter] = useState(null);
  const [filteredHistory, setFilteredHistory] = useState(historyList);
  useEffect(() => {
    if (filter) {
      const {
        searchValue,
        selectingStatus,
        selectingPatient,
        from,
        to,
      } = filter;
      const result = historyList.filter((h) => (
        searchValue ? h.id.toString().indexOf(searchValue) > -1 : true)
        && (from ? moment(h.instance.dateTime).isAfter(from) : true)
        && (to ? moment(h.instance.dateTime).isBefore(to) : true)
        && (selectingStatus && selectingStatus?.value !== 0 ? h.status === selectingStatus?.value : true)
        && (selectingPatient && selectingPatient?.value !== 0 ? h.customer.id === selectingPatient?.value : true));
      setFilteredHistory(result);
    }
  }, [filter, historyList]);

  return (
    <Container fluid>
      {loadingGetHistory ? (
        <StyledDiv>
          <Spinner color="info" />
        </StyledDiv>
      ) : (
        <Row>
          <Col xs={12} className="mt-3">
            <HistorySelect defaultValue={SERVICES.COVID} />
          </Col>
          <Col xs={12}>
            <CovidExaminationHistoryFilter onChange={setFilter} />
          </Col>
          {filteredHistory
            .sort((a, b) => moment(b.instance.dateTime) - moment(a.instance.dateTime))
            .map((e) => (
              <Col key={e.id} lg={4} sm={6} xs={12}>
                <CovidExaminationAppointment initialData={e} />
              </Col>
            ))}
          <Col xs={12} className="w-100 mt-2">
            {filteredHistory.length === 0 && (
              <Label style={{ color: 'red' }}>Không tìm thấy</Label>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default CovidExaminationHistory;
