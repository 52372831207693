import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';
import { Spinner } from 'reactstrap';

import {
  getServiceType,
  selectServiceType,
  selectClinic,
} from '../actions/homecare';

import ButtonFragment from './ButtonFragment';

const StyledSelect = styled(Select)`
  max-width: 350px;
  width: 100%;
  background-color: transparent;
`;

const ServiceTypeSelect = () => {
  const {
    selectedClinic,
    listServiceTypes,
    loadingSerivceType,
  } = useSelector((state) => state.homecare);
  const [selectedServiceType, setSelectedServiceType] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedClinic) {
      dispatch(getServiceType(selectedClinic));
    }
  }, [dispatch, selectedClinic]);

  const options = listServiceTypes.map((e) => ({
    ...e,
    label: e.Name,
    value: e.Id,
  }));

  return (
    <div className="w-100">
      <StyledSelect
        isDisabled={loadingSerivceType}
        options={options}
        className="basic-single mb-2"
        classNamePrefix="select"
        name="color"
        placeholder="Chọn loại hình"
        isSearchable
        onChange={(selected) => setSelectedServiceType(selected)}
      />
      {loadingSerivceType ? (
        <Spinner color="info" />
      ) : (
        <ButtonFragment
          onGoNext={() => dispatch(selectServiceType(selectedServiceType))}
          onGoBack={() => dispatch(selectClinic(null))}
          data={selectedServiceType}
        />
      )}
    </div>
  );
};

export default ServiceTypeSelect;
