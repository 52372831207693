import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Select from 'react-select';

import ButtonFragment from './ButtonFragment';

const StyledSelect = styled(Select)`
  max-width: 350px;
  width: 100%;
  background-color: transparent;
`;

const options = [
  { value: 71, label: 'Thu phí' },
  { value: 70, label: 'Không thu phí' },
];

const ServiceTypeSelect = (props) => {
  const {
    initialServiceType,
    onChange,
    onBack,
  } = props;

  return (
    <div className="w-100">
      <StyledSelect
        isSearchable
        isClearable
        className="basic-single mb-2"
        classNamePrefix="select"
        name="color"
        placeholder="Loại hình tiêm"
        options={options.map((e) => ({
          key: e.value,
          value: e.value,
          label: e.label,
        }))}
        onChange={onChange}
      />
      <ButtonFragment
        disabled={!initialServiceType}
        onGoNext={() => onChange(initialServiceType)}
        onGoBack={onBack}
      />
    </div>
  );
};

ServiceTypeSelect.propTypes = {
  initialServiceType: PropTypes.shape({}),
  onChange: PropTypes.func,
  onBack: PropTypes.func,
};

ServiceTypeSelect.defaultProps = {
  initialServiceType: {},
  onChange: () => {},
  onBack: () => {},
};


export default ServiceTypeSelect;
