import React from 'react';

import { ToastProvider } from 'react-toast-notifications';
import { Provider } from 'react-redux';
import store from 'app/store';
import AppRouter from 'app/routes/AppRouter';
import ConfirmModal from './ConfirmModal';

const App = () => (
  <ToastProvider
    autoDismiss
    autoDismissTimeout={3000}
    placement="top-center"
  >
    <Provider store={store}>
      <AppRouter />
      <ConfirmModal />
    </Provider>
  </ToastProvider>
);

export default App;
