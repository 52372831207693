import React from 'react';

const HomeCareTab = (props) => {
  return (
    <div>
      HomeCareTab
    </div>
  );
};

export default HomeCareTab;
