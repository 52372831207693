import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';

import Steps, { Step } from 'rc-steps';
import { Container, Spinner } from 'reactstrap';
import { FaCheck } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';

import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';

import { useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { setToken, getUserInfoV2, getProfiles } from 'app/actions/auth';
import { getServiceForm } from 'app/actions/global';
import { SERVICE_FORM } from 'app/utils/constants';
import { register, setStep } from '../actions/examination-booking';

import HospitalSelect from '../components/booking/HospitalSelect';
import DateSelect from '../components/booking/DateSelect';
import PatientSelect from '../components/booking/PatientSelect';
import Appointment from '../components/booking/Appointment';

const StyledDiv = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Wrapper = styled.div`
  /* width: 100vw;
  height: 100vh; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const spinner = (
  <Wrapper>
    <Spinner color="info" />
  </Wrapper>
);

const useQuery = () => new URLSearchParams(useLocation().search);

const CovidExaminationBookingPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery(location);

  const tokenParam = query.get('s');

  useEffect(() => {
    dispatch(setToken(tokenParam));
  }, [dispatch, tokenParam]);

  // component
  const { currentStep, registerLoading, registerData } = useSelector(
    (state) => state.examinationBooking,
  );

  const { getProfileLoading, token, userInfo } = useSelector(
    (state) => state.auth,
  );
  const isLoading = userInfo === null || getProfileLoading;

  useEffect(() => {
    if (token) {
      dispatch(getUserInfoV2());
      dispatch(getProfiles());
      dispatch(getServiceForm(SERVICE_FORM.EXAMINATION));
    }
  }, [dispatch, token]);

  const [patientSubject, setPatientSubject] = useState(null);
  const [hospital, setHospital] = useState(null);
  const [room, setRoom] = useState(null);
  const [interval, setInterval] = useState(null);
  const [date, setDate] = useState(null);
  const [doctor, setDoctor] = useState(null);

  const handleRefresh = () => {
    setPatientSubject(null);
    setHospital(null);
    setRoom(null);
    setInterval(null);
    setDate(null);
  };

  const patientName = patientSubject?.fullname ?? '';
  const hospitalLabel = hospital?.name ?? '';

  const { addToast } = useToasts();
  const submit = () => {
    const data = {
      interval,
      unit: {
        ...hospital,
        information: hospital.introduction,
      },
      doctor: {
        ...doctor,
        fullname: doctor.description,
      },
      room: {
        ...room,
        name: room.description,
      },
      service: {
        id: 'f2490f62-1d28-4edd-362a-08d8a7232229',
        name: 'Xét nghiệm COVID-19',
      },
      customer: {
        ...patientSubject,
        phone: patientSubject.phoneNumber,
        birthDate: patientSubject.dateOfBirth,
        provinceCode: patientSubject.province,
        districtCode: patientSubject.district,
        wardCode: patientSubject.ward,
      },
      date,
      bookedByUser: userInfo.id,
    };
    dispatch(register(data))
      .then(() => {
        addToast('Tạo phiếu hẹn thành công', { appearance: 'success' });
      })
      .catch(() => {
        addToast('Thời gian trên đã bị đặt, vui lòng chọn thời gian khác', {
          appearance: 'error',
        });
        dispatch(setStep(2));
      });
  };

  const steps = [
    {
      key: 0,
      isSelected: patientName ? (
        <>
          <span>{'Người xét nghiệm - '}</span>
          <span className="text-secondary">{`${patientName}`}</span>
        </>
      ) : (
        ''
      ),
      title: 'Người xét nghiệm',
      component: (
        <PatientSelect
          isLoading={isLoading}
          initialPatient={patientSubject}
          onChange={(d) => {
            setPatientSubject(d);
            if (d) {
              dispatch(setStep(currentStep + 1));
            }
          }}
        />
      ),
    },
    {
      key: 1,
      isSelected: hospitalLabel ? (
        <>
          <span>{'Cơ sở xét nghiệm - '}</span>
          <span className="text-secondary">{`${hospitalLabel}`}</span>
        </>
      ) : (
        ''
      ),
      title: 'Cơ sở xét nghiệm',
      component: (
        <HospitalSelect
          onChange={(d) => {
            setHospital(d);
            if (d) {
              dispatch(setStep(currentStep + 1));
            }
          }}
          onBack={() => {
            setPatientSubject(null);
            setHospital(null);
          }}
        />
      ),
    },
    {
      key: 2,
      isSelected: '',
      title: 'Thời gian làm việc',
      component: (
        <DateSelect
          hospitalId={hospital?.id ?? ''}
          onIntervalChange={setInterval}
          onDoctorRoomChange={({ doctor: d, room: r, date: day }) => {
            setDate(day);
            setDoctor(d);
            setRoom(r);
          }}
          onBack={() => {
            setHospital(null);
            setInterval(null);
          }}
          onFinish={submit}
        />
      ),
    },
  ];

  const appointment = useMemo(
    () => <Appointment id={registerData?.id} onRefresh={handleRefresh} />,
    [registerData],
  );

  const container = useMemo(
    () => (
      <Container fluid className="pt-3">
        {/* {appointment} */}
        {!registerData && !registerLoading && <h5>Đặt lịch xét nghiệm</h5>}
        {!registerData && !registerLoading && (
          <Steps direction="vertical" current={currentStep}>
            {steps.map((s) => (
              <Step
                icons={<FaCheck />}
                key={s.key}
                title={s.isSelected.length === 0 ? s.title : s.isSelected}
                description={s.key === currentStep ? s.component : null}
              />
            ))}
          </Steps>
        )}
        {registerData && appointment}
        {registerLoading && (
          <StyledDiv>
            <h5 className="text-secondary font-weight-normal">
              Đang tiến hành tạo phiếu hẹn
            </h5>
            <Spinner color="info" />
          </StyledDiv>
        )}
      </Container>
    ),
    [steps, currentStep, appointment, registerData, registerLoading],
  );

  return <div>{token ? container : spinner}</div>;
};

export default CovidExaminationBookingPage;
