import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Spinner,
  Collapse,
  CardHeader,
} from 'reactstrap';
import DatePicker from 'react-day-picker';
import { FaCaretDown } from 'react-icons/fa';
import styled from 'styled-components';
import moment from 'moment';

import ButtonFragment from './ButtonFragment';
import {
  getHealthCareScheduleByDoctor,
  selectBookingDate,
  createExamination,
  selectExpert,
} from '../actions/booking';

import 'react-day-picker/lib/style.css';

const StyledButton = styled(Button)`
  margin: 3px;
  border-radius: 16px;
  width: 70px;
`;

const ButtonWrapper = styled.div`
  margin-top: 15px;
  text-align: left;
`;

const ExpertSelectDate = () => {
  const dispatch = useDispatch();
  const {
    selectedExpert,
    selectedClinic,
    selectedDate,
    listSchedulesByDoctor,
    insuranceInfo,
    selectedPatient,
    selectedServiceType,
  } = useSelector((state) => state.booking);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTime, setSelectedTime] = useState(0);
  const [month, setMonth] = useState(new Date());
  const [disableDays, setDisableDays] = useState([]);
  const [selectedGroupOfTime, setSelectedGroupOfTime] = useState(0);

  useEffect(() => {
    dispatch(
      getHealthCareScheduleByDoctor(
        selectedClinic,
        selectedExpert.doctorId,
        selectedExpert.serviceId,
      ),
    );
  }, [dispatch, selectedClinic, selectedExpert]);

  useEffect(() => {
    // get list of days in month
    let dayOfMonth = [];
    let i = 0;
    do {
      const day = moment(month)
        .startOf('month')
        .startOf('day')
        .add(i, 'days');
      dayOfMonth.push(day);
      i += 1;
    } while (i < moment(month).daysInMonth());

    // remove available date from list of days in month
    const availableDays = listSchedulesByDoctor.map((e) => moment(e.Date));
    dayOfMonth = dayOfMonth.filter(
      (el) => !availableDays
        .map((d) => d.format('DD-MM-YYYY'))
        .includes(el.format('DD-MM-YYYY')),
    );
    setDisableDays(dayOfMonth.map((d) => moment(d).toDate()));
  }, [month, listSchedulesByDoctor]);

  const selectDate = (d, { selected }, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }

    setSelectedDay(selected ? undefined : d);

    const scheduleObj = listSchedulesByDoctor.find(
      (el) => moment(el.Date).format('DD-MM-YYYY') === moment(d).format('DD-MM-YYYY'),
    );
    if (scheduleObj && scheduleObj.Morning.length === 0) {
      setSelectedGroupOfTime(2);
    } else {
      setSelectedGroupOfTime(1);
    }
    dispatch(selectBookingDate(scheduleObj));
  };

  const handleToggle = (e) => {
    if (e === selectedGroupOfTime) {
      setSelectedGroupOfTime(0);
    } else {
      setSelectedGroupOfTime(e);
    }
  };

  const submit = () => {
    const data = {
      HasHealthInsurance: insuranceInfo.haveInsurance,
      HospitalId: selectedClinic.Id,
      HealthCareSchedulerId: selectedTime,
      ServiceId: selectedExpert.serviceId,
      CustomerId: selectedPatient.value,
      PatientId: selectedPatient.value,
      DoctorId: selectedExpert ? selectedExpert.doctorId : 0,
      Type: selectedServiceType.value,
    };
    dispatch(createExamination(data));
  };

  return (
    <div>
      {listSchedulesByDoctor.length !== 0 ? (
        <>
          <Row>
            <Col xs="12" lg="5" className="text-center mt-3">
              <Card>
                <CardBody>
                  <DatePicker
                    initialMonth={
                      new Date(
                        new Date(
                          month.getFullYear(),
                          new Date(month.getMonth()),
                        ),
                      )
                    }
                    selectedDays={selectedDay}
                    disabledDays={disableDays}
                    onDayClick={selectDate}
                    onMonthChange={(m) => setMonth(m)}
                  />
                </CardBody>
              </Card>
              <ButtonWrapper className="d-none d-lg-block">
                <ButtonFragment
                  onGoBack={() => {
                    dispatch(selectExpert(null));
                    dispatch(selectBookingDate(null));
                  }}
                  onFinish={() => submit()}
                  data={selectedTime === 0 ? null : { selected: selectedTime }}
                />
              </ButtonWrapper>
            </Col>
            {selectedDate && (
              <Col xs="12" lg="7" className="mt-3">
                {selectedDate.Morning.length !== 0 && (
                  <Card className="mb-3">
                    <CardHeader onClick={() => handleToggle(1)}>
                      Buổi sáng
                      <FaCaretDown className="ml-2" />
                    </CardHeader>
                    <Collapse
                      isOpen={selectedGroupOfTime === 1}
                      className="mb-3"
                    >
                      <CardBody>
                        {selectedDate.Morning.map((e) => (
                          <StyledButton
                            key={e.TimeId}
                            disabled={!e.Active}
                            color="info"
                            outline
                            active={e.TimeId === selectedTime}
                            onClick={() => setSelectedTime(e.TimeId)}
                          >
                            {e.From.toString().substring(0, e.From.length - 3)}
                          </StyledButton>
                        ))}
                      </CardBody>
                    </Collapse>
                  </Card>
                )}
                {selectedDate.Afternoon.length !== 0 && (
                  <Card>
                    <CardHeader onClick={() => handleToggle(2)}>
                      Buổi chiều
                      <FaCaretDown className="ml-2" />
                    </CardHeader>
                    <Collapse isOpen={selectedGroupOfTime === 2}>
                      <CardBody>
                        {selectedDate.Afternoon.map((e) => (
                          <StyledButton
                            key={e.TimeId}
                            disabled={!e.Active}
                            color="info"
                            outline
                            active={e.TimeId === selectedTime}
                            onClick={() => setSelectedTime(e.TimeId)}
                          >
                            {e.From.toString().substring(0, e.From.length - 3)}
                          </StyledButton>
                        ))}
                      </CardBody>
                    </Collapse>
                  </Card>
                )}
              </Col>
            )}
          </Row>
          <ButtonWrapper className="d-xs-block d-lg-none">
            <ButtonFragment
              onGoBack={() => {
                dispatch(selectExpert(null));
                dispatch(selectBookingDate(null));
              }}
              onFinish={() => submit()}
              data={selectedTime === 0 ? null : { selected: selectedTime }}
            />
          </ButtonWrapper>
        </>
      ) : (
        <Spinner color="info" />
      )}
    </div>
  );
};

export default ExpertSelectDate;
