import apiLinks from 'app/utils/api-links';
import httpClient from 'app/utils/http-client';
import { EXAMINATION_FORM } from 'app/utils/constants';

import types from './types';

const setStep = (step) => ({ type: types.EXAMINATION_SET_STEP, payload: step });

const getProfilesRequest = () => ({ type: types.EXAMINATION_GET_PROFILES_REQUEST });
const getProfilesSuccess = (response) => ({ type: types.EXAMINATION_GET_PROFILES_SUCCESS, payload: response });
const getProfilesFailure = (error) => ({ type: types.EXAMINATION_GET_PROFILES_FAILURE, payload: error });

const getProfiles = (CustomerId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getProfilesRequest());
  httpClient.callApi({
    url: apiLinks.getProfiles,
    params: {
      CustomerId,
    },
  }).then((response) => {
    dispatch(getProfilesSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getProfilesFailure(error));
    reject();
  });
});

const getDiseasesRequest = () => ({ type: types.EXAMINATION_GET_DISEASES_REQUEST });
const getDiseasesSuccess = (response) => ({ type: types.EXAMINATION_GET_DISEASES_SUCCESS, payload: response });
const getDiseasesFailure = (error) => ({ type: types.EXAMINATION_GET_DISEASES_FAILURE, payload: error });

const getDiseases = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getDiseasesRequest());
  httpClient.callApi({
    url: apiLinks.getDiseases,
  }).then((response) => {
    dispatch(getDiseasesSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getDiseasesFailure(error));
    reject();
  });
});

const getVaccinesRequest = () => ({ type: types.EXAMINATION_GET_VACCINES_REQUEST });
const getVaccinesSuccess = (response) => ({ type: types.EXAMINATION_GET_VACCINES_SUCCESS, payload: response });
const getVaccinesFailure = (error) => ({ type: types.EXAMINATION_GET_VACCINES_FAILURE, payload: error });

const getVaccines = (diseaseId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getVaccinesRequest());
  httpClient.callApi({
    url: apiLinks.getVaccines,
    params: {
      diseaseId,
    },
  }).then((response) => {
    dispatch(getVaccinesSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getVaccinesFailure(error));
    reject();
  });
});

const getVaccineInjectionRequest = () => ({ type: types.EXAMINATION_GET_VACCINE_INJECTION_REQUEST });
const getVaccineInjectionSuccess = (response) => ({ type: types.EXAMINATION_GET_VACCINE_INJECTION_SUCCESS, payload: response });
const getVaccineInjectionFailure = (error) => ({ type: types.EXAMINATION_GET_VACCINE_INJECTION_FAILURE, payload: error });

const getVaccineInjection = (vaccineId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getVaccineInjectionRequest());
  httpClient.callApi({
    url: apiLinks.getVaccineInjectionObjects,
    params: {
      vaccineId,
    },
  }).then((response) => {
    dispatch(getVaccineInjectionSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getVaccineInjectionFailure(error));
    reject();
  });
});

const getInjectionObjectsRequest = () => ({ type: types.EXAMINATION_GET_INJECTION_REQUEST });
const getInjectionObjectsSuccess = (response) => ({ type: types.EXAMINATION_GET_INJECTION_SUCCESS, payload: response });
const getInjectionObjectsFailure = (error) => ({ type: types.EXAMINATION_GET_INJECTION_FAILURE, payload: error });

const getInjectionObjects = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getInjectionObjectsRequest());
  httpClient.callApi({
    url: apiLinks.getInjectionObjects,
  }).then((response) => {
    dispatch(getInjectionObjectsSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getInjectionObjectsFailure(error));
    reject();
  });
});

const getByHealthcareRequest = () => ({ type: types.EXAMINATION_GET_CLINIC_REQUEST });
const getByHealthcareSuccess = (response) => ({ type: types.EXAMINATION_GET_CLINIC_SUCCESS, payload: response });
const getByHealthcareFailure = (error) => ({ type: types.EXAMINATION_GET_CLINIC_FAILURE, payload: error });

const getByHealthcare = (customerId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getByHealthcareRequest());
  httpClient.callApi({
    url: apiLinks.getByHealthCareId + 2596,
    params: {
      customerId,
    },
  }).then((response) => {
    dispatch(getByHealthcareSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getByHealthcareFailure(error));
    reject();
  });
});

const getSchedulesRequest = () => ({ type: types.EXAMINATION_GET_SCHEDULE_REQUEST });
const getSchedulesSuccess = (response) => ({ type: types.EXAMINATION_GET_SCHEDULE_SUCCESS, payload: response });
const getSchedulesFailure = (error) => ({ type: types.EXAMINATION_GET_SCHEDULE_FAILURE, payload: error });

const getSchedules = (hospitalId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getSchedulesRequest());
  httpClient.callApi({
    url: apiLinks.getExaminationSchedules(hospitalId),
    params: {
      form: EXAMINATION_FORM,
      serviceId: 'b9d3aeb8-02d6-4d44-9353-dde186547d0e',
    },
  }).then((response) => {
    dispatch(getSchedulesSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getSchedulesFailure(error));
    reject();
  });
});

const getTicketsRequest = () => ({ type: types.EXAMINATION_GET_TICKETS_REQUEST });
const getTicketsSuccess = (response) => ({ type: types.EXAMINATION_GET_TICKETS_SUCCESS, payload: response });
const getTicketsFailure = (error) => ({ type: types.EXAMINATION_GET_TICKETS_FAILURE, payload: error });

const getTickets = (hospitalId, date) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getTicketsRequest());
  httpClient.callApi({
    url: apiLinks.getExaminationTickets,
    params: {
      hospitalId,
      date,
      serviceId: 'b9d3aeb8-02d6-4d44-9353-dde186547d0e',
    },
  }).then((response) => {
    dispatch(getTicketsSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getTicketsFailure(error));
    reject();
  });
});

const getTicketsWithoutDateRequest = () => ({ type: types.EXAMINATION_GET_TICKETS_WITHOUT_DATE_REQUEST });
const getTicketsWithoutDateSuccess = (response) => ({ type: types.EXAMINATION_GET_TICKETS_WITHOUT_DATE_SUCCESS, payload: response });
const getTicketsWithoutDateFailure = (error) => ({ type: types.EXAMINATION_GET_TICKETS_WITHOUT_DATE_FAILURE, payload: error });

const getTicketsWithoutDate = (hospitalId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getTicketsWithoutDateRequest());
  httpClient.callApi({
    url: apiLinks.getExaminationTickets,
    params: {
      hospitalId,
      serviceId: 'b9d3aeb8-02d6-4d44-9353-dde186547d0e',
    },
  }).then((response) => {
    dispatch(getTicketsWithoutDateSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getTicketsWithoutDateFailure(error));
    reject();
  });
});

const getAppointmentRequest = () => ({ type: types.EXAMINATION_GET_APPOINTMENT_REQUEST });
const getAppointmentSuccess = (response) => ({ type: types.EXAMINATION_GET_APPOINTMENT_SUCCESS, payload: response });
const getAppointmentFailure = (error) => ({ type: types.EXAMINATION_GET_APPOINTMENT_FAILURE, payload: error });

const getAppointment = (id) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getAppointmentRequest());
  httpClient.callApi({
    url: apiLinks.getExaminationAppointment + id,
  }).then((response) => {
    dispatch(getAppointmentSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getAppointmentFailure(error));
    reject();
  });
});

const getHistoryRequest = () => ({ type: types.EXAMINATION_GET_HISTORY_REQUEST });
const getHistorySuccess = (response) => ({ type: types.EXAMINATION_GET_HISTORY_SUCCESS, payload: response });
const getHistoryFailure = (error) => ({ type: types.EXAMINATION_GET_HISTORY_FAILURE, payload: error });

const getHistory = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getHistoryRequest());
  httpClient.callApi({
    url: apiLinks.getExaminationHistory,
  }).then((response) => {
    dispatch(getHistorySuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getHistoryFailure(error));
    reject();
  });
});

const getDoctorByFormRequest = () => ({ type: types.EXAMINATION_GET_DOCTOR_REQUEST });
const getDoctorByFormSuccess = (response) => ({ type: types.EXAMINATION_GET_DOCTOR_SUCCESS, payload: response });
const getDoctorByFormFailure = (error) => ({ type: types.EXAMINATION_GET_DOCTOR_FAILURE, payload: error });

const getDoctorByForm = (hospitalId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getDoctorByFormRequest());
  httpClient.callApi({
    url: apiLinks.getDoctor(hospitalId),
    params: {
      Form: EXAMINATION_FORM,
    },
  }).then((response) => {
    dispatch(getDoctorByFormSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getDoctorByFormFailure(error));
    reject();
  });
});

const registerRequest = () => ({ type: types.EXAMINATION_REGISTER_REQUEST });
const registerSuccess = (response) => ({ type: types.EXAMINATION_REGISTER_SUCCESS, payload: response });
const registerFailure = (error) => ({ type: types.EXAMINATION_REGISTER_FAILURE, payload: error });

const register = (data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(registerRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.registerExamination,
    data,
  }).then((response) => {
    dispatch(registerSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(registerFailure(error));
    reject();
  });
});

const assignToHcdcRequest = () => ({ type: types.CREATE_ASSIGN_REQUEST });
const assignToHcdcSuccess = (response) => ({ type: types.CREATE_ASSIGN_SUCCESS, payload: response });
const assignToHcdcFailure = (error) => ({ type: types.CREATE_ASSIGN_FAILURE, payload: error });

const assignToHcdc = (data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(assignToHcdcRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.assign.create,
    data,
  }).then((response) => {
    dispatch(assignToHcdcSuccess(response.data));
    resolve(response.data);
  }).catch((error) => {
    dispatch(assignToHcdcFailure(error));
    reject();
  });
});

const createSubjectRequest = () => ({ type: types.CREATE_SUBJECT_REQUEST });
const createSubjectSuccess = (response) => ({ type: types.CREATE_SUBJECT_SUCCESS, payload: response });
const createSubjectFailure = (error) => ({ type: types.CREATE_SUBJECT_FAILURE, payload: error });

const createSubject = (data) => (dispatch) => new Promise(
  (resolve, reject) => {
    dispatch(createSubjectRequest());
    httpClient.callApi({
      method: 'POST',
      url: apiLinks.subject.create,
      data,
    }).then((response) => {
      dispatch(createSubjectSuccess(response.data));
      resolve(response.data);
    }).catch(() => {
      dispatch(createSubjectFailure());
      reject();
    });
  },
);

const updateExaminationAppointmentRequest = () => ({ type: types.EXAMINATION_UPDATE_APPOINTMENT_REQUEST });
const updateExaminationAppointmentSuccess = (response) => ({ type: types.EXAMINATION_UPDATE_APPOINTMENT_SUCCESS, payload: response });
const updateExaminationAppointmentFailure = (error) => ({ type: types.EXAMINATION_UPDATE_APPOINTMENT_FAILURE, payload: error });

const updateExaminationAppointment = (id, status) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(updateExaminationAppointmentRequest());
  httpClient.callApi({
    method: 'PUT',
    url: apiLinks.updateExaminationAppointment,
    data: {
      id,
      status,
    },
  }).then((response) => {
    dispatch(updateExaminationAppointmentSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(updateExaminationAppointmentFailure(error));
    reject();
  });
});

const resetExamination = () => ({ type: types.EXAMINATION_RESET });

export {
  setStep,
  getProfiles,
  getDiseases,
  getVaccines,
  getVaccineInjection,
  getInjectionObjects,
  getByHealthcare,
  getSchedules,
  getTickets,
  getTicketsWithoutDate,
  getAppointment,
  getHistory,
  getDoctorByForm,
  register,
  resetExamination,
  assignToHcdc,
  createSubject,
  updateExaminationAppointment,
};
