import { getUserInfo, setToken } from 'app/actions/auth';
import ChangePassword from 'app/components/ChangePassword';
import UpdateInfo from 'app/components/UpdateInfo';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap';

const useQuery = () => new URLSearchParams(useLocation().search);
const UserInfoPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery(location);

  const tokenParam = query.get('s');

  useEffect(() => {
    dispatch(setToken(tokenParam));
  }, [dispatch, tokenParam]);

  const { token } = useSelector((state) => state.auth);
  useEffect(() => {
    if (token) {
      dispatch(getUserInfo());
    }
  }, [dispatch, token]);

  const [activeTab, setActiveTab] = useState('1');
  return (
    <div className="mx-3 mx-md-0">
      <Nav tabs>
        <NavItem>
          <NavLink
            active={activeTab === '1'}
            onClick={() => setActiveTab('1')}
          >
            Cập nhật thông tin
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === '2'}
            onClick={() => setActiveTab('2')}
          >
            Đổi mật khẩu
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12" className="">
              <UpdateInfo />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <ChangePassword />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default UserInfoPage;
