import MainLayout from 'app/layouts/MainLayout';
import HomePage from 'app/pages/HomePage';
import LoginPageV2 from 'app/pages/LoginPageV2';
import LandingPage from 'app/pages/LandingPage';
import RegisterPageV2 from 'app/pages/RegisterPageV2';
import ForgotPasswordPage from 'app/pages/ForgotPasswordPage';

import MedicalCheckupBookingPage from 'medical-checkup-booking/pages/MedicalCheckupBookingPage';
import EmergencyPage from 'emergency/pages/EmergencyPage';
import HomecarePage from 'homecare/pages/HomecarePage';
import TelehealthPage from 'telehealth/pages/TelehealthPage';
import HistoryPage from 'history/pages/HistoryPage';
import ImmunizationPage from 'immunization/pages/ImmunizationPage';
import ImmunizationHistoryPage from 'immunization/pages/ImmunizationHistoryPage';
import CovidExaminationPage from 'covid-examination/pages/CovidExaminationPage';
import CovidExaminationBookingPage from 'covid-examination-booking/pages/CovidExaminationBookingPage';
import HealthDeclarationPage from 'covid-examination/pages/HealthDeclarationPage';
import UserInfoPage from 'app/pages/UserInfoPage';
import CovidExaminationHistoryPage from 'covid-examination/pages/CovidExaminationHistoryPage';
import ImmunizationBookingPage from 'immunization-booking/pages/ImmunizationBookingPage';
import ImmunizationBookingHistoryPage from 'immunization-booking/pages/ImmunizationBookingHistoryPage';

const routes = [
  {
    path: '/',
    key: 'LandingPage',
    component: LandingPage,
    layout: null,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/home',
    key: 'HomePage',
    component: HomePage,
    layout: null,
    isPrivate: true,
    exact: false,
  },
  {
    path: '/login',
    key: 'LoginPage',
    component: LoginPageV2,
    layout: null,
    isPrivate: false,
    exact: false,
  },
  {
    path: '/register',
    key: 'RegisterPage',
    component: RegisterPageV2,
    layout: null,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/forgot-password',
    key: 'ForgotPasswordPage',
    component: ForgotPasswordPage,
    layout: null,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/user-info',
    key: 'UserInfoPage',
    component: UserInfoPage,
    layout: null,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/medical-checkup-booking',
    key: 'MedicalCheckupBookingPage',
    component: MedicalCheckupBookingPage,
    layout: MainLayout,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/emergency',
    key: 'EmergencyPage',
    component: EmergencyPage,
    layout: MainLayout,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/homecare',
    key: 'HomecarePage',
    component: HomecarePage,
    layout: MainLayout,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/telehealth',
    key: 'TelehealthPage',
    component: TelehealthPage,
    layout: MainLayout,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/history',
    key: 'HistoryPage',
    component: HistoryPage,
    layout: MainLayout,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/immunization',
    key: 'ImmunizationPage',
    component: ImmunizationPage,
    layout: null,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/immunization-history',
    key: 'ImmunizationHistoryPage',
    component: ImmunizationHistoryPage,
    layout: null,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/covid-examination-history',
    key: 'CovidExaminationHistoryPage',
    component: CovidExaminationHistoryPage,
    layout: null,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/covid-examination',
    key: 'CovidExaminationPage',
    component: CovidExaminationPage,
    layout: null,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/covid-examination-booking',
    key: 'CovidExaminationBookingPage',
    component: CovidExaminationBookingPage,
    layout: null,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/health-declaration',
    key: 'HealthDeclaration',
    component: HealthDeclarationPage,
    layout: null,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/immunization-booking',
    key: 'ImmunizationBookingPage',
    component: ImmunizationBookingPage,
    layout: null,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/immunization-booking-history',
    key: 'ImmunizationBookingHistoryPage',
    component: ImmunizationBookingHistoryPage,
    layout: null,
    isPrivate: false,
    exact: true,
  },
];

export default routes;
