import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from 'reactstrap';
import HealthDeclarationForm from './HealthDeclarationForm';

const HealthDeclarationModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    patient,
  } = props;

  return (
    <Modal size="xl" isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        TỜ KHAI Y TẾ ĐỐI VỚI NGƯỜI TỪ TỈNH/THÀNH PHỐ CÓ DỊCH TRỞ VỀ
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm={12} className="text-center">
            <b>
              Đây là tài liệu quan trọng, thông tin của Anh/Chị sẽ giúp cơ quan
              y tế liên lạc khi cần thiết để phòng chống dịch bệnh truyền nhiễm.
            </b>
          </Col>
          <Col sm={12} className="mt-3">
            <HealthDeclarationForm patient={patient} onSubmit={onSubmit} />
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

HealthDeclarationModal.propTypes = {
  open: PropTypes.bool,
  patient: PropTypes.shape({}),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

HealthDeclarationModal.defaultProps = {
  open: false,
  patient: null,
  onClose: () => {},
  onSubmit: () => {},
};

export default HealthDeclarationModal;
