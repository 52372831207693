export default {
  IMMUNIZATION_SET_STEP: 'IMMUNIZATION_SET_STEP',

  IMMUNIZATION_GET_PROFILES_REQUEST: 'IMMUNIZATION_GET_PROFILES_REQUEST',
  IMMUNIZATION_GET_PROFILES_SUCCESS: 'IMMUNIZATION_GET_PROFILES_SUCCESS',
  IMMUNIZATION_GET_PROFILES_FAILURE: 'IMMUNIZATION_GET_PROFILES_FAILURE',

  IMMUNIZATION_GET_DISEASES_REQUEST: 'IMMUNIZATION_GET_DISEASES_REQUEST',
  IMMUNIZATION_GET_DISEASES_SUCCESS: 'IMMUNIZATION_GET_DISEASES_SUCCESS',
  IMMUNIZATION_GET_DISEASES_FAILURE: 'IMMUNIZATION_GET_DISEASES_FAILURE',

  IMMUNIZATION_GET_VACCINES_REQUEST: 'IMMUNIZATION_GET_VACCINES_REQUEST',
  IMMUNIZATION_GET_VACCINES_SUCCESS: 'IMMUNIZATION_GET_VACCINES_SUCCESS',
  IMMUNIZATION_GET_VACCINES_FAILURE: 'IMMUNIZATION_GET_VACCINES_FAILURE',

  IMMUNIZATION_GET_VACCINE_INJECTION_REQUEST: 'IMMUNIZATION_GET_VACCINE_INJECTION_REQUEST',
  IMMUNIZATION_GET_VACCINE_INJECTION_SUCCESS: 'IMMUNIZATION_GET_VACCINE_INJECTION_SUCCESS',
  IMMUNIZATION_GET_VACCINE_INJECTION_FAILURE: 'IMMUNIZATION_GET_VACCINE_INJECTION_FAILURE',

  IMMUNIZATION_GET_INJECTION_REQUEST: 'IMMUNIZATION_GET_INJECTION_REQUEST',
  IMMUNIZATION_GET_INJECTION_SUCCESS: 'IMMUNIZATION_GET_INJECTION_SUCCESS',
  IMMUNIZATION_GET_INJECTION_FAILURE: 'IMMUNIZATION_GET_INJECTION_FAILURE',

  IMMUNIZATION_GET_HEALTHCARE_REQUEST: 'IMMUNIZATION_GET_HEALTHCARE_REQUEST',
  IMMUNIZATION_GET_HEALTHCARE_SUCCESS: 'IMMUNIZATION_GET_HEALTHCARE_SUCCESS',
  IMMUNIZATION_GET_HEALTHCARE_FAILURE: 'IMMUNIZATION_GET_HEALTHCARE_FAILURE',

  IMMUNIZATION_GET_CLINIC_REQUEST: 'IMMUNIZATION_GET_CLINIC_REQUEST',
  IMMUNIZATION_GET_CLINIC_SUCCESS: 'IMMUNIZATION_GET_CLINIC_SUCCESS',
  IMMUNIZATION_GET_CLINIC_FAILURE: 'IMMUNIZATION_GET_CLINIC_FAILURE',

  IMMUNIZATION_GET_SCHEDULE_REQUEST: 'IMMUNIZATION_GET_SCHEDULE_REQUEST',
  IMMUNIZATION_GET_SCHEDULE_SUCCESS: 'IMMUNIZATION_GET_SCHEDULE_SUCCESS',
  IMMUNIZATION_GET_SCHEDULE_FAILURE: 'IMMUNIZATION_GET_SCHEDULE_FAILURE',

  IMMUNIZATION_GET_TICKETS_REQUEST: 'IMMUNIZATION_GET_TICKETS_REQUEST',
  IMMUNIZATION_GET_TICKETS_SUCCESS: 'IMMUNIZATION_GET_TICKETS_SUCCESS',
  IMMUNIZATION_GET_TICKETS_FAILURE: 'IMMUNIZATION_GET_TICKETS_FAILURE',

  IMMUNIZATION_REGISTER_REQUEST: 'IMMUNIZATION_REGISTER_REQUEST',
  IMMUNIZATION_REGISTER_SUCCESS: 'IMMUNIZATION_REGISTER_SUCCESS',
  IMMUNIZATION_REGISTER_FAILURE: 'IMMUNIZATION_REGISTER_FAILURE',

  IMMUNIZATION_GET_APPOINTMENT_REQUEST: 'IMMUNIZATION_GET_APPOINTMENT_REQUEST',
  IMMUNIZATION_GET_APPOINTMENT_SUCCESS: 'IMMUNIZATION_GET_APPOINTMENT_SUCCESS',
  IMMUNIZATION_GET_APPOINTMENT_FAILURE: 'IMMUNIZATION_GET_APPOINTMENT_FAILURE',

  IMMUNIZATION_GET_HISTORY_REQUEST: 'IMMUNIZATION_GET_HISTORY_REQUEST',
  IMMUNIZATION_GET_HISTORY_SUCCESS: 'IMMUNIZATION_GET_HISTORY_SUCCESS',
  IMMUNIZATION_GET_HISTORY_FAILURE: 'IMMUNIZATION_GET_HISTORY_FAILURE',

  IMMUNIZATION_UPDATE_APPOINTMENT_REQUEST: 'IMMUNIZATION_UPDATE_APPOINTMENT_REQUEST',
  IMMUNIZATION_UPDATE_APPOINTMENT_SUCCESS: 'IMMUNIZATION_UPDATE_APPOINTMENT_SUCCESS',
  IMMUNIZATION_UPDATE_APPOINTMENT_FAILURE: 'IMMUNIZATION_UPDATE_APPOINTMENT_FAILURE',

  IMMUNIZATION_RESET: 'IMMUNIZATION_RESET',
};
