import apiLinks from 'app/utils/api-links';
import httpClient from 'app/utils/http-client';
import {
  HISTORY_GET_MCB_SCHEDULER_REQUEST,
  HISTORY_GET_MCB_SCHEDULER_SUCCESS,
  HISTORY_GET_MCB_SCHEDULER_FAILURE,
  HISTORY_GET_TELE_SCHEDULER_REQUEST,
  HISTORY_GET_TELE_SCHEDULER_SUCCESS,
  HISTORY_GET_TELE_SCHEDULER_FAILURE,
  // HISTORY_GET_PAYMENT_STATUS_REQUEST,
  // HISTORY_GET_PAYMENT_STATUS_SUCCESS,
  // HISTORY_GET_PAYMENT_STATUS_FAILURE,
} from './types';

// const getPaymentStatusRequest = () => ({ type: HISTORY_GET_PAYMENT_STATUS_REQUEST });
// const getPaymentStatusSuccess = (response) => ({ type: HISTORY_GET_PAYMENT_STATUS_SUCCESS, payload: response });
// const getPaymentStatusFailure = (error) => ({ type: HISTORY_GET_PAYMENT_STATUS_FAILURE, payload: error });

// const getPaymentStatus = (examId) => (dispatch) => new Promise((resolve, reject) => {
//   dispatch(getPaymentStatusRequest());
//   httpClient.callApi({
//     method: 'POST',
//     url: apiLinks.getPaymentStatus(examId),
//     params: {
//       status: true,
//     },
//   }).then((response) => {
//     dispatch(getPaymentStatusSuccess(response.data));
//     resolve();
//   }).catch((error) => {
//     dispatch(getPaymentStatusFailure(error));
//     reject();
//   });
// });

const getTicketsMCBRequest = () => ({ type: HISTORY_GET_MCB_SCHEDULER_REQUEST });
const getTicketsMCBSuccess = (response) => ({ type: HISTORY_GET_MCB_SCHEDULER_SUCCESS, payload: response });
const getTicketsMCBFailure = (error) => ({ type: HISTORY_GET_MCB_SCHEDULER_FAILURE, payload: error });

const getMCBTickets = (customerId, formId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getTicketsMCBRequest());
  httpClient.callApi({
    url: apiLinks.getTickets,
    params: {
      CustomerId: customerId,
      form: formId,
    },
  }).then((response) => {
    dispatch(getTicketsMCBSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getTicketsMCBFailure(error));
    reject();
  });
});

const getTicketsTeleRequest = () => ({ type: HISTORY_GET_TELE_SCHEDULER_REQUEST });
const getTicketsTeleSuccess = (response) => ({ type: HISTORY_GET_TELE_SCHEDULER_SUCCESS, payload: response });
const getTicketsTeleFailure = (error) => ({ type: HISTORY_GET_TELE_SCHEDULER_FAILURE, payload: error });

const getTeleTickets = (customerId, formId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getTicketsTeleRequest());
  httpClient.callApi({
    url: apiLinks.getTickets,
    params: {
      CustomerId: customerId,
      form: formId,
    },
  }).then((response) => {
    dispatch(getTicketsTeleSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getTicketsTeleFailure(error));
    reject();
  });
});

export {
  getMCBTickets,
  getTeleTickets,
  // getPaymentStatus,
};
