export const HOMECARE_SET_STEP = 'HOMECARE_SET_STEP';
export const HOMECARE_SET_ADDRESS = 'HOMECARE_SET_ADDRESS';
export const HOMECARE_SET_PHONE = 'HOMECARE_SET_PHONE';
export const HOMECARE_SELECT_SERVICE = 'HOMECARE_SELECT_SERVICE';
export const HOMECARE_SELECT_CLINIC = 'HOMECARE_SELECT_CLINIC';
export const HOMECARE_SELECT_PATIENT = 'HOMECARE_SELECT_PATIENT';
export const HOMECARE_SELECT_EXPERT = 'HOMECARE_SELECT_EXPERT';
export const HOMECARE_SELECT_SERVICE_TYPE = 'HOMECARE_SELECT_SERVICE_TYPE';
export const HOMECARE_SELECT_DATE = 'HOMECARE_SELECT_DATE';
export const HOMECARE_GET_SERVICE_TYPE_REQUEST = 'HOMECARE_GET_SERVICE_TYPE_REQUEST';
export const HOMECARE_GET_SERVICE_TYPE_SUCCESS = 'HOMECARE_GET_SERVICE_TYPE_SUCCESS';
export const HOMECARE_GET_SERVICE_TYPE_FAILURE = 'HOMECARE_GET_SERVICE_TYPE_FAILURE';

export const HOMECARE_GET_HEALTHCARE_BY_HOSPITAL_REQUEST = 'HOMECARE_GET_HEALTHCARE_BY_HOSPITAL_REQUEST';
export const HOMECARE_GET_HEALTHCARE_BY_HOSPITAL_SUCCESS = 'HOMECARE_GET_HEALTHCARE_BY_HOSPITAL_SUCCESS';
export const HOMECARE_GET_HEALTHCARE_BY_HOSPITAL_FAILURE = 'HOMECARE_GET_HEALTHCARE_BY_HOSPITAL_FAILURE';

export const HOMECARE_GET_HEALTHCARE_SCHEDULE_REQUEST = 'HOMECARE_GET_HEALTHCARE_SCHEDULE_REQUEST';
export const HOMECARE_GET_HEALTHCARE_SCHEDULE_SUCCESS = 'HOMECARE_GET_HEALTHCARE_SCHEDULE_SUCCESS';
export const HOMECARE_GET_HEALTHCARE_SCHEDULE_FAILURE = 'HOMECARE_GET_HEALTHCARE_SCHEDULE_FAILURE';

export const HOMECARE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_REQUEST = 'HOMECARE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_REQUEST';
export const HOMECARE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_SUCCESS = 'HOMECARE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_SUCCESS';
export const HOMECARE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_FAILURE = 'HOMECARE_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_FAILURE';

export const HOMECARE_GET_SERIVCE_BY_DOCTOR_REQUEST = 'HOMECARE_GET_SERIVCE_BY_DOCTOR_REQUEST';
export const HOMECARE_GET_SERIVCE_BY_DOCTOR_SUCCESS = 'HOMECARE_GET_SERIVCE_BY_DOCTOR_SUCCESS';
export const HOMECARE_GET_SERIVCE_BY_DOCTOR_FAILURE = 'HOMECARE_GET_SERIVCE_BY_DOCTOR_FAILURE';

export const HOMECARE_GET_DOCTOR_REQUEST = 'HOMECARE_GET_DOCTOR_REQUEST';
export const HOMECARE_GET_DOCTOR_SUCCESS = 'HOMECARE_GET_DOCTOR_SUCCESS';
export const HOMECARE_GET_DOCTOR_FAILURE = 'HOMECARE_GET_DOCTOR_FAILURE';

export const HOMECARE_CREATE_EXAMINATION_REQUEST = 'HOMECARE_CREATE_EXAMINATION_REQUEST';
export const HOMECARE_CREATE_EXAMINATION_SUCCESS = 'HOMECARE_CREATE_EXAMINATION_SUCCESS';
export const HOMECARE_CREATE_EXAMINATION_FAILURE = 'HOMECARE_CREATE_EXAMINATION_FAILURE';

export const HOMECARE_DELETE_EXAMINATION_REQUEST = 'HOMECARE_DELETE_EXAMINATION_REQUEST';
export const HOMECARE_DELETE_EXAMINATION_SUCCESS = 'HOMECARE_DELETE_EXAMINATION_SUCCESS';
export const HOMECARE_DELETE_EXAMINATION_FAILURE = 'HOMECARE_DELETE_EXAMINATION_FAILURE';

export const HOMECARE_RESET = 'HOMECARE_RESET';
