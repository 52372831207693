import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Row,
  Card,
  CardTitle,
  CardText,
  Col,
  CardBody,
  Button,
} from 'reactstrap';
import styled from 'styled-components';

import { showConfirmModal, setHistoryTab } from 'app/actions/global';
import { deleteExamination, resetBooking } from 'medical-checkup-booking/actions/booking';
import { useToasts } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';

const BorderedCol = styled(Col)`
  border-top: 1px solid white;
`;

const StyledCardText = styled(CardText)`
  color: gold;
  font-weight: bold;
`;

const Appointment = (props) => {
  const { history } = props;
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const {
    selectedServiceType,
    examinationData,
  } = useSelector((state) => state.booking);
  const redirectToHistory = () => {
    history.push('/history');
    dispatch(setHistoryTab(1));
  };

  useEffect(() => {
    if (examinationData !== null) {
      addToast('Tạo phiếu hẹn thành công', { appearance: 'success' });
    }
  }, [examinationData, addToast]);
  return (
    <>
      <h3 className="text-info text-center font-weight-normal" xs="12">
        Phiếu hẹn
      </h3>
      <Row xs="1" md="2" xl="3" className="justify-content-center">
        <Card body inverse color="info">
          <CardTitle className="mb-0 text-center">
            <h4>{examinationData.Hospital.Name}</h4>
            <p>{`Địa chỉ: ${examinationData.Hospital.Address}`}</p>
          </CardTitle>
          <CardBody className="py-0">
            <h4 className="text-center font-weight-normal" xs="12">
              Mã phiếu hẹn:
              {` ${examinationData.Id}`}
            </h4>
          </CardBody>

          <Row xs="2">
            <BorderedCol>
              <CardText>Họ và tên</CardText>
            </BorderedCol>
            <BorderedCol>
              <CardText>{examinationData.Customer.FullName}</CardText>
            </BorderedCol>
            <Col>
              <CardText>Mã định danh</CardText>
            </Col>
            <Col>{/* <CardText></CardText> */}</Col>
            <Col>
              <CardText>Ngày sinh</CardText>
            </Col>
            <Col>
              <CardText>
                {auth.userInfo && moment(auth.userInfo.BirthDate).format('DD-MM-YYYY')}
              </CardText>
            </Col>
            <Col>
              <CardText>Điện thoại</CardText>
            </Col>
            <Col>
              <CardText>{examinationData.Customer.Phone}</CardText>
            </Col>
            <Col>
              <CardText>BHYT</CardText>
            </Col>
            <Col>
              <CardText>{examinationData.Customer.HealthInsurance}</CardText>
            </Col>
            <BorderedCol>
              <CardText>Loại hình</CardText>
            </BorderedCol>
            <BorderedCol>
              <CardText>{selectedServiceType.Name}</CardText>
            </BorderedCol>
            <Col>
              <CardText>Ngày</CardText>
            </Col>
            <Col>
              <StyledCardText>{examinationData.Detail.Time}</StyledCardText>
            </Col>
            <Col>
              <CardText>Giờ</CardText>
            </Col>
            <Col>
              <StyledCardText>
                {moment(examinationData.Detail.From, 'HH:mm:ss').format(
                  'HH:mm',
                )}
              </StyledCardText>
            </Col>
            <Col>
              <CardText>STT</CardText>
            </Col>
            <Col>{/* <CardText></CardText> */}</Col>
            <Col>
              <CardText>Sử dụng BHYT</CardText>
            </Col>
            <Col>
              <CardText>
                {examinationData.HasHealthInsurance ? 'Có' : 'Không'}
              </CardText>
            </Col>
            <Col>
              <CardText>Chuyên gia</CardText>
            </Col>
            <Col>
              <CardText>{examinationData.Doctor.FullName}</CardText>
            </Col>
            <Col>
              <CardText>Chuyên khoa</CardText>
            </Col>
            <Col>
              <CardText>{examinationData.Service.Name}</CardText>
            </Col>
            <Col>
              <CardText>Phòng</CardText>
            </Col>
            <Col>
              <CardText>{examinationData.Room.Name}</CardText>
            </Col>
            <BorderedCol>
              <CardText>Tổng phí</CardText>
            </BorderedCol>
            <BorderedCol>
              <CardText>{examinationData.Price}</CardText>
            </BorderedCol>
            <Col>
              <CardText>Thanh toán</CardText>
            </Col>
            <Col>
              {examinationData.PaymentStatus === null ? (
                <CardText className="text-danger font-weight-bold">
                  Chưa thanh toán
                </CardText>
              ) : (
                <CardText className="text-success font-weight-bold">
                  Đã thanh toán
                </CardText>
              )}
            </Col>
          </Row>
        </Card>
      </Row>
      <Row className="d-xs-block d-lg-none">
        <Col xs="12" md="4" className="text-center mx-auto">
          <Button outline color="primary" className="mt-3" onClick={() => redirectToHistory()}>
            Thanh toán sau
          </Button>
        </Col>
        <Col xs="12" md="4" className="text-center mx-auto">
          <Button outline color="info" className="mx-3 mt-3">
            Thanh toán ngay
          </Button>
        </Col>
        <Col xs="12" md="4" className="text-center mx-auto">
          <Button
            outline
            color="danger"
            className="mt-3"
            onClick={() => dispatch(showConfirmModal(`Xác nhận hủy phiếu hẹn số ${examinationData.Id}?`, () => {
              dispatch(deleteExamination(examinationData.Id)).then(() => {
                addToast('Hủy phiếu hẹn thành công', { appearance: 'success' });
                dispatch(resetBooking());
              });
            }))}
          >
            Hủy phiếu hẹn
          </Button>
        </Col>
      </Row>
      <Row xs="1" className="d-none d-lg-block justify-content-center pt-3">
        <Col className="text-center mx-auto">
          <Button outline color="primary" onClick={() => redirectToHistory()}>
            Thanh toán sau
          </Button>
          <Button outline color="info" className="mx-3">
            Thanh toán ngay
          </Button>
          <Button
            outline
            color="danger"
            onClick={() => dispatch(showConfirmModal(`Xác nhận hủy phiếu hẹn số ${examinationData.Id}?`, () => {
              dispatch(deleteExamination(examinationData.Id)).then(() => {
                addToast('Hủy phiếu hẹn thành công', { appearance: 'success' });
                dispatch(resetBooking());
              });
            }))}
          >
            Hủy phiếu hẹn
          </Button>
        </Col>
      </Row>
    </>
  );
};

Appointment.propTypes = {
  /** react-router-dom history object */
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(Appointment);
