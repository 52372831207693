export const MCB_SET_STEP = 'MCB_SET_STEP';
export const MCB_SET_INSURANCE = 'MCB_SET_INSURANCE';
export const MCB_SELECT_SERVICE = 'MCB_SELECT_SERVICE';
export const MCB_ADD_PATIENT_MODAL = 'MCB_ADD_PATIENT_MODAL';
export const MCB_SELECT_CLINIC = 'MCB_SELECT_CLINIC';
export const MCB_SELECT_PATIENT = 'MCB_SELECT_PATIENT';
export const MCB_SELECT_EXPERT = 'MCB_SELECT_EXPERT';
export const MCB_SELECT_SERVICE_TYPE = 'MCB_SELECT_SERVICE_TYPE';
export const MCB_SELECT_DATE = 'MCB_SELECT_DATE';

// get service type
export const MCB_GET_SERVICE_TYPE_REQUEST = 'MCB_GET_SERVICE_TYPE_REQUEST';
export const MCB_GET_SERVICE_TYPE_SUCCESS = 'MCB_GET_SERVICE_TYPE_SUCCESS';
export const MCB_GET_SERVICE_TYPE_FAILURE = 'MCB_GET_SERVICE_TYPE_FAILURE';

// get health care by hospital
export const MCB_GET_HEALTHCARE_BY_HOSPITAL_REQUEST = 'MCB_GET_HEALTHCARE_BY_HOSPITAL_REQUEST';
export const MCB_GET_HEALTHCARE_BY_HOSPITAL_SUCCESS = 'MCB_GET_HEALTHCARE_BY_HOSPITAL_SUCCESS';
export const MCB_GET_HEALTHCARE_BY_HOSPITAL_FAILURE = 'MCB_GET_HEALTHCARE_BY_HOSPITAL_FAILURE';

// get health care schedule
export const MCB_GET_HEALTHCARE_SCHEDULE_REQUEST = 'MCB_GET_HEALTHCARE_SCHEDULE_REQUEST';
export const MCB_GET_HEALTHCARE_SCHEDULE_SUCCESS = 'MCB_GET_HEALTHCARE_SCHEDULE_SUCCESS';
export const MCB_GET_HEALTHCARE_SCHEDULE_FAILURE = 'MCB_GET_HEALTHCARE_SCHEDULE_FAILURE';

// get health care schedule by doctor
export const MCB_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_REQUEST = 'MCB_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_REQUEST';
export const MCB_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_SUCCESS = 'MCB_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_SUCCESS';
export const MCB_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_FAILURE = 'MCB_GET_HEALTHCARE_SCHEDULE_BY_DOCTOR_FAILURE';

export const MCB_GET_DOCTOR_REQUEST = 'MCB_GET_DOCTOR_REQUEST';
export const MCB_GET_DOCTOR_SUCCESS = 'MCB_GET_DOCTOR_SUCCESS';
export const MCB_GET_DOCTOR_FAILURE = 'MCB_GET_DOCTOR_FAILURE';

export const MCB_GET_SERIVCE_BY_DOCTOR_REQUEST = 'MCB_GET_SERIVCE_BY_DOCTOR_REQUEST';
export const MCB_GET_SERIVCE_BY_DOCTOR_SUCCESS = 'MCB_GET_SERIVCE_BY_DOCTOR_SUCCESS';
export const MCB_GET_SERIVCE_BY_DOCTOR_FAILURE = 'MCB_GET_SERIVCE_BY_DOCTOR_FAILURE';

export const MCB_CREATE_EXAMINATION_REQUEST = 'MCB_CREATE_EXAMINATION_REQUEST';
export const MCB_CREATE_EXAMINATION_SUCCESS = 'MCB_CREATE_EXAMINATION_SUCCESS';
export const MCB_CREATE_EXAMINATION_FAILURE = 'MCB_CREATE_EXAMINATION_FAILURE';

export const MCB_DELETE_EXAMINATION_REQUEST = 'MCB_DELETE_EXAMINATION_REQUEST';
export const MCB_DELETE_EXAMINATION_SUCCESS = 'MCB_DELETE_EXAMINATION_SUCCESS';
export const MCB_DELETE_EXAMINATION_FAILURE = 'MCB_DELETE_EXAMINATION_FAILURE';

export const MCB_RESET = 'MCB_RESET';
