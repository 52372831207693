import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import auth from 'app/utils/auth';

const LandingPage = (props) => {
  const { history } = props;

  useEffect(() => {
    if (history) {
      history.push(auth.isAuthenticated() ? '/home' : '/login');
    }
  }, [history]);

  return (
    <div>
      ...
    </div>
  );
};

LandingPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default LandingPage;
