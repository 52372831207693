import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Table,
  Label,
  CustomInput,
} from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import nations from 'app/mock/nations';

const col1 = [
  {
    key: 1,
    text: 'Có tiếp xúc với trường hợp bệnh hoặc nghi ngờ mắc bệnh COVID-19?',
  },
  { key: 2, text: 'Có đi từ vùng có dịch bệnh COVID-19?' },
  { key: 3, text: 'Có tiếp xúc với trường hợp đi về từ vùng dịch?' },
];

const col2 = [
  { key: 4, text: 'Sốt' },
  { key: 5, text: 'Ho' },
  { key: 6, text: 'Khó thở' },
  { key: 7, text: 'Viêm phổi' },
  { key: 8, text: 'Đau họng' },
  { key: 9, text: 'Mệt mỏi' },
];

const StyledSwitch = styled(CustomInput)`
  display: inline;
`;

const { animatedComponents } = makeAnimated();

const CovidTable = (props) => {
  const {
    onCovidChange,
    onNationsChange,
  } = props;

  const [haveSymptom, setHaveSymptom] = useState([]);
  useEffect(() => {
    onCovidChange(haveSymptom);
  }, [haveSymptom, onCovidChange]);

  return (
    <Table hover size="sm" className="mb-0">
      <thead>
        <tr>
          <th>#</th>
          <th>Câu hỏi</th>
          <th>Trả lời</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th colSpan={3}>Trong vòng 14 ngày qua, Anh/Chị:</th>
        </tr>
        {col1.map((c) => (
          <tr key={c.key}>
            <th scope="row">{c.key}</th>
            <td>{c.text}</td>
            <td>
              <Label for={`covid${c.key}`}>Không</Label>
              <StyledSwitch
                type="switch"
                id={`covid${c.key}`}
                name={`covid${c.key}`}
                className="ml-2"
                onChange={(e) => {
                  if (e.target.checked) {
                    setHaveSymptom([
                      ...haveSymptom,
                      c.key,
                    ]);
                  } else {
                    setHaveSymptom(haveSymptom.filter((s) => s !== c.key));
                  }
                }}
              />
              <Label for={`covid${c.key}`}>Có</Label>
            </td>
          </tr>
        ))}
        <tr>
          <th colSpan={3}>Trong vòng 14 ngày, Anh/Chị có thấy xuất hiện dấu hiệu nào sau đây không?</th>
        </tr>
        {col2.map((c) => (
          <tr key={c.key}>
            <th scope="row">{c.key}</th>
            <td>{c.text}</td>
            <td>
              <Label for={`covid${c.key}`}>Không</Label>
              <StyledSwitch
                type="switch"
                id={`covid${c.key}`}
                name={`covid${c.key}`}
                className="ml-2"
                onChange={(e) => {
                  if (e.target.checked) {
                    setHaveSymptom([
                      ...haveSymptom,
                      c.key,
                    ]);
                  } else {
                    setHaveSymptom(haveSymptom.filter((s) => s !== c.key));
                  }
                }}
              />
              <Label for={`covid${c.key}`}>Có</Label>
            </td>
          </tr>
        ))}
        <tr>
          <th colSpan={3}>Trong 14 ngày, Anh/Chị có đến quốc gia/vùng lãnh thổ nào (có thể đi qua nhiều quốc gia):</th>
        </tr>
        <tr>
          <td colSpan={3}>
            <Select
              isMulti
              isSearchable
              isClearable
              components={animatedComponents}
              placeholder="Quốc gia"
              noOptionsMessage={() => <span>Không tìm thấy</span>}
              onChange={onNationsChange}
              options={nations.map((n) => ({
                value: n.countryCode,
                label: n.name,
              }))}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

CovidTable.propTypes = {
  onCovidChange: PropTypes.func,
  onNationsChange: PropTypes.func,
};

CovidTable.defaultProps = {
  onCovidChange: () => {},
  onNationsChange: () => {},
};

export default CovidTable;
