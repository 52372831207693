import moment from 'moment';
import types from '../actions/types';

const initialState = {
  currentStep: 0,

  profileList: [],
  diseaseList: [],
  vaccineList: [],
  vaccineInjectionList: [],
  injectionObjectList: [],
  healthCareList: [],
  clinicList: [],
  scheduleList: [],
  ticketList: [],
  ticketListView: [],
  historyList: [],
  registerData: null,
  // appointmentData: {"Id":1459,"DoctorId":4636,"DoctorName":"Nguyễn Thị Hoa","ServiceId":2327,"ServiceName":"5 trong 1: Bệnh Bạch hầu-Uốn ván-Ho gà-Viêm gan B-Viêm phổi/viêm màng não mủ do vi khuẩn Hib","HospitalId":2105,"HospitalName":"Trung tâm y tế Tân Phú Vườn Lài","HospitalInfo":{"Address":"","Email":"","Website":"","Phone":""},"RoomId":1137,"RoomName":"Buồng 01","InstanceId":594029,"IsDeleted":false,"DateCreated":"2020-07-01T00:00:00","Status":1,"CustomerId":5431,"Customer":{"Id":5431,"FullName":"Lâm Gia Hân","Address":"108/1H Đặng","BirthDate":"2014-01-31T00:00:00","Phone":"","ImmunizationCode":""},"Form":null,"BookedUser":"NguyenVanDo002","Schedule":{"Id":594029,"Date":"2020-07-02T00:00:00","Time":"07:00:00"},"SecondaryDisease":"","ContactInfo":[{"Name":"Đặng Xuân Anh","Phone":"0366997840","Relationship":"Bà"}],"ReferenceId":null},
  appointmentData: null,
  loadingGetProfiles: false,
  loadingGetDiseases: false,
  loadingGetVaccines: false,
  loadingGetVaccineInjection: false,
  loadingGetInjectionObjects: false,
  loadingGetHealthcare: false,
  loadingGetByHealthcare: false,
  loadingGetSchedule: false,
  loadingGetTickets: false,
  loadingRegister: false,
  loadingGetAppointment: false,
  loadingUpdateAppointment: false,
  loadingGetHistory: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.IMMUNIZATION_SET_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case types.IMMUNIZATION_GET_PROFILES_REQUEST:
      return {
        ...state,
        loadingGetProfiles: true,
      };
    case types.IMMUNIZATION_GET_PROFILES_SUCCESS: {
      const response = action.payload;
      let result = [];
      result = response.map((e) => {
        const { BirthDate } = e;
        const today = moment();
        const diff = today.diff(BirthDate, 'days');
        return {
          ...e,
          diff,
        };
      });
      return {
        ...state,
        loadingGetProfiles: false,
        profileList: result,
      };
    }
    case types.IMMUNIZATION_GET_PROFILES_FAILURE:
      return {
        ...state,
        loadingGetProfiles: false,
      };
    case types.IMMUNIZATION_GET_DISEASES_REQUEST:
      return {
        ...state,
        loadingGetDiseases: true,
      };
    case types.IMMUNIZATION_GET_DISEASES_SUCCESS:
      return {
        ...state,
        loadingGetDiseases: false,
        diseaseList: action.payload,
      };
    case types.IMMUNIZATION_GET_DISEASES_FAILURE:
      return {
        ...state,
        loadingGetDiseases: false,
      };
    case types.IMMUNIZATION_GET_VACCINES_REQUEST:
      return {
        ...state,
        loadingGetVaccines: true,
      };
    case types.IMMUNIZATION_GET_VACCINES_SUCCESS:
      return {
        ...state,
        loadingGetVaccines: false,
        vaccineList: action.payload,
      };
    case types.IMMUNIZATION_GET_VACCINES_FAILURE:
      return {
        ...state,
        loadingGetVaccines: false,
      };
    case types.IMMUNIZATION_GET_VACCINE_INJECTION_REQUEST:
      return {
        ...state,
        loadingGetVaccineInjection: true,
      };
    case types.IMMUNIZATION_GET_VACCINE_INJECTION_SUCCESS:
      return {
        ...state,
        loadingGetVaccineInjection: false,
        vaccineInjectionList: action.payload,
      };
    case types.IMMUNIZATION_GET_VACCINE_INJECTION_FAILURE:
      return {
        ...state,
        loadingGetVaccineInjection: false,
      };
    case types.IMMUNIZATION_GET_INJECTION_REQUEST:
      return {
        ...state,
        loadingGetInjectionObjects: true,
      };
    case types.IMMUNIZATION_GET_INJECTION_SUCCESS:
      return {
        ...state,
        loadingGetInjectionObjects: false,
        injectionObjectList: action.payload,
      };
    case types.IMMUNIZATION_GET_INJECTION_FAILURE:
      return {
        ...state,
        loadingGetInjectionObjects: false,
      };
    case types.IMMUNIZATION_GET_HEALTHCARE_REQUEST:
      return {
        ...state,
        loadingGetHealthcare: true,
      };
    case types.IMMUNIZATION_GET_HEALTHCARE_SUCCESS:
      return {
        ...state,
        loadingGetHealthcare: false,
        healthCareList: action.payload,
      };
    case types.IMMUNIZATION_GET_HEALTHCARE_FAILURE:
      return {
        ...state,
        loadingGetHealthcare: false,
      };
    case types.IMMUNIZATION_GET_CLINIC_REQUEST:
      return {
        ...state,
        loadingGetByHealthcare: true,
      };
    case types.IMMUNIZATION_GET_CLINIC_SUCCESS:
      return {
        ...state,
        loadingGetByHealthcare: false,
        clinicList: action.payload,
      };
    case types.IMMUNIZATION_GET_CLINIC_FAILURE:
      return {
        ...state,
        loadingGetByHealthcare: false,
      };
    case types.IMMUNIZATION_GET_SCHEDULE_REQUEST:
      return {
        ...state,
        loadingGetSchedule: true,
      };
    case types.IMMUNIZATION_GET_SCHEDULE_SUCCESS:
      return {
        ...state,
        loadingGetSchedule: false,
        scheduleList: action.payload,
      };
    case types.IMMUNIZATION_GET_SCHEDULE_FAILURE:
      return {
        ...state,
        loadingGetSchedule: false,
      };
    case types.IMMUNIZATION_REGISTER_REQUEST:
      return {
        ...state,
        loadingRegister: true,
      };
    case types.IMMUNIZATION_REGISTER_SUCCESS:
      return {
        ...state,
        loadingRegister: false,
        registerData: action.payload,
      };
    case types.IMMUNIZATION_REGISTER_FAILURE:
      return {
        ...state,
        loadingRegister: false,
      };
    case types.IMMUNIZATION_GET_APPOINTMENT_REQUEST:
      return {
        ...state,
        loadingGetAppointment: true,
      };
    case types.IMMUNIZATION_GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loadingGetAppointment: false,
        appointmentData: action.payload,
      };
    case types.IMMUNIZATION_GET_APPOINTMENT_FAILURE:
      return {
        ...state,
        loadingGetAppointment: false,
      };
    case types.IMMUNIZATION_GET_TICKETS_REQUEST:
      return {
        ...state,
        loadingGetTickets: true,
      };
    case types.IMMUNIZATION_GET_TICKETS_SUCCESS:
      return {
        ...state,
        loadingGetTickets: false,
        ticketList: action.payload,
        ticketListView: [...new Set(action.payload.map((t) => t.time))].sort(),
      };
    case types.IMMUNIZATION_GET_TICKETS_FAILURE:
      return {
        ...state,
        loadingGetTickets: false,
      };
    case types.IMMUNIZATION_GET_HISTORY_REQUEST:
      return {
        ...state,
        loadingGetHistory: true,
      };
    case types.IMMUNIZATION_GET_HISTORY_SUCCESS:
      return {
        ...state,
        loadingGetHistory: false,
        historyList: action.payload,
      };
    case types.IMMUNIZATION_GET_HISTORY_FAILURE:
      return {
        ...state,
        loadingGetHistory: false,
      };
    case types.IMMUNIZATION_UPDATE_APPOINTMENT_REQUEST:
      return {
        ...state,
        loadingUpdateAppointment: true,
      };
    case types.IMMUNIZATION_UPDATE_APPOINTMENT_SUCCESS:
    case types.IMMUNIZATION_UPDATE_APPOINTMENT_FAILURE:
      return {
        ...state,
        loadingUpdateAppointment: false,
      };
    case types.IMMUNIZATION_RESET:
      return {
        ...state,
        currentStep: 0,
        registerData: null,
        appointmentData: null,
      };
    default:
      return state;
  }
}
