export const HISTORY_GET_MCB_SCHEDULER_REQUEST = 'HISTORY_GET_MCB_SCHEDULER_REQUEST';
export const HISTORY_GET_MCB_SCHEDULER_SUCCESS = 'HISTORY_GET_MCB_SCHEDULER_SUCCESS';
export const HISTORY_GET_MCB_SCHEDULER_FAILURE = 'HISTORY_GET_MCB_SCHEDULER_FAILURE';

export const HISTORY_GET_TELE_SCHEDULER_REQUEST = 'HISTORY_GET_TELE_SCHEDULER_REQUEST';
export const HISTORY_GET_TELE_SCHEDULER_FAILURE = 'HISTORY_GET_TELE_SCHEDULER_FAILURE';
export const HISTORY_GET_TELE_SCHEDULER_SUCCESS = 'HISTORY_GET_TELE_SCHEDULER_SUCCESS';

// export const HISTORY_GET_PAYMENT_STATUS_REQUEST = 'HISTORY_GET_PAYMENT_STATUS_REQUEST';
// export const HISTORY_GET_PAYMENT_STATUS_FAILURE = 'HISTORY_GET_PAYMENT_STATUS_FAILURE';
// export const HISTORY_GET_PAYMENT_STATUS_SUCCESS = 'HISTORY_GET_PAYMENT_STATUS_SUCCESS';
