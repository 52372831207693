/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  CardImg,
  Card,
  CardBody,
  CardSubtitle,
  Button,
  Alert,
} from 'reactstrap';

import { useSelector } from 'react-redux';
import apiLinks from 'app/utils/api-links';

import HospitalFilter from './HospitalFilter';

const StyledCol4 = styled(Col)`
  border-right: 1px solid #b6b6b6;
`;

const StyledCardBody = styled(CardBody)`
  height: 57px !important;
  padding-left: .25rem !important;
  padding-right: .25rem !important;
  padding-top: .7rem !important;
  border-top: 1px dashed rgba(0,0,0,.2);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const StyledCardImg = styled(CardImg)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100px;
`;

const StyledCard = styled(Card)`
  border: ${(props) => (props.active ? '2px solid rgb(226, 107, 71)' : '')};
  cursor: pointer;
`;

const HospitalModal = (props) => {
  const { open, onClose, onSubmit } = props;
  const { hospitalList } = useSelector((state) => state.examinationBooking);

  const [filter, setFilter] = useState(null);
  const [hospital, setHospital] = useState(null);
  const [filterHospital, setFilterHospital] = useState(hospitalList);

  useEffect(() => {
    if (filter) {
      const { searchValue, selectingUnitType, selectedLocation } = filter;
      const result = hospitalList.filter((c) => (
        searchValue ? c.name.toLowerCase().includes(searchValue) : true)
        && (selectingUnitType ? c.unitTypeId === selectingUnitType?.value : true)
        && (selectedLocation?.province ? c.province == selectedLocation?.province?.value : true)
        && (selectedLocation?.district ? c.district == selectedLocation?.district?.value : true)
        && (selectedLocation?.ward ? c.ward == selectedLocation?.ward?.value : true));
      // );
      setFilterHospital(result);
    }
  }, [filter, hospitalList]);

  return (
    <Modal size="xl" isOpen={open} toggle={onClose}>
      <ModalHeader toggle={onClose}>Chọn cơ sở xét nghiệm</ModalHeader>
      <ModalBody>
        <Row>
          {/* hide filter */}
          <StyledCol4 sm={4} className="mt-3">
            <HospitalFilter filter={filter} onChange={setFilter} />
          </StyledCol4>
          <Col sm={8} className="mt-3">
            <Row>
              {(filterHospital || []).map((e) => (
                <Col xs="6" lg="4" key={e.id} className="mb-3">
                  <StyledCard
                    active={hospital !== null && hospital.id === e.id ? 1 : 0}
                    onClick={() => setHospital(e)}
                  >
                    <ImageWrapper>
                      <StyledCardImg src={`${apiLinks.gateway.hospitals.getLogo}/${e.id}`} alt="Hospital Image" />
                    </ImageWrapper>
                    <StyledCardBody>
                      <CardSubtitle>{e.name}</CardSubtitle>
                    </StyledCardBody>
                  </StyledCard>
                </Col>
              ))}
            </Row>
            {filterHospital.length === 0 && filter && (
              <Alert color="danger">
                Không tìm thấy cơ sở phù hợp
              </Alert>
            )}
            {filterHospital.length > 0 && (
              <Row>
                <Col sm={12} className="text-left">
                  <Button
                    color="info"
                    disabled={!hospital}
                    onClick={() => onSubmit(hospital)}
                  >
                    Chọn
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

HospitalModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

HospitalModal.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
};

export default HospitalModal;
