import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import { Spinner } from 'reactstrap';

import { getDoctorByForm, selectExpert, selectServiceType } from '../actions/homecare';

import ButtonFragment from './ButtonFragment';

const StyledSelect = styled(Select)`
  max-width: 350px;
  width: 100%;
  background-color: transparent;
`;

const ExpertSelect = () => {
  const { selectedClinic, selectedServiceType, listDoctors } = useSelector(
    (state) => state.homecare,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDoctorByForm(selectedClinic, selectedServiceType));
    // eslint-disable-next-line
  }, [dispatch, selectedClinic, selectedServiceType]);

  const [selectedExpert, setSelectedExpert] = useState(null);
  const [listServicesByDoctor, setListServicesByDoctor] = useState([]);

  useEffect(() => {
    const tmp = listDoctors.flatMap((d) => (d.listServices
      ? d.listServices.map((s) => ({
        label: `${d.FullName} - ${s.Name} - ${s.Price}đ`,
        doctorId: d.Id,
        serviceId: s.Id,
        value: `${d.Id}${s.Id}`,
      }))
      : []
    ));
    setListServicesByDoctor(tmp);
  }, [listDoctors]);

  return (
    <div className="w-100">
      <StyledSelect
        isDisabled={listServicesByDoctor.length === 0}
        options={listServicesByDoctor}
        className="basic-single mb-2"
        classNamePrefix="select"
        value={listServicesByDoctor.value}
        name="color"
        placeholder="Chọn chuyên gia"
        isSearchable
        onChange={(selected) => setSelectedExpert(selected)}
      />
      {listServicesByDoctor.length === 0 ? (
        <Spinner color="info" />
      ) : (
        <ButtonFragment
          onGoNext={() => dispatch(selectExpert(selectedExpert))}
          onGoBack={() => dispatch(selectServiceType(null))}
          data={selectedExpert}
        />
      )}
    </div>
  );
};

export default ExpertSelect;
