// auth
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const LOG_IN_REQUEST = 'LOG_IN_REQUEST';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';

export const SET_TOKEN = 'SET_TOKEN';

export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';

export const LOG_OUT = 'LOG_OUT';

// sidebar
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const ADD_PATIENT_MODAL = 'ADD_PATIENT_MODAL';

export const SHOW_CONFIRM_MODAL = 'SHOW_CONFIRM_MODAL';
export const SET_HISTORY_TAB = 'SET_HISTORY_TAB';

export const GET_HOSPITAL_BY_FORM_REQUEST = 'GET_SERVICE_BY_FORM_REQUEST';
export const GET_HOSPITAL_BY_FORM_SUCCESS = 'GET_SERVICE_BY_FORM_SUCCESS';
export const GET_HOSPITAL_BY_FORM_FAILURE = 'GET_SERVICE_BY_FORM_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';

export const ADD_RELATIVE_REQUEST = 'ADD_RELATIVE_REQUEST';
export const ADD_RELATIVE_SUCCESS = 'ADD_RELATIVE_SUCCESS';
export const ADD_RELATIVE_FAILURE = 'ADD_RELATIVE_FAILURE';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';

export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';

export default {
  ADD_PROFILE_MODAL: 'ADD_PROFILE_MODAL',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  CREATE_ACCOUNT_V2_REQUEST: 'CREATE_ACCOUNT_V2_REQUEST',
  CREATE_ACCOUNT_V2_SUCCESS: 'CREATE_ACCOUNT_V2_SUCCESS',
  CREATE_ACCOUNT_V2_FAILURE: 'CREATE_ACCOUNT_V2_FAILURE',

  CREATE_PROFILE_REQUEST: 'CREATE_PROFILE_REQUEST',
  CREATE_PROFILE_SUCCESS: 'CREATE_PROFILE_SUCCESS',
  CREATE_PROFILE_FAILURE: 'CREATE_PROFILE_FAILURE',

  UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

  GET_PROFILES_REQUEST: 'GET_PROFILES_REQUEST',
  GET_PROFILES_SUCCESS: 'GET_PROFILES_SUCCESS',
  GET_PROFILES_FAILURE: 'GET_PROFILES_FAILURE',

  GET_SERVICE_FORM_REQUEST: 'GET_SERVICE_FORM_REQUEST',
  GET_SERVICE_FORM_SUCCESS: 'GET_SERVICE_FORM_SUCCESS',
  GET_SERVICE_FORM_FAILURE: 'GET_SERVICE_FORM_FAILURE',

  GET_SERVICE_TYPE_REQUEST: 'GET_SERVICE_TYPE_REQUEST',
  GET_SERVICE_TYPE_SUCCESS: 'GET_SERVICE_TYPE_SUCCESS',
  GET_SERVICE_TYPE_FAILURE: 'GET_SERVICE_TYPE_FAILURE',
};
