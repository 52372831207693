import types, {
  TOGGLE_SIDEBAR,
  GET_HOSPITAL_BY_FORM_REQUEST,
  GET_HOSPITAL_BY_FORM_SUCCESS,
  GET_HOSPITAL_BY_FORM_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  ADD_PATIENT_MODAL,
  SHOW_CONFIRM_MODAL,
  SET_HISTORY_TAB,
  ADD_RELATIVE_REQUEST,
  ADD_RELATIVE_SUCCESS,
  ADD_RELATIVE_FAILURE,
  CREATE_ACCOUNT_REQUEST,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILURE,
} from 'app/actions/types';
import httpClient from 'app/utils/http-client';
import apiLinks from 'app/utils/api-links';

// toggle sidebar
const toggleSidebar = () => ({ type: TOGGLE_SIDEBAR });
// add patient modal
const toggleAddPatientModal = () => ({ type: ADD_PATIENT_MODAL });
const toggleAddProfileModal = () => ({ type: types.ADD_PROFILE_MODAL });

const showConfirmModal = (message, confirmCallback) => ({ type: SHOW_CONFIRM_MODAL, payload: { message, confirmCallback } });

const setHistoryTab = (tabId) => ({ type: SET_HISTORY_TAB, payload: tabId });

// forget password
const forgotPasswordRequest = () => ({ type: FORGOT_PASSWORD_REQUEST });
const forgotPasswordSuccess = (response) => ({ type: FORGOT_PASSWORD_SUCCESS, payload: response });
const forgotPasswordFailure = (error) => ({ type: FORGOT_PASSWORD_FAILURE, payload: error });

const forgotPassword = (email, username) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(forgotPasswordRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.forgotPassword,
    params: {
      Type: 0,
      Email: email,
      Username: username,
    },
  }).then((response) => {
    dispatch(forgotPasswordSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(forgotPasswordFailure(error));
    reject();
  });
});

// create customer
const createCustomerRequest = () => ({ type: CREATE_CUSTOMER_REQUEST });
const createCustomerSuccess = (response) => ({ type: CREATE_CUSTOMER_SUCCESS, payload: response });
const createCustomerFailure = (error) => ({ type: CREATE_CUSTOMER_FAILURE, payload: error });

const createCustomer = (data, customerId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(createCustomerRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.createBkCustomer,
    data,
    params: {
      customerId,
    },
  }).then((response) => {
    dispatch(createCustomerSuccess(response.data));
    resolve(response.data);
  }).catch((error) => {
    const { Message } = error.response.data;
    dispatch(createCustomerFailure(Message));
    reject();
  });
});

// create account
const createAccountRequest = () => ({ type: CREATE_ACCOUNT_REQUEST });
const createAccountSuccess = (response) => ({ type: CREATE_ACCOUNT_SUCCESS, payload: response });
const createAccountFailure = (error) => ({ type: CREATE_ACCOUNT_FAILURE, payload: error });

const createAccount = (data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(createAccountRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.createAccount,
    data,
  }).then((response) => {
    dispatch(createAccountSuccess(response.data));
    resolve(response.data);
  }).catch((error) => {
    const { Message } = error.response.data;
    dispatch(createAccountFailure(Message));
    reject();
  });
});

// change password account
const changePasswordRequest = () => ({ type: types.CHANGE_PASSWORD_REQUEST });
const changePasswordSuccess = (response) => ({ type: types.CHANGE_PASSWORD_SUCCESS, payload: response });
const changePasswordFailure = (error) => ({ type: types.CHANGE_PASSWORD_FAILURE, payload: error });

const changePassword = (data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(changePasswordRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.changePassword,
    data,
  }).then((response) => {
    dispatch(changePasswordSuccess(response.data));
    resolve(response.data);
  }).catch((error) => {
    const { Message, ModelState } = error.response.data;
    dispatch(changePasswordFailure({ Message, ModelState }));
    reject();
  });
});

// update customer
const updateCustomerRequest = () => ({ type: UPDATE_CUSTOMER_REQUEST });
const updateCustomerSuccess = (response) => ({ type: UPDATE_CUSTOMER_SUCCESS, payload: response });
const updateCustomerFailure = (error) => ({ type: UPDATE_CUSTOMER_FAILURE, payload: error });

const updateCustomer = (data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(updateCustomerRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.updateBkCustomer,
    data,
  }).then((response) => {
    dispatch(updateCustomerSuccess(response.data));
    resolve(response.data);
  }).catch((error) => {
    const { Message } = error.response.data;
    dispatch(updateCustomerFailure(Message));
    reject();
  });
});

// add relative
const addRelativeRequest = () => ({ type: ADD_RELATIVE_REQUEST });
const addRelativeSuccess = (response) => ({ type: ADD_RELATIVE_SUCCESS, payload: response });
const addRelativeFailure = (error) => ({ type: ADD_RELATIVE_FAILURE, payload: error });

const addRelative = (CustomerId, ProfileId) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(addRelativeRequest());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.addProfiles,
    params: {
      CustomerId,
      ProfileId,
    },
  }).then((response) => {
    dispatch(addRelativeSuccess(response.data));
    resolve(response.data);
  }).catch((error) => {
    dispatch(addRelativeFailure(error));
    reject();
  });
});


// get by form
const getHospitalByFormRequest = () => ({ type: GET_HOSPITAL_BY_FORM_REQUEST });
const getHospitalByFormSuccess = (response) => ({ type: GET_HOSPITAL_BY_FORM_SUCCESS, payload: response });
const getHospitalByFormFailure = (error) => ({ type: GET_HOSPITAL_BY_FORM_FAILURE, payload: error });

const getHospitalByForm = (id) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getHospitalByFormRequest());
  httpClient.callApi({
    url: apiLinks.getHospitalByForm,
    params: {
      form: id,
    },
  }).then((response) => {
    dispatch(getHospitalByFormSuccess(response.data));
    resolve();
  }).catch((error) => {
    dispatch(getHospitalByFormFailure(error));
    reject();
  });
});

// create customer
const createProfileV2Request = () => ({ type: types.CREATE_PROFILE_REQUEST });
const createProfileV2Success = (response) => ({ type: types.CREATE_PROFILE_SUCCESS, payload: response });
const createProfileV2Failure = (error) => ({ type: types.CREATE_PROFILE_FAILURE, payload: error });

const createProfileV2 = (data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(createProfileV2Request());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.gateway.profiles.create,
    data,
  }).then((response) => {
    dispatch(createProfileV2Success(response.data));
    resolve(response.data);
  }).catch((error) => {
    const { Message } = error.response.data;
    dispatch(createProfileV2Failure(Message));
    reject();
  });
});

// create account
const createAccountV2Request = () => ({ type: types.CREATE_ACCOUNT_V2_REQUEST });
const createAccountV2Success = (response) => ({ type: types.CREATE_ACCOUNT_V2_SUCCESS, payload: response });
const createAccountV2Failure = (error) => ({ type: types.CREATE_ACCOUNT_V2_FAILURE, payload: error });

const createAccountV2 = (data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(createAccountV2Request());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.gateway.users.create,
    data,
  }).then((response) => {
    dispatch(createAccountV2Success(response.data));
    resolve(response.data);
  }).catch((error) => {
    const { Message } = error.response.data;
    dispatch(createAccountV2Failure(Message));
    reject();
  });
});

const changePasswordV2Request = () => ({ type: types.CHANGE_PASSWORD_REQUEST });
const changePasswordV2Success = (response) => ({ type: types.CHANGE_PASSWORD_SUCCESS, payload: response });
const changePasswordV2Failure = (error) => ({ type: types.CHANGE_PASSWORD_FAILURE, payload: error });

const changePasswordV2 = (data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(changePasswordV2Request());
  httpClient.callApi({
    method: 'POST',
    url: apiLinks.gateway.users.changePassword,
    data,
  }).then((response) => {
    dispatch(changePasswordV2Success(response.data));
    resolve(response.data);
  }).catch((error) => {
    const { Message, ModelState } = error.response.data;
    dispatch(changePasswordV2Failure({ Message, ModelState }));
    reject();
  });
});

// update customer
const updateProfileV2Request = () => ({ type: UPDATE_CUSTOMER_REQUEST });
const updateProfileV2Success = (response) => ({ type: UPDATE_CUSTOMER_SUCCESS, payload: response });
const updateProfileV2Failure = (error) => ({ type: UPDATE_CUSTOMER_FAILURE, payload: error });

const updateProfileV2 = (data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(updateProfileV2Request());
  httpClient.callApi({
    method: 'PUT',
    url: apiLinks.gateway.profiles.update,
    data,
  }).then((response) => {
    dispatch(updateProfileV2Success(response.data));
    resolve(response.data);
  }).catch((error) => {
    const { Message } = error.response.data;
    dispatch(updateProfileV2Failure(Message));
    reject();
  });
});

const getServiceFormRequest = () => ({ type: types.GET_SERVICE_FORM_REQUEST });
const getServiceFormSuccess = (response, serviceForm) => ({ type: types.GET_SERVICE_FORM_SUCCESS, payload: { response, serviceForm } });
const getServiceFormFailure = (error) => ({ type: types.GET_SERVICE_FORM_FAILURE, payload: error });

const getServiceForm = (serviceForm) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getServiceFormRequest());
  httpClient.callApi({
    url: apiLinks.gateway.serviceForms.get,
  }).then((response) => {
    dispatch(getServiceFormSuccess(response.data, serviceForm));
    resolve();
  }).catch((error) => {
    dispatch(getServiceFormFailure(error));
    reject();
  });
});

export {
  toggleSidebar,
  forgotPassword,
  createCustomer,
  updateCustomer,
  createAccount,
  changePassword,
  addRelative,
  setHistoryTab,
  toggleAddPatientModal,
  getHospitalByForm,
  showConfirmModal,
  toggleAddProfileModal,
  createAccountV2,
  changePasswordV2,
  createProfileV2,
  updateProfileV2,
  getServiceForm,
};
