import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Form,
  CardImg,
  FormFeedback,
  Spinner,
  CardTitle,
} from 'reactstrap';

import {
  FaUserAlt,
  FaEnvelope,
  FaRegQuestionCircle,
  FaLock,
} from 'react-icons/fa';

import styled from 'styled-components';

import { useSelector, useDispatch } from 'react-redux';

import logo from '../assets/images/color-logo.png';
import background from '../assets/images/hcdc-background.jpg';

const Wrapper = styled.div`
  background-image: url(${background});
  height: 100vh;
  width: 100vw;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const StyledCard = styled(Card)`
  width: 400px;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.9);
`;

const StyledImg = styled(CardImg)`
  padding: 15px;
`;

const StyledCardTitle = styled(CardTitle)`
  color: rgb(226, 107, 71) !important;
  font-size: 20px;
  text-align: center;
  font-weight: 300;
`;

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const { forgotPasswordLoading } = useSelector((state) => state.auth);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [forgotPasswordFailed, setForgotPasswordFailed] = useState(false);
  const [notMatch, setNotMatch] = useState(false);

  const forgotPassword = (e) => {
    e.preventDefault();
    setForgotPasswordFailed(false);
    dispatch(forgotPassword(email, username));
  };

  return (
    <Wrapper>
      <StyledCard>
        <StyledImg top width="100%" src={logo} alt="Logo" />
        <CardBody className="pt-0">
          <StyledCardTitle>
            <FaRegQuestionCircle className="mb-1 mr-2" />
            Quên mật khẩu
          </StyledCardTitle>
          <Form onSubmit={(e) => forgotPassword(e)}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FaUserAlt />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                invalid={forgotPasswordFailed}
                placeholder="Tên đăng nhập"
                disabled={forgotPasswordLoading}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setForgotPasswordFailed(false);
                }}
              />
            </InputGroup>
            <InputGroup className="mt-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FaEnvelope />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="email"
                invalid={forgotPasswordFailed}
                placeholder="Email"
                disabled={forgotPasswordLoading}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setForgotPasswordFailed(false);
                }}
              />
            </InputGroup>
            <InputGroup className="mt-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FaLock />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="password"
                invalid={notMatch}
                placeholder="Mật khẩu mới"
                disabled={forgotPasswordLoading}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setNotMatch(false);
                }}
              />
            </InputGroup>
            <InputGroup className="mt-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <FaLock />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="password"
                invalid={notMatch}
                placeholder="Xác nhận mật khẩu mới"
                disabled={forgotPasswordLoading}
                onChange={(e) => {
                  setConfirm(e.target.value);
                }}
                onBlur={() => {
                  if (password && password !== confirm) {
                    setNotMatch(true);
                  } else {
                    setNotMatch(false);
                  }
                }}
              />
              {notMatch ? (
                <FormFeedback>
                  Mật khẩu xác nhận chưa chính xác
                </FormFeedback>
              ) : (
                <></>
              )}
            </InputGroup>
            <Button
              color="info"
              className="mt-3 w-100"
              disabled={forgotPasswordFailed}
            >
              {forgotPasswordLoading ? (
                <Spinner color="light" />
              ) : (
                <span>Tiếp tục</span>
              )}
            </Button>
          </Form>
        </CardBody>
      </StyledCard>
    </Wrapper>
  );
};

ForgotPasswordPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default ForgotPasswordPage;
