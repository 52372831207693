import React from 'react';

const EmergencyTab = (props) => {
  return (
    <div>
      EmergencyTab
    </div>
  );
};

export default EmergencyTab;
