import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Row,
} from 'reactstrap';
import {
  FaAddressBook,
  FaCity,
  FaMap,
  FaMapMarkerAlt,
} from 'react-icons/fa';
import Select from 'react-select';


import locations from 'app/mock/locations.json';
import ButtonFragment from 'homecare/components/ButtonFragment';
import { selectPatient, setAddress } from 'homecare/actions/homecare';

const AddressSelect = () => {
  const styles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const dispatch = useDispatch();
  const [addressDetail, setAddressDetail] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({
    province: null,
    district: null,
    ward: null,
  });

  return (
    <Row className="w-75">
      <Col md="6" xl="3" xs="12" className="mb-3">
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <FaAddressBook />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Số nhà, đường *"
            onChange={(e) => {
              setAddressDetail(e.target.value);
            }}
          />
        </InputGroup>
      </Col>
      <Col md="6" xl="3" xs="12" className="mb-3">
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <FaCity />
            </InputGroupText>
          </InputGroupAddon>
          <Select
            placeholder="Tỉnh/Thành phố"
            noOptionsMessage={() => <span>Không tìm thấy</span>}
            isSearchable
            styles={styles}
            onChange={(el) => setSelectedLocation({
              province: locations.find(
                (province) => province.Value === el.value,
              ),
            })}
            options={locations.map((province) => ({
              value: province.Value,
              label: province.Name,
            }))}
          />
        </InputGroup>
      </Col>
      <Col md="6" xl="3" xs="12" className="mb-3">
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <FaMap />
            </InputGroupText>
          </InputGroupAddon>
          <Select
            isDisabled={!selectedLocation.province}
            placeholder="Quận/Huyện *"
            noOptionsMessage={() => <span>Không tìm thấy</span>}
            isSearchable
            styles={styles}
            onChange={(el) => setSelectedLocation({
              ...selectedLocation,
              district: selectedLocation.province.Districts.find(
                (district) => district.Value === el.value,
              ),
            })}
            options={
              selectedLocation.province
              && selectedLocation.province.Districts.map((district) => ({
                value: district.Value,
                label: district.Name,
              }))
            }
          />
        </InputGroup>
      </Col>
      <Col md="6" xl="3" xs="12" className="mb-3">
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <FaMapMarkerAlt />
            </InputGroupText>
          </InputGroupAddon>
          <Select
            isDisabled={!selectedLocation.district}
            placeholder="Phường/Xã *"
            noOptionsMessage={() => <span>Không tìm thấy</span>}
            isSearchable
            styles={styles}
            onChange={(el) => {
              setSelectedLocation({
                ...selectedLocation,
                ward: selectedLocation.district.Wards.find(
                  (ward) => ward.Value === el.value,
                ),
              });
            }}
            options={
              selectedLocation.district
              && selectedLocation.district.Wards.map((ward) => ({
                value: ward.Value,
                label: ward.Name,
              }))
            }
          />
        </InputGroup>
      </Col>
      <Col xs="12">
        <ButtonFragment
          onGoNext={() => dispatch(setAddress({ addressDetail, selectedLocation }))}
          onGoBack={() => dispatch(selectPatient(null))}
          data={selectedLocation.ward}
        />
      </Col>
    </Row>
  );
};

export default AddressSelect;
