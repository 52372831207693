import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import { Spinner } from 'reactstrap';

import {
  getHealthCare,
  selectService,
  selectServiceType,
} from '../actions/homecare';

import ButtonFragment from './ButtonFragment';

const StyledSelect = styled(Select)`
  max-width: 350px;
  width: 100%;
  background-color: transparent;
`;

const ServiceSelect = () => {
  const {
    selectedClinic,
    selectedServiceType,
    listServices,
    loadingListServices,
  } = useSelector((state) => state.homecare);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHealthCare(selectedClinic, selectedServiceType));
  }, [dispatch, selectedClinic, selectedServiceType]);

  const [selectedService, setSelectedService] = useState(null);

  const options = listServices.map((e) => ({
    label: `${e.Name} - ${e.Price}đ`,
    value: e.Id,
  }));

  return (
    <div className="w-100">
      <StyledSelect
        isDisabled={loadingListServices}
        options={options}
        className="basic-single mb-2"
        classNamePrefix="select"
        name="color"
        placeholder="Chọn chuyên khoa"
        isSearchable
        onChange={(selected) => setSelectedService(selected)}
      />
      {loadingListServices ? (
        <Spinner color="info" />
      ) : (
        <ButtonFragment
          onGoNext={() => dispatch(selectService(selectedService))}
          onGoBack={() => dispatch(selectServiceType(null))}
          data={selectedService}
        />
      )}
    </div>
  );
};

export default ServiceSelect;
