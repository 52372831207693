/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Select from 'react-select';

import { useDispatch, useSelector } from 'react-redux';
import { getService } from 'immunization-booking/actions/immunization-booking';

import ButtonFragment from './ButtonFragment';

const StyledSelect = styled(Select)`
  max-width: 350px;
  width: 100%;
  background-color: transparent;
`;

const DiseaseSelect = (props) => {
  const {
    injectionObject,
    serviceType,
    onChange,
    onBack,
  } = props;

  const { selectingServiceFormId } = useSelector((s) => s.global);

  const [selectingSyringe, setSelectingSyringe] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (serviceType && selectingServiceFormId && injectionObject) {
      dispatch(getService(serviceType.value, selectingServiceFormId, injectionObject.value));
    }
  }, [dispatch, serviceType, selectingServiceFormId, injectionObject]);

  const {
    serviceList,
    getServiceLoading,
  } = useSelector((state) => state.immunizationBooking);


  const syringeSelect = useMemo(() => (
    <StyledSelect
      isSearchable
      isLoading={getServiceLoading}
      className="basic-single mb-2"
      classNamePrefix="select"
      name="color"
      placeholder="Bệnh cần tiêm"
      noOptionsMessage={() => (
        <span>Không tìm thấy bệnh cần tiêm cho đối tượng này</span>
      )}
      options={serviceList
        .map((e) => ({
          key: e.id,
          value: e.id,
          label: e.name,
        }))}
      onChange={(d) => {
        if (d) {
          if (d.value !== selectingSyringe?.value) {
            setSelectingSyringe(d);
            onChange(d);
          }
        } else {
          setSelectingSyringe(null);
          onChange(null);
        }
      }}
    />
  ), [
    onChange,
    selectingSyringe,
    serviceList,
    getServiceLoading,
  ]);

  return (
    <div>
      {syringeSelect}
      <ButtonFragment
        disabled={!selectingSyringe}
        onGoNext={() => onChange(selectingSyringe)}
        onGoBack={onBack}
      />
    </div>
  );
};

DiseaseSelect.propTypes = {
  injectionObject: PropTypes.shape({
    value: PropTypes.string,
  }),
  serviceType: PropTypes.shape({
    value: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onBack: PropTypes.func,
};

DiseaseSelect.defaultProps = {
  injectionObject: {},
  serviceType: {},
  onChange: () => {},
  onBack: () => {},
};

export default DiseaseSelect;
