import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import DatePicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const MONTHS = [
  'Tháng 1',
  'Tháng 2',
  'Tháng 3',
  'Tháng 4',
  'Tháng 5',
  'Tháng 6',
  'Tháng 7',
  'Tháng 8',
  'Tháng 9',
  'Tháng 10',
  'Tháng 11',
  'Tháng 12',
];
const WEEKDAYS_LONG = [
  'Chủ nhật',
  'Thứ hai',
  'Thứ ba',
  'Thứ tư',
  'Thứ năm',
  'Thứ sáu',
  'Thứ bảy',
];
const WEEKDAYS_SHORT = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'];

const DatePickerVietnam = (props) => {
  const {
    month,
    selectedDays,
    disabledDays,
    onDayClick,
    onMonthChange,
  } = props;

  return (
    <DatePicker
      firstDayOfWeek={1}
      initialMonth={moment(month).startOf('month').toDate()}
      locale="vi_VN"
      months={MONTHS}
      weekdaysLong={WEEKDAYS_LONG}
      weekdaysShort={WEEKDAYS_SHORT}
      selectedDays={selectedDays}
      disabledDays={disabledDays}
      onDayClick={onDayClick}
      onMonthChange={onMonthChange}
    />
  );
};

DatePickerVietnam.propTypes = {
  month: PropTypes.instanceOf(Date),
  selectedDays: PropTypes.shape({}),
  disabledDays: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    PropTypes.func,
  ]),
  onDayClick: PropTypes.func,
  onMonthChange: PropTypes.func,
};

DatePickerVietnam.defaultProps = {
  month: new Date(),
  selectedDays: null,
  disabledDays: [],
  onDayClick: () => {},
  onMonthChange: () => {},
};

export default DatePickerVietnam;
