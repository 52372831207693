import types from '../actions/types';

const initialState = {
  currentStep: 0,
  hospitalList: [],
  getHospitalLoading: false,
  unitTypeList: [],
  getUnitTypeLoading: false,
  scheduleList: [],
  getScheduleLoading: false,
  intervalList: [],
  getIntervalsLoading: false,
  registerData: null,
  registerLoading: false,
  historyList: [],
  getHistoryLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.EB_SET_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case types.EB_GET_HOSPITAL_REQUEST:
      return {
        ...state,
        getHospitalLoading: true,
      };
    case types.EB_GET_HOSPITAL_SUCCESS:
      return {
        ...state,
        getHospitalLoading: false,
        hospitalList: action.payload,
      };
    case types.EB_GET_HOSPITAL_FAILURE:
      return {
        ...state,
        getHospitalLoading: false,
      };
    case types.EB_GET_INJECTION_REQUEST:
      return {
        ...state,
        getInfectionObjectLoadings: true,
      };
    case types.EB_GET_INJECTION_SUCCESS:
      return {
        ...state,
        getInfectionObjectLoadings: false,
        injectionObjectList: action.payload,
      };
    case types.EB_GET_INJECTION_FAILURE:
      return {
        ...state,
        getInfectionObjectLoadings: false,
      };
    case types.EB_GET_UNIT_TYPE_REQUEST:
      return {
        ...state,
        getUnitTypeLoading: true,
      };
    case types.EB_GET_UNIT_TYPE_SUCCESS:
      return {
        ...state,
        unitTypeList: action.payload,
        getUnitTypeLoading: false,
      };
    case types.EB_GET_UNIT_TYPE_FAILURE:
      return {
        ...state,
        getUnitTypeLoading: false,
      };
    case types.EB_GET_SCHEDULE_REQUEST:
      return {
        ...state,
        getScheduleLoading: true,
      };
    case types.EB_GET_SCHEDULE_SUCCESS:
      return {
        ...state,
        getScheduleLoading: false,
        scheduleList: action.payload,
      };
    case types.EB_GET_SCHEDULE_FAILURE:
      return {
        ...state,
        getScheduleLoading: false,
      };
    case types.EB_REGISTER_REQUEST:
      return {
        ...state,
        registerLoading: true,
      };
    case types.EB_REGISTER_SUCCESS:
      return {
        ...state,
        registerLoading: false,
        registerData: action.payload,
      };
    case types.EB_REGISTER_FAILURE:
      return {
        ...state,
        registerLoading: false,
      };
    case types.EB_GET_APPOINTMENT_REQUEST:
      return {
        ...state,
        getAppointmentLoading: true,
      };
    case types.EB_GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        getAppointmentLoading: false,
        appointmentData: action.payload,
      };
    case types.EB_GET_APPOINTMENT_FAILURE:
      return {
        ...state,
        getAppointmentLoading: false,
      };
    case types.EB_GET_INTERVALS_REQUEST:
      return {
        ...state,
        getIntervalsLoading: true,
      };
    case types.EB_GET_INTERVALS_SUCCESS:
      return {
        ...state,
        getIntervalsLoading: false,
        intervalList: action.payload,
      };
    case types.EB_GET_INTERVALS_FAILURE:
      return {
        ...state,
        getIntervalsLoading: false,
      };
    case types.EB_GET_HISTORY_REQUEST:
      return {
        ...state,
        getHistoryLoading: true,
      };
    case types.EB_GET_HISTORY_SUCCESS:
      return {
        ...state,
        getHistoryLoading: false,
        historyList: action.payload,
      };
    case types.EB_GET_HISTORY_FAILURE:
      return {
        ...state,
        getHistoryLoading: false,
      };
    case types.EB_UPDATE_APPOINTMENT_REQUEST:
      return {
        ...state,
        updateAppointmentLoading: true,
      };
    case types.EB_UPDATE_APPOINTMENT_SUCCESS:
    case types.EB_UPDATE_APPOINTMENT_FAILURE:
      return {
        ...state,
        updateAppointmentLoading: false,
      };
    case types.EB_RESET:
      return {
        ...state,
        currentStep: 0,
        registerData: null,
        appointmentData: null,
      };
    default:
      return state;
  }
}
