export const TOKEN = 'TOKEN';
export const EXPIRED_TIME = 'EXPIRED_TIME';
export const MCB_FORM = 1;
export const TELE_FORM = 2;
export const HOMECARE_FORM = 3;
export const IMMUNIZATION_FORM = 5;
export const EXAMINATION_FORM = 6;

export const APPOINTMENT_PER_PAGE = 12;

export const SERVICES = {
  IMMUNIZATION: 0,
  COVID: 1,
};

export const SERVICE_FORM = {
  IMMUNIZATION: 'TIÊM CHỦNG',
  EXAMINATION: 'XÉT NGHIỆM',
};

export const ServicesOptions = [
  { key: SERVICES.IMMUNIZATION, value: SERVICES.IMMUNIZATION, label: 'Lịch sử tiêm chủng' },
  { key: SERVICES.COVID, value: SERVICES.COVID, label: 'Lịch sử xét nghiệm' },
];
