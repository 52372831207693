import React, { useState } from 'react';
import {
  Button,
  Form,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Spinner,
  Container,
  Label,
  FormFeedback,
  FormGroup,
  Col,
} from 'reactstrap';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import 'react-day-picker/lib/style.css';

import { useSelector } from 'react-redux';
import auth from '../utils/auth';

const LoginPageV2 = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  const login = (e) => {
    e.preventDefault();
    setLoginFailed(false);
    auth
      .login(username, password, remember)
      .then((res) => {
        let url = '';
        const current = window.location !== window.parent.location ? document.referrer : document.location.href;
        if (current.indexOf('demo') > -1) {
          url = `https://demo3.hcdc.vn/login/token/${res.access_token}`;
        } else if (current.indexOf('gov') > -1) {
          url = `https://hcdc.gov.vn/login/token/${res.access_token}`;
        } else {
          url = `https://hcdc.vn/login/token/${res.access_token}`;
        }
        window.parent.location = url;
      })
      .catch(() => {
        setLoginFailed(true);
      });
  };

  const { loginLoading } = useSelector((state) => state.auth);
  return (
    <Container className="login-page">
      <Form onSubmit={login} className="mt-3">
        <Col xs="12" className="text-center">
          <h4>Đăng nhập</h4>
        </Col>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <FaUserAlt />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            invalid={loginFailed}
            placeholder="Tên đăng nhập"
            disabled={loginLoading}
            onChange={(e) => {
              setUsername(e.target.value);
              setLoginFailed(false);
            }}
          />
        </InputGroup>
        <InputGroup className="mt-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <FaLock />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="password"
            invalid={loginFailed}
            placeholder="Mật khẩu"
            disabled={loginLoading}
            onChange={(e) => {
              setPassword(e.target.value);
              setLoginFailed(false);
            }}
          />
          {loginFailed ? (
            <>
              <FormFeedback>
                Tên đăng nhập hoặc mật khẩu chưa chính xác
              </FormFeedback>
            </>
          ) : (
            <></>
          )}
        </InputGroup>
        <FormGroup check inline className="mt-3">
          <Label check>
            <Input
              type="checkbox"
              className="mr-2"
              onChange={(e) => setRemember(e.target.checked)}
              disabled={loginLoading}
            />
            Lưu mật khẩu
          </Label>
        </FormGroup>
        <Button color="info" className="mt-3 w-100" disabled={loginFailed}>
          {loginLoading ? <Spinner color="light" /> : <span>Đăng nhập</span>}
        </Button>
      </Form>
    </Container>
  );
};

export default LoginPageV2;
