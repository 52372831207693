import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Spinner } from 'reactstrap';

import { useLocation } from 'react-router-dom';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  setToken,
  getProfiles,
} from 'app/actions/auth';

import ImmunizationHistory from '../components/history/ImmunizationBookingHistory';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const spinner = (
  <Wrapper>
    <Spinner color="info" />
  </Wrapper>
);

const useQuery = () => new URLSearchParams(useLocation().search);

const ImmunizationHistoryPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery(location);

  const tokenParam = query.get('s');

  useEffect(() => {
    dispatch(setToken(tokenParam));
    dispatch(getProfiles());
  }, [dispatch, tokenParam]);

  const { token } = useSelector((state) => state.auth);
  return (
    <div>
      {token ? <ImmunizationHistory /> : spinner}
    </div>
  );
};

export default ImmunizationHistoryPage;
