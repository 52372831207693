import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Select from 'react-select';

import { useSelector, useDispatch } from 'react-redux';
import { getHospital } from 'covid-examination-booking/actions/examination-booking';

import ButtonFragment from './ButtonFragment';
import HospitalModal from './HospitalModal';

const StyledSelect = styled(Select)`
  max-width: 350px;
  width: 100%;
  background-color: transparent;
`;

const HospitalSelect = (props) => {
  const {
    serviceId,
    onChange,
    onBack,
  } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHospital(serviceId));
  }, [
    serviceId,
    dispatch,
  ]);


  const { hospitalList, getHospitalLoading } = useSelector((state) => state.examinationBooking);
  const [selectingHospital, setSelectingHospital] = useState(null);
  useEffect(() => {
    if (hospitalList.length === 1) {
      setSelectingHospital(hospitalList[0]);
    }
    // eslint-disable-next-line
  }, [hospitalList]);

  const [modal, setModal] = useState(false);

  const hospitalSelect = useMemo(() => (
    <StyledSelect
      isDisabled={getHospitalLoading}
      isLoading={getHospitalLoading}
      value={selectingHospital?.id}
      className="basic-single mb-2"
      classNamePrefix="select"
      name="color"
      placeholder={selectingHospital?.name ?? 'Cơ sở xét nghiệm'}
      onMenuOpen={() => setModal(true)}
    />
  ), [
    getHospitalLoading,
    selectingHospital,
  ]);

  return (
    <div>
      {hospitalSelect}
      <ButtonFragment
        disabled={!selectingHospital}
        onGoNext={() => onChange(selectingHospital)}
        onGoBack={onBack}
      />

      <HospitalModal
        key={modal ? 'OpenHospitalModal' : 'CloseHospitalModal'}
        open={modal}
        onClose={() => setModal(false)}
        onSubmit={(d) => {
          setModal(false);
          setSelectingHospital(selectingHospital);
          onChange(d);
        }}
      />
    </div>
  );
};

HospitalSelect.propTypes = {
  serviceId: PropTypes.string,
  onChange: PropTypes.func,
  onBack: PropTypes.func,
};

HospitalSelect.defaultProps = {
  serviceId: 'f2490f62-1d28-4edd-362a-08d8a7232229',
  onChange: () => {},
  onBack: () => {},
};


export default HospitalSelect;
