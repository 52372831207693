/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Select, { components } from 'react-select';
import { Button, Row, Spinner } from 'reactstrap';

import { FaEye } from 'react-icons/fa';

import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import { toggleAddProfileModal, toggleSidebar } from 'app/actions/global';

import AddPatientModal from 'app/components/AddPatientModal';
import ButtonFragment from './ButtonFragment';
import PatientModal from './PatientModal';
import CovidModal from './CovidModal';

const relationOptions = [
  'Cha',
  'Mẹ',
  'Vợ',
  'Chồng',
  'Ông',
  'Bà',
  'Cô',
  'Dì',
  'Chú',
  'Bác',
  'Con',
  'Cháu',
  'Anh',
  'Chị',
  'Em',
  'Bạn bè',
  'Khác',
];

const StyledSelect = styled(Select)`
  max-width: 350px;
  width: 100%;
  background-color: transparent;
`;

const StyledSelect2 = styled(Select)`
  max-width: 150px;
  width: 100%;
  background-color: transparent;
  margin-left: 15px !important;
`;

const { Option } = components;

const StyledFaEye = styled(FaEye)`
  margin-left: 10px;
`;

const StyledButton = styled(Button)`
  padding: 0 !important;
  color: #808080 !important;
`;

const RelativeSelect = (props) => {
  const {
    isLoading,
    initialPatient,
    initialRelative,
    initialRelation,
    onRelativeChange,
    onRelationChange,
    onBack,
  } = props;

  const { sidebarCollapsed } = useSelector((state) => state.global);
  const { userInfo, profileList } = useSelector((state) => state.auth);

  const [options, setOptions] = useState([]);
  const [patientModal, setPatientModal] = useState({
    isOpen: false,
    data: null,
  });

  useEffect(() => {
    if (userInfo) {
      setOptions([
        { value: 0, label: 'Tạo mới' },
        ...profileList
          .filter((p) => p.id !== (initialPatient ? initialPatient.id : ''))
          .map((p) => ({
            label: p.fullname,
            value: p.id,
            key: p.id,
            subject: p,
          })),
      ]);
    }
  }, [userInfo, profileList, initialPatient]);

  const dispatch = useDispatch();
  const handleClick = (e, subject) => {
    e.stopPropagation();
    setPatientModal({
      isOpen: true,
      data: subject,
    });
  };

  const IconOption = (iProps) => {
    const { data } = iProps;
    return (
      <Option {...iProps}>
        <Row className="d-flex justify-content-between px-3">
          <span>{data.label}</span>
          {data.subject && (
            <span className="border-left">
              <StyledButton
                onClick={(e) => handleClick(e, data.subject)}
                size="sm"
                color="link"
              >
                <StyledFaEye />
              </StyledButton>
            </span>
          )}
        </Row>
      </Option>
    );
  };

  const [covidModal, setCovidModal] = useState(false);
  const { addToast } = useToasts();
  const [selectingRelative, setSelectingRelative] = useState(null);
  const selectRef = useRef(null);
  const handleSubmit = (d) => {
    const { haveCovid, selectingNations } = d;

    if (haveCovid.length > 0) {
      addToast('Không đủ điều kiện đi cùng', { appearance: 'error' });
      onRelativeChange(null);
    } else {
      onRelativeChange({
        ...selectingRelative,
        QuocGiaDaDiQua: selectingNations || [].map((n) => n.label),
      });
    }
    setCovidModal(false);
  };

  useEffect(() => {
    if (selectingRelative) {
      const { fullname, phoneNumber } = selectingRelative;
      const allowToGoNextStep = Boolean(fullname && phoneNumber);
      if (!allowToGoNextStep) {
        setPatientModal({
          isOpen: true,
          data: selectingRelative,
        });
      } else {
        setCovidModal(true);
      }
    }
  }, [selectingRelative]);

  return (
    <div>
      <div className="w-100 d-flex">
        <StyledSelect
          name="color"
          isSearchable
          isClearable
          className="basic-single mb-2"
          classNamePrefix="select"
          placeholder="Người đi cùng"
          components={{ Option: IconOption }}
          options={options}
          isDisabled={isLoading}
          controlShouldRenderValue={Boolean(initialRelative?.id)}
          defaultValue={initialRelative?.id ?? 0}
          noOptionsMessage={() => <span>Không tìm thấy</span>}
          onChange={(d) => {
            if (d) {
              // create new subject
              if (d?.value === 0) {
                dispatch(toggleAddProfileModal());
                if (!sidebarCollapsed) {
                  dispatch(toggleSidebar(!sidebarCollapsed));
                }
              } else {
                // setSelectingRelative(profileList.find((p) => p.id === d.value));
                onRelativeChange(profileList.find((p) => p.id === d.value));
                selectRef.current.focus();
                onRelationChange(null);
              }
            } else {
              onRelativeChange(null);
              onRelationChange(null);
            }
          }}
        />
        <StyledSelect2
          ref={selectRef}
          openMenuOnFocus
          value={initialRelation}
          isSearchable
          isClearable
          classNamePrefix="select"
          name="color"
          placeholder="Quan hệ"
          options={relationOptions.map((r) => ({
            key: r,
            value: r,
            label: r,
          }))}
          onChange={onRelationChange}
        />
      </div>

      {isLoading ? (
        <Spinner color="info" />
      ) : (
        <ButtonFragment
          disabled={initialRelative && !initialRelation}
          onGoBack={onBack}
        />
      )}

      <AddPatientModal isRelative />

      <CovidModal
        open={covidModal}
        onClose={() => setCovidModal(false)}
        onSubmit={handleSubmit}
      />

      <PatientModal
        isRelative
        open={patientModal.isOpen}
        initialData={patientModal.data}
        onClose={(p) => {
          setPatientModal({ isOpen: false, data: null });
          if (p.id) {
            selectRef.current.focus();
            setCovidModal(true);
          } else {
            setSelectingRelative(null);
          }
        }}
      />
    </div>
  );
};

RelativeSelect.propTypes = {
  isLoading: PropTypes.bool,
  initialRelative: PropTypes.shape({}),
  initialRelation: PropTypes.shape({}),
  initialPatient: PropTypes.shape({
    id: PropTypes.string,
  }),
  onRelativeChange: PropTypes.func,
  onRelationChange: PropTypes.func,
  onBack: PropTypes.func,
};

RelativeSelect.defaultProps = {
  isLoading: false,
  initialPatient: {},
  initialRelative: {},
  initialRelation: {},
  onRelativeChange: () => {},
  onRelationChange: () => {},
  onBack: () => {},
};

export default RelativeSelect;
